import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import useBadgeValues from "hooks/useBadgeValues";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import {
  Article,
  Block,
  CheckCircle,
  ConnectWithoutContact,
  FolderShared,
  PersonAdd,
  Public,
  QuestionAnswer,
  ThumbDown,
} from "@mui/icons-material";
import {
  initialApplicantPreHireStatusSubFilterCards,
  initialApplicantStatusScreenedSubFilterCards,
} from "layouts/pages/applicants/components/ApplicantStatusSubFilters";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

const ApplicantsFilterCards = ({
  setFilters,
  setPage,
  filters,
  parent = "applicants",
  initialCards = null,
  doReplace = false, // Don't combine filters
  useElemMatch,
  baseFilters,
}) => {
  const { setCurrentApplicant, company } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);

  const mainFieldName =
    company?.companyType === "Venue" || parent === "Customers"
      ? "applicantStatus"
      : "jobs.applicantStatus";

  const initialFilterCards = initialCards?.length
    ? [...initialCards]
    : [
        {
          icon: <PersonAdd {...CommonIconProps} color="secondary" fontSize="large" />,
          title: "New",
          color: "secondary",
          tooltip: "New Applicants",
          number: 0,
          default: true,
          ...(parent === "CompanyJobs"
            ? {
                filters: {
                  [mainFieldName]: "New",
                  employmentStatus: "Inactive;Terminated",
                },
              }
            : {
                filterField: mainFieldName,
                filterValue: "New",
              }),
        },
        {
          icon: <ConnectWithoutContact {...CommonIconProps} color="warning" fontSize="large" />,
          title: "ATC",
          color: "warning",
          tooltip: "Applicants Attempted to Contact",
          number: 0,
          ...(parent === "CompanyJobs"
            ? {
                filters: {
                  [mainFieldName]: "ATC",
                  employmentStatus: "Inactive;Terminated",
                },
              }
            : {
                filterField: mainFieldName,
                filterValue: "ATC",
              }),
        },
        {
          icon: <QuestionAnswer {...CommonIconProps} color="info" fontSize="large" />,
          title: "Screened",
          color: "info",
          tooltip: "Screened Applicants",
          number: 0,
          ...(parent === "CompanyJobs"
            ? {
                filters: {
                  [mainFieldName]: "Screened",
                  employmentStatus: "Inactive;Terminated",
                },
              }
            : {
                filterField: mainFieldName,
                filterValue: "Screened",
              }),
        },
        {
          icon: <FolderShared {...CommonIconProps} color="success" fontSize="large" />,
          title: "Pre-Hire",
          color: "success",
          tooltip: "Pre-Hire Applicants",
          number: 0,
          ...(parent === "CompanyJobs"
            ? {
                filters: {
                  [mainFieldName]: "Pre-Hire",
                  employmentStatus: "Inactive;Terminated",
                },
              }
            : {
                filterField: mainFieldName,
                filterValue: "Pre-Hire",
              }),
        },
        {
          icon: <ThumbDown {...CommonIconProps} color="error" fontSize="large" />,
          title: "Declined",
          color: "primary",
          tooltip: "Declined Applicants",
          number: 0,
          ...(parent === "CompanyJobs"
            ? {
                filters: {
                  [mainFieldName]: "Declined",
                  employmentStatus: "Inactive;Terminated",
                },
              }
            : {
                filterField: mainFieldName,
                filterValue: "Declined",
              }),
        },
        {
          icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
          title: "All",
          color: "info",
          tooltip: "All Applicants",
          number: 0,
          ...(parent === "CompanyJobs"
            ? {
                filters: {
                  [mainFieldName]: "New;ATC;Screened;Pre-Hire;Declined",
                  employmentStatus: "Inactive;Terminated",
                },
              }
            : {
                filterField: mainFieldName,
                filterValue: "New;ATC;Screened;Pre-Hire;Declined",
              }),
        },
        ...(parent === "CompanyJobs"
          ? [
              {
                color: "success",
                icon: <CheckCircle fontSize="large" color="success" />,
                title: "Assignment",
                tooltip: "",
                number: 0,
                filterField: "jobs.status",
                filterValue: "On Assignment",
              },
            ]
          : []),
        {
          icon: <Article {...CommonIconProps} fontSize="large" />,
          color: "secondary",
          title: "Licenses",
          tooltip: "Licenses",
          number: 0,
          filterField: "licenses.licenseType.value",
          filterValue: "gamingLicense;securityLicense;tabcLicense",
        },
        {
          icon: <Block {...CommonIconProps} color="error" fontSize="large" />,
          title: "Do Not Use",
          color: "error",
          tooltip: "Do Not Use",
          number: 0,
          filterField: "isDnu",
          filterValue: "Yes",
        },
      ];

  const { cards } = useBadgeValues({
    initialCards: initialFilterCards,
    // If doReplace is on, the cards don't combine filters
    // So instead of counting using the current filters, use the base filters (shared between all cards)
    filters: doReplace && baseFilters ? baseFilters : { ...(baseFilters ?? {}), ...filters },
    entity: "applicants",
    refreshBadges,
    useElemMatch,
  });

  const navigate = useNavigate();

  const setApplicantFilters = (filter) => {
    if (parent === "applicants") {
      navigate("/applicants");
    }

    const newFilters = { ...filters };
    delete newFilters._id;
    if (!filters?.tags && ["Venues", "Jobs", "Customers", "CompanyJobs"].includes(parent)) {
      delete newFilters?.["licenses.licenseType.value"];
      delete newFilters?.status;
    }

    if (filter?.applicantStatus !== "Screened") {
      initialApplicantStatusScreenedSubFilterCards.forEach((f) => {
        if (f.filterField in newFilters) {
          delete newFilters[f.filterField];
        }
      });
    }
    if (filter?.applicantStatus !== "Pre-Hire") {
      initialApplicantPreHireStatusSubFilterCards.forEach((f) => {
        if (f.filterField in newFilters) {
          delete newFilters[f.filterField];
        }
      });
    }

    setCurrentApplicant(null);
    if (doReplace && !filters?.tags) setFilters({ ...filter });
    else
      setFilters({
        ...newFilters,
        ...(filters?.tags ? { tags: filters.tags } : filter),
      });

    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  const venueFilter = filters?.["venues.venueSlug"];
  const jobsFilter = filters?.["jobs.jobSlug"];
  useEffect(() => {
    setRefreshBadges((prev) => !prev);
  }, [venueFilter, jobsFilter]);

  const initialCardIndex = initialFilterCards.findIndex((card) => card.default);

  return (
    <span>
      {initialFilterCards?.length ? (
        <FilterCardBar
          color="error"
          cards={cards}
          parent="Applicants"
          bgColor="primary"
          filters={filters}
          setExistingFilters={setFilters}
          setFilters={setApplicantFilters}
          setPage={setPage}
          initialIndex={initialCardIndex}
        />
      ) : null}
    </span>
  );
};

export default ApplicantsFilterCards;

ApplicantsFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
  initialCards: [],
  filters: {},
  parent: "",
};

// Typechecking props for the TableAction
ApplicantsFilterCards.propTypes = {
  initialCards: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node || PropTypes.string, // Change this from `string` to `node` to allow React elements
      title: PropTypes.string.isRequired,
      number: PropTypes.number,
      color: PropTypes.string,
      tooltip: PropTypes.string,
      filterField: PropTypes.string,
      filterValue: PropTypes.string,
    })
  ),
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  filters: PropTypes.object, // Assuming `filters` is an object, add this if needed
  parent: PropTypes.string,
};
