import { useState, useMemo, useEffect } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import { makeStyles } from "@mui/styles";
import { Stack, Button, Grid, useMediaQuery } from "@mui/material";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchInterviewEvents from "layouts/pages/events/actions/fetchInterviewEvents";
import fetchInterviewsForDashboard from "layouts/pages/events/actions/fetchInterviewsForDashboard";
import Searchbar from "components/Searchbar";
import useSort from "utils/useSort";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import EventDate from "components/EventDate";
import InfiniteScrollDataList from "components/InfiniteScrollDataList";
import DataList from "components/DataList";
import useSessionAuth from "hooks/useSessionAuth";
import fetchCustomers from "api/companies/fetchCustomers";
import MyInterviewsRenderRow from "./MyInterviewsRenderRow";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  tableRowBordered: {
    "&:last-child td, &:last-child th": { border: 0 },
    cursor: "pointer",
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
});

const PAGE_SIZE = 10;

const MyInterviews = ({
  fetchAll,
  setActionSelected = "info",
  title = "Title",
  filters,
  setFilters,
  isMobile = false,
}) => {
  const {
    venues,
    setVenues,
    company,
    setCompany,
    companyType,
    setCompanyType,
    setCurrentEvent,
    currentLoggedUser,
    companies,
    userType,
  } = useAppContextController();

  const [myCustomers, setMyCustomers] = useState([]);
  const [userApplicant, setUserApplicant] = useState(null);
  const [userIsLoading, setUserIsLoading] = useState(true);

  const [isMobileWidth, setIsMobileWidth] = useState(false);
  const isLaptop = useMediaQuery("(min-width: 1281px)");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileWidth(true);
      } else {
        setIsMobileWidth(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    const setUserCustomers = async (appId) => {
      const app = await fetchSingleApplicant({ applicantId: appId });
      setUserApplicant(app.data);
      const customerList = app?.data?.companies
        ?.filter((item) => item.companySlug)
        ?.map((item) => item.companySlug);
      if (customerList?.length) {
        const tempList =
          companies?.data?.filter((item) => {
            return currentLoggedUser.companies.includes(item.slug);
          }) || [];
        if (userType === "User") setMyCustomers(tempList);
      }
    };

    if (currentLoggedUser) {
      if (userType === "User") {
        try {
          setUserCustomers(currentLoggedUser.applicantId);
          setUserIsLoading(false);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      } else if (userType && userType !== "User") {
        setMyCustomers(
          currentLoggedUser?.companies?.length ? [...currentLoggedUser.companies] : []
        );
        setUserIsLoading(false);
      }
    }
  }, [currentLoggedUser, companies, userType]);

  const navigate = useNavigate();
  const addNew = () => {
    setCurrentEvent({});
    navigate("/events/create");
  };
  const viewAll = () => navigate("/events");

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const [page, setPage] = useState(1);
  const { order, orderBy, toggleSort } = useSort("date", "asc");

  const options = useMemo(() => {
    return fetchAll ? { fetchAll } : { limit: 10, order, orderBy };
  }, [fetchAll, order, orderBy]);
  const allOptions = useMemo(() => {
    return {
      ...options,
      filters: {
        companySlug: myCustomers?.length ? myCustomers.join(";") : [],
      },
      page,
      order: "desc",
      orderBy: "eventDate",
    };
  }, [myCustomers, options, page]);

  const [applicant, setApplicant] = useState(null);
  const [customerData, setCustomerData] = useState({});

  const {
    data: interviews,
    isLoading,
    isFetching,
    fetchNextPage,
    refetch: refetchMyEvents,
  } = useInfiniteQuery(
    ["myinterview", allOptions],
    () => fetchInterviewsForDashboard(allOptions),
    {
      enabled: !!currentLoggedUser?.companies?.length,
      onSuccess: async (data) => {
        // Assuming that data is an array of interviews
        const customers = {};

        try {
          // Get all company slugs
          let companySlugs = data?.data?.map((comp) => comp.companySlug);
          // Remove duplicates
          companySlugs = [...new Set(companySlugs)];

          const results = await fetchCustomers({
            filters: { slug: companySlugs.join(";") },
            page: 1,
            limit: 0,
          });

          (results?.data ?? []).forEach((cstmr) => {
            customers[cstmr.slug] = cstmr;
          });
          setCustomerData(customers);
        } catch (error) {
          if (String(error).includes("401") || error?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      },
    },
    { keepPreviousData: true }
  );

  function showSmallDate(date) {
    const opts = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      seconds: "omit",
      hourCycle: "h12", // or 'h23' for 24-hour format
    };
    return date.toLocaleString("en-US", opts).replace(",", ""); // remove the comma separator
  }

  const columns = useMemo(
    () => [
      {
        title: "Logo",
        field: "companySlug",
        width: 40,
        customCell: (slug, field, row) => {
          const cust = customerData[slug] ? customerData[slug] : {};
          return (
            <>
              <CompanyLogoSquare company={cust} defaultCompany={company} />;
            </>
          );
        },
      },
      {
        title: "Company",
        field: "companyName",
        width: 100,
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      { title: "Event", field: "eventName", width: 150 },
      {
        title: "Event Date/Report Time",
        field: "eventDate",
        width: 100,

        customCell: (date, field, row) =>
          isMobileWidth || isMobile ? (
            <span>{showSmallDate(new Date(date))}</span>
          ) : (
            <EventDate date={date} reportTimeTBD={row?.reportTimeTBD} />
          ),
      },

      //   { title: "Status", field: "status" },
    ],
    [company, customerData, isMobile, isMobileWidth]
  );

  const renderRow = (row) => {
    return <MyInterviewsRenderRow row={row} customerData={customerData} />;
  };

  const searchBarRowClick = (item) => {
    setCurrentEvent(item);
    navigate(`/events/${item.eventUrl}`);
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2}>
        <Grid item sm={6.5}>
          <Searchbar
            fetch={fetchInterviewEvents}
            fetchAll={fetchAll}
            placeholder="Search Interview"
            columns={columns}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy="title,companySlug,companyName"
            filterBy="companySlug"
            onRowClick={(item) => searchBarRowClick(item)}
          />
        </Grid>
        <Grid item sm={5.5}>
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  const fullData = useMemo(
    () =>
      interviews?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [interviews?.pages]
  );

  return (
    <DataList
      renderRow={renderRow}
      infiniteData={interviews?.pages?.[interviews?.pages?.length - 1]}
      icon="event-seat"
      iconColor="success"
      title="My Interviews"
      isLoading={isLoading}
      greybar
      divider
      tableHeight={420}
      height={500}
      header={header}
      scrollY
      page={page}
      setPage={setPage}
      data={fullData}
      isFetching={isFetching}
      loadMore={fetchNextPage}
    />
  );
};

export default MyInterviews;
