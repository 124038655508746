import { Close } from "@mui/icons-material";
import { Button, Checkbox, Grid, IconButton, Stack, Typography } from "@mui/material";
import DataList from "components/DataList";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import useDropdowns from "hooks/useDropdowns";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";

const AIQuestionsTemplateModal = ({ open, onClose, onSubmit }) => {
  const { currentLoggedUser } = useAppContextController();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const { dropdowns: questions } = useDropdowns({ entity: "aiPredefinedQuestions" });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      setSelectedQuestions([]);
    }
  }, [open]);

  const questionCheckboxStateMapped = useMemo(() => {
    if (!questions || !selectedQuestions) return {};
    const result = {};
    for (let index = 0; index < selectedQuestions.length; index += 1) {
      const sq = selectedQuestions[index];
      const foundQuestionIndex = questions.findIndex((q) => q === sq);
      if (foundQuestionIndex >= 0) result[foundQuestionIndex] = true;
    }
    return result;
  }, [questions, selectedQuestions]);

  const onChangeCheckbox = (event, value) => {
    if (event.target.checked) setSelectedQuestions((current) => [...current, value]);
    else setSelectedQuestions((current) => current.filter((e) => e !== value));
  };

  const header = (
    <Grid container flexDirection="row" justifyContent="space-between">
      <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
        Add predefined questions
      </MDTypography>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <Close fontSize="medium" color="dark" />
      </IconButton>
    </Grid>
  );

  const modalBody = (
    <div>
      <DataList
        renderRow={(row, idx) => (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Checkbox
              checked={!!questionCheckboxStateMapped[idx]}
              onChange={(e) => onChangeCheckbox(e, row)}
            />
            <Typography variant="text1">{row}</Typography>
          </Stack>
        )}
        data={questions ?? []}
        greybar
        divider
        scrollY
        boxPadding={0}
        cardVariant="outlined"
        headerStyle={() => ({ display: "none" })}
        width="100%"
        tableHeight={250}
        height={251}
      />
    </div>
  );

  const buttons = (
    <Stack direction="row" spacing={2} px={2}>
      <Button
        variant="contained"
        style={{ color: "white" }}
        sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
        disabled={!selectedQuestions?.length}
        onClick={() => {
          onSubmit(selectedQuestions);
          onClose();
        }}
      >
        Add
      </Button>
    </Stack>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={onClose}
        body={modalBody}
        header={header}
        buttons={buttons}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "1000px !important",
          overflow: "hidden !important",
          minHeight: "450px",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default AIQuestionsTemplateModal;
