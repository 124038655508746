import { baseAxios } from "config";

export const updatePartnerCustomFields = async (id, data) => {
  try {
    const res = await baseAxios.put(`/partners/${id}/custom-fields`, data);
    return res.data;
  } catch (err) {
    console.error(`Error fetching applicant custom field:`, err);
    throw new Error(err.toString());
  }
};
