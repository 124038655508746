import { baseAxios } from "config";

const addCallMe = async ({ jobSlug, applicantId }) => {
  try {
    const res = await baseAxios.post(`/outside-protected/applicants/${applicantId}/add/callme`, {
      jobSlug,
    });
    return res.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};

export default addCallMe;
