/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Autocomplete,
  Grid,
  Card,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";
import {
  checkCustomFieldUsage,
  createCustomField,
  deleteCustomField,
  getCustomFieldById,
  getCustomFields,
  reorderCustomFields,
  updateCustomField,
} from "api/customFields";
import { useAppContextController } from "context/AppContext";
import ConfirmationModal from "components/ConfirmationModal";
import SortableCustomField from "../SortableCustomField";
import CustomFieldModal from "../CustomFieldModal";
import { defaultValues } from "./fixtures";

const CustomFieldsTab = () => {
  const { companyType } = useAppContextController();

  const features =
    companyType === "Venue"
      ? [
          "Applicants & Employees",
          "Jobs",
          "Venues",
          "Events",
          "Primary Company",
          "Partners",
          "Users",
        ]
      : ["Applicants & Employees", "Jobs", "Customers", "Interviews", "Primary Company", "Users"];

  const [selectedFeature, setSelectedFeature] = useState(features[0]);
  const [isCustomFieldModalOpen, setIsCustomFieldModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [toDeleteItem, setToDeleteItem] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
    status: "",
  });

  const handleLoad = useCallback(async () => {
    try {
      const response = await getCustomFields();
      if (response.data && response.data.length > 0) {
        setCustomFields(response.data?.filter((field) => field.feature === selectedFeature));
      }
    } catch (err) {
      console.error("Error fetching custom fields:", err);
      setToastAlert({
        isShow: true,
        message: `Error fetching custom fields: ${err.message}`,
        status: "error",
      });
    }
  }, [selectedFeature]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const handleFeatureChange = (value) => {
    setSelectedFeature(value);
  };

  const handleSubmit = async (fieldData) => {
    try {
      if (fieldToEdit) {
        await updateCustomField(fieldToEdit._id, fieldData);
        setToastAlert({
          isShow: true,
          message: "Custom field updated successfully!",
          status: "success",
        });
      } else {
        await createCustomField({
          ...fieldData,
          feature: selectedFeature,
        });
        setToastAlert({
          isShow: true,
          message: "Custom field created successfully!",
          status: "success",
        });
      }
      handleLoad();
      setFieldToEdit(null);
      setIsCustomFieldModalOpen(false);
    } catch (err) {
      console.error("Error saving custom field:", err);
      setToastAlert({
        isShow: true,
        message: `Error saving custom field: ${err.message}`,
        status: "error",
      });
    }
  };

  const handleDeleteField = async (id) => {
    try {
      const { count } = await checkCustomFieldUsage(id);

      if (count > 0) {
        setRecordCount(count);
        const record = count === 1 ? "record" : "records";

        setDeleteMessage(
          `Are you sure you want to delete this custom field? There are ${count} ${record} that is using this.`
        );
      } else {
        setDeleteMessage("Are you sure you want to delete this custom field?");
      }

      setToDeleteItem(id);
      setIsConfirmationOpen(true);
    } catch (err) {
      console.error("Error checking custom field usage:", err);
      setToastAlert({
        isShow: true,
        message: `Error checking custom field usage: ${err.message}`,
        status: "error",
      });
    }
  };

  const handleConfirmDeleteField = async () => {
    try {
      if (toDeleteItem) {
        await deleteCustomField(toDeleteItem);
        handleLoad();
        setToastAlert({
          isShow: true,
          message: "Custom field deleted successfully!",
          status: "success",
        });
      } else {
        setToastAlert({
          isShow: true,
          message: "Missing custom field id.",
          status: "error",
        });
      }

      setIsConfirmationOpen(false);
      setToDeleteItem(null);
    } catch (err) {
      console.error("Error deleting custom field:", err);
      setToastAlert({
        isShow: true,
        message: `Error deleting custom field: ${err.message}`,
        status: "error",
      });
    }
  };

  const handleToggleVisibility = async (field) => {
    try {
      await updateCustomField(field._id, {
        isVisible: !field.isVisible,
      });
      handleLoad();
      setToastAlert({
        isShow: true,
        message: `Field visibility ${field.isVisible ? "hidden" : "shown"} successfully!`,
        status: "success",
      });
    } catch (err) {
      console.error("Error toggling visibility:", err);
      setToastAlert({
        isShow: true,
        message: `Error toggling visibility: ${err.message}`,
        status: "error",
      });
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = customFields.findIndex((field) => field._id === active.id);
      const newIndex = customFields.findIndex((field) => field._id === over.id);

      // Update local state immediately
      const reorderedFields = arrayMove(customFields, oldIndex, newIndex);
      setCustomFields(reorderedFields);

      try {
        const reorderedData = reorderedFields.map((field, index) => ({
          id: field._id.toString(),
          order: index + 1,
        }));

        await reorderCustomFields({
          feature: selectedFeature,
          reorderedData,
        });
      } catch (err) {
        console.error("Error reordering custom fields:", err);
        handleLoad(); // Revert on error
        setToastAlert({
          isShow: true,
          message: `Error reordering fields: ${err.message}`,
          status: "error",
        });
      }
    }
  };

  const handleEditField = (field) => {
    setFieldToEdit(field);
    setIsCustomFieldModalOpen(true);
  };

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          variant="filled"
          sx={{ width: "100%" }}
          style={{
            backgroundColor: toastAlert.status === "success" ? "#4caf4f" : "#f44336",
          }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>

      <Card>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          py={1}
          px={3}
        >
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={features}
              value={selectedFeature}
              onChange={(event, value) => handleFeatureChange(value)}
              renderInput={(params) => (
                <TextField {...params} label="Select Feature" variant="outlined" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
            <Button
              startIcon={<AddCircleRoundedIcon />}
              variant="text"
              onClick={() => setIsCustomFieldModalOpen(true)}
            >
              Add Custom Field
            </Button>
          </Grid>
        </Grid>

        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={customFields.map((field) => field._id)}
            strategy={rectSortingStrategy}
          >
            <Grid container spacing={2} p={3}>
              {customFields.map((field) => (
                <SortableCustomField
                  key={field._id}
                  field={field}
                  handleDeleteField={handleDeleteField}
                  handleEditField={handleEditField}
                  handleToggleVisibility={handleToggleVisibility}
                />
              ))}
            </Grid>
          </SortableContext>
        </DndContext>

        <CustomFieldModal
          isOpen={isCustomFieldModalOpen}
          toggleOpen={setIsCustomFieldModalOpen}
          handleClearFieldToEdit={() => setFieldToEdit(null)}
          initialData={fieldToEdit}
          onSubmit={handleSubmit}
          setToastAlert={setToastAlert}
        />

        <ConfirmationModal
          isOpen={isConfirmationOpen}
          onClose={() => setIsConfirmationOpen(false)}
          title={deleteMessage}
          onConfirm={handleConfirmDeleteField}
        />
      </Card>
    </>
  );
};

export default CustomFieldsTab;
