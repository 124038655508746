import { useEffect, useState } from "react";

export const useSSE = (url, disabled) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!disabled) {
      const eventSource = new EventSource(url, { withCredentials: true });

      eventSource.onmessage = (event) => {
        try {
          setData(JSON.parse(event.data));
        } catch (e) {
          // Do nothing
        }
      };

      eventSource.onerror = (err) => {
        setError(err);
        eventSource.close();
        setIsConnected(false);
      };

      eventSource.onopen = () => {
        setIsConnected(true);
      };

      return () => {
        eventSource.close();
        setIsConnected(false);
      };
    }
    return () => {};
  }, [disabled, url]);

  return { data, error, isConnected };
};
