import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import fetchApplicantByCode from "layouts/pages/applicants/actions/fetchApplicantByCode";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { VENUE_STATE_TAX_FORM_STEPS } from "utils/constants/venues";
import { APPLICANT_STEPS, ONBOARDING_STEPS } from "utils/constants/applicant";
import { getApplicantVenueState } from "utils/helpers/applicant";
import { useInvalidateCheckForHelmAllowed } from "hooks/useInvalidateCheckForHelmAllowed";
import newApplicantReducer, { newApplicantInitialState } from "../reducers/newApplicantReducer";
import useMinStageToOnboarding from "./useMinStageToOnboarding";

const useNewApplicant = ({ outsideMode = "" }) => {
  const { venues, userType, currentApplicant, setCurrentApplicant } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(newApplicantReducer, newApplicantInitialState);
  const [stepsRefreshed, setStepsRefreshed] = useState(0);
  const { minStage, allowedStages, disallowedStages } = useMinStageToOnboarding();

  const isOnboardingComplete = useMemo(
    () => !!state.applicant?.acknowledged?.date,
    [state.applicant?.acknowledged?.date]
  );

  const onNextStep = useCallback(() => {
    const STEPS_LENGTH = state.registrationSteps.length;
    const activeStepId = state.activeStepId >= STEPS_LENGTH ? STEPS_LENGTH : state.activeStepId + 1;
    const step = state.registrationSteps[activeStepId - 1];
    dispatch({ type: "SET_REGISTRATION_STEP", data: { activeStepId, step: step.applicantObject } });
  }, [state.activeStepId, state.registrationSteps]);

  const onPreviousStep = useCallback(() => {
    const activeStepId = state.activeStepId <= 1 ? 1 : state.activeStepId - 1;
    const step = state.registrationSteps[activeStepId - 1];
    dispatch({ type: "SET_REGISTRATION_STEP", data: { activeStepId, step: step.applicantObject } });
  }, [state.activeStepId, state.registrationSteps]);

  const setActiveStep = useCallback(
    (stepId) => {
      const step = state.registrationSteps[stepId - 1];
      dispatch({
        type: "SET_REGISTRATION_STEP",
        data: { activeStepId: stepId, step: step.applicantObject },
      });
    },
    [state.registrationSteps]
  );

  const initializeApplicant = useCallback(
    async (applicantCode) => {
      try {
        const { data } = await fetchApplicantByCode(applicantCode, outsideMode);
        if (data) {
          dispatch({ type: "UPDATE_APPLICANT", data });
        } else {
          dispatch({ type: "UPDATE_APPLICANT_ERROR", error: "Error finding applicant" });
        }
        return data;
      } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        throw new Error(err.toString());
      }
    },
    [outsideMode]
  );

  const createApplicant = useCallback((data) => {
    dispatch({ type: "CREATE_APPLICANT", data });
  }, []);

  const createI9Form = useCallback((data) => {
    dispatch({ type: "CREATE_I_9_FORM", data });
  }, []);
  const { invalidateCheckForHelmAllowed } = useInvalidateCheckForHelmAllowed();

  const updateApplicantAction = useCallback(
    async (applicantId, data) => {
      try {
        const res = await updateApplicant({ applicantId, data, outsideMode });
        if (res.acknowledged) {
          enqueueSnackbar("Applicant info saved.", { variant: "success" });
          dispatch({ type: "UPDATE_APPLICANT", data });
          setCurrentApplicant({ ...currentApplicant, ...data });
          if (res?.updatedApplicant) {
            setCurrentApplicant({ ...currentApplicant, ...res.updatedApplicant });
            dispatch({ type: "UPDATE_APPLICANT", data: res?.updatedApplicant });
          }
          if (!outsideMode && "employerI9Form" in data) {
            await invalidateCheckForHelmAllowed(true, applicantId);
          }
        } else {
          enqueueSnackbar(
            "An error has occurred. Please try refreshing the page and re-verifying.",
            { variant: "error" }
          );
          dispatch({ type: "UPDATE_APPLICANT_ERROR", error: "Updated failed. Error encountered." });
        }
        return data;
      } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        if (err.request?.status === 401) {
          enqueueSnackbar("Session expired.", {
            variant: "error",
          });
          window.location.reload();
          throw err;
        }
        enqueueSnackbar("An error has occurred. Please try refreshing the page and re-verifying.", {
          variant: "error",
        });
        throw new Error(err.toString());
      }
    },
    [
      currentApplicant,
      enqueueSnackbar,
      invalidateCheckForHelmAllowed,
      outsideMode,
      setCurrentApplicant,
    ]
  );
  const loadApplicantAction = useCallback(
    async (data, silent) => {
      try {
        if (!silent) enqueueSnackbar("Applicant info loaded.", { variant: "success" });
        dispatch({ type: "UPDATE_APPLICANT", data });
        return data;
      } catch (err) {
        if (err.message === "Request failed with status code 404") return [];
        throw new Error(err.toString());
      }
    },
    [enqueueSnackbar]
  );

  const updateButtons = useCallback((buttonState) => {
    dispatch({ type: "UPDATE_BUTTONS", buttonState });
  }, []);

  const updateCurrentFormState = useCallback((currentFormState) => {
    dispatch({ type: "UPDATE_FORM_STATE", currentFormState });
  }, []);

  const getActiveRegistrationStep = useCallback(
    () => state.registrationSteps.find((item) => item.id === state.activeStepId),
    [state]
  );

  const handleUserSteps = useCallback(() => {
    const updatedSteps = [...state.registrationSteps];
    if (!["Master", "Admin"].includes(userType)) {
      updatedSteps.splice(5, 1);
      dispatch({ type: "SET_REGISTRATION_STEPS", data: updatedSteps });
    }
  }, [state.registrationSteps, userType]);

  const setApplicantSteps = useCallback(
    (status, applicantStatus, acknowledged, forceRefreshSteps) => {
      if (
        (allowedStages.includes(applicantStatus) && !acknowledged) ||
        ["Master", "Admin"].includes(userType)
      ) {
        dispatch({ type: "SET_REGISTRATION_STEPS", data: ONBOARDING_STEPS });
      } else {
        dispatch({ type: "SET_REGISTRATION_STEPS", data: APPLICANT_STEPS });
      }
      setStepsRefreshed((p) => {
        if (!p || forceRefreshSteps) return p + 1;
        return p;
      });
    },
    [allowedStages, userType]
  );

  const adjustRegistrationSteps = useCallback(() => {
    let updatedSteps = [...state.registrationSteps];
    const venueState = getApplicantVenueState(state.applicant, venues);

    if (
      venueState &&
      !isEmpty(VENUE_STATE_TAX_FORM_STEPS?.[venueState]) &&
      updatedSteps[0].applicantObject !== "applicantInfo"
    ) {
      const appliedVenue = VENUE_STATE_TAX_FORM_STEPS[venueState];
      const hasVenue =
        updatedSteps.findIndex((step) => step.applicantObject === appliedVenue.applicantObject) >=
        0;

      if (!hasVenue && !["Master", "Admin"].includes(userType)) {
        let hasStateTax = 0;

        const idx = updatedSteps.findIndex((step) => step.applicantObject === "directDeposit");
        if (updatedSteps.length === 8) {
          hasStateTax = 1;
        }
        updatedSteps.splice(idx + hasStateTax, hasStateTax, VENUE_STATE_TAX_FORM_STEPS[venueState]);
        updatedSteps = updatedSteps.map((step, index) => ({ ...step, id: index + 1 }));
        dispatch({ type: "SET_REGISTRATION_STEPS", data: updatedSteps });
      } else if (!hasVenue && ["Master", "Admin"].includes(userType)) {
        let hasStateTax = 0;
        const idx = updatedSteps.findIndex((step) => step.applicantObject === "directDeposit");
        if (updatedSteps.length === 9) {
          hasStateTax = 1;
        }
        updatedSteps.splice(idx - hasStateTax, hasStateTax, VENUE_STATE_TAX_FORM_STEPS[venueState]);
        updatedSteps = updatedSteps.map((step, index) => ({ ...step, id: index + 1 }));
        dispatch({ type: "SET_REGISTRATION_STEPS", data: updatedSteps });
      }
    } else if (["Master", "Admin"].includes(userType)) {
      dispatch({ type: "SET_REGISTRATION_STEPS", data: ONBOARDING_STEPS });
    }
  }, [state.applicant, state.registrationSteps, venues]);

  const setApplicant = useCallback((applicant) => {
    dispatch({ type: "SET_APPLICANT", data: applicant });
  }, []);

  const setApplicantProgress = useCallback(() => {
    let stepId = 0;
    state.registrationSteps.forEach((step, i) => {
      if (state.applicant[step.applicantObject]) {
        stepId = i;
      }
    });
    dispatch({ type: "SET_ONBOARDING_PROGRESS", data: stepId });
  }, [state.registrationSteps, state.applicant]);

  useEffect(() => {
    adjustRegistrationSteps();
  }, [state.applicant, venues]);

  useEffect(() => {
    handleUserSteps();
  }, [userType]);

  useEffect(() => {
    setApplicantProgress();
  }, [state.registrationSteps, state.applicant]);

  useEffect(() => {
    if (stepsRefreshed) {
      setActiveStep(1);
      handleUserSteps();
    }
  }, [stepsRefreshed]);

  return {
    ...state,
    isOnboardingComplete,
    onNextStep,
    setApplicantSteps,
    onPreviousStep,
    initializeApplicant,
    createApplicant,
    createI9Form,
    setActiveStep,
    updateApplicantAction,
    loadApplicantAction,
    updateButtons,
    updateCurrentFormState,
    getActiveRegistrationStep,
    setApplicant,
  };
};

export default useNewApplicant;
