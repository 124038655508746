import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormHelperText,
  Stack,
  Button,
  Autocomplete,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import GenericModal from "components/GenericModal";
import CustomTextField from "components/Form/CustomTextField";
import AddStringValues from "components/DropdownEntitiesModal/AddStringValues";
import DataList from "components/DataList";
import DropdownStringValue from "components/DropdownValueItem/DropdownStringValue";
import { isEqual } from "lodash";
import { Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { customFieldSchema } from "data/customFieldModalForm";
import { defaultValues } from "../CustomFieldsTab/fixtures";

const CustomFieldModal = ({
  isOpen,
  toggleOpen,
  initialData,
  onSubmit,
  handleClearFieldToEdit,
  setToastAlert,
}) => {
  const resolver = useYupValidationResolver(customFieldSchema);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    setError,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver,
  });

  const type = watch("type");
  const options = watch("options");
  const [optionValue, setOptionValue] = useState("");

  // Reset form on modal open/close or initial data change
  useEffect(() => {
    if (isOpen) {
      reset(initialData || defaultValues);
    } else {
      reset(defaultValues);
    }
  }, [isOpen, initialData, reset]);

  const handleConfirmChanges = async () => {
    // Additional validation for dropdown and radio group
    if (["Dropdown", "Radio Group"].includes(type) && (!options || options.length === 0)) {
      setToastAlert({
        isShow: true,
        message: `Options are required for ${type}`,
        status: "error",
      });

      return;
    }

    const isValidForm = await trigger();

    if (onSubmit && isValidForm) {
      handleSubmit(onSubmit)();
      toggleOpen(false);
    }
  };

  const handleCancel = () => {
    reset(defaultValues);
    handleClearFieldToEdit();
    toggleOpen(false);
  };

  const checkEmptyValue = (val, err, message = "You must enter a value") => {
    if (!val?.trim()) {
      setError(err, { message });
    } else {
      clearErrors(err);
    }
  };

  const handleAddOption = () => {
    checkEmptyValue(optionValue, "optionValue");
    if (errors?.optionValue) return;
    setValue("options", [...options, optionValue], { shouldDirty: true });
    setOptionValue("");
  };

  const handleEditOption = (row, idx, text) => {
    if (isEqual(row, text)) {
      clearErrors(`option_${idx}`);
      return;
    }

    checkEmptyValue(text, `option_${idx}`);
    if (errors?.[`option_${idx}`]) return;

    const updatedOptions = options.map((item, i) => (i === idx ? text : item));
    setValue("options", updatedOptions, { shouldDirty: true });
  };

  const handleRemoveOption = (idx) => {
    const updatedOptions = options.filter((_, i) => i !== idx);
    setValue("options", updatedOptions, { shouldDirty: true });
  };

  const handleTypeChange = (value) => {
    setValue("type", value);
    if (!["Dropdown", "Radio Group"].includes(value)) {
      setValue("options", []);
      clearErrors("options");
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    handleClearFieldToEdit();
    toggleOpen(false);
  };

  const renderOptions = (row, idx) => (
    <DropdownStringValue
      row={row}
      idx={idx}
      onEdit={handleEditOption}
      onRemove={handleRemoveOption}
      clearErrors={clearErrors}
      errors={errors}
    />
  );

  const header = (
    <Box display="flex" justifyContent="space-between" ml={1}>
      <Typography variant="h4">{initialData ? "Edit Custom Field" : "Add Custom Field"}</Typography>
    </Box>
  );

  const body = (
    <div>
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Button variant="text" startIcon={<CancelIcon />} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          onClick={() => handleConfirmChanges()}
          loading={isSubmitting}
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Save
        </LoadingButton>
      </Box>

      <Box border="1px solid #E0E0E0" borderRadius={2} padding={3} marginBottom={3}>
        <Grid container spacing={3}>
          {/* Label Field */}
          <Grid item xs={12}>
            <Controller
              name="label"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  {...field}
                  label="Label"
                  InputLabelProps={{ shrink: true }}
                  showError={!!error?.message}
                  errorMessage={error?.message}
                />
              )}
            />
          </Grid>

          {/* Type Field */}
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  options={[
                    "Text",
                    "Textarea",
                    "Date",
                    "Dropdown",
                    "Radio Group",
                    "Price",
                    "Quantity",
                    "Checkbox",
                  ]}
                  value={field.value}
                  onChange={(_, value) => handleTypeChange(value)}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      label="Type"
                      InputLabelProps={{ shrink: true }}
                      showError={!!error?.message}
                      errorMessage={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          {/* Placeholder Field */}
          <Grid item xs={12}>
            <Controller
              name="placeholder"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  {...field}
                  label="Placeholder"
                  InputLabelProps={{ shrink: true }}
                  showError={!!error?.message}
                  errorMessage={error?.message}
                />
              )}
            />
          </Grid>

          {/* Options Field */}
          {["Dropdown", "Radio Group"].includes(type) && (
            <Grid item xs={12}>
              <Box>
                <MDTypography component="label" variant="button" fontWeight="bold" color="info">
                  VALUES
                </MDTypography>
                <AddStringValues
                  value={optionValue}
                  setValue={setOptionValue}
                  onAdd={handleAddOption}
                  errors={errors}
                />
                <DataList renderRow={renderOptions} data={options} maxHeight="200px" />
              </Box>
            </Grid>
          )}

          {/* Checkboxes */}
          <Grid item xs={12} display="flex" gap={2}>
            <Controller
              key="isVisible"
              name="isVisible"
              control={control}
              render={({ field: checkboxField }) => (
                <Box display="flex" alignItems="center" mb={1}>
                  <Checkbox
                    {...checkboxField}
                    checked={checkboxField.value}
                    onChange={(e) => checkboxField.onChange(e.target.checked)}
                  />
                  <Typography>Visible</Typography>
                </Box>
              )}
            />

            {watch("type") !== "Checkbox" && (
              <Controller
                key="isRequired"
                name="isRequired"
                control={control}
                render={({ field: checkboxField }) => (
                  <Box display="flex" alignItems="center" mb={1}>
                    <Checkbox
                      {...checkboxField}
                      checked={checkboxField.value}
                      onChange={(e) => checkboxField.onChange(e.target.checked)}
                    />
                    <Typography>Required</Typography>
                  </Box>
                )}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return (
    <GenericModal
      open={isOpen}
      setOpen={handleClose}
      header={header}
      body={body}
      sx={(theme) => ({
        width: "90% !important",
        maxWidth: "700px !important",
        [theme.breakpoints.down("md")]: {
          "& .MuiCardContent-root": {
            padding: "0px",
          },
        },
      })}
    />
  );
};

export default CustomFieldModal;
