import { useMemo, useState } from "react";

import { Grid, Icon, Stack, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  Cancel,
  Email,
  AccountCircle,
  AccountCircleOutlined,
  Edit,
  Lock,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";

import { dataStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const AIQuestionsItem = ({ row, idx, onEdit, onRemove, onToggleLocked, readOnly }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { text } = row;
  const isIconMenuOpen = Boolean(anchorEl);

  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: row.text,
  });

  const style = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition: transform ? transition : undefined,
    }),
    [transform, transition]
  );

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        ref={setNodeRef}
        style={style}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            position: "relative",
            transform: isDragging ? "scale(1.02)" : "scale(1)",
            zIndex: isDragging ? 999 : 1,
            opacity: isDragging ? 0.9 : 1,
            boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.1)" : "none",
            willChange: "transform",
            flexWrap: "nowrap",
          }}
        >
          <Grid item flexGrow={1}>
            <MDTypography
              sx={{
                ...styles.font,
                ...(readOnly
                  ? {}
                  : {
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 4,
                    }),
              }}
            >
              {parse(text ?? "")}
            </MDTypography>
          </Grid>
          {!readOnly && (
            <>
              <Grid item textAlign="right">
                <Tooltip title="Edit" placement="top">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(idx, row, true);
                    }}
                  >
                    <Edit color="info" fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item textAlign="right">
                <Tooltip title="Lock" placement="top">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleLocked(idx);
                    }}
                  >
                    <Lock color={row.locked ? "info" : "disabled"} fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <MDBox textAlign="right">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove(idx);
                    }}
                  >
                    <Cancel color="error" fontSize="medium" />
                  </IconButton>
                </MDBox>
              </Grid>
              <Grid item>
                <MDBox textAlign="right">
                  <IconButton
                    {...listeners}
                    {...attributes}
                    sx={{
                      cursor: isDragging ? "grabbing" : "grab",
                      p: 0,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      touchAction: "none",
                    }}
                  >
                    <DragIndicatorIcon />
                  </IconButton>
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default AIQuestionsItem;
