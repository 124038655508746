/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, styled } from "@mui/material";
import {
  ContactPage,
  Email,
  FmdGood,
  FolderCopy,
  Image,
  Info,
  Settings,
  Timeline,
  TrendingUp,
  Try,
} from "@mui/icons-material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import CustomCard from "components/CustomCard";

import { useAppContextController } from "context/AppContext";
import useCompanyStore from "stores/useCompanyStore";
import CustomerForm from "layouts/pages/customers/components/CustomerForm";

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "4rem",
  height: "4rem",
  background: theme.palette.warning.main,
  color: "white",
  borderRadius: "0.8rem",
}));

const CustomerInfoPanel = ({
  setRefreshCompanies = () => null,
  handleSubmit = () => null,
  selectedPanel = "",
  onPanelSelect = () => null,
}) => {
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const {
    company: primaryCompany,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    company,
  } = useAppContextController();

  const isEdit = !!currentCompany?._id;

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => onPanelSelect(panel));
    } else {
      onPanelSelect(panel);
    }
  };

  const panelOptions = [
    {
      title: "Activities",
      icon: <Timeline />,
      hasShadow: selectedPanel === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handlePanelChange("activities"),
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: selectedPanel === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handlePanelChange("additionalInfo"),
    },
    {
      title: "Locations",
      icon: <FmdGood />,
      hasShadow: selectedPanel === "locations" ? "emd" : "",
      color: "success",
      ariaLabel: "locations",
      fn: () => handlePanelChange("locations"),
    },
    {
      title: "Contacts",
      icon: <ContactPage />,
      hasShadow: selectedPanel === "contacts" ? "emd" : "",
      color: "warning",
      ariaLabel: "contacts",
      fn: () => handlePanelChange("contacts"),
    },
    {
      title: "Messaging",
      icon: <Email />,
      hasShadow: selectedPanel === "message" ? "emd" : "",
      color: "error",
      ariaLabel: "message",
      fn: () => handlePanelChange("message"),
    },
    {
      title: "Sales",
      icon: <TrendingUp />,
      hasShadow: selectedPanel === "sales" ? "emd" : "",
      color: "success",
      ariaLabel: "sales",
      fn: () => handlePanelChange("sales"),
    },
    {
      title: "Image Gallery",
      icon: <Image />,
      hasShadow: selectedPanel === "images" ? "emd" : "",
      color: "",
      ariaLabel: "image gallery",
      fn: () => handlePanelChange("images"),
    },
    {
      title: "Custom Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "customcontent" ? "emd" : "",
      color: "primary",
      ariaLabel: "custom content",
      fn: () => handlePanelChange("customcontent"),
    },
    {
      title: "Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "settings",
      fn: () => handlePanelChange("settings"),
    },
    ...(company?.aiInterviewsAvailable === "Yes"
      ? [
          {
            title: "AI Settings",
            icon: <Try />,
            hasShadow: selectedPanel === "aiSettings" ? "emd" : "",
            color: "violet",
            ariaLabel: "ai settings",
            fn: () => handlePanelChange("aiSettings"),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!isEdit) onPanelSelect("");
  }, [isEdit]);

  return (
    <CustomCard
      icon={
        isEdit && currentCompany?.squareLogoUrl ? (
          <CompanyLogoSquare company={currentCompany} defaultCompany={primaryCompany} />
        ) : (
          <StyledImageContainer>
            <AccountCircleIcon fontSize="large" />
          </StyledImageContainer>
        )
      }
      iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "-1.5rem" }}
      cardSx={{ width: "100%", overflow: "visible !important", p: 0, height: 850 }}
    >
      <CustomerForm
        onSubmit={handleSubmit}
        setRefreshCompanies={setRefreshCompanies}
        panelOptions={panelOptions}
        onPanelSelect={onPanelSelect}
        selectedPanel={selectedPanel}
      />
    </CustomCard>
  );
};

export default CustomerInfoPanel;
