import AvTimerIcon from "@mui/icons-material/AvTimer";
import CheckIcon from "@mui/icons-material/Check";
import HourglassIcon from "@mui/icons-material/HourglassTop";
import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import { useRosterApplicants } from "hooks/useRosterApplicants";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

const checkColor = {
  Roster: "success",
  Waitlist: "warning",
  Request: "warning",
};

const MyEventsActions = ({
  eventUrl,
  row,
  setNavigateUrl,
  status,
  openEventPreview,
  openTimeModal,
}) => {
  const {
    userType,
    currentLoggedUser: { applicantId, clientOrgs, employeeType },
  } = useAppContextController();

  const { data } = useRosterApplicants({
    eventUrl,
    options: {
      onSuccess: () => "",
      enabled: ["Roster", "Request"].includes(status),
    },
  });

  const applicant = data?.result?.find((item) => item.id === applicantId);

  const onClickHandler = useCallback(
    (action, e) => {
      e.stopPropagation();
      setNavigateUrl(
        `/${userType === "Client" ? "clientevents" : "events"}/${eventUrl}/action/${action}`
      );
    },
    [eventUrl, setNavigateUrl]
  );

  const eventTimeColor = useMemo(() => {
    if (applicant?.timeOut) {
      return "info";
    }
    if (applicant?.timeIn) {
      return "warning";
    }
    return "success";
  }, [applicant]);

  const actions = useMemo(
    () => [
      {
        name: "viewEvent",
        color: "info",
        icon: "preview",
        clickAction: () => openEventPreview(),
        tooltip: "View Event",
      },
      {
        name: "eventsapplicants",
        color: "error",
        icon: "people",
        showBadge: true,
        clickAction: (e) => onClickHandler("eventsroster", e),
        tooltip: "Event Roster",
      },
      {
        name: "eventPartners",
        color: "secondary",
        icon: "handshake",
        clickAction: (e) => onClickHandler("eventsall", e),
        tooltip: "Event Partners",
      },
      {
        name: "editEvent",
        color: "secondary",
        icon: "edit",
        clickAction: (e) => onClickHandler("eventsinfo", e),
        tooltip: "Edit Event",
      },
      {
        name: "eventTime",
        color: eventTimeColor,
        icon: <AvTimerIcon />,
        clickAction: (e) => openTimeModal(),
        tooltip: "Event time",
      },
    ],
    [eventTimeColor, openEventPreview, openTimeModal, onClickHandler]
  );

  const filteredActions = useMemo(() => {
    const updatedActions = [...actions];
    switch (status) {
      case "Roster":
      case "Request":
        updatedActions.unshift({
          name: "rosterStatus",
          color: checkColor[status],
          icon: <CheckIcon fontSize="large" />,
          clickAction: () => "",
          tooltip: `Event Status: ${status}`,
        });
        break;
      case "Waitlist":
        updatedActions.unshift({
          name: "rosterStatus",
          color: checkColor[status],
          icon: <HourglassIcon fontSize="large" />,
          clickAction: () => "",
          tooltip: `Event Status: ${status}`,
        });
        break;
      default:
        break;
    }
    if (userType === "User") {
      const userActions = ["viewEvent", "rosterStatus", "eventTime"];
      const result = updatedActions.filter((action) => userActions.includes(action.name));
      if (employeeType === "Event Admin") {
        const clientOrgVenue = clientOrgs?.find((item) => item.slug === row?.venueSlug);
        if (clientOrgVenue)
          result.unshift({
            name: "eventsapplicants",
            color: "error",
            icon: "people",
            showBadge: true,
            clickAction: (e) => onClickHandler("eventsroster", e),
            tooltip: "Event Roster",
          });
      }
      return result;
    }
    if (userType === "Client") {
      const clientActions = ["viewEvent", "eventsapplicants"];
      return updatedActions.filter((action) => clientActions.includes(action.name));
    }
    const hideAdminActions = ["eventTime"];
    return updatedActions.filter((action) => !hideAdminActions.includes(action.name));
  }, [userType, status, actions]);

  return (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap">
      {filteredActions.map((prop) => (
        <TableAction {...prop} key={`myevents_actions_${prop.name}`} />
      ))}
    </MDBox>
  );
};

MyEventsActions.defaultProps = {
  eventUrl: "",
  isFavorite: false,
};

MyEventsActions.propTypes = {
  eventUrl: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default MyEventsActions;
