import BugReportIcon from "@mui/icons-material/BugReport";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
// eslint-disable-next-line import/no-unresolved
// import Placeholder from "layouts/pages/placeholder";
import Applicants from "layouts/pages/applicants";
import PollIcon from "@mui/icons-material/Poll";
// eslint-disable-next-line import/no-named-as-default-member
import Dashboard from "layouts/pages/dashboard";
import Employees from "layouts/pages/employees";
import Events from "layouts/pages/events";
import ClientEvents from "layouts/pages/clientevents";
import Jobs from "layouts/pages/jobs";
import CompanyJobs from "layouts/pages/companyjobs";
import Venues from "layouts/pages/venues";
// import Campaigns from "layouts/pages/campaigns";
import Activities from "layouts/pages/activities";
import Issues from "layouts/pages/issues";
import Partners from "layouts/pages/partners";
import Setup from "layouts/pages/setup";
import Users from "layouts/pages/users";
import Tasks from "layouts/pages/tasks";
// import SignInBasic from "layouts/authentication/sign-in/basic";
import Logout from "layouts/authentication/logout";

// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import LocationCityIcon from "@mui/icons-material/LocationCity";
// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { NotificationsActive } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import CompanyPage from "layouts/pages/companies";
import Customers from "layouts/pages/customers";
import Notifications from "layouts/pages/notifications";
import Reports from "layouts/pages/reports";
// import JobResults from "layouts/pages/web/JobResults";

const routes = [
  {
    type: "divider",
    key: "divider-2",
    userType: ["Master", "Admin", "User", "Client", "Event Admin"],
  },
  {
    type: "title",
    key: "title-user",
    title: "User",
    userType: ["Master", "Admin", "User", "Client", "Event Admin"],
  },
  {
    type: "link",
    name: "Dashboard",
    key: "analytics",
    route: "/dashboards/analytics",
    icon: <Icon>dashboard</Icon>,
    component: <Dashboard />,
    userType: ["Master", "Admin", "User", "Client", "Event Admin"],
  },
  {
    type: "link",
    name: "Company",
    key: "companies",
    route: "/companies",
    icon: <LocationCityIcon fontSize="medium" />,
    component: <CompanyPage />,
    userType: ["Master"],
  },
  {
    type: "link",
    name: "Customers",
    key: "customers",
    route: "/customers",
    icon: <Icon>business</Icon>,
    component: <Customers />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    type: "link",
    name: "Venues",
    key: "venues",
    route: "/venues",
    component: <Venues />,
    icon: <Icon fontSize="medium">stadium</Icon>,
    userType: ["Master", "Admin", "User", "Client", "Event Admin"],
    companyType: "Venue",
  },
  {
    type: "link",
    name: "Jobs",
    key: "jobs",
    route: "/jobs",
    component: <Jobs mode="edit" />,
    icon: <Icon fontSize="medium">work</Icon>,
    userType: ["Master", "Admin"],
    companyType: "Venue",
  },
  {
    type: "link",
    name: "Jobs",
    key: "companyjobs",
    route: "/companyjobs",
    component: <CompanyJobs mode="edit" />,
    icon: <Icon fontSize="medium">work</Icon>,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    type: "link",
    name: "Events",
    key: "events",
    route: "/events",
    component: <Events />,
    icon: <Icon fontSize="medium">event-seat</Icon>,
    userType: ["Master", "Admin", "User", "Event Admin"],
    companyType: "Venue",
  },
  {
    type: "link",
    name: "Events",
    key: "clientevents",
    route: "/clientevents",
    component: <ClientEvents />,
    icon: <Icon fontSize="medium">event-seat</Icon>,
    userType: ["Client"],
    companyType: "Venue",
  },
  {
    type: "link",
    name: "Interviews",
    key: "events",
    route: "/events",
    component: <Events />,
    icon: <Icon fontSize="medium">event-seat</Icon>,
    userType: ["Master", "Admin", "User", "Event Admin"],
    companyType: "Company",
  },
  // Admin pages
  { type: "divider", key: "divider-1", userType: ["Master", "Admin"] },
  { type: "title", key: "title-admin", title: "Admin", userType: ["Master", "Admin"] },

  {
    type: "link",
    name: "Applicants",
    key: "applicants",
    route: "/applicants",
    component: <Applicants mode="" />,
    icon: <Icon fontSize="medium">personadd</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Employees",
    key: "employees",
    route: "/employees",
    component: <Employees mode="" />,
    icon: <Icon fontSize="medium">people</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Partners",
    key: "partners",
    route: "/partners",
    component: <Partners />,
    icon: <Icon fontSize="medium">handshake</Icon>,
    companyType: "Venue",
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Users",
    key: "users",
    route: "/users",
    component: <Users mode="" />,
    icon: <Icon fontSize="medium">account_circle</Icon>,
    userType: ["Master"],
  },
  {
    type: "link",
    name: "Tasks",
    key: "tasks",
    route: "/tasks",
    component: <Tasks mode="" />,
    icon: <Icon fontSize="medium">task</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Reports",
    key: "reports",
    route: "/reports",
    component: <Reports mode="" />,
    icon: <Icon fontSize="medium">report</Icon>,
    userType: ["Master"],
  },
  {
    type: "link",
    name: "Activities",
    key: "activities",
    route: "/activities",
    component: <Activities mode="" />,
    icon: <Icon fontSize="medium">timeline</Icon>,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Issues",
    key: "issues",
    route: "/issues",
    component: <Issues mode="" />,
    icon: <BugReportIcon fontSize="medium" />,
    userType: ["Master", "Admin"],
  },
  {
    type: "link",
    name: "Setup",
    key: "setup",
    route: "/setup",
    component: <Setup mode="" />,
    icon: <SettingsApplicationsIcon fontSize="medium" />,
    userType: ["Master"],
  },
  {
    type: "link",
    name: "Notifications",
    key: "notifications",
    route: "/notifications",
    component: <Notifications />,
    icon: <NotificationsActive fontSize="medium" />,
    userType: ["Master", "Admin", "User", "Client", "Event Admin"],
  },
  {
    type: "divider",
    key: "divider-4",
  },
  {
    type: "link",
    name: "Logout",
    key: "logout",
    icon: <LogoutIcon />,
    route: "/authentication/logout",
    component: <Logout />,
  },
];

export default routes;
