import { baseAxios } from "config";

export const updateJobCustomFields = async (jobSlug, data) => {
  try {
    const res = await baseAxios.put(`/jobs/${jobSlug}/custom-fields`, data);
    return res.data;
  } catch (err) {
    console.error(`Error fetching job custom field with ID ${jobSlug}:`, err);
    throw new Error(err.toString());
  }
};
