import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import LandscapeIcon from "@mui/icons-material/Landscape";
import { Autocomplete, Box, Button, Card, Grid, Switch, Tooltip } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import colors from "assets/theme/base/colors";
import ConfirmDialog from "components/ConfirmDialog";
import { baseAxios } from "config";
import ConfirmationModal from "components/ConfirmationModal";
import { useAppContextController } from "context/AppContext";
import Header from "layouts/pages/profile/components/Header";
import useDropdowns from "hooks/useDropdowns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
// Material Dashboard 2 PRO React components
import { makeStyles } from "@mui/styles";
import updateUser from "api/users/updateUser";
import DeleteModal from "components/ConfirmDialog/ConfirmModal";
import DataListContainer from "components/DataListContainer";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import Attachments from "layouts/pages/applicants/components/ApplicantOnboardingPanel/Attachments";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import UserPanel from "layouts/pages/applicants/components/UserPanel";
import deleteUserIdentityValidation from "layouts/pages/profile/actions/deleteUserIdentityValidation";
import fetchUserIdentity from "layouts/pages/profile/actions/fetchUserIdentity";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import updateUserIdentity from "layouts/pages/profile/actions/updateUserIdentity";
import BannerDropzone from "layouts/pages/profile/components/BannerDropzone";
import ProfileMainInfoPanel from "layouts/pages/profile/components/ProfileMainInfoPanel";
import createUser from "layouts/pages/users/actions/createUser";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import moment from "moment";
import postNote from "layouts/pages/applicants/actions/postNote";
import { useSnackbar } from "notistack";
import GenericModal from "components/GenericModal";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import useVenueCache from "hooks/useVenueCache";
import { PatternFormat } from "react-number-format";
import useSessionAuth from "hooks/useSessionAuth";
import { useRefreshApplicant } from "hooks/useRefreshApplicant";
import OrganizationsItem from "../OrganizationsItem";
import OrganizationsModal from "../OrganizationsModal";
import ProfileActivities from "../ProfileActivities";
import ProfileAttachments from "../ProfileAttachments";
import ProfileOrganizations from "../ProfileOrganizations";
import ProfileReport from "../ProfileReport";
import ProfileSelectorActivities from "../ProfileSelectorActivities";
import ProfileNotes from "../ProfileNotes";
import ChangeEmailModal from "./ChangeEmailModal";
import AdditionalInformation from "../AdditionalInformation";

const { black } = colors;

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 170,
  },
  dropzone: {
    minHeight: 70,
  },
  previewButton: {
    padding: 0,
  },
  words: {
    wordWrap: "break-word",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  switchBase: {
    "&.Mui-checked": {
      "& .MuiSwitch-thumb": {
        border: "1px solid #1A73E8 !important",
      },
      "& + .MuiSwitch-track": {
        opacity: "1 !important",
        backgroundColor: "#1A73E8 !important",
        borderColor: "#1A73E8 !important",
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: "0.3 !important",
      backgroundColor: "grey",
      borderColor: "grey",
    },
  },
  thumb: {
    color: "white !important",
    border: "1px solid grey !important",
  },
});

function ProfileInfo({
  mode = "edit",
  userType: currentUserType,
  currentApplicant,
  setCurrentApplicant,
  setBottomPanel,
  currentUser,
  setCurrentUser,
  sourceComponent,
  setFilters,
  panelRef,
}) {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const {
    currentLoggedUser,
    company,
    userType: loggedUserType,
    venues,
    setVenues,
  } = useAppContextController();
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const isCompany = company?.companyType === "Company";
  const isVenue = company?.companyType === "Venue";
  const IMAGE_SERVER = company?.imageUrl;
  const [isModified, setIsModified] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  // If there is no current user, let's route the user to login
  const navigate = useNavigate();
  const userApplicant = currentUser;
  const [bannerAnchorRef, setBannerAnchorRef] = useState();
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [userIdentityVerification, setUserIdentityVerification] = useState("Unsubmitted");
  const [identityVerificationColor, setIdentityVerificationColor] = useState("dark");
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [isOrgModalOpen, setOrgModalOpen] = useState(false);
  const [orgIndex, setOrgIndex] = useState(null);
  const [currentOrg, setCurrentOrg] = useState({});
  const [otp, setOtp] = useState("");
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false);
  const [changeEmailSuccessModalOpen, setChangeEmailSuccessModalOpen] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(5);
  const [clickCount, setClickCount] = useState(0);
  const [disableResendButton, setDisableResendButton] = useState(false);
  const [newEmailEdit, setNewEmailEdit] = useState(null);

  const messageRef = useRef("");

  const { dropdowns: states } = useDropdowns({ entity: "stateCodes" });
  const { dropdowns: userTypes } = useDropdowns({ entity: "userTypes" });
  const { dropdowns: userStatusField } = useDropdowns({ entity: "userStatusField" });
  const { dropdowns: userSPStatusField } = useDropdowns({ entity: "userSPStatusField" });
  const { dropdowns: employeeTypes } = useDropdowns({ entity: "employeeType", enabled: isVenue });

  const isEdit = !!currentUser?._id;
  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    control,
    watch,
    reset,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (company?.companyType === "Venue" && (!venues || Object.keys(venues).length))
      refetchVenues();
  }, [venues, company?.companyType]);

  const defaultValues = {
    firstName: "",
    lastName: "",
    userType: null,
    userId: "",
    applicant: {
      firstName: "",
      lastName: "",
      address1: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      altPhone: "",
      emailVerified: "No",
    },
    emailAddress: "",
    organization: company?.name || "",
    employeeType: "",
    master: "No",
    status: null,
    spStatus: null,
    logoVerified: "No",
    endDate: null,
    startDate: null,
    firstDate: null,
    ...(userApplicant?.userType === "Client" && { clientOrgs: [] }),
  };

  const firstName = {
    ...register("firstName", {
      required: "First Name is Required",
      pattern: {
        value: /^[A-Za-z\s]*$/,
        message: "First Name contains invalid characters.",
      },
    }),
  };
  const lastName = {
    ...register("lastName", {
      required: "Last Name is Required",
      pattern: {
        value: /^[A-Za-z\s]*$/,
        message: "Last Name contains invalid characters.",
      },
    }),
  };
  const userType = {
    ...register("userType", {
      disabled: currentUserType === "User",
      validate: { exist: (value) => userTypes?.includes(value) || "Insert a valid user type" },
    }),
  };
  const emailAddress = {
    ...register("emailAddress", {
      required: "Email is Required",
      pattern: {
        // eslint-disable-next-line
        value: /^[a-z0-9_.-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/,
        message: "Your email address is invalid",
      },
      validate: {
        repeated: async (value) => {
          if (mode === "create") {
            const filteredApplicants = await fetchApplicants({
              filters: { email: value },
              fetchAll: true,
            });
            const filteredUsers = await fetchUsers({
              filters: { email: value },
              fetchAll: true,
            });
            const anyUser = !filteredApplicants.data && !filteredUsers.data;
            return anyUser || "this email is already in use ";
          }
          return true;
        },
      },
    }),
  };
  const organization = {
    ...register("organization"),
  };

  const title = {
    ...register("title"),
  };

  const employeeType = {
    ...register("employeeType", {
      validate: {
        exist: (value) =>
          employeeTypes?.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Employee Type",
      },
    }),
  };
  const status = {
    ...register("status", {
      validate: {
        exist: (value) =>
          userStatusField?.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Status",
      },
    }),
  };
  const spStatus = {
    ...register("spStatus", {
      validate: {
        exist: (value) =>
          userSPStatusField?.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Status",
      },
    }),
  };

  const startDate = {
    ...register("startDate", {
      validate: {
        exist: (value) => {
          if (getValues("endDate") && getValues("startDate")) {
            return (
              moment(value).isBefore(getValues("endDate")) ||
              "The Start Date can’t be later than the End Date"
            );
          }
          return true;
        },
      },
    }),
  };

  const endDate = {
    ...register("endDate", {
      validate: {
        exist: (value) => {
          if (getValues("startDate") && getValues("endDate")) {
            return (
              moment(value).isAfter(getValues("startDate")) ||
              "The End Date can’t be earlier than the Start Date"
            );
          }
          return true;
        },
      },
    }),
  };

  const loginVerified = register("loginVerified");
  const emailVerified = register("applicant.emailVerified");
  const phone = {
    ...register("applicant.phone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };
  const altPhone = {
    ...register("applicant.altPhone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };

  const address1 = register("applicant.address1");
  const city = register("applicant.city");
  const state = {
    ...register("applicant.state", {
      validate: { exist: (value) => states?.includes(value) || "Insert a valid state" },
    }),
  };
  const zip = {
    ...register("applicant.zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Zip code is invalid" },
    }),
  };

  const { logoutAuth0User } = useSessionAuth();

  const getApplicantIdentity = async (email) => {
    try {
      const identityVerification = await fetchUserIdentity(email);
      if (identityVerification?.status) {
        setUserIdentityVerification(identityVerification.status);
        if (identityVerification?.status === "verified") {
          setIdentityVerificationColor("success");
        } else if (identityVerification?.status === "submitted") {
          setIdentityVerificationColor("warning");
        } else {
          setIdentityVerificationColor("dark");
        }
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const queryClient = useQueryClient();

  const uploadImgMutation = useMutation(
    async (imgForm) => {
      await baseAxios.post(`/upload/users/${currentUser._id}/${imgForm.type}`, imgForm.form);
      await updateProfile({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, data) => {
        setCurrentUser({ ...currentUser, ...data.data });
        enqueueSnackbar("Picture has been updated!", { variant: "success" });
      },
    }
  );
  const uploadSignatureImgMutation = useMutation(
    async (imgForm) => {
      await baseAxios.post(
        `/upload/applicants/${currentApplicant._id}/${imgForm.type}`,
        imgForm.form
      );
      await updateProfile({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, data) => {
        setCurrentUser({ ...currentUser, ...data.data });
        enqueueSnackbar("Picture has been updated!", { variant: "success" });
      },
    }
  );

  const { mutate, mutateAsync, isLoading } = useMutation(createUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({
        ...currentUser,
        ...data,
        userId: data.emailAddress,
      });
      enqueueSnackbar("User has been created!", { variant: "success" });
      navigate(`/users/${_.insertedId}`);
    },
  });

  const updateUserMutation = useMutation(updateProfile, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      enqueueSnackbar(messageRef.current, { variant: "success" });
      setIsModified(false);
    },
  });

  const updateUserIdentityMutation = useMutation(updateUserIdentity, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async () => {
      await queryClient.invalidateQueries("users");
      enqueueSnackbar(messageRef.current, { variant: "success" });
      if (currentUser?.emailAddress && currentLoggedUser?.userType !== "User") {
        getApplicantIdentity(currentUser.emailAddress);
      }
    },
  });

  const deleteUserIdentityMutation = useMutation(deleteUserIdentityValidation, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async () => {
      await queryClient.invalidateQueries("users");
      enqueueSnackbar(messageRef.current, { variant: "error" });
      if (currentUser?.emailAddress && currentLoggedUser?.userType !== "User") {
        getApplicantIdentity(currentUser.emailAddress);
      }
    },
  });

  const updateUserInfoMutation = useMutation(updateUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      enqueueSnackbar(messageRef.current, { variant: "success" });
    },
  });

  const createUserHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser?._id;
    await mutateAsync(data);
    setIsModified(false);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getUserValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = userApplicant?.[key] || "";
    });
    return values;
  };

  const resendOtp = async () => {
    if (!userApplicant?.userId) {
      enqueueSnackbar("Something went wrong!, no Id", { variant: "error" });
      return;
    }
    const values = {
      oldEmail: userApplicant?.emailAddress,
      newEmail: newEmailEdit,
      isProfile: true,
    };

    await updateUserMutation.mutateAsync({
      userId: userApplicant?.userId,
      data: values,
    });
  };

  const verifyOneTimePasscode = async () => {
    let result;
    try {
      result = await baseAxios.post(`/users/account/id/${userApplicant?._id}/verify`, {
        otp: parseFloat(otp),
      });
    } catch (error) {
      if (error.response.data.expired) {
        enqueueSnackbar("Expired one-time passcode", { variant: "error" });
      } else {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      }
    }

    if (result?.data?.success) {
      enqueueSnackbar("Email has been updated!", { variant: "success" });
      setOpenOTPModal(false);
      setChangeEmailSuccessModalOpen(true);
      setValue("emailAddress", newEmailEdit);
      setNewEmailEdit("");
    }
    setSubmittingOTP(false);
    setOtp("");
  };

  function diffObj(obj1, obj2) {
    const result = {};
    if (Object.is(obj1, obj2)) return undefined;
    if (!obj2 || typeof obj2 !== "object") return obj2;
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        let val;
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) val = obj2[key];

        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = diffObj(obj1[key], obj2[key]);
          if (value !== undefined) val = value;
        } else if (val !== undefined) {
          result[key] = val;
        }
      });
    return result;
  }
  const cancelChanges = () => {
    reset(getUserValues());
    setIsModified(false);
    enqueueSnackbar("Changes has been abandoned", { variant: "success" });
  };

  const saveChanges = async () => {
    if (!currentUser?.userId) {
      enqueueSnackbar("Something went wrong!, no Id", { variant: "error" });
      return;
    }
    const values = diffObj(getUserValues(), watch());

    if (JSON.stringify(getUserValues().applicant) !== JSON.stringify(watch().applicant)) {
      values.applicant = diffObj(getUserValues().applicant, watch().applicant);
    }
    // eslint-disable-next-line
    if (values.userId !== values.emailAddress) values.userId = values.emailAddress;
    messageRef.current = "User has been updated!";

    if (
      (userApplicant?.userType === "Client" ||
        (userApplicant?.userType === "User" && userApplicant?.employeeType === "Event Admin")) &&
      watch("clientOrgs") !== userApplicant?.clientOrgs
    )
      values.clientOrgs = watch("clientOrgs");

    await updateUserMutation.mutateAsync({
      userId: currentUser?.userId,
      data: values,
    });
  };

  const deleteUser = async () => {
    messageRef.current = "User has been deleted!";
    await updateUserInfoMutation.mutateAsync({
      userId: currentUser?._id,
      data: { status: "Deleted" },
    });
    navigate("/users");
    setFilters({});
    setCurrentUser({});
    setOpenDeleteUserModal(false);
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  const onDropImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { ...currentUser, profileImg: file.name },
      type: "photo",
    });
  };
  const onDropSignatureImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);
    uploadSignatureImgMutation.mutate({
      form,
      data: { ...currentUser, signature: file.name },
      type: "signature",
    });
  };

  const [banner, setBanner] = useState(null);

  const onDropBannerHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);
    setBanner(file.name);
    uploadImgMutation.mutate({
      form,
      data: { ...currentUser, bannerImg: file.name },
      type: "banner",
    });
  };

  const handleResendClick = () => {
    const delay = [30, 60, 90, 120];
    if (clickCount < delay.length) {
      setClickCount(clickCount + 1);
      setSecondsLeft(delay[clickCount]);
      setDisableResendButton(true);
    }
    resendOtp(); // Assuming sendOTP is a function that sends the OTP
  };

  useEffect(() => {
    let timer;
    if (clickCount > -1 && secondsLeft > 0) {
      timer = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setDisableResendButton(false);
      setSecondsLeft(null);
    }
    return () => clearTimeout(timer);
  }, [clickCount, secondsLeft]);

  const verifyModalHeader = (
    <>
      <MDTypography variant="h3">Verify Your Account</MDTypography>
    </>
  );
  const verifyModalBody = (
    <>
      <Box textAlign="center">
        <MDTypography textAlign="center">
          A One-Time Passcode has been sent to {getValues().email}.
        </MDTypography>
        <Box mt={3} width="60%" sx={{ marginLeft: "20%" }}>
          <PatternFormat
            value={otp}
            format="# # # # # #"
            mask="-"
            allowEmptyFormatting
            onValueChange={(e) => setOtp(e.value)}
            style={{
              fontSize: "4rem",
              textAlign: "center",
              width: "100%",
              boxSizing: "border-box",
            }}
          />
        </Box>
        {clickCount === 4 ? (
          <MDTypography textAlign="center">
            <span>Can&apos;t request to resend code</span>
          </MDTypography>
        ) : (
          <MDTypography textAlign="center">
            {secondsLeft !== null ? (
              <span>Resend available in {secondsLeft} seconds</span>
            ) : (
              <span>
                Didn&apos;t receive the code?{" "}
                <Button
                  disabled={disableResendButton}
                  sx={{ fontSize: "1.2rem", color: "red" }}
                  onClick={handleResendClick}
                >
                  RESEND CODE
                </Button>
              </span>
            )}
          </MDTypography>
        )}
      </Box>
    </>
  );
  const verifyModalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        color="secondary"
        onClick={() => {
          setOpenOTPModal(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        style={{ color: "white" }}
        disabled={submittingOTP}
        color="error"
        onClick={() => {
          setSubmittingOTP(true);
          verifyOneTimePasscode();
        }}
      >
        Submit
      </Button>
    </>
  );

  // function stringAvatar(userName) {
  //   if (userName?.firstName && userName?.lastName)
  //     return {
  //       children: `${userName?.firstName[0]}${userName?.lastName[0]}`,
  //     };
  //   return { children: "U" };
  // }

  // const avatar =
  //   currentUser?.profileImg && currentUser?._id ? (
  //     <MDAvatar
  //       src={`${IMAGE_SERVER}/users/${currentUser?._id}/photo/${currentUser?.profileImg}`}
  //       alt="profile-image"
  //       size="xl"
  //       shadow="sm"
  //     />
  //   ) : (
  //     <Avatar sx={{ bgcolor: black.light, width: 75, height: 75 }} {...stringAvatar(currentUser)} />
  //   );

  const signatureAvatar =
    currentUser?.signature && currentApplicant?._id ? (
      <Card>
        <img
          src={`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/signature/${currentUser?.signature}`}
          alt="signature"
          style={{ width: "100%", height: "100%" }}
        />
      </Card>
    ) : (
      <LandscapeIcon sx={{ width: "5em", height: "5em" }} />
    );

  const openChangeMyW4 = async () => {
    await setBottomPanel("changeMyW4");
    panelRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const openChangeDeposit = async () => {
    await setBottomPanel("changeDirectDeposit");
    panelRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const attachmentTitle = (
    <MDTypography component="label" variant="button" fontWeight="bold" color="info">
      UPLOAD ATTACHMENTS: ID PHOTO, DOCUMENTS
    </MDTypography>
  );

  const componentTitle = useMemo(() => {
    if (sourceComponent === "profile") {
      return "My Profile";
    }
    if (mode === "create") {
      return "Create User";
    }

    return `User: ${currentUser?.firstName} ${currentUser?.lastName} Info`;
  }, [mode, sourceComponent, userApplicant]);

  const handleOpenBannerMenu = (e) => {
    setBannerAnchorRef(e.target);
  };

  const handleCloseBannerMenu = (e) => {
    setBannerAnchorRef(null);
  };

  const handleUserIdentityDelete = async () => {
    messageRef.current = "Identity verification has been deleted!";
    await deleteUserIdentityMutation.mutateAsync({
      data: { email: userApplicant?.emailAddress },
    });
    toggleConfirmationModal(false);
  };

  const handleUserIdentityUpdate = async () => {
    messageRef.current = "Identity verification has been updated!";
    await updateUserIdentityMutation.mutateAsync({
      data: {
        email: userApplicant?.emailAddress,
        createAgent: currentLoggedUser?._id,
      },
    });
  };

  const handleEditOrg = (row, isOpen, idx) => {
    setCurrentOrg(row);
    setOrgModalOpen(isOpen);
    setOrgIndex(idx);
  };

  const handleRemoveOrg = (idx) => {
    const updatedOrgs = watch("clientOrgs").filter((_, index) => index !== idx);
    setValue("clientOrgs", updatedOrgs);
  };

  const handlePrimaryChange = (newIdx) => {
    const tempOrgs = watch("clientOrgs");
    const oldIdx = tempOrgs.findIndex(({ primary }) => primary === "Yes");
    const updatedOrgs = tempOrgs.map((item, i) => {
      if (i === oldIdx) return { ...item, primary: "No" };
      if (i === newIdx) return { ...item, primary: "Yes" };
      return item;
    });

    setValue("clientOrgs", updatedOrgs, { shouldDirty: true });
  };

  const deleteUserModalHandler = () => setOpenDeleteUserModal(!openDeleteUserModal);

  const renderUserBanner = () => (
    <BannerDropzone
      title="Select Banner"
      handleClose={handleCloseBannerMenu}
      anchorElem={bannerAnchorRef}
      attribute="bannerImg"
      urlPath=""
      apiUrl={`/upload/${currentUser?.slug}/venues/banner`}
      currentUser={currentUser}
      setCurrentUser={setCurrentUser}
    />
  );

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};

      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentUser(data);
      reset(defaultValues);
    }
  }, [watch, mode]);

  useEffect(() => {
    if (currentUser?.emailAddress && currentLoggedUser?.userType !== "User") {
      getApplicantIdentity(currentUser.emailAddress);
    }
  }, [currentUser?.emailAddress, currentLoggedUser?.userType]);

  useEffect(() => {
    if (mode === "edit") {
      if (userApplicant) {
        clearErrors();

        Object.keys(watch())?.forEach((key) => {
          if (typeof userApplicant[key] === "object") {
            Object.keys(watch())?.forEach((applicantKey) => {
              setValue(key.applicantKey, userApplicant[key.applicantKey] || "");
            });
          }
          setValue(key, userApplicant[key] || "");
        });
        setIsModified(false);
      }

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
    }
    return "";
  }, [getValues, userApplicant, confirmModal.response]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (userApplicant) {
        setIsModified(JSON.stringify(getUserValues()) !== JSON.stringify(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getUserValues, userApplicant]);

  useEffect(() => {
    if (mode === "create") {
      if (watch("emailAddress").split("@").pop() === `${company?.companyUrl}`) {
        setValue("spStatus", "Employee");
      } else {
        setValue("spStatus", null);
      }
    }
  }, [watch("emailAddress"), company?.companyUrl, mode]);

  const { refreshApplicant } = useRefreshApplicant();

  useEffect(() => {
    if (
      userApplicant?.userType === "Client" ||
      (userApplicant?.userType === "User" && userApplicant?.employeeType === "Event Admin")
    )
      setValue("clientOrgs", userApplicant?.clientOrgs);
    setOrgIndex(null);
    setCurrentOrg({});
  }, [userApplicant?._id]);

  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [panelSelect, setPanelSelect] = useState("activities");
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const [noteIndex, setNoteIndex] = useState(null);

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: (err) => {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
    },
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");

      if (data.status === "Deleted") {
        setToastAlert({ isShow: true, message: "Member was archived", status: "success" });
      } else {
        setToastAlert({ isShow: true, message: "Member has been updated!", status: "success" });
      }
    },
  });

  const saveNote = async (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };
    let res;
    if (noteIndex !== null) {
      const newNotesArray = currentApplicant?.notes ? [...currentApplicant?.notes] : [];
      newNotesArray[noteIndex] = newNote;

      setValue("notes", newNotesArray, { shouldDirty: true });
      await updateApplicantMutation.mutateAsync({
        applicantId: currentApplicant?._id,
        data: {
          notes: newNotesArray,
        },
      });
      await refreshApplicant();
    } else {
      await postNote({ applicantId: currentApplicant?._id, data: newNote })
        .then((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: "Notes Have Been Successfully Updated",
            status: "success",
          });
        })
        .catch((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: `Something went wrong! ${response}`,
            status: "error",
          });
        });
      if (res?.data?.modifiedCount) {
        await refreshApplicant();
      }
    }
    return res;
  };

  const updateNotes = async (newNotes) => {
    await updateApplicantMutation
      .mutateAsync({
        applicantId: currentApplicant?._id,
        data: {
          notes: newNotes,
        },
      })
      .then((response) => {
        setToastAlert({
          isShow: true,
          message: "Notes Have Been Successfully Updated",
          status: "success",
        });
      })
      .catch((response) => {
        setToastAlert({
          isShow: true,
          message: `Something went wrong! ${response}`,
          status: "error",
        });
      });
    await refreshApplicant();
  };
  const defaultReportData = [
    {
      id: 0,
      name: "Required",
      color: "#1A73E8",
      icon: <InfoIcon sx={{ color: "#1A73E8" }} />,
      value: 100,
    },
    {
      id: 1,
      name: "On Roster",
      color: "#4CAF50",
      icon: <CheckSharpIcon sx={{ color: "#4CAF50" }} />,
      value: 80,
    },
    {
      id: 2,
      name: "On Premise",
      color: "#FB8C00",
      icon: <WhereToVoteIcon sx={{ color: "#FB8C00" }} />,
      value: 90,
    },
    {
      id: 3,
      name: "Late",
      color: "#F44335",
      icon: <HourglassBottomIcon sx={{ color: "#F44335" }} />,
      value: 50,
    },
    {
      id: 4,
      name: "Mobile User",
      color: colors.violet.focus,
      icon: <AddToHomeScreenIcon sx={{ color: colors.violet.focus }} />,
      value: "60",
    },
  ];

  function panelSelector(selection) {
    switch (selection) {
      case "activities":
        return <ProfileSelectorActivities user={currentUser} profilePanel />;
      case "additionalInfo":
        return <AdditionalInformation setToastAlert={setToastAlert} />;
      case "attachments":
        return (
          <ProfileAttachments
            currentApplicant={currentApplicant}
            setCurrentApplicant={setCurrentApplicant}
            setCurrentUser={setCurrentUser}
            currentUser={currentUser}
            company={company}
          />
        );
      case "currentUser":
        return (
          <UserPanel
            currentApplicant={currentUser}
            profilePanel
            userIdentityVerification={userIdentityVerification}
            identityVerificationColor={identityVerificationColor}
            toggleConfirmationModal={toggleConfirmationModal}
            handleUserIdentityUpdate={handleUserIdentityUpdate}
          />
        );
      case "organizations":
        return (
          <ProfileOrganizations
            currentUser={currentUser}
            watch={watch}
            mode={mode}
            venues={venues}
            isCompany={isCompany}
            company={company}
            updateUserMutation={updateUserMutation}
            userApplicant={userApplicant}
            setCurrentUser={setCurrentUser}
            getUserValues={getUserValues()}
          />
        );
      // case "message":
      //   return <ApplicantSendMessagePanel profilePanel control={control} loginVerified={loginVerified} emailVerified={emailVerified} />;
      case "note":
        return (
          <ProfileNotes
            setOpen={setNoteModalOpen}
            currentApplicant={currentApplicant}
            saveNote={saveNote}
            updateNotes={updateNotes}
            toastAlert={toastAlert}
            setToastAlert={setToastAlert}
            currentUser={currentUser}
            watch={watch}
            getValues={getValues}
            setNoteIndex={setNoteIndex}
            noteIndex={noteIndex}
            isModified={isModified}
          />
        );
      case "report":
        return <ProfileReport data={defaultReportData} />;
      default:
        return null;
    }
  }

  return (
    <Card
      style={{
        backgroundColor: sourceComponent !== "profile" ? "transparent" : "#fff",
        boxShadow: sourceComponent !== "profile" ? "none" : "",
      }}
    >
      <>
        {sourceComponent !== "profile" ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingTop: "30px",
              paddingBottom: "20px",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "calc(50% - 10px)" }}>
              <ProfileMainInfoPanel
                setPanelSelect={setPanelSelect}
                panelSelect={panelSelect}
                currentUser={currentUser || {}}
                setCurrentUser={setCurrentUser}
                setFilters={setFilters}
                mode={mode}
                currentUserType={currentUserType}
                currentLoggedUser={currentLoggedUser}
                loggedUserType={loggedUserType}
                company={company}
              />
            </Box>
            <Box sx={{ width: "calc(50% - 10px)" }}>{panelSelector(panelSelect)}</Box>
          </Box>
        ) : (
          <form
            onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createUserHandler)}
          >
            <MDBox display="flex" sx={{ padding: "40px 40px 25px 40px" }}>
              <MDBox display="flex" width="100%">
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Grid container display="flex" direction="column">
                    <Grid item>
                      <MDTypography variant="h5" color="dark">
                        {componentTitle}
                      </MDTypography>
                    </Grid>
                    {mode === "edit" && (
                      <Grid item>
                        <MDTypography variant="body2">
                          Last Update:{" "}
                          {moment(userApplicant?.modifiedDate).format("ddd MM/DD/YYYY hh:mm a")}
                        </MDTypography>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
              </MDBox>

              <MDBox display="flex" alignItems="center">
                {currentUserType === "Master" &&
                  currentUser &&
                  isEdit &&
                  currentUser?._id !== currentLoggedUser?._id && (
                    <Button
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={deleteUserModalHandler}
                      style={{ width: "10rem", color: "#F44335" }}
                    >
                      Delete User
                    </Button>
                  )}
                {mode === "edit" && isModified && (
                  <>
                    <Button startIcon={<CancelIcon />} onClick={onCancel} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      startIcon={<SaveIcon />}
                      style={{ color: "#4CAF50" }}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>{" "}
                  </>
                )}
              </MDBox>

              {mode === "create" && (
                <MDBox display="flex" alignItems="center">
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isLoading}
                  >
                    Create
                  </Button>
                </MDBox>
              )}
            </MDBox>
            <MDBox sx={{ padding: "0 40px" }}>
              <Header
                currentUser={currentUser}
                userBanner={banner || currentUser?.bannerImg}
                onProfileImageChange={onDropImageHandler}
                onCoverPhotoChange={onDropBannerHandler}
                onOpenBannerMenu={handleOpenBannerMenu}
                renderUserBanner={renderUserBanner}
              />
            </MDBox>

            <MDBox sx={{ padding: "25px 40px 40px 40px" }}>
              <Grid container spacing={3} direction="column">
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <FormField
                        {...firstName}
                        key={`ProfileInfo_${firstName}`}
                        type="text"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors?.firstName && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.firstName?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <FormField
                        {...lastName}
                        key={`ProfileInfo_${lastName}`}
                        type="text"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors?.lastName && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.lastName?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    {/* {sourceComponent === "user" && ( */}
                    <Grid item xs={3}>
                      <Controller
                        {...status}
                        control={control}
                        defaultValue={userApplicant?.status}
                        render={({ field }) => (
                          <Autocomplete
                            key={`autocomplete_status_${userApplicant?._id}`}
                            options={userStatusField || []}
                            autoSelect
                            name="status"
                            defaultValue={userApplicant?.status}
                            value={field.value}
                            className={classes.status}
                            onChange={(e, v) => {
                              if (v === "Partner") setValue("partnerSlug", "");
                              else setValue("systemSource", userApplicant?.systemSource);
                              return field.onChange(v);
                            }}
                            onBlur={() => trigger(["rank", "primaryPosition", "status"])}
                            renderInput={(params) => (
                              <FormField
                                label="Status"
                                key={`status_${userApplicant?._id}`}
                                {...params}
                                type="text"
                              />
                            )}
                            classes={{ input: classes.status }}
                          />
                        )}
                      />
                      {errors?.status && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.status?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    {/* )} */}
                    {currentLoggedUser?.userType !== "User" && (
                      <Grid item xs={3}>
                        <Controller
                          {...userType}
                          control={control}
                          defaultValue={userApplicant && userApplicant?.userType}
                          render={({ field }) => (
                            <Autocomplete
                              disabled={mode === "edit"}
                              key={`userType_autocomplete_${currentUser?._id}`}
                              options={
                                mode === "edit" ? userTypes || [] : ["Master", "Admin", "Client"]
                              }
                              name="userType"
                              autoSelect
                              defaultValue={userApplicant && userApplicant?.userType}
                              value={field.value}
                              onChange={(e, v) => field.onChange(v)}
                              renderInput={(params) => (
                                <FormField
                                  key={`userType_autocomplete_${currentUser?._id}`}
                                  {...params}
                                  InputLabelProps={{ shrink: true }}
                                  type="text"
                                  label="User Type"
                                />
                              )}
                            />
                          )}
                        />
                        {errors?.userType && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.userType?.message}
                          </MDTypography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3}>
                    {/* {sourceComponent === "user" && ( */}
                    <Grid item xs={3}>
                      <Controller
                        {...spStatus}
                        control={control}
                        defaultValue={userApplicant?.spStatus}
                        render={({ field }) => (
                          <Autocomplete
                            key={`autocomplete_spStatus_${userApplicant?._id}`}
                            options={userSPStatusField || []}
                            autoSelect
                            freeSolo
                            disabled={currentUserType === "User"}
                            name="spStatus"
                            defaultValue={userApplicant?.spStatus}
                            value={field.value}
                            className={classes.spStatus}
                            onChange={(e, v) => {
                              if (v === "Partner") setValue("partnerSlug", "");
                              else setValue("systemSource", userApplicant?.systemSource);
                              return field.onChange(v);
                            }}
                            onBlur={() => trigger(["rank", "primaryPosition", "spStatus"])}
                            renderInput={(params) => (
                              <FormField
                                label={`${company?.name} Status`}
                                key={`spStatus_${userApplicant?._id}`}
                                {...params}
                                type="text"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                            classes={{ input: classes.spStatus }}
                          />
                        )}
                      />
                      {errors?.status && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.spStatus?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    {/* )} */}
                    <Grid item xs={3}>
                      <FormField
                        {...emailAddress}
                        disabled={mode === "edit"}
                        type="text"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors?.emailAddress && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.emailAddress?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        {...phone}
                        control={control}
                        defaultValue={userApplicant?.applicant?.phone}
                        render={({ field }) => (
                          <PatternFormat
                            format="(###) ### ####"
                            defaultValue={userApplicant?.applicant?.phone}
                            allowEmptyFormatting
                            mask="_"
                            label="Primary Phone"
                            {...field}
                            customInput={FormField}
                          />
                        )}
                      />

                      {errors?.applicant?.phone && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.applicant?.phone?.message}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        {...altPhone}
                        control={control}
                        defaultValue={userApplicant?.applicant?.altPhone}
                        render={({ field }) => (
                          <PatternFormat
                            format="(###) ### ####"
                            allowEmptyFormatting
                            defaultValue={userApplicant?.applicant?.altPhone}
                            mask="_"
                            label="Secondary Phone"
                            {...field}
                            customInput={FormField}
                          />
                        )}
                      />

                      {errors?.applicant?.altPhone && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.applicant?.altPhone?.message}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {sourceComponent === "user" &&
                  (loggedUserType === "Master" || loggedUserType === "Admin") && (
                    <Grid item>
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          {isCompany && (
                            <>
                              {" "}
                              <FormField
                                {...organization}
                                type="text"
                                label="Employer's Business or Organization Name"
                                InputLabelProps={{ shrink: true }}
                              />
                              {errors?.organization && (
                                <MDTypography className={classes.error} color="error">
                                  {errors?.organization?.message}
                                </MDTypography>
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid item sm={6}>
                          {isCompany && (
                            <>
                              <FormField
                                {...title}
                                type="text"
                                label="Title of Employer or Authorized Representative"
                                InputLabelProps={{ shrink: true }}
                              />
                              {errors?.title && (
                                <MDTypography className={classes.error} color="error">
                                  {errors?.title?.message}
                                </MDTypography>
                              )}
                            </>
                          )}
                          {!isCompany && watch("userType") === "User" && (
                            <>
                              <Controller
                                {...employeeType}
                                control={control}
                                defaultValue={(currentUser && currentUser?.employeeType) || " "}
                                render={({ field }) => (
                                  <Autocomplete
                                    key={`autocomplete_employeeType_${currentUser?.id}`}
                                    options={employeeTypes || []}
                                    autoSelect
                                    freeSolo
                                    name="employeeType"
                                    defaultValue={(currentUser && currentUser?.employeeType) || " "}
                                    value={field.value}
                                    onChange={(e, v) => {
                                      field.onChange(v);
                                    }}
                                    onBlur={() => trigger("title")}
                                    renderInput={(params) => (
                                      <FormField
                                        key={`employeeType_${currentUser?._id}`}
                                        {...params}
                                        type="text"
                                        label="Employee Type"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                )}
                              />

                              {errors?.employeeType && (
                                <MDTypography className={classes.error} color="error">
                                  {errors?.employeeType?.message}
                                </MDTypography>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormField
                        {...address1}
                        // key={`firstScreen_address1_`}
                        type="text"
                        label="Address"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <FormField
                        {...city}
                        // key={`firstScreen_city_`}
                        type="text"
                        label="City"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Controller
                            {...state}
                            control={control}
                            defaultValue={(userApplicant && userApplicant?.applicant?.state) || " "}
                            render={({ field }) => (
                              <Autocomplete
                                key={`autocomplete_state_${userApplicant?.applicant?.id}`}
                                options={states || []}
                                autoSelect
                                freeSolo
                                name="state"
                                defaultValue={
                                  (userApplicant && userApplicant?.applicant?.state) || " "
                                }
                                value={field.value}
                                onChange={(e, v) => {
                                  field.onChange(v.toUpperCase());
                                }}
                                onBlur={() => trigger("state")}
                                renderInput={(params) => (
                                  <FormField
                                    key={`state_${currentUser?._id}`}
                                    {...params}
                                    type="text"
                                    label="State"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                              />
                            )}
                          />
                          {errors?.applicant?.state && (
                            <MDTypography className={classes.error} color="error">
                              {errors?.applicant?.state?.message}
                            </MDTypography>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <FormField
                            {...zip}
                            // key={`firstScreen_zip_`}
                            type="text"
                            label="Zip Code"
                            InputLabelProps={{ shrink: true }}
                          />
                          {errors?.applicant?.zip && (
                            <MDTypography className={classes.error} color="error">
                              {errors?.applicant?.zip?.message}
                            </MDTypography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {sourceComponent === "user" && loggedUserType === "Master" && (
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item sm={6}>
                        <Controller
                          {...startDate}
                          name="startDate"
                          control={control}
                          defaultValue={
                            userApplicant?.startDate
                              ? moment(userApplicant?.startDate).format("MM/DD/YYYY")
                              : null
                          }
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                label="Start Date"
                                value={!field.value ? null : field.value}
                                onChange={(value) => {
                                  if (moment(value).isValid()) {
                                    field.onChange(new Date(value));
                                  } else {
                                    field.onChange(value);
                                  }
                                }}
                                renderInput={(params) => (
                                  <FormField {...params} InputLabelProps={{ shrink: true }} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors?.startDate && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.startDate?.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item sm={6}>
                        <Controller
                          {...endDate}
                          name="endDate"
                          control={control}
                          defaultValue={
                            userApplicant?.endDate
                              ? moment(userApplicant?.endDate).format("MM/DD/YYYY")
                              : null
                          }
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                disablePast
                                label="End Date"
                                value={!field.value ? null : field.value}
                                onChange={(value) => {
                                  if (moment(value).isValid()) {
                                    field.onChange(new Date(value));
                                  } else {
                                    field.onChange(value);
                                  }
                                }}
                                renderInput={(params) => (
                                  <FormField {...params} InputLabelProps={{ shrink: true }} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors?.endDate && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.endDate?.message}
                          </MDTypography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {loggedUserType === "Master" &&
                  (watch("userType") === "Client" ||
                    (watch("userType") === "User" && watch("employeeType") === "Event Admin")) && (
                    <Grid item sm={6}>
                      <DataListContainer
                        onAdd={() => {
                          setOrgIndex(null);
                          setCurrentOrg({});
                          setOrgModalOpen(true);
                        }}
                        data={watch("clientOrgs") || []}
                        renderRow={(row, idx) => (
                          <OrganizationsItem
                            row={row}
                            idx={idx}
                            onEdit={handleEditOrg}
                            onRemove={handleRemoveOrg}
                            onPrimaryChange={handlePrimaryChange}
                          />
                        )}
                        title="Organizations"
                      />
                    </Grid>
                  )}

                <Grid item mt={1}>
                  <Grid container spacing={3}>
                    {(loggedUserType === "Master" || loggedUserType === "Admin") && (
                      <>
                        <Grid item sm={6}>
                          {isEdit && (
                            <MDBox>
                              <MDTypography component="label" variant="body2">
                                Signature File
                              </MDTypography>

                              <FileDropzone
                                className={classes.dropzone}
                                onDrop={onDropSignatureImageHandler}
                                avatar={signatureAvatar}
                              />
                            </MDBox>
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MDTypography variant="body2">Email Verified</MDTypography>
                          <Controller
                            {...emailVerified}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                disabled
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                classes={{
                                  switchBase: classes.switchBase,
                                  thumb: classes.thumb,
                                }}
                              />
                            )}
                          />
                          {/* <MDTypography variant="body2">
                    {getValues().applicant?.emailVerified}
                  </MDTypography> */}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MDTypography variant="body2">Login Verified</MDTypography>
                          <Controller
                            {...loginVerified}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                disabled
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                classes={{
                                  switchBase: classes.switchBase,
                                  thumb: classes.thumb,
                                }}
                              />
                            )}
                          />
                          {/* <MDTypography variant="body2">{getValues().loginVerified}</MDTypography> */}
                        </Grid>
                      </>
                    )}
                    {currentLoggedUser?.userType === "Master" && currentUser?.userType !== "User" && (
                      <Grid item sm={2} display="flex" alignItems="center">
                        <Tooltip
                          title={`Email ${userApplicant?.emailAddress} is ${userIdentityVerification} for SES Email`}
                        >
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <MDTypography
                              variant="body2"
                              fontWeight={
                                userIdentityVerification === "verified" ||
                                userIdentityVerification === "submitted"
                                  ? "bold"
                                  : "light"
                              }
                              color={identityVerificationColor}
                            >
                              Identity Verified
                            </MDTypography>
                            <Switch
                              classes={{
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                              }}
                              disabled={mode === "create"}
                              color={identityVerificationColor}
                              checked={
                                userIdentityVerification === "verified" ||
                                userIdentityVerification === "submitted"
                              }
                              onChange={(e) => {
                                if (
                                  userIdentityVerification === "verified" ||
                                  userIdentityVerification === "submitted"
                                )
                                  toggleConfirmationModal(true);
                                else {
                                  handleUserIdentityUpdate();
                                }
                              }}
                            />
                          </Grid>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {currentUserType === "User" && sourceComponent === "profile" && (
                  <>
                    <Grid item sm={12}>
                      <Grid container spacing={4}>
                        <Grid item sm={3} sx={{ display: "flex", flexDirection: "column" }}>
                          <Button fullWidth variant="contained" onClick={setAttachmentOpen}>
                            UPLOAD ATTACHMENTS
                          </Button>
                        </Grid>
                        <Grid item sm={3} sx={{ display: "flex", flexDirection: "column" }}>
                          <Button fullWidth variant="contained" onClick={openChangeMyW4}>
                            CHANGE MY W-4 WITHHOLDINGS
                          </Button>
                        </Grid>
                        <Grid item sm={3}>
                          <Button fullWidth variant="contained" onClick={openChangeDeposit}>
                            CHANGE MY DIRECT DEPOSIT
                          </Button>
                        </Grid>
                        <Grid item sm={3}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              setChangeEmailModalOpen(true);
                            }}
                          >
                            CHANGE MY EMAIL ADDRESS
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <Attachments
                        currentApplicant={currentApplicant}
                        setOpen={setAttachmentOpen}
                        attachmentTitle={attachmentTitle}
                        noButton
                      />
                    </Grid>
                  </>
                )}
                <MDTypography fontWeight="bold" sx={{ mt: 1.5, ml: 3, mb: -1, color: "#1A73E8" }}>
                  Activities
                </MDTypography>
                <Grid item>
                  <MDBox sx={{ maxHeight: 700 }}>
                    <ProfileActivities
                      currentId={currentUser?.applicantId}
                      height={702}
                      tableHeight={700}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </form>
        )}
      </>
      <OrganizationsModal
        isOpen={isOrgModalOpen}
        setOpen={setOrgModalOpen}
        setValue={setValue}
        organizations={watch("clientOrgs") || []}
        orgIndex={orgIndex}
        currentOrg={currentOrg}
        userRecordUserType={currentUser?.userType}
      />
      <AddANoteModal
        currentApplicant={currentApplicant}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        showType={false}
        saveNote={saveNote}
      />
      <UploadFileModal
        currentApplicant={currentApplicant}
        setCurrentApplicant={setCurrentApplicant}
        open={attachmentOpen}
        setOpen={setAttachmentOpen}
      />
      <ConfirmationModal
        title={`Are you sure you want to set ${userApplicant?.emailAddress} to Unsubmitted?        ?`}
        isOpen={isConfirmationOpen}
        onClose={() => {
          toggleConfirmationModal(false);
        }}
        onConfirm={() => handleUserIdentityDelete()}
      />
      <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
      {openDeleteUserModal && (
        <DeleteModal
          title="Delete User"
          description="Please confirm you want to DELETE this user."
          isOpen={openDeleteUserModal}
          negativeBtn={{
            label: "Cancel",
            fn: deleteUserModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deleteUser,
            isShown: true,
          }}
        />
      )}
      {(changeEmailModalOpen || changeEmailSuccessModalOpen) && (
        <ChangeEmailModal
          user={userApplicant}
          changeEmailModalOpen={changeEmailModalOpen}
          setChangeEmailModalOpen={setChangeEmailModalOpen}
          changeEmailSuccessModalOpen={changeEmailSuccessModalOpen}
          setChangeEmailSuccessModalOpen={setChangeEmailSuccessModalOpen}
          openOTPModal={openOTPModal}
          setOpenOTPModal={setOpenOTPModal}
          oldEmail={userApplicant?.emailAddress}
          newEmailEdit={newEmailEdit}
          setNewEmailEdit={setNewEmailEdit}
        />
      )}
      {openOTPModal && (
        <GenericModal
          open={openOTPModal}
          setOpen={setOpenOTPModal}
          header={verifyModalHeader}
          body={verifyModalBody}
          buttons={verifyModalButtons}
          width="25%"
        />
      )}
    </Card>
  );
}

export default ProfileInfo;
