import DataList from "components/DataList";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { IconButton, Stack } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const DataListContainer = ({
  onAdd,
  data,
  renderRow,
  title,
  subtext = null,
  tableHeight = 155,
  height = 157,
  header,
  selected,
  tableHead,
  isLoading = false,
  noCreate = false,
  AddIcon,
  allowReorder,
  ListWrapper,
  listWrapperProps,
  autoHeight,
}) => {
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mt: 1, mb: "5px" }}
      >
        <MDTypography
          component="label"
          variant="button"
          fontWeight="regular"
          color="secondary"
          sx={{ fontSize: "0.74rem" }}
        >
          {title || ""}
        </MDTypography>
        {subtext && (
          <MDTypography
            component="label"
            variant="button"
            fontWeight="bold"
            color="info"
            sx={{ fontSize: "0.74rem" }}
          >
            {subtext || ""}
          </MDTypography>
        )}
        <Stack direction="row">
          {header}
          {!noCreate && (
            <IconButton color="info" onClick={() => onAdd(null, null, true)} sx={{ padding: 0 }}>
              {AddIcon ?? <AddCircleRoundedIcon />}
            </IconButton>
          )}
        </Stack>
      </MDBox>
      <DataList
        renderRow={renderRow}
        data={data}
        greybar
        divider
        scrollY
        boxPadding={0}
        cardVariant="outlined"
        headerStyle={() => ({ display: "none" })}
        width="100%"
        tableHeight={tableHeight}
        height={height}
        selected={selected}
        tableHead={tableHead}
        isLoading={isLoading}
        ListWrapper={ListWrapper}
        listWrapperProps={listWrapperProps}
        autoHeight={autoHeight}
      />
    </>
  );
};

export default DataListContainer;
