import DateRangeIcon from "@mui/icons-material/DateRange";
import ListIcon from "@mui/icons-material/List";
import { Box, Button, Grid, Stack, Tooltip, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import enrollUserToEvent from "api/events/enrollUserToEvent";
import colors from "assets/theme/base/colors";
import DataList from "components/DataList";
import EventDate from "components/EventDate";
import FilterButtons from "components/FilterButtons";
import { MY_EVENTS_OPTIONS } from "components/FilterButtons/options";
import MDTypography from "components/MDTypography";
import MessageModal from "components/MessageModal";
import Searchbar from "components/Searchbar";
import TimeInOutModal from "components/TimeInOutModal";
import EventNumbers from "components/EventNumbers";
import VenueIcon from "components/VenueIcon";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import MyEventsActions from "layouts/pages/dashboard/components/MyEventsActions";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchEventsCurrent from "layouts/pages/events/actions/fetchEventsCurrent";
import EventCalendar from "layouts/pages/events/components/EventCalendar";
import EventsPreviewModal from "layouts/pages/events/components/EventsPreviewModal";
import {
  APPLICANT_EVENT_STATUS_ENUM,
  APPLICANT_VENUE_STATUS_ENUM,
} from "utils/constants/applicant";
import { checkIfApplicantVenuePending, getApplicantEventStatus } from "utils/helpers/applicant";
import { filterApplicantEvents, getReportDateTime } from "utils/helpers/events";
import { isAdmin, isEmployeeUser } from "utils/helpers/roles";
import InfiniteScrollDataList from "components/InfiniteScrollDataList";
import useSessionAuth from "hooks/useSessionAuth";

const PAGE_SIZE = 16;

const MyEvents = ({ fetchAll, filters, setFilters }) => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const {
    company,
    setCompany,
    venues,
    setVenues,
    setCurrentEvent,
    userType,
    currentLoggedUser,
    currentApplicant,
    setCompanyType,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const [page, setPage] = useState(1);
  const [dataView, setDataView] = useState("list");
  const [isEventPreviewOpen, toggleEventPreview] = useState(false);
  const [isTimeModalOpen, toggleTimeModal] = useState(false);
  const [isMessageModalOpen, toggleMessageModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [myVenues, setMyVenues] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    rosterStatus: "all",
  });

  const isEventAdmin = currentLoggedUser?.employeeType === "Event Admin";

  const eventAdminVenues = useMemo(
    () => currentLoggedUser?.clientOrgs?.map((item) => item.slug) ?? [],
    [currentLoggedUser?.clientOrgs]
  );
  const options = useMemo(() => {
    return { fetchAll: false };
  }, []);

  const allOptions = useMemo(() => {
    const result =
      userType !== "User"
        ? {
            ...options,
            filters: { ...filters, timeFrame: filters.timeFrame ? filters.timeFrame : "Current" },
            page,
            limit: PAGE_SIZE,
            order: "asc",
            orderBy: "eventDate",
          }
        : {
            fetchAll: false,
            filters: {
              ...filters,
              eventType: "Event",
              timeFrame: filters.timeFrame ? filters.timeFrame : "Current",
              venueSlug: myVenues.join(";"),
            },
            applicantId: currentLoggedUser?.applicantId,
            page,
            limit: PAGE_SIZE,
            order: "asc",
            orderBy: "eventDate",
          };

    if (userType === "User" && dataFilter?.rosterStatus === "inRoster")
      result.filters["applicants.id"] = currentLoggedUser?.applicantId;
    return result;
  }, [
    currentLoggedUser?.applicantId,
    dataFilter?.rosterStatus,
    filters,
    myVenues,
    options,
    page,
    userType,
  ]);

  const {
    data: fetchedEvents,
    isLoading,
    isFetching,
    fetchNextPage,
    refetch: refetchMyEvents,
  } = useInfiniteQuery(
    ["events", allOptions],
    ({ queryKey, pageParam }) => {
      if (allOptions.filters?.venueSlug) {
        return fetchEvents({ ...allOptions, page: pageParam ?? 1 });
      }
      return {};
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
          return undefined;
        }

        const nextPage = lastPage?.pagination?.next?.page;
        return nextPage ?? false;
      },
    }
  );
  const fullData = useMemo(
    () =>
      fetchedEvents?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [fetchedEvents?.pages]
  );

  const allOptionsForCalendar = useMemo(() => {
    const result = { ...allOptions };
    result.fetchAll = true;
    result.projectedFields = [
      "eventDate",
      "rosterStatus",
      "reportTimeTBD",
      "eventName",
      "eventUrl",
      "makePublicAndSendNotification",
      "positionsRequested",
    ].join(",");
    delete result.limit;
    delete result.page;
    return result;
  }, [allOptions]);

  const { data: fetchedEventsForCalendar, isLoading: isLoadingForCalendar } = useQuery(
    ["events-for-calendar", allOptionsForCalendar],
    () => {
      if (allOptions.filters?.venueSlug) {
        return fetchEvents(allOptionsForCalendar);
      }
      return {};
    },
    {
      enabled: dataView === "calendar",
      staleTime: 30000,
    }
  );

  const { mutate: enrollUserMutation } = useMutation(enrollUserToEvent, {
    onSuccess: () => refetchMyEvents(),
  });

  const navigate = useNavigate();
  const addNew = () => {
    setCurrentEvent({});
    navigate("/events/create");
  };
  const viewAll = () => navigate("/events?myVenues=true");
  const { logoutAuth0User } = useSessionAuth();

  useMemo(() => {
    const setUserVenues = async (appId, employeeType, clientOrgs) => {
      const app = await fetchSingleApplicant({ applicantId: appId });
      let venList = app?.data?.venues
        ?.filter((venue) => venue.status !== APPLICANT_VENUE_STATUS_ENUM.LOCKED)
        .map((item) => item.venueSlug);

      if (employeeType === "Event Admin" && clientOrgs?.length) {
        const adminVenList = clientOrgs.map((item) => item.slug);
        venList = Array.from(new Set([...venList, ...adminVenList]));
      }

      setMyVenues(venList || []);
    };
    if (userType === "User" && currentLoggedUser && currentLoggedUser.applicantId) {
      try {
        setUserVenues(
          currentLoggedUser.applicantId,
          currentLoggedUser.employeeType,
          currentLoggedUser.clientOrgs
        );
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [currentLoggedUser, venues]);

  const isApplicantEventVenuePending = useMemo(() => {
    const eventVenueSlug = selectedEvent?.venueSlug;
    const applicantVenues = currentApplicant?.venues;
    if (!isEmpty(eventVenueSlug) && !isEmpty(applicantVenues)) {
      return checkIfApplicantVenuePending(applicantVenues, eventVenueSlug);
    }
    return false;
  }, [selectedEvent, currentApplicant]);

  const handleTogglePreview = (isOpen) => {
    if (isApplicantEventVenuePending && isEmployeeUser(userType)) {
      toggleMessageModal(true);
      const applicantEvent = getApplicantEventStatus(currentApplicant, selectedEvent);
      if (
        selectedEvent?.eventUrl &&
        applicantEvent?.status === APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER
      ) {
        const body = {
          requestType: "Request",
          agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
          createAgent: currentLoggedUser._id,
        };
        enrollUserMutation({
          eventUrl: selectedEvent?.eventUrl,
          applicantId: currentLoggedUser.applicantId,
          body,
        });
      }
    } else {
      toggleEventPreview(isOpen);
    }
  };

  const columns = useMemo(
    () => [
      { title: "", field: "logoUrl", type: "image", width: "10%" },
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
        width: "15%",
      },
      { title: "Event Url", field: "eventUrl", width: "25%" },
      { title: "Event", field: "eventName", width: "25%" },
      {
        title: "Event Date",
        field: "eventDate",
        customCell: (date) => moment(date).format("MM/DD/YYYY"),
        width: "10%",
      },
      {
        title: "Report Time",
        field: "eventDate",
        width: "10%",
        customCell: (reportTime) => {
          if (reportTime) {
            const nonFormattedTime = reportTime.split(" ")[0];
            const hours = Number(nonFormattedTime.split(":")[0]);
            const minutes = nonFormattedTime.split(":")[1];

            let formattedTime = "";
            let time = "";

            if (hours < 12) {
              time = `${hours}:${minutes} AM`;
            }
            if (hours === 12) {
              time = `${hours}:${minutes} AM`;
            }
            if (hours > 12) {
              time = `${hours - 12}:${minutes} PM`;
            }

            if (time.split(":")[0].length === 1) formattedTime = `0${time}`;
            else formattedTime = time;

            return formattedTime;
          }
          return "";
        },
      },

      { title: "Status", field: "status" },
    ],
    []
  );

  const renderRow = (row) => (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap">
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={12} md={7} sx={{ display: "flex", gap: "10px" }}>
          {venues && (
            <VenueIcon
              logoUrl={venues[row?.venueSlug]?.logoUrl}
              slug={row?.venueSlug}
              name={row?.venueName}
            />
          )}
          <Grid container alignItems="center" key={row?.eventUrl}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <MDTypography variant="subtitle2">{row.eventName}</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="body2">
                  <EventDate
                    date={getReportDateTime(
                      row?.applicants,
                      row?.eventDate,
                      currentLoggedUser?.applicantId,
                      userType
                    )}
                    reportTimeTBD={row?.reportTimeTBD}
                  />
                </MDTypography>
                <Box sx={{ whiteSpace: "nowrap" }}>
                  {row.venueCity}, {row.venueState}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Grid container flexDirection="column" alignItems="flex-end">
            {/* Updated here */}
            <Grid item>
              <MyEventsActions
                status={row?.rosterStatus}
                eventUrl={row?.eventUrl}
                row={row}
                setNavigateUrl={navigate}
                openEventPreview={() => {
                  setSelectedEvent(row);
                  handleTogglePreview(true);
                }}
                openTimeModal={() => {
                  setSelectedEvent(row);
                  toggleTimeModal(true);
                }}
              />
            </Grid>
            {(userType !== "User" ||
              (isEventAdmin && eventAdminVenues.includes(row?.venueSlug))) && (
              <Grid item mt={2} sx={{ width: "100%" }}>
                {" "}
                <EventNumbers
                  eventUrl={row?.eventUrl}
                  requested={row?.positionsRequested}
                  onRoster={row?.numberOnRoster}
                  onWaitlist={row?.numberOnWaitlist}
                  onRequest={row?.numberOnRequest}
                  onPremise={row?.numberOnPremise}
                  fontSize="medium"
                  isClient={userType === "Client"}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );

  const calendarListView = (
    <Tooltip title="Click to Toggle Calendar/List View">
      <Button
        onClick={() => setDataView((prevState) => (prevState === "calendar" ? "list" : "calendar"))}
        startIcon={dataView === "calendar" ? <ListIcon /> : <DateRangeIcon />}
        color="inherit"
        sx={{
          textTransform: "capitalize",
          whiteSpace: "nowrap",
          color: dataView !== "calendar" ? colors.success.main : colors.error.main,
          fontSize: "14px",
          "& svg": {
            fontSize: "1.25rem !important",
          },
          px: "1rem",
        }}
      >
        {dataView === "calendar" ? "LIST" : "CALENDAR"}
      </Button>
    </Tooltip>
  );

  const searchBarRowClick = (item) => {
    setCurrentEvent(item);
    navigate(`/events/${item.eventUrl}/action/eventsinfo`);
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      {userType !== "Client" ? (
        <Grid container mt={2} ml={5}>
          <Grid item xs={12} md={userType === "User" ? 6 : 5}>
            {isAdmin(userType) ? (
              <Searchbar
                fetch={fetchEventsCurrent}
                fetchAll={fetchAll}
                placeholder="Search Event"
                columns={columns.slice(1, columns.length - 1)}
                queryCharacterLimit={1}
                resultsLimit={10}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["venueSlug", "eventName"]}
                filterBy="venueSlug"
                onRowClick={(item) => searchBarRowClick(item)}
              />
            ) : (
              <FilterButtons
                options={MY_EVENTS_OPTIONS}
                filter={dataFilter}
                setFilter={setDataFilter}
                name="rosterStatus"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FlexWrapper
              justifyContent={{ xs: "space-evenly", md: "flex-end" }}
              flexDirection={{ xs: "row" }}
              height="100%"
            >
              {calendarListView}
              <Button variant="text" color="primary" onClick={viewAll} sx={{ px: "1rem" }}>
                View All
              </Button>
              {userType !== "User" && (
                <Button variant="text" color="primary" onClick={addNew}>
                  Add New
                </Button>
              )}
            </FlexWrapper>
          </Grid>
        </Grid>
      ) : (
        <FlexWrapper
          justifyContent={{ xs: "space-evenly", md: "flex-end" }}
          flexDirection={{ xs: "row" }}
          height="100%"
          sx={{ mr: 1 }}
        >
          {calendarListView}
        </FlexWrapper>
      )}
    </Stack>
  );

  const filteredDataForCalendar = useMemo(() => {
    if (!userType) return null;
    if (isEmployeeUser(userType)) {
      if (currentApplicant && myVenues?.length) {
        return {
          ...fetchedEventsForCalendar,
          data:
            fetchedEventsForCalendar?.data?.lenght < 1
              ? []
              : filterApplicantEvents(currentApplicant, fetchedEventsForCalendar?.data),
        };
      }
      return null;
    }
    return fetchedEventsForCalendar;
  }, [fetchedEventsForCalendar, userType, currentApplicant, myVenues]);

  const filteredData = useMemo(() => {
    if (!userType) return null;
    if (isEmployeeUser(userType)) {
      if (currentApplicant && myVenues?.length) {
        return {
          ...fetchedEvents,
          data:
            fetchedEvents?.data?.lenght < 1
              ? []
              : filterApplicantEvents(currentApplicant, fetchedEvents?.data),
        };
      }
      return null;
    }
    return fetchedEvents;
  }, [fetchedEvents, userType, currentApplicant, myVenues]);

  return (
    <>
      <DataList
        renderRow={renderRow}
        infiniteData={fetchedEvents?.pages?.[fetchedEvents?.pages?.length - 1]}
        icon="event-seat"
        iconColor="success"
        title="My Events"
        isLoading={isLoading}
        greybar
        divider
        header={header}
        tableHeight="60%"
        height={500}
        scrollY
        computedHeight
        keyField="eventUrl"
        cardVariant="outlined"
        dataFilter={dataFilter}
        dataViewComponent={
          <Box style={{ maxHeight: 400, overflowY: "scroll" }}>
            <EventCalendar
              events={filteredDataForCalendar}
              isLoading={isLoadingForCalendar}
              calendarListView="Calendar"
              onClickEventOverride={(row) => {
                setSelectedEvent(row);
                handleTogglePreview(true);
              }}
            />
          </Box>
        }
        renderDataView={dataView === "calendar"}
        data={fullData}
        isFetching={isFetching}
        loadMore={fetchNextPage}
      />
      {isEventPreviewOpen && (
        <EventsPreviewModal
          open={isEventPreviewOpen}
          setOpen={toggleEventPreview}
          eventPreview={selectedEvent}
          setEventPreview={setSelectedEvent}
          refetchEvents={refetchMyEvents}
          content="all"
        />
      )}
      {isTimeModalOpen && (
        <TimeInOutModal
          id={currentLoggedUser?.applicantId}
          isOpen={isTimeModalOpen}
          toggleOpen={toggleTimeModal}
          event={selectedEvent}
        />
      )}
      {isMessageModalOpen && (
        <MessageModal
          id={currentLoggedUser?.applicantId}
          message="You must be approved to work at this Venue before you can view and sign up for events. Check your email for an approval within the next 24-48 hours"
          isOpen={isMessageModalOpen}
          toggleOpen={toggleMessageModal}
        />
      )}
    </>
  );
};

export default MyEvents;
