import { useEffect, useMemo, useState } from "react";
import {
  Card,
  Icon,
  Typography,
  Grid,
  Autocomplete,
  Switch,
  Stack,
  Button,
  Box,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient, useQuery } from "react-query";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { useAppContextController } from "context/AppContext";
import { makeStyles, styled } from "@mui/styles";
import { applicantAdditionalInfoSchema } from "data/applicantAdditionalInfo";
import { getDirtyFields } from "utils/helpers/formHelpers";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FormField from "layouts/applications/wizard/components/FormField";
import useDropdowns from "hooks/useDropdowns";
import MDEditor from "components/MDEditor";

import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { NumericFormat } from "react-number-format";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import MDTypography from "components/MDTypography";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { getCustomFieldByFeature } from "api/customFields";
import CustomField from "components/CustomField";
import { fetchApplicantCustomFields } from "api/applicants/fetchApplicantCustomFields";
import { featureCustomFieldSchema } from "data/featureCustomFieldSchema";

const useStyle = makeStyles({
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
    height: 850,
  },
});

const ApplicantAdditionalInfo = () => {
  const { currentApplicant, setCurrentApplicant } = useAppContextController();
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [usersNames, setUsersNames] = useState();
  const { dropdowns: lifts } = useDropdowns({ entity: "lift" });
  const { dropdowns: transportationOptions } = useDropdowns({ entity: "transportation" });
  const { dropdowns: criminalRecords } = useDropdowns({ entity: "criminalRecord" });
  const { dropdowns: shifts } = useDropdowns({ entity: "shift" });
  const { dropdowns: employmentTypes } = useDropdowns({ entity: "employmentTypes" });
  const { dropdowns: languages } = useDropdowns({ entity: "language" });
  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );

  const { data: customFieldsResponse, isLoading: isLoadingFields } = useQuery(
    ["fetchCustomFieldsByFeature", "Applicants & Employees"],
    () => getCustomFieldByFeature("Applicants & Employees"),
    {
      enabled: !!currentApplicant?._id,
      onError: (error) => {
        enqueueSnackbar(`Error loading custom fields: ${error.message}`, { variant: "error" });
      },
    }
  );

  const customFields = useMemo(
    () => customFieldsResponse?.data || [],
    [customFieldsResponse?.data]
  );

  const defaultValues = {
    additional: {
      comments: "",
      language: [],
      lift: "",
      maxPayRate: 0,
      minPayRate: 0,
      // samComments: "",
      shift: [],
      employmentType: [],
      transportation: "",
      interviewedBy: "",
    },
    criminalHistoryDisclosure: "",
  };

  const validationSchema = useMemo(() => {
    const customFieldSchema = featureCustomFieldSchema(customFields);

    return applicantAdditionalInfoSchema.concat(customFieldSchema);
  }, [customFields]);

  const resolver = useYupValidationResolver(validationSchema);

  const { control, handleSubmit, setValue, getValues, clearErrors, watch, reset, formState } =
    useForm({ defaultValues: currentApplicant, resolver });
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful, dirtyFields } = formState;

  const { data: applicantCustomFieldsResponse, isLoading: isLoadingApplicantFields } = useQuery(
    ["fetchApplicantCustomFields", currentApplicant?._id],
    () => fetchApplicantCustomFields(currentApplicant?._id),
    {
      enabled: !!currentApplicant?._id,
      onSuccess: (response) => {
        const customFieldsData = response?.data || {};
        reset({
          ...currentApplicant,
          customFields: customFieldsData,
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Error loading event custom fields: ${error.message}`, {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (!customFieldsResponse?.data) {
      reset((formValues) => ({
        ...formValues,
        customFields: {},
      }));
    }
  }, [customFieldsResponse, reset]);

  const onCancel = () => {
    reset({ ...currentApplicant, customFields: applicantCustomFieldsResponse?.data || {} });
  };

  const { mutateAsync: updateApplicantMutation } = useMutation(updateApplicant);
  const handleAdditionalInformationSubmit = (data) => {
    const parsedData = {
      ...data,
      additional: {
        ...data.additional,
        maxPayRate: Number(data?.additional?.maxPayRate),
        minPayRate: Number(data?.additional?.minPayRate),
      },
    };

    updateApplicantMutation(
      {
        applicantId: currentApplicant?._id,
        data: {
          ...getDirtyFields(parsedData, dirtyFields),
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Applicant has been updated.", { variant: "success" });
          reset(data, { keepValues: true });
        },
      }
    );
  };

  useEffect(() => {
    if (users?.data) {
      const filteredUsers = users?.data
        ?.filter((item) => {
          return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
        })
        .map((user) => `${user.firstName} ${user.lastName}`);
      const interviewedBySet = new Set(filteredUsers);
      const newInterviewByArray = Array.from(interviewedBySet).sort();
      setUsersNames(newInterviewByArray);
    }
  }, [users]);

  useEffect(() => {
    if (currentApplicant) reset(currentApplicant);
    else {
      reset(defaultValues);
    }
  }, [currentApplicant]);

  return (
    <Card className={classes.box} key={`card_sendMessage_${currentApplicant?.id}`}>
      <form onSubmit={handleSubmit(handleAdditionalInformationSubmit)}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          key={`box_sendMessage_${currentApplicant?.id}`}
        >
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="violet"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="large">
                <NoteAddIcon />
              </Icon>
            </MDBox>
            <MDBox sx={{ mt: 2, ml: 2 }}>
              <Typography variant="h5">Additional Info</Typography>
            </MDBox>
          </MDBox>
          <MDBox p={3}>
            <Button
              type="submit"
              variant="text"
              startIcon={<SaveIcon />}
              style={{ color: "#4CAF50" }}
              disabled={isSubmitting}
              sx={{ padding: "0.5rem 1rem" }}
            >
              Save
            </Button>
          </MDBox>
        </MDBox>
        <StyledContainer px={5}>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Controller
                name="additional.lift"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={lifts || []}
                    autoSelect
                    freeSolo
                    name="lift"
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("additional.lift", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField
                        type="text"
                        label="Lift"
                        showError
                        errorMessage={error?.message}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="criminalHistoryDisclosure"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={criminalRecords || []}
                    autoSelect
                    freeSolo
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("criminalHistoryDisclosure", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField {...params} type="text" label="Criminal Record" />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={3}>
              <Controller
                name="additional.minPayRate"
                key={`minPayRate_${currentApplicant?.id}`}
                control={control}
                defaultValue={currentApplicant?.additional?.minPayRate || 0}
                render={({ field }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      inputProps: {
                        style: { textAlign: "end" },
                        inputMode: "numeric",
                        type: "number",
                      },
                    }}
                    onValueChange={({ floatValue }) =>
                      setValue("additional.minPayRate", floatValue, { shouldDirty: true })
                    }
                    label="Min Pay Rate"
                    decimalScale={2}
                    value={field.value || 0}
                    {...field}
                    customInput={FormField}
                  />
                )}
              />
              {errors?.["additional.minPayRate"] && (
                <MDTypography color="error">
                  {errors?.["additional.minPayRate"].message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={3}>
              <Controller
                name="additional.maxPayRate"
                control={control}
                rules={{ valueAsNumber: true }}
                key={`maxPayRate_${currentApplicant?.id}`}
                defaultValue={currentApplicant?.additional?.maxPayRate || 0}
                render={({ field, fieldState: { error } }) => (
                  <NumericFormat
                    InputProps={{
                      startAdornment: "$",
                      inputProps: {
                        style: { textAlign: "end" },
                        inputMode: "numeric",
                      },
                    }}
                    onValueChange={({ floatValue }) =>
                      setValue("additional.maxPayRate", floatValue, { shouldDirty: true })
                    }
                    label="Max Pay Rate"
                    decimalScale={2}
                    value={field.value || 0}
                    showError
                    errorMessage={error?.message}
                    {...field}
                    customInput={FormField}
                  />
                )}
              />
              {errors?.["additional.maxPayRate"] && (
                <MDTypography color="error">
                  {errors?.["additional.maxPayRate"].message}
                </MDTypography>
              )}
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="additional.interviewedBy"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []}
                    autoSelect
                    freeSolo
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("additional.interviewedBy", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField {...params} type="text" label="Interviewed By" />
                    )}
                  />
                )}
              />
            </Grid>
            {/* <Grid item sm={6}>
              <Stack display="flex" direction="row" alignItems="center">
                <Typography variant="h6">SMART</Typography>
                <Controller
                  name="additional.smart"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value === "Yes"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "Yes" : "No", { shouldDirty: true })
                      }
                    />
                  )}
                />
                <Typography variant="body2">{watch("additional.smart")}</Typography>
              </Stack>
            </Grid> */}
            <Grid item sm={6}>
              <Controller
                name="additional.employmentType"
                control={control}
                defaultValue={currentApplicant?.additional?.employmentType || []}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={employmentTypes || []}
                    autoSelect
                    freeSolo
                    multiple
                    name="employmentType"
                    value={field.value || []}
                    onChange={(e, v) => {
                      setValue("additional.employmentType", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        type="text"
                        label="Employment Type"
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="additional.language"
                control={control}
                defaultValue={currentApplicant?.additional?.language || []}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={languages || []}
                    autoSelect
                    freeSolo
                    multiple
                    name="language"
                    value={field.value || []}
                    onChange={(e, v) => {
                      setValue("additional.language", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        type="text"
                        showError
                        errorMessage={error?.message}
                        label="Language"
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="additional.shift"
                control={control}
                defaultValue={currentApplicant?.additional?.shift || []}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={shifts || []}
                    autoSelect
                    freeSolo
                    multiple
                    name="shift"
                    value={field.value || []}
                    onChange={(e, v) => {
                      setValue("additional.shift", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        type="text"
                        label="Shift"
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="additional.transportation"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={transportationOptions || []}
                    autoSelect
                    freeSolo
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("additional.transportation", v, { shouldDirty: true });
                    }}
                    renderInput={(params) => (
                      <FormField {...params} type="text" label="Transportation" />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item sm={12}>
              <Typography variant="body2">Comments</Typography>
              <Controller
                name="additional.comments"
                control={control}
                disabled
                defaultValue=""
                render={({ field: { value, onChange, ...props } }) => (
                  <Box p={1}>
                    <FormField
                      multiline
                      rows={8}
                      disabled
                      value={value}
                      style={{ height: 180 }}
                      {...props}
                    />
                  </Box>
                )}
              />
            </Grid>

            <Grid container spacing={4} p={3}>
              {customFields
                .sort((a, b) => a.order - b.order)
                .filter((field) => field.isVisible)
                .map((field) => (
                  <Grid item xs={12} md={6} key={field.field}>
                    <CustomField
                      field={field}
                      control={control}
                      error={errors?.[`customFields.${field.field}`]?.message}
                    />
                  </Grid>
                ))}
            </Grid>

            {/* <Grid item sm={6}>
                            <Typography variant="body2">
                                Sam Comments
                            </Typography>
                            <Controller
                                name="additional.samComments"
                                control={control}
                                defaultValue="<p></p>"
                                render={({ field: { value, onChange, ...props } }) => (
                                    <MDEditor
                                        theme="snow"
                                        onChange={(text) => {
                                            onChange(text);
                                        }}
                                        value={value}
                                        style={{ height: 230 }}
                                        {...props}
                                    />
                                )}
                            />
                        </Grid> */}
          </Grid>
        </StyledContainer>
      </form>
    </Card>
  );
};

export default ApplicantAdditionalInfo;

const StyledContainer = styled(MDBox)(() => ({
  height: 700,
  overflowY: "auto",
}));
