import {
  AspectRatio,
  Badge,
  CloseFullscreen,
  Mail,
  ManageSearch,
  PublishedWithChanges,
  RemoveModerator,
  Shield,
  StarHalf,
  SwapVert,
  Try,
  VerifiedUser,
} from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TableHead,
  Tooltip,
} from "@mui/material";
import DataList from "components/DataList";
import FilterButtons from "components/FilterButtons";
import JobsStatusAIInterviewModal from "components/JobsStatusAIInterviewModal";
import JobsStatusAIQuestionsModal from "components/JobsStatusAIQuestionsModal";
import { useAppContextController } from "context/AppContext";
import addApplicantToJob from "layouts/pages/applicants/actions/addApplicantToJob";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchInterviewApplicantsForJobStatus from "layouts/pages/applicants/actions/fetchInterviewApplicantsForJobStatus";
import ApplicantResumeTextModal from "layouts/pages/applicants/components/ApplicantResumeTextModal";
import ApplicantsSearchAllModal from "layouts/pages/applicants/components/ApplicantsSearchAllModal";
import rankApplicants from "layouts/pages/companyjobs/actions/rankApplicants";
import searchAllApplicantsRanked from "layouts/pages/companyjobs/actions/searchAllApplicantsRanked";
import { BulkApplicantStageChangeModal } from "layouts/pages/events/components/BulkApplicantStageChangeModal";
import updateApplicantAIScreenedFlag from "layouts/pages/applicants/actions/updateApplicantAIScreenedFlag";
import updateApplicantCallMeFlag from "layouts/pages/applicants/actions/updateApplicantCallMeFlag";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import JobsStatusRow from "layouts/pages/jobs/components/JobsStatus/JobStatusRow";
import { cloneDeep } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APPLICANT_JOB_STATUS_ENUM, JOB_STATUS_ENUM } from "utils/constants/applicant";
import { calculateInterviewOverallFeedback } from "utils/helpers/events";
import { isAdmin } from "utils/helpers/roles";
import ConfirmationModal from "components/ConfirmationModal";
import useJobStatusFilterCards from "./useJobStatusFilterCards";

const sortValues = {
  FIRSTNAME: "firstName:asc",
  RANK: "jobsCopy.score:desc,firstName:asc,_id:asc",
  APPLICATION_DATE: "jobsCopy.dateModified:desc,firstName:asc",
};

const adminFilterIcons = {
  [null]: {
    color: "secondary",
    icon: <Shield fontSize="large" />,
    tooltip: "Showing all applicants",
  },
  only: {
    color: "info",
    icon: <VerifiedUser fontSize="large" />,
    tooltip: "Showing only applicants added manually",
  },
  exclude: {
    color: "error",
    icon: <RemoveModerator fontSize="large" />,
    tooltip: "Excluding applicants added manually",
  },
};

const JobsStatus = ({ currentVenue, fullscreen }) => {
  const { company, currentJob, setCurrentJob, userType, currentLoggedUser } =
    useAppContextController();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramApplicantStatus = searchParams.get("jsApplicantStatus");
  const paramStatus = searchParams.get("jsStatus");
  const paramSortBy = searchParams.get("jsSortBy");
  const paramAddedManually = searchParams.get("jsAddedManually");
  const paramAiScore = searchParams.get("jsAiScore");

  const navigate = useNavigate();
  const [isOpenSelectAllModal, setIsOpenSelectAllModal] = useState(false);
  const [applicantResumeText, setApplicantResumeText] = useState(null);
  const [applicantDataFilter, setApplicantDataFilter] = useState({
    status: paramApplicantStatus ?? "all",
  });
  const [dataFilter, setDataFilter] = useState({ status: paramStatus ?? "all" });
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(paramSortBy ?? sortValues.RANK);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isBulkApplicantStageChangeModalOpen, toggleBulkApplicantStageChangeModal] =
    useState(false);
  const [aiQuestionModalSelectedApplicant, setAIQuestionModalSelectedApplicant] = useState(null);
  const [aiScreeningResultModalSelectedApplicant, setAIScreeningResultModalSelectedApplicant] =
    useState(null);
  const [removeFlagModalSelectedApplicant, setRemoveFlagModalSelectedApplicant] = useState(null);
  const [removeFlagModalCurrentFlag, setRemoveFlagModalCurrentFlag] = useState(null);
  const [isFilteringManuallyAdded, setIsFilteringManuallyAdded] = useState(
    // eslint-disable-next-line no-unneeded-ternary
    paramAddedManually ? paramAddedManually : null
  );

  const [isShowingAIScore, setIsShowingAIScore] = useState(paramAiScore?.toLowerCase() === "true");
  const toggleAIScore = () => setIsShowingAIScore((v) => !v);

  useEffect(() => {
    let updated = false;
    if (searchParams.has("jsApplicantStatus")) {
      searchParams.delete("jsApplicantStatus");
      updated = true;
    }
    if (searchParams.has("jsStatus")) {
      searchParams.delete("jsStatus");
      updated = true;
    }
    if (searchParams.has("jsSortBy")) {
      searchParams.delete("jsSortBy");
      updated = true;
    }
    if (searchParams.has("jsAddedManually")) {
      searchParams.delete("jsAddedManually");
      updated = true;
    }
    if (searchParams.has("jsAiScore")) {
      searchParams.delete("jsAiScore");
      updated = true;
    }

    if (updated) setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const toggleFullscreen = (e) => {
    e.stopPropagation();
    setPage(1);

    navigate(
      `/jobs/${currentJob?.jobSlug}/action/jobstatus?jsApplicantStatus=${
        applicantDataFilter?.status
      }&jsStatus=${dataFilter?.status}&jsSortBy=${sortBy}&jsAddedManually=${
        isFilteringManuallyAdded ?? ""
      }&jsAiScore=${isShowingAIScore}`
    );
  };

  const minimize = (e) => {
    e.stopPropagation();
    setPage(1);
    navigate(
      `/jobs/${currentJob?.jobSlug}/action/jobinfo?panel=status&jsApplicantStatus=${
        applicantDataFilter?.status
      }&jsStatus=${dataFilter?.status}&jsSortBy=${sortBy}&jsAddedManually=${
        isFilteringManuallyAdded ?? ""
      }&jsAiScore=${isShowingAIScore}`
    );
  };

  const queryClient = useQueryClient();

  const orderByOverride = sortBy;

  const rankingDisabled = !currentJob?.tags || !currentJob?.tags?.length;

  const aiInterviewsEnabled =
    company?.aiInterviewsAvailable === "Yes" &&
    currentJob?.aiSettings?.aiInterviewsEnabled === "Yes" &&
    company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
    currentVenue?.aiSettings?.aiInterviewsEnabled === "Yes";

  const [anchorEl, setAnchorEl] = useState(null);
  const handleIconClick = (e) => setAnchorEl(e.currentTarget);
  const handleIconClose = () => setAnchorEl(null);

  const { enqueueSnackbar } = useSnackbar();

  const [searchAllApplicantList, setSearchAllApplicantList] = useState(null);

  const { jobApplicantOptions, jobStatusOptions } = useJobStatusFilterCards({
    currentJob,
    applicantStatus: applicantDataFilter.status,
  });

  const handleCloseApplicantResumeModal = () => {
    setApplicantResumeText(null);
  };

  const handleOpenApplicantResumeModal = (applicantName, text) => {
    setApplicantResumeText({ applicantName, text });
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
    handleIconClose();
  };

  const handleOpenAIQuestionsModal = (row, screeningComplete) => {
    if (screeningComplete) setAIScreeningResultModalSelectedApplicant(row);
    else setAIQuestionModalSelectedApplicant(row);
  };

  const handleCloseAIQuestionsModal = () => {
    setAIQuestionModalSelectedApplicant(null);
  };

  const handleOpenAIScreeningResultModal = (row) => {
    setAIScreeningResultModalSelectedApplicant(row);
  };

  const handleCloseAIScreeningResultModal = () => {
    setAIScreeningResultModalSelectedApplicant(null);
  };

  const handleOpenRemoveFlagModal = (row, flag) => {
    setRemoveFlagModalSelectedApplicant(row);
    setRemoveFlagModalCurrentFlag(flag);
  };

  const handleCloseRemoveFlagModal = () => {
    setRemoveFlagModalSelectedApplicant(null);
    setRemoveFlagModalCurrentFlag(null);
  };

  useEffect(() => {
    setSearchAllApplicantList(null);
  }, [currentJob?.jobSlug]);

  const options = useMemo(() => {
    const result = {
      fetchAll: false,
      orderByOverride,
      limit: 10,
      useElemMatch: true,
      availableForJobSlug: currentJob?.jobSlug,
    };
    return result;
  }, [currentJob?.jobSlug, orderByOverride]);

  const fetchOptions = useMemo(() => {
    const updatedOptions = {
      ...options,
      filters: { "jobs.jobSlug": currentJob?.jobSlug },
      page,
    };
    if (applicantDataFilter.status !== "all")
      updatedOptions.filters = {
        ...updatedOptions.filters,
        applicantStatus: applicantDataFilter.status,
      };
    if (dataFilter.status !== "all") {
      updatedOptions.filters = {
        ...updatedOptions.filters,
        "jobs.status": dataFilter.status,
      };
    }
    if (isFilteringManuallyAdded) {
      updatedOptions.filters = {
        ...updatedOptions.filters,
        "jobs.agent": isFilteringManuallyAdded === "only" ? "!!Indeed;Internet" : "Indeed;Internet",
      };
    }
    if (dataFilter.status === "Interview") {
      updatedOptions.interviewJobSlug = currentJob?.jobSlug;
      updatedOptions.jobApplicantStatus = applicantDataFilter.status;
      updatedOptions.interviewStatus = "all";
      updatedOptions.createAgent = currentLoggedUser._id;
      if (orderByOverride === sortValues.APPLICATION_DATE) {
        updatedOptions.orderByOverride = "event.eventDate";
      }
    }
    if (isShowingAIScore) {
      updatedOptions.aiInterviewedOnly = true;
    }
    return updatedOptions;
  }, [
    options,
    currentJob?.jobSlug,
    page,
    applicantDataFilter.status,
    dataFilter.status,
    isFilteringManuallyAdded,
    isShowingAIScore,
    currentLoggedUser._id,
    orderByOverride,
  ]);

  const sortCandidatesFunc = (a, b) => {
    const sortByLocal = sortBy ?? sortValues.FIRSTNAME;
    switch (sortByLocal) {
      case sortValues.FIRSTNAME:
        if (a.firstName > b.firstName) {
          return 1;
        }
        if (a.firstName < b.firstName) {
          return -1;
        }
        return 0;
      case sortValues.RANK:
        if (a.rank > b.rank) {
          return -1;
        }
        if (a.rank < b.rank) {
          return 1;
        }
        return 0;
      case sortValues.APPLICATION_DATE:
        if (a.jobModifiedDate > b.jobModifiedDate) {
          return -1;
        }
        if (a.jobModifiedDate < b.jobModifiedDate) {
          return 1;
        }
        return 0;

      default:
        return 0;
    }
  };

  const isJobFull = useMemo(() => {
    if (currentJob?.positions == null) return false;
    const openPositions = currentJob.positions.filter((pos) => pos.status === "Open").length;
    return openPositions <= 0;
  }, [currentJob?.positions]);

  useEffect(() => {
    if (searchAllApplicantList) {
      setSearchAllApplicantList((current) => [...(current ?? []).sort(sortCandidatesFunc)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const mapApplicantsAddExtraInfo = (appl) => {
    let rank = 0;
    let aiRank = null;
    let hasNotApplied = false;
    let jobModifiedDate = "";
    let jobApplyDate = "";
    let jobAgent = "";
    let jobModifiedAgent = "";
    let jobModifiedAgentName = "";
    let waitingForCall = null;
    const foundJob = (appl.jobs ?? []).find((applJob) => applJob.jobSlug === currentJob?.jobSlug);
    if (foundJob) {
      rank = foundJob.score ?? 0;
      jobModifiedDate = foundJob.dateModified ?? "";
      jobApplyDate = foundJob.applyDate ?? "";
      jobAgent = foundJob.agent !== "Internet" && foundJob.agent !== "Indeed" ? foundJob.agent : "";
      jobModifiedAgent = foundJob.modifiedAgent ?? "";
      jobModifiedAgentName = foundJob.modifiedAgentName ?? "";
      waitingForCall = foundJob.waitingForCall;
      if (foundJob.aiInterview?.score != null) {
        aiRank = foundJob.aiInterview.score ?? 0;
      }
    } else {
      hasNotApplied = true;
      if (appl.rank) rank = appl.rank;
    }

    return {
      ...appl,
      hasNotApplied,
      rank,
      aiRank,
      jobModifiedDate,
      jobApplyDate,
      jobAgent,
      jobModifiedAgent,
      jobModifiedAgentName,
      waitingForCall,
    };
  };

  const queryFunction = async ({ queryKey, pageParam }) => {
    let data =
      dataFilter.status === "Interview"
        ? await fetchInterviewApplicantsForJobStatus({ ...fetchOptions, page: pageParam ?? 1 })
        : await fetchApplicants({ ...fetchOptions, page: pageParam ?? 1 });
    if (data?.data) {
      data = {
        ...data,
        data: data.data.map(mapApplicantsAddExtraInfo),
      };
    }
    return data;
  };

  const {
    data: applicants,
    isLoading,
    isFetching,
    refetch: refetchApplicants,
    fetchNextPage,
  } = useInfiniteQuery(["jobsStatusApplicants", fetchOptions], queryFunction, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
        return undefined;
      }

      const nextPage = lastPage?.pagination?.next?.page;
      return nextPage ?? false;
    },
    enabled: !searchAllApplicantList,
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
  });

  const fetchOptionsForBulk = useMemo(() => {
    const updatedOptions = {
      fetchAll: true,
      useElemMatch: true,
      filters: { "jobs.jobSlug": currentJob?.jobSlug },
      availableForJobSlug: currentJob?.jobSlug,
    };
    if (applicantDataFilter.status !== "all")
      updatedOptions.filters = {
        ...updatedOptions.filters,
        applicantStatus: applicantDataFilter.status,
      };
    if (dataFilter.status !== "all") {
      updatedOptions.filters = {
        ...updatedOptions.filters,
        "jobs.status": dataFilter.status,
      };
    }
    if (isFilteringManuallyAdded) {
      updatedOptions.filters = {
        ...updatedOptions.filters,
        "jobs.agent": isFilteringManuallyAdded === "only" ? "!!Indeed;Internet" : "Indeed;Internet",
      };
    }
    return updatedOptions;
  }, [
    currentJob?.jobSlug,
    applicantDataFilter.status,
    dataFilter.status,
    isFilteringManuallyAdded,
  ]);

  const queryFunctionBulkMessasging = async ({ queryKey, pageParam }) => {
    const data = await fetchApplicants({ ...fetchOptionsForBulk });
    return data;
  };

  const {
    data: applicantsForBulk,
    isLoading: isLoadingForBulk,
    refetch: refetchEmployeeForBulk,
  } = useQuery(["employeesForBulkMessaging", fetchOptionsForBulk], queryFunctionBulkMessasging, {
    enabled: !!isBulkMessageModalOpen || !!isBulkApplicantStageChangeModalOpen,
  });

  const changeLocalApplicantStatus = (params) => {
    const { applicantId, eventUrl, applicantStatus, status, interviewFeedback } = params ?? {};
    const allEvents = cloneDeep(queryClient.getQueryData(["jobsStatusApplicants", fetchOptions]));
    const pages = allEvents?.pages ?? [];
    const applicantIndexToDelete = [];

    for (let indexPage = 0; indexPage < pages.length; indexPage += 1) {
      const applPage = pages[indexPage];
      const pageData = applPage.data ?? [];
      for (let indexData = 0; indexData < pageData.length; indexData += 1) {
        const appl = pageData[indexData];
        if (applicantId && applicantId === appl._id) {
          // Found applicant to update
          if (
            interviewFeedback &&
            eventUrl &&
            eventUrl === allEvents.pages[indexPage].data[indexData].interview?.eventUrl
          ) {
            // Recalculate feedback
            let currentFeedbacks = (appl.feedback ?? []).filter(
              (applFeed) => applFeed.eventUrl === eventUrl
            );

            if (interviewFeedback === "None") {
              currentFeedbacks = currentFeedbacks.filter(
                (fb) => fb.createAgent !== currentLoggedUser?._id
              );
            } else {
              const agentFeedbackIndex = currentFeedbacks.findIndex(
                (fb) => fb.createAgent === currentLoggedUser?._id
              );
              if (agentFeedbackIndex >= 0) {
                // Feedback exists, change it
                currentFeedbacks[agentFeedbackIndex].rating = interviewFeedback;
              } else {
                currentFeedbacks.push({
                  createAgent: currentLoggedUser?._id,
                  agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
                  firstName: currentLoggedUser.firstName,
                  lastName: currentLoggedUser.lastName,
                  eventUrl,
                  modifiedDate: new Date().toISOString(),
                  rating: interviewFeedback,
                });
              }
            }

            const { overallRating, agentRating, tooltip } =
              calculateInterviewOverallFeedback(currentFeedbacks);

            const interviewFeedbackStatus =
              interviewFeedback && ["None", "Hired", "Pending", "Declined"].includes(overallRating)
                ? overallRating
                : "None";

            allEvents.pages[indexPage].data[indexData].interview = {
              ...allEvents.pages[indexPage].data[indexData].interview,
              status: interviewFeedbackStatus,
              tooltip,
              agentRating,
            };
          }

          const applJobIndex = appl.jobs.findIndex((apJb) => apJb.jobSlug === currentJob?.jobSlug);
          if (applJobIndex >= 0) {
            // Found job to update
            if (applicantStatus) {
              allEvents.pages[indexPage].data[indexData].applicantStatus = applicantStatus;
              allEvents.pages[indexPage].data[indexData].jobModifiedDate = new Date().toISOString();
            }
            if (status) {
              if (status === JOB_STATUS_ENUM.NONE) {
                applicantIndexToDelete.push(indexData);
                // allEvents.pages[indexPage].data.splice(indexData, 1);
              } else {
                allEvents.pages[indexPage].data[indexData].jobs[applJobIndex].status = status;
                allEvents.pages[indexPage].data[indexData].jobModifiedDate =
                  new Date().toISOString();
              }
            }
          }
        }
      }

      if (applicantIndexToDelete.length) {
        allEvents.pages[indexPage].data = allEvents.pages[indexPage].data.filter(
          (_, index) => !applicantIndexToDelete.includes(index)
        );
      }
    }

    queryClient.setQueryData(["jobsStatusApplicants", fetchOptions], allEvents);
  };

  const changeLocalJobAIInterviewData = (params) => {
    const { applicantId, data } = params ?? {};
    const allApplicants = cloneDeep(
      queryClient.getQueryData(["jobsStatusApplicants", fetchOptions])
    );
    const pages = allApplicants?.pages ?? [];
    const applicantIndexToDelete = [];

    for (let indexPage = 0; indexPage < pages.length; indexPage += 1) {
      const applPage = pages[indexPage];
      const pageData = applPage.data ?? [];
      for (let indexData = 0; indexData < pageData.length; indexData += 1) {
        const appl = pageData[indexData];
        if (applicantId && applicantId === appl._id) {
          // Found applicant to update
          const applJobIndex = appl.jobs.findIndex((apJb) => apJb.jobSlug === currentJob?.jobSlug);
          if (applJobIndex >= 0) {
            // Found job to update
            if (data) {
              allApplicants.pages[indexPage].data[indexData].jobs[applJobIndex].aiInterview = data;
            }
          }
        }
      }
    }

    queryClient.setQueryData(["jobsStatusApplicants", fetchOptions], allApplicants);
  };

  const changeLocalFlag = (applicantId, flag) => {
    const allEvents = cloneDeep(queryClient.getQueryData(["jobsStatusApplicants", fetchOptions]));
    const pages = allEvents?.pages ?? [];

    for (let indexPage = 0; indexPage < pages.length; indexPage += 1) {
      const applPage = pages[indexPage];
      const pageData = applPage.data ?? [];
      for (let indexData = 0; indexData < pageData.length; indexData += 1) {
        const appl = pageData[indexData];
        if (applicantId && applicantId === appl._id) {
          // Found applicant to update

          const applJobIndex = appl.jobs.findIndex((apJb) => apJb.jobSlug === currentJob?.jobSlug);
          if (applJobIndex >= 0) {
            // Found job to update
            allEvents.pages[indexPage].data[indexData][flag] = null;
          }
        }
      }
    }

    queryClient.setQueryData(["jobsStatusApplicants", fetchOptions], allEvents);
  };

  const handleBulkChangeLocalStatus = (applicantStatus, applicantIdList) => {
    if (applicantStatus != null && applicantIdList != null) {
      applicantIdList.forEach((applicantId) => {
        changeLocalApplicantStatus({ applicantId, applicantStatus });
      });
    }
  };

  const rankApplicantsMutation = useMutation(rankApplicants, {
    onSuccess: (_, { data }) => {
      enqueueSnackbar(`Applicants ranked successfully`, { variant: "success" });
      refetchApplicants();
      setSortBy(sortValues.RANK);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "rankApplicantsMutation",
  });

  const searchAllApplicantsRankedMutation = useMutation(searchAllApplicantsRanked, {
    onSuccess: ({ data }, _) => {
      enqueueSnackbar(`Search successfull`, { variant: "success" });
      const candidates = (data?.candidates ?? []).map(mapApplicantsAddExtraInfo);

      setSearchAllApplicantList(candidates.sort(sortCandidatesFunc));
      setSortBy(sortValues.RANK);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "searchAllApplicantsRankedMutation",
  });

  const addApplicantToJobMutation = useMutation(addApplicantToJob, {
    onSuccess: (data, { applicantId, jobSlug, score }) => {
      enqueueSnackbar(`Applicant added to job successfully`, { variant: "success" });
      const modifiedApplicantIndex = searchAllApplicantList?.findIndex(
        (appl) => appl._id === applicantId
      );
      if (modifiedApplicantIndex >= 0) {
        setSearchAllApplicantList([
          ...searchAllApplicantList.slice(0, modifiedApplicantIndex),
          {
            ...searchAllApplicantList[modifiedApplicantIndex],
            hasNotApplied: false,
            rank: score,
            jobAgent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
            jobModifiedDate: new Date().toISOString(),
            jobApplyDate: new Date().toISOString(),
            jobs: [
              ...(searchAllApplicantList[modifiedApplicantIndex].jobs ?? []),
              {
                jobSlug,
                status: "Pending",
                dateModified: new Date().toISOString(),
                agent: "",
                applicantStatus: "New",
                score,
              },
            ],
          },
          ...searchAllApplicantList.slice(modifiedApplicantIndex + 1),
        ]);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "addApplicantToJobMutation",
  });

  const updateApplicantAIScreenedFlagMutation = useMutation(updateApplicantAIScreenedFlag, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { applicantId, jobSlug }) => {
      changeLocalFlag(applicantId, "aiScreened");
      enqueueSnackbar("Applicant has been updated!", { variant: "success" });
    },
  });

  const updateApplicantCallMeFlagMutation = useMutation(updateApplicantCallMeFlag, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { applicantId, jobSlug }) => {
      changeLocalFlag(applicantId, "waitingForCall");
      enqueueSnackbar("Applicant has been updated!", { variant: "success" });
    },
  });

  const touchLastActivityLocal = (applicantId) => {
    const allEvents = cloneDeep(queryClient.getQueryData(["jobsStatusApplicants", fetchOptions]));
    const pages = allEvents?.pages ?? [];

    for (let indexPage = 0; indexPage < pages.length; indexPage += 1) {
      const applPage = pages[indexPage];
      const pageData = applPage.data ?? [];
      for (let indexData = 0; indexData < pageData.length; indexData += 1) {
        const appl = pageData[indexData];
        if (applicantId && applicantId === appl._id) {
          // Found applicant to update

          const applJobIndex = appl.jobs.findIndex((apJb) => apJb.jobSlug === currentJob?.jobSlug);
          if (applJobIndex >= 0) {
            // Found job to update
            allEvents.pages[indexPage].data[indexData].jobModifiedDate = new Date().toISOString();
          }
        }
      }
    }

    queryClient.setQueryData(["jobsStatusApplicants", fetchOptions], allEvents);
  };

  const touchLastActivitySearchAll = (applicantId) => {
    const modifiedApplicantIndex = searchAllApplicantList?.findIndex(
      (appl) => appl._id === applicantId
    );
    if (modifiedApplicantIndex >= 0) {
      setSearchAllApplicantList([
        ...searchAllApplicantList.slice(0, modifiedApplicantIndex),
        {
          ...searchAllApplicantList[modifiedApplicantIndex],
          jobModifiedDate: searchAllApplicantList[modifiedApplicantIndex].jobModifiedDate
            ? new Date().toISOString()
            : searchAllApplicantList[modifiedApplicantIndex].jobModifiedDate,
        },
        ...searchAllApplicantList.slice(modifiedApplicantIndex + 1),
      ]);
    }
  };

  const touchLastActivity = (applicantId) => {
    touchLastActivityLocal(applicantId);
    touchLastActivitySearchAll(applicantId);
  };

  const handleRankApplicants = () =>
    rankApplicantsMutation.mutate({ jobSlug: currentJob?.jobSlug });

  const handleSearchAll = (numberOfCandidates, jobSkills, geoRadius, geoPreference) => {
    searchAllApplicantsRankedMutation.mutate({
      jobSlug: currentJob?.jobSlug,
      numberOfCandidates,
      jobSkills,
      geoRadius,
      geoPreference,
    });
    setIsOpenSelectAllModal(false);
  };

  const handleReset = () => {
    setSearchAllApplicantList(null);
    refetchApplicants();
  };

  const handleChangeStatusInSearchMode = ({ applicantId, applicantStatus, employmentStage }) => {
    const modifiedApplicantIndex = searchAllApplicantList?.findIndex(
      (appl) => appl._id === applicantId
    );
    if (modifiedApplicantIndex >= 0) {
      const modifiedJobIndex = searchAllApplicantList[modifiedApplicantIndex].jobs.findIndex(
        (applJob) => applJob.jobSlug === currentJob?.jobSlug
      );

      if (modifiedJobIndex) {
        const changesObj = {};
        if (applicantStatus) changesObj.applicantStatus = applicantStatus;
        if (employmentStage && employmentStage !== APPLICANT_JOB_STATUS_ENUM.REMOVE)
          changesObj.employmentStage = employmentStage;

        if (employmentStage === APPLICANT_JOB_STATUS_ENUM.REMOVE) {
          setSearchAllApplicantList((oldList) => [
            ...oldList.slice(0, modifiedApplicantIndex),
            ...oldList.slice(modifiedApplicantIndex + 1),
          ]);
        } else {
          setSearchAllApplicantList((oldList) => [
            ...oldList.slice(0, modifiedApplicantIndex),
            {
              ...oldList[modifiedApplicantIndex],
              jobModifiedDate: new Date().toISOString(),
              jobs: [
                ...oldList[modifiedApplicantIndex].jobs.slice(0, modifiedJobIndex),
                {
                  ...oldList[modifiedApplicantIndex].jobs[modifiedJobIndex],
                  ...changesObj,
                },
                ...oldList[modifiedApplicantIndex].jobs.slice(modifiedJobIndex + 1),
              ],
            },
            ...oldList.slice(modifiedApplicantIndex + 1),
          ]);
        }
      }
    }
  };

  const handleRemoveAIScreenedFlagFromApplicant = async (applicantId) => {
    await updateApplicantAIScreenedFlagMutation.mutateAsync({
      applicantId,
      jobSlug: currentJob?.jobSlug,
    });
  };

  const handleRemoveCallMeFlagFromApplicant = async (applicantId) => {
    await updateApplicantCallMeFlagMutation.mutateAsync({
      applicantId,
      jobSlug: currentJob?.jobSlug,
    });
  };

  const fullData = useMemo(
    () =>
      applicants?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [applicants?.pages]
  );

  const header = (
    <Grid container direction="row" justifyContent="flex-end" pr="2rem" pt="1rem">
      {searchAllApplicantList ? (
        <Button
          isLoading={
            rankApplicantsMutation.isLoading || searchAllApplicantsRankedMutation.isLoading
          }
          disabled={rankApplicantsMutation.isLoading || searchAllApplicantsRankedMutation.isLoading}
          variant="contained"
          style={{ color: "white", marginRight: "0.5rem" }}
          onClick={handleReset}
        >
          Reset
        </Button>
      ) : (
        <>
          {!fullscreen && (
            <Tooltip title="Go Fullscreen">
              <span>
                <IconButton color="info" onClick={toggleFullscreen}>
                  <AspectRatio fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {fullscreen && (
            <Tooltip title="Minimize">
              <span>
                <IconButton color="info" onClick={minimize}>
                  <CloseFullscreen fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {aiInterviewsEnabled && (
            <Tooltip
              title={isShowingAIScore ? "Hide AI Screening Score" : "Show AI Screening Score"}
            >
              <span>
                <IconButton
                  color={isShowingAIScore ? "violet" : "secondary"}
                  onClick={toggleAIScore}
                  disabled={
                    rankApplicantsMutation.isLoading || searchAllApplicantsRankedMutation.isLoading
                  }
                >
                  <Try fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title={adminFilterIcons[isFilteringManuallyAdded].tooltip}>
            <span>
              <IconButton
                color={adminFilterIcons[isFilteringManuallyAdded].color}
                onClick={() =>
                  setIsFilteringManuallyAdded((value) => {
                    if (value == null) return "only";
                    if (value === "only") return "exclude";
                    return null;
                  })
                }
                disabled={
                  rankApplicantsMutation.isLoading || searchAllApplicantsRankedMutation.isLoading
                }
              >
                {adminFilterIcons[isFilteringManuallyAdded].icon}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Bulk Stage Change">
            <span>
              <IconButton
                color="success"
                onClick={() => toggleBulkApplicantStageChangeModal(true)}
                disabled={
                  !fullData ||
                  fullData.length < 1 ||
                  !!searchAllApplicantList ||
                  rankApplicantsMutation.isLoading ||
                  searchAllApplicantsRankedMutation.isLoading
                }
              >
                <PublishedWithChanges fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Bulk Message">
            <span>
              <IconButton
                color="error"
                onClick={() => {
                  toggleBulkMessageModal(true);
                }}
                disabled={
                  !fullData ||
                  fullData.length < 1 ||
                  !!searchAllApplicantList ||
                  rankApplicantsMutation.isLoading ||
                  searchAllApplicantsRankedMutation.isLoading
                }
              >
                <Mail fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={rankingDisabled ? "Requires at least one must-have skill" : "Search All"}>
            <span>
              <IconButton
                color="info"
                onClick={() => setIsOpenSelectAllModal(true)}
                disabled={
                  rankingDisabled ||
                  rankApplicantsMutation.isLoading ||
                  searchAllApplicantsRankedMutation.isLoading
                }
              >
                <ManageSearch fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={rankingDisabled ? "Requires at least one must-have skill" : "Rank Applied"}
          >
            <span>
              <IconButton
                color="warning"
                onClick={handleRankApplicants}
                disabled={
                  rankingDisabled ||
                  rankApplicantsMutation.isLoading ||
                  searchAllApplicantsRankedMutation.isLoading
                }
              >
                <StarHalf fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
      <Button
        variant="text"
        startIcon={<SwapVert />}
        onClick={handleIconClick}
        // disabled={isSubmitting}
        style={{ fontSize: 14 }}
      >
        Sort By
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleIconClose}
        sx={{
          "& .MuiPaper-root": { minWidth: "10rem", p: "0.2rem" },
          li: { minWidth: "10rem", pl: "0.5rem", pr: "0.5rem" },
        }}
      >
        <RadioGroup
          aria-labelledby={`sortbyradiobuttons_${currentJob?.jobSlug}`}
          value={sortBy}
          onChange={handleSortByChange}
          name="radio-buttons-group"
          sx={{ p: "0.2rem" }}
        >
          <MenuItem onClick={() => {}} disableRipple sx={{ p: 0, width: "5rem" }}>
            <FormControlLabel value={sortValues.FIRSTNAME} control={<Radio />} label="Name" />
          </MenuItem>
          <MenuItem onClick={() => {}} disableRipple sx={{ p: 0, width: "5rem" }}>
            <FormControlLabel value={sortValues.RANK} control={<Radio />} label="Rank" />
          </MenuItem>
          <MenuItem onClick={() => {}} disableRipple sx={{ p: 0, width: "5rem" }}>
            <FormControlLabel
              value={sortValues.APPLICATION_DATE}
              control={<Radio />}
              label={dataFilter.status === "Interview" ? "Interview Date" : "Last Activity Date"}
            />
          </MenuItem>
        </RadioGroup>
      </Menu>
    </Grid>
  );

  const topButtonBar = (
    <>
      <TableHead
        sx={{
          zIndex: 3,
          position: "sticky",
          top: 0,
          background: "white",
          width: "100%",
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: "1.5rem",
          paddingBottom: 0,
        }}
        display="flex"
        width="100%"
      >
        {isAdmin(userType) && (
          <Grid container rowSpacing={2} sx={{ opacity: searchAllApplicantList ? 0.5 : 1 }}>
            <Grid item xs={12}>
              <FilterButtons
                filter={searchAllApplicantList ? { status: "all" } : applicantDataFilter}
                setFilter={setApplicantDataFilter}
                name="status"
                options={jobApplicantOptions}
                isDisabled={!!searchAllApplicantList}
                fullscreen={fullscreen}
                // direction="row-reverse"
              />
            </Grid>
            <Grid item xs={12}>
              {fullscreen ? (
                <FilterButtons
                  filter={searchAllApplicantList ? { status: "Applies" } : dataFilter}
                  setFilter={setDataFilter}
                  name="status"
                  options={jobStatusOptions}
                  isDisabled={!!searchAllApplicantList}
                  fontSize="0.72rem"
                  itemsx={{ padding: "8px" }}
                  fullscreen
                  // direction="row-reverse"
                />
              ) : (
                <>
                  <FilterButtons
                    filter={searchAllApplicantList ? { status: "Applies" } : dataFilter}
                    setFilter={setDataFilter}
                    name="status"
                    options={jobStatusOptions.slice(0, 3)}
                    isDisabled={!!searchAllApplicantList}
                    fontSize="0.72rem"
                    itemsx={{ padding: "8px" }}
                    // direction="row-reverse"
                  />
                  <FilterButtons
                    filter={searchAllApplicantList ? { status: "Applies" } : dataFilter}
                    setFilter={setDataFilter}
                    name="status"
                    options={jobStatusOptions.slice(3, 6)}
                    isDisabled={!!searchAllApplicantList}
                    fontSize="0.72rem"
                    sx={{ marginTop: "-1px" }}
                    itemsx={{ padding: "8px", borderTopWidth: 0 }}
                    // direction="row-reverse"
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}
      </TableHead>
    </>
  );
  const renderRow = (row) => (
    <JobsStatusRow
      row={row}
      refresh={changeLocalApplicantStatus}
      isInterview={dataFilter.status === "Interview"}
      onAddToJob={addApplicantToJobMutation.mutate}
      onChangeStatus={searchAllApplicantList ? handleChangeStatusInSearchMode : null}
      onOpenResumeModal={handleOpenApplicantResumeModal}
      onTouchJob={touchLastActivity}
      onOpenAIQuestionsModal={handleOpenAIQuestionsModal}
      onOpenRemoveFlagModal={handleOpenRemoveFlagModal}
      isJobFull={isJobFull}
      isSearchingAll={searchAllApplicantList}
      isShowingAiScore={isShowingAIScore}
      currentVenue={currentVenue}
      fullscreen={fullscreen}
    />
  );

  const defaultSearchRange =
    currentJob?.geoPreference ?? currentVenue?.geoPreference ?? company?.geoPreference ?? "Radius";

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={searchAllApplicantList ?? fullData}
        icon={<Badge color="white" />}
        iconColor="success"
        title={`Job Status: ${currentJob?.title}`}
        isLoading={isLoading}
        isFetching={isFetching}
        topButtonBar={topButtonBar}
        greybar
        divider
        header={header}
        tableHeight={450}
        height={850}
        scrollY
        computedHeight
        loadMore={fetchNextPage}
        infiniteData={
          searchAllApplicantList ? null : applicants?.pages?.[applicants?.pages?.length - 1]
        }
      />
      {isOpenSelectAllModal && (
        <ApplicantsSearchAllModal
          open={isOpenSelectAllModal}
          setOpen={setIsOpenSelectAllModal}
          currentJob={currentJob}
          onSubmit={handleSearchAll}
          defaultSearchRange={defaultSearchRange}
        />
      )}
      {applicantResumeText && (
        <ApplicantResumeTextModal
          open={!!applicantResumeText}
          setOpen={handleCloseApplicantResumeModal}
          text={applicantResumeText.text}
          applicantName={applicantResumeText.applicantName}
        />
      )}
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          applicants={applicantsForBulk?.data?.map((item) => ({ ...item, id: item?._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext="Applicant"
          attachmentContext="Job"
          isLoading={isLoadingForBulk}
          jobId={currentJob?._id}
        />
      )}
      {isBulkApplicantStageChangeModalOpen && (
        <BulkApplicantStageChangeModal
          applicants={applicantsForBulk?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkApplicantStageChangeModalOpen}
          toggleModal={() => toggleBulkApplicantStageChangeModal(false)}
          isLoading={isLoadingForBulk}
          status={applicantDataFilter?.status}
          jobSlug={currentJob?.jobSlug}
          onChange={handleBulkChangeLocalStatus}
        />
      )}
      {!!aiQuestionModalSelectedApplicant && (
        <JobsStatusAIQuestionsModal
          open={!!aiQuestionModalSelectedApplicant}
          onClose={handleCloseAIQuestionsModal}
          applicant={aiQuestionModalSelectedApplicant}
          onUpdate={changeLocalJobAIInterviewData}
          currentVenue={currentVenue}
        />
      )}
      {!!aiScreeningResultModalSelectedApplicant && (
        <JobsStatusAIInterviewModal
          open={!!aiScreeningResultModalSelectedApplicant}
          onClose={handleCloseAIScreeningResultModal}
          applicant={aiScreeningResultModalSelectedApplicant}
          currentVenue={currentVenue}
          onChangeStatus={changeLocalApplicantStatus}
        />
      )}
      {!!removeFlagModalSelectedApplicant && (
        <ConfirmationModal
          isOpen={!!removeFlagModalSelectedApplicant}
          onClose={handleCloseRemoveFlagModal}
          title={`Are you sure you want to remove this flag from ${removeFlagModalSelectedApplicant?.firstName} ${removeFlagModalSelectedApplicant?.lastName}?`}
          onConfirm={(e) => {
            e.stopPropagation();
            if (removeFlagModalCurrentFlag === "aiScreened")
              handleRemoveAIScreenedFlagFromApplicant(removeFlagModalSelectedApplicant._id);
            else handleRemoveCallMeFlagFromApplicant(removeFlagModalSelectedApplicant._id);
            handleCloseRemoveFlagModal();
          }}
        />
      )}
    </>
  );
};

export default JobsStatus;
