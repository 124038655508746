import { FormControlLabel, List, ListItem, Radio, RadioGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { southCarolinaStateFormSchema } from "data/southCarolinaStateTaxForm";
import { useNewApplicantContext } from "context/NewApplicantContext";
import CommonTaxFields from "components/FormFields/CommonTaxFields";
import CurrencyField from "components/FormFields/CurrencyField";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { NumericFormat } from "react-number-format";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },

  displayTextDiv: {
    border: "solid 1px black",
    fontSize: "1rem",
    borderRadius: "10px",
    backgroundSize: "1rem 1rem",
    backgroundColor: "rgba(0, 0, 0, 0.10)",
  },
  displayText: {
    font: "inherit",
    letterSpacing: "inherit",
    color: "currentColor",
    justifyContent: "space-between",
    boxSizing: "content-box",
    background: "none",
    height: "1.4375em",
    margin: 0,
    display: "flex",
    minWidth: 0,
    padding: "20px 12px",
    textAlign: "end",
  },
});

const SouthCarolinaForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(southCarolinaStateFormSchema);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
    reset,
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const parsedData = {
      stateTaxForm: { state: "southCarolina", ...data },
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.value, { shouldDirty: true });
  };

  const [canContinue, setCanContinue] = useState(false);
  const renderKey = useRef(0); // Used to prevent race condition when running useEffect with promises

  useEffect(() => {
    const currentRenderKey = Math.round(Math.random() * 10000);
    renderKey.current = currentRenderKey;
    if (applicant && applicant.stateTaxForm) {
      southCarolinaStateFormSchema
        .validate(applicant.stateTaxForm)
        .then(() => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(true);
          }
        })
        .catch((error) => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(false);
          }
        });
    } else if (renderKey.current === currentRenderKey) {
      setCanContinue(false);
    }
  }, [applicant]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      next: {
        show: true,
        disabled: !canContinue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canContinue]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: !canContinue,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (applicant?.stateTaxForm) {
      reset(applicant.stateTaxForm);
    }
  }, [applicant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} selectedState="SC" />
        <Grid item sm={12}>
          <RadioGroup
            aria-labelledby={`filingStatus_${applicant?.venueSlug}`}
            name="filingStatus"
            {...(applicant?.stateTaxForm?.filingStatus
              ? { defaultValue: applicant?.stateTaxForm?.filingStatus }
              : {})}
            onChange={handleFilingStatusChange}
          >
            <Grid container>
              <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <FormControlLabel value="Single" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Single{" "}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <FormControlLabel value="Married" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Married{" "}
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <FormControlLabel
                  value=" MarriedWithholdHigherSingleRate"
                  control={<Radio />}
                  sx={{ marginRight: "0" }}
                />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Married, but withhold at higher Single rate (Check if married but filling
                  separately.)
                </MDTypography>
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item sm={12} display="flex" alignItems="center">
          <Controller
            name="differentLastName"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id="differentLastName"
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
              />
            )}
          />
          <MDTypography fontSize="0.875rem">
            Check if your last name is different on you Social Security Card
          </MDTypography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <MDTypography fontSize="0.875rem">
            5. Total number of allowances (from the applicable worksheet on page 3)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="totalAllowances"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    type: "number",
                    step: 1,
                    min: 0,
                    max: 25,
                  },
                }}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                label=""
                decimalScale={0}
                onChange={field.onChange}
                customInput={CustomTextField}
                value={field.value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography fontSize="0.875rem">
            6. Additional amount, if any, to withhold from each paycheck .
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="additionalAmount"
            control={control}
            render={({ field }) => (
              <CurrencyField label="" value={field.value} onChange={field.onChange} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MDTypography fontSize="0.875rem">
            7. I claim exemption from withholding for 2024. Check the box for the exemption reason
            and write <strong>Excempt</strong> on line 7
          </MDTypography>

          <List sx={{ marginLeft: 5 }}>
            <ListItem sx={{ marginY: 1 }}>
              <Controller
                name="noTaxLiability"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    id="noTaxLiability"
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
              <MDTypography fontSize="0.875rem">
                For tax year 2023, I had a right to a refund of all South Carolina Income Tax
                withheld because I had no tax liability, and for tax year 2024 I expect a refund of
                all South Carolina Income Tax withheld because I expect to have no tax liability.
              </MDTypography>
            </ListItem>
            <ListItem sx={{ marginY: 1 }}>
              <Controller
                name="militarySpouse"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    id="militarySpouse"
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      if (!e.target.checked) {
                        setValue("stateOfDomicile", "", { shouldDirty: true });
                      }
                    }}
                  />
                )}
              />
              <MDTypography fontSize="0.875rem">
                I elect to use the same state of residence for tax purposes as my military
                servicemember spouse. I have provided my employer with a copy of my current military
                ID card and a copy of my spouse&apos;s latest Leave and Earning Statement (LES).
              </MDTypography>
              <Controller
                name="stateOfDomicile"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="State of domicile"
                    variant="outlined"
                    disabled={!watch("militarySpouse")}
                    inputProps={{ tabIndex: -1 }}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <FormErrors errors={errors} />
    </form>
  );
};

export default SouthCarolinaForm;
