import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation, useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Card, Icon, Grid, IconButton, Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { SnackbarProvider } from "notistack";
import ActivitiesTableActions from "layouts/pages/activities/components/ActivitiesTableActions";
import GetIcons from "components/Activities/GetIcons";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import ActivitiesDetailModal from "components/Activities/ActivitiesDetailModal";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import {
  generateActivityChartReport,
  generateActivityTableReport,
} from "api/reports/activityReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import useSessionAuth from "hooks/useSessionAuth";
import fetchActivities from "../../actions/fetchActivities";
// import ActivitiesActionModal from "../ActivitiesActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const ActivitiesTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setToastAlert,
}) => {
  const { setCurrentActivity, currentActivity, company, userType } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  const isCompany = company?.companyType === "Company";

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(10); // Default pageSize was 10. Use preferred pageSize
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [detailActivityModalOpen, setDetailActivityModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ data: currentActivity });
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);

  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { mutateAsync: createActivityTableReport } = useMutation(generateActivityTableReport);
  const { mutateAsync: createActivityChartReport } = useMutation(generateActivityChartReport);
  const { mutateAsync: exportActivityReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveActivityReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const { data: users, isLoading } = useQuery(
    ["activities", allOptions],
    () => fetchActivities(allOptions),
    { refetchInterval: 10000 }
  );

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const { logoutAuth0User } = useSessionAuth();

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialActivityReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const response = await fetchModuleReports("activities");

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialActivityReport();
  }, [handleLoadInitialActivityReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportActivityReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Activity ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportActivityReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate,
            end: values?.endDate,
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        await createActivityTableReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartTableSrc(response?.iframe_url);
              setTableQueryDetails(response?.queryDetails);
              setTableReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Activity Table Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
        selectedSubtables: values?.selectedSubtables || [],
      };

      if (values?.chartFilters) {
        reportPayload = {
          ...reportPayload,
          chartFilters: values?.chartFilters,
        };
      }

      if (values?.chartDateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.chartStartDate, // ISO formatted date already handled
            end: values?.chartEdDate, // ISO formatted date already handled
            field: values?.chartDateField,
            range: values?.chartDateRange,
          },
        };
      }

      try {
        await createActivityChartReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Activity Chart Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveActivityReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData, formType: "chart" };
        }

        await saveActivityReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Activity ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving activity report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveActivityReport, tableReportData]
  );

  const columns = [
    {
      title: "Icon",
      field: "type",
      customCell: (image, field, row) => <GetIcons action={row?.action} activity={row} />,
    },
    {
      title: "Type",
      field: "type",
    },
    {
      title: "Date",
      field: "activityDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "User",
      field: "userFirstName",
      customCell: (id, field, row) => {
        let userName;
        if (row?.integration === "Indeed") {
          userName = "Indeed";
        } else if (
          (row?.userId === null || row?.userId === undefined) &&
          row?.integration !== "Indeed"
        ) {
          userName = "Internet";
        } else {
          userName = `${row.userFirstName} ${row.userLastName}`;
        }
        return <MDTypography variant="body">{userName}</MDTypography>;
      },
    },
    {
      title: "Applicant",
      field: "applicantFirstName",
      customCell: (id, field, row) => (
        <MDTypography variant="body">
          {row.applicantFirstName} {row.applicantLastName}
        </MDTypography>
      ),
    },
    {
      title: "Action",
      field: "action",
      customCell: (subject) => (
        <Tooltip title={subject}>
          <MDTypography variant="body">
            {subject?.length > 20 ? `${subject.substring(0, 18)}...` : subject}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      field: "description",
      customCell: (desc) => (
        <Tooltip title={desc}>
          <MDTypography
            sx={{
              fontSize: "16px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {desc?.length > 80 ? `${desc.substring(0, 78)}...` : desc}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      title: "Activiy Actions",
      field: "_id",
      customCell: (id, field, row) => (
        <ActivitiesTableActions
          id={id}
          row={row}
          setCurrentActivity={setCurrentActivity}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
        />
      ),
    },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/User ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
  ];

  const columnsForApplicantSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email", field: "email" },
    { title: "Type", field: "status" },
  ];

  const handleRowClick = (activity, e) => {
    e.stopPropagation();
    setCurrentActivity({ ...activity });
    // setDetailActivityModalOpen(true);
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} pr={2}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-6}
            >
              <TimelineIcon fontSize="large" />
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Activities
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={6}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {showSearchBar && (
            <Searchbar
              fetch={fetchApplicants}
              placeholder="Search Applicants"
              columns={columnsForApplicantSearch}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["firstName", "lastName", "email"]}
              filterBy="applicantId"
              filterByColumn="_id"
            />
          )}
          {/* <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox> */}

          {showSearchBar && (
            <Searchbar
              fetch={fetchUsers}
              placeholder="Search Users"
              columns={columnsForSearch}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["firstName", "lastName", "emailAddress"]}
              filterBy="userId"
              filterByColumn="_id"
            />
          )}

          {userType !== "User" && userType !== "Client" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}

          {/* <MDBox>
              <IconButton className={classes.addButton} color="info" onClick={addNew}>
                <AddCircleRoundedIcon />
              </IconButton>
            </MDBox> */}
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={users}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          defaultSort="activityDate:desc"
        />
      </MDBox>
      {/* <ActivitiesDetailModal
          activity={currentActivity}
          detailActivityModalOpen={detailActivityModalOpen}
          setDetailActivityModalOpen={setDetailActivityModalOpen}
        /> */}
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="activities"
          message="Coming Soon!"
          title="Activity Report"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          filterCardList={
            isCompany
              ? [
                  "Login",
                  "Applicant",
                  "Event",
                  "Customer",
                  "Message",
                  "Notification",
                  "Job",
                  "Integrations",
                  "All",
                ]
              : [
                  "Login",
                  "Applicant",
                  "Event",
                  "Venue",
                  "Message",
                  "Notification",
                  "Job",
                  "Integrations",
                  "All",
                ]
          }
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveActivityReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
ActivitiesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Users",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
ActivitiesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default ActivitiesTable;
