import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// prop-types is a library for typechecking of props
import { useAppContextController } from "context/AppContext";
import PropTypes from "prop-types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import VenuesEventsTable from "layouts/pages/events/components/EventsTable";
import VenuesClientEventsTable from "layouts/pages/clientevents/components/ClientEventsTable";
import JobsTable from "layouts/pages/jobs/components/JobsTable";
import VenuesTable from "layouts/pages/venues/components/VenueTable";
import VenueApplicantsTable from "layouts/pages/venues/components/VenuesApplicantsTable";
import ProspectFilterCards from "layouts/pages/customers/components/ProspectFilterCards"; // initialCards,
import VenuesFilterCards from "layouts/pages/venues/components/VenuesFilterCards";
// eslint-disable-next-line import/no-named-as-default-member
import VenueMainInfoPanel from "layouts/pages/venues/components/VenueMainInfoPanel";
import VenueMediaGallery from "layouts/pages/venues/components/VenueMediaGallery";
import VenuePreviewPanel from "layouts/pages/venues/components/VenuePreviewPanel";
import VenueSettingsPanel from "layouts/pages/venues/components/VenueSettingsPanel";

import MessageModal from "components/MessageModal";
import useLocalStorage from "hooks/useLocalStorage";
import EventsPreviewModal from "layouts/pages/events/components/EventsPreviewModal";
import VenueInstructionsModal from "layouts/pages/venues/components/VenueInstructionsModal";
import { checkIfApplicantVenuePending } from "utils/helpers/applicant";
import { isEmployeeUser } from "utils/helpers/roles";
import generateFiltersFromString from "utils/generateFiltersFromString";
import generateFilterString from "utils/generateFilterString";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import useHandleBackButtonMainPage from "hooks/useHandleBackButtonMainPage";
import useGetBackButton from "hooks/useGetBackButton";

import MessagingPanel from "components/MessagingPanel";
import { Alert, Button, Snackbar } from "@mui/material";
import VenueActivities from "./components/VenueActivities";
import VenueLocations from "./components/VenueLocations";
import VenueContacts from "./components/VenueContacts";
import VenueSales from "./components/VenueSales";
import VenueMedia from "./components/VenueMedia";
import VenueCustomContent from "./components/VenueCustomContent";
import VenueAISettings from "../customers/components/CustomerAISettings";
import AdditionalInformation from "./components/AdditionalInformation";

const venueCards = [
  { icon: "info", title: "Details", number: null },
  { icon: "work", title: "Jobs", number: null },
  { icon: "event-seat", title: "Events", number: null },
  { icon: "people", title: "Applicants", number: null },
  { icon: "public", title: "venueall", number: null },
];

function Venues({ mode = "edit" }) {
  const navigate = useNavigate();
  const { currentVenue, setCurrentVenue, currentLoggedUser, venues, userType, currentApplicant } =
    useAppContextController();
  const { slug, action } = useParams();
  const [searchParams] = useSearchParams();
  const myVenues = searchParams.get("myVenues");
  const [open, setOpen] = useState(false);
  const [venuePreview, setVenuePreview] = useState(null);
  const [actionSelected, setActionSelected] = useState(action || "");

  const [filters, setFilters] = useState({ status: "Active" });
  const [applicantsFilters, setApplicantsFilters] = useState({});
  const [employeesFilters, setEmployeesFilters] = useState({});

  // Handle back button for this screen
  const {
    backListUrl,
    filterParam,
    pageParam,
    subFilterParam,
    subPageParam,
    pageFromUrl,
    filtersFromUrl,
    subPageFromUrl,
    subFiltersFromUrl,
    navigateToListWithBack,
    navigateToScreenWithBack,
  } = useHandleBackButtonMainPage({
    baseUrl: "/venues",
    screenName: "Venues",
  });

  // Get back button generated by other screens
  const { backParam, backToTitle, backToUrl, backButton } = useGetBackButton();

  useEffect(() => {
    if (filterParam != null) {
      if (filterParam) {
        const newFilters = generateFiltersFromString(filterParam);
        setFilters(newFilters);
      } else {
        setFilters({});
      }
    }
  }, [filterParam]);

  const [eventPreview, setEventPreview] = useState(null);
  const [openEventPreview, setOpenEventPreview] = useState(false);
  const [venuesPage, setVenuesPage] = useState(1);
  const [refreshVenues, setRefreshVenues] = useState(false);
  const [venueInstructionModalOpen, setVenueInstructionModalOpen] = useState(false);
  const [isMessageModalOpen, toggleMessageModal] = useState(false);
  const [instructions, setInstructions] = useLocalStorage("venueInstructions", true);
  const [selectedPanel, setSelectedPanel] = useState("activities");
  const [selectedContact, setSelectedContact] = useState();

  const isNotUser = userType !== "User";
  const isNotClient = userType !== "Client";

  const refetchEventsFuncRef = useRef(null);

  const bindRefetchEvents = (callback) => {
    refetchEventsFuncRef.current = callback;
  };

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const refetchEvents = refetchEventsFuncRef.current;

  const renderPanel = (selection) => {
    switch (selection) {
      case "activities":
        return <VenueActivities />;
      case "additionalInfo":
        return <AdditionalInformation setToastAlert={setToastAlert} />;
      case "locations":
        return <VenueLocations />;
      case "contacts":
        return (
          <VenueContacts
            setSelectedPanel={setSelectedPanel}
            setSelectedContact={setSelectedContact}
          />
        );
      case "message":
        return (
          <MessagingPanel
            selectedContact={selectedContact}
            currentData={currentVenue}
            isVenue
            parent="Venue"
            messageContext="Venue"
            attachmentContext="Venue"
          />
        );
      case "sales":
        return <VenueSales />;
      case "media":
        return <VenueMedia />;
      case "customcontent":
        return <VenueCustomContent />;
      case "settings":
        return <VenueSettingsPanel />;
      case "aiSettings":
        return <VenueAISettings isVenue />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (slug && action) setFilters({ slug });
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
      const newFilters = { status: "Active" };
      if (
        myVenues &&
        currentLoggedUser?.venues?.length &&
        typeof currentLoggedUser.venues !== "string"
      ) {
        newFilters.slug = currentLoggedUser.venues.join(";");
        // moved inside if, causes filter to default even if selecting different filter card
        setFilters(newFilters);
      }
    }
    if (slug && (!currentVenue || currentVenue.slug !== slug)) {
      const venue = venues?.[slug];
      if (venue) setCurrentVenue(venue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, action, currentVenue]);

  const setNavigateUrl = (url) => navigate(url);

  useEffect(() => {
    if (instructions) {
      setVenueInstructionModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedContact();
  }, [currentVenue]);

  const isApplicantEventVenuePending = () => {
    const applicantVenues = currentApplicant?.venues;
    return checkIfApplicantVenuePending(applicantVenues, currentVenue?.slug);
  };

  const jobsFilters = useMemo(
    () => (currentVenue?.slug ? { venueSlug: currentVenue?.slug } : {}),
    [currentVenue?.slug]
  );

  const eventFilters = useMemo(
    () => (currentVenue?.slug ? { venueSlug: currentVenue.slug, timeFrame: "Current" } : {}),
    [currentVenue?.slug]
  );

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {backToUrl ? (
            <Grid item xs={12} sm={12} md={12} pb={3} pt={3}>
              {backButton}
            </Grid>
          ) : (
            <>
              {userType !== "Client" ? (
                <>
                  {isNotUser && <VenuesFilterCards setFilters={setFilters} />}
                  {isNotUser && filters.status === "Prospect" ? (
                    <ProspectFilterCards
                      setFilters={setFilters}
                      entity="venues"
                      filters={filters}
                    />
                  ) : null}
                </>
              ) : null}
            </>
          )}

          <VenuesTable
            slug={slug}
            fetchAll
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            filters={filters}
            setFilters={setFilters}
            setOpen={setOpen}
            setVenuePreview={setVenuePreview}
            refreshVenues={refreshVenues}
            setToastAlert={setToastAlert}
            parentPage={pageFromUrl}
            parentNavigateWithBack={navigateToListWithBack}
          />
        </Grid>
        {backListUrl && (
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(backListUrl);
              }}
            >
              Back to Venue List
            </Button>
          </Grid>
        )}
        {["venueinfo", "venueall", ""].includes(actionSelected) &&
          (slug || mode === "create") &&
          isNotUser &&
          isNotClient && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <VenueMainInfoPanel
                    mode={mode}
                    open={open}
                    setOpen={setOpen}
                    setVenuePreview={setVenuePreview}
                    setRefreshVenues={setRefreshVenues}
                    selectedPanel={selectedPanel}
                    onPanelSelect={setSelectedPanel}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {renderPanel(selectedPanel)}
                </Grid>
              </Grid>
            </Grid>
          )}
        {["venuejobs", "venueall"].includes(actionSelected) && isNotUser && isNotClient && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <JobsTable
                title={`Venue Jobs at: ${currentVenue?.slug?.toUpperCase()}`}
                filters={jobsFilters}
                fetchAll
                showFiltersList={false}
                showSearchBar={false}
                defaultSort="title"
                parent="Venues"
                setToastAlert={setToastAlert}
                name={`${currentVenue?.name}`}
                parentPage={subPageFromUrl}
                parentNavigateWithBack={navigateToScreenWithBack}
              />
            </Grid>
          </>
        )}
        {["venueevents", "venueEvents", "venueall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              {isNotClient ? (
                <VenuesEventsTable
                  showFiltersList={false}
                  showSearchBar={false}
                  title={`Venue Events at: ${currentVenue?.slug?.toUpperCase()}`}
                  filters={eventFilters}
                  navigateToAction="eventsinfo"
                  setOpen={(isOpen) => {
                    if (isApplicantEventVenuePending() && isEmployeeUser(userType)) {
                      setOpenEventPreview(false);
                      toggleMessageModal(true);
                      return;
                    }
                    setOpenEventPreview(isOpen);
                  }}
                  defaultSort="eventDate"
                  setEventPreview={setEventPreview}
                  parent="Venues"
                  bindRefetchEvents={bindRefetchEvents}
                  setToastAlert={setToastAlert}
                  name={currentVenue?.name}
                  parentPage={subPageFromUrl}
                  parentNavigateWithBack={navigateToScreenWithBack}
                />
              ) : (
                <VenuesClientEventsTable
                  showFiltersList={false}
                  fetchAll
                  showSearchBar={false}
                  title={`Venue Events at: ${currentVenue?.slug?.toUpperCase()}`}
                  filters={eventFilters}
                  navigateToAction="eventsinfo"
                  setOpen={(isOpen) => {
                    setOpenEventPreview(isOpen);
                  }}
                  defaultSort="eventDate"
                  setEventPreview={setEventPreview}
                  parent="Venues"
                  bindRefetchEvents={bindRefetchEvents}
                  setActionSelected={setActionSelected}
                  setFilters={setFilters}
                  setNavigateUrl={setNavigateUrl}
                  parentPage={subPageFromUrl}
                  parentNavigateWithBack={navigateToScreenWithBack}
                />
              )}
            </Grid>
          </>
        )}
        {["venueemployees", "venueall"].includes(actionSelected) && isNotUser && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <VenueApplicantsTable
                type="Staffing"
                showFiltersList
                showSearchBar={false}
                fetchAll
                filters={
                  slug ? { "venues.venueSlug": slug, ...employeesFilters } : { ...employeesFilters }
                }
                title={`Staffing Pool at: ${currentVenue?.slug?.toUpperCase()}`}
                navigateToAction="employeeinfo"
                defaultSort="lastName:asc, firstName:asc"
                venueSlug={slug}
                showEventStatus
                pageSize={50}
                page={venuesPage}
                setPage={setVenuesPage}
                messageContext="Venue"
                venueId={currentVenue?._id}
                staffingPoolName={currentVenue?.name}
                setToastAlert={setToastAlert}
                parentPage={subPageFromUrl}
                parentFilters={subFiltersFromUrl}
                parentNavigateWithBack={navigateToScreenWithBack}
              />
            </Grid>
          </>
        )}
        {["venueapplicants", "venueall"].includes(actionSelected) && isNotUser && isNotClient && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <VenueApplicantsTable
                type="Applicants"
                showFiltersList
                showSearchBar={false}
                fetchAll
                filters={
                  slug
                    ? { "venues.venueSlug": slug, ...applicantsFilters }
                    : { ...applicantsFilters }
                }
                title={`Venue Applicants at: ${currentVenue?.slug?.toUpperCase()}`}
                navigateToAction="applicantinfo"
                venueSlug={slug}
                showEventStatus
                pageSize={50}
                page={venuesPage}
                setPage={setVenuesPage}
                messageContext="Venue"
                venueId={currentVenue?._id}
                parentPage={subPageFromUrl}
                parentFilters={subFiltersFromUrl}
                parentNavigateWithBack={navigateToScreenWithBack}
              />
            </Grid>
          </>
        )}
        {["venuemedia", "venueall"].includes(actionSelected) && isNotUser && isNotClient && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <VenueMediaGallery title="Media Gallery" />
            </Grid>
          </>
        )}
        {venuePreview && (
          <VenuePreviewPanel
            open={open}
            setOpen={setOpen}
            venueId={venuePreview._id}
            refreshVenues={refreshVenues}
          />
        )}
        <EventsPreviewModal
          open={openEventPreview}
          setOpen={setOpenEventPreview}
          eventPreview={eventPreview}
          refetchEvents={refetchEvents}
        />
      </Grid>
      {userType === "User" && (
        <VenueInstructionsModal
          open={venueInstructionModalOpen}
          setOpen={setVenueInstructionModalOpen}
          setInstructions={setInstructions}
        />
      )}
      {isMessageModalOpen && (
        <MessageModal
          message="You must be approved to work at this Venue before you can view and sign up for events. Check your email for an approval within the next 24-48 hours"
          isOpen={isMessageModalOpen}
          toggleOpen={toggleMessageModal}
        />
      )}
    </DashboardLayout>
  );
}

// Setting default values for the props
Venues.defaultProps = {
  mode: "edit",
};

// Typechecking props
Venues.propTypes = {
  mode: PropTypes.string,
};

export default Venues;
