import { baseAxios } from "config";

const updateApplicantJobAISettings = ({ data, applicantId, jobSlug }) => {
  return baseAxios.post(`/applicants/${applicantId}/job/aisettings`, {
    jobSlug,
    data,
  });
};

export default updateApplicantJobAISettings;
