import { Grid } from "@mui/material";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDTypography from "components/MDTypography";

const AIInterviewChatWindowMessageDetails = ({ questionScoring }) => {
  return (
    <Grid container width="20rem">
      <Grid container overflow="hidden" alignItems="center">
        <Grid item xs={7}>
          <MDTypography
            component="label"
            variant="h6"
            fontWeight="bold"
            color="dark"
            textAlign="right"
          >
            Relevance:
          </MDTypography>
        </Grid>
        <Grid item xs={5}>
          <JobsStatusRankStars
            rank={(questionScoring?.scores?.relevance ?? 0) / 4}
            color="#8935f4"
            linear
            offsetTop={5}
          />
        </Grid>
      </Grid>
      <Grid container overflow="hidden" alignItems="center">
        <Grid item xs={7}>
          <MDTypography
            component="label"
            variant="h6"
            fontWeight="bold"
            color="dark"
            textAlign="right"
          >
            Experience Skills:
          </MDTypography>
        </Grid>
        <Grid item xs={5}>
          <JobsStatusRankStars
            rank={(questionScoring?.scores?.experience_skills ?? 0) / 4}
            color="#8935f4"
            linear
            offsetTop={5}
          />
        </Grid>
      </Grid>
      <Grid container overflow="hidden" alignItems="center">
        <Grid item xs={7}>
          <MDTypography
            component="label"
            variant="h6"
            fontWeight="bold"
            color="dark"
            textAlign="right"
          >
            Problem Solving:
          </MDTypography>
        </Grid>
        <Grid item xs={5}>
          <JobsStatusRankStars
            rank={(questionScoring?.scores?.problem_solving ?? 0) / 4}
            color="#8935f4"
            linear
            offsetTop={5}
          />
        </Grid>
      </Grid>
      <Grid container overflow="hidden" alignItems="center">
        <Grid item xs={7}>
          <MDTypography
            component="label"
            variant="h6"
            fontWeight="bold"
            color="dark"
            textAlign="right"
          >
            Communication:
          </MDTypography>
        </Grid>
        <Grid item xs={5}>
          <JobsStatusRankStars
            rank={(questionScoring?.scores?.communication ?? 0) / 4}
            color="#8935f4"
            linear
            offsetTop={5}
          />
        </Grid>
      </Grid>
      <Grid container overflow="hidden" alignItems="center">
        <Grid item xs={7}>
          <MDTypography
            component="label"
            variant="h6"
            fontWeight="bold"
            color="dark"
            textAlign="right"
          >
            Situational Awareness:
          </MDTypography>
        </Grid>
        <Grid item xs={5}>
          <JobsStatusRankStars
            rank={(questionScoring?.scores?.situational_awareness ?? 0) / 4}
            color="#8935f4"
            linear
            offsetTop={5}
          />
        </Grid>
      </Grid>
      <Grid container>
        <MDTypography
          component="label"
          variant="body2"
          fontWeight="normal"
          color="dark"
          textAlign="left"
        >
          {questionScoring?.notes}
        </MDTypography>
      </Grid>
    </Grid>
  );
};

// Setting default values for the props of CalendarDayModal
AIInterviewChatWindowMessageDetails.defaultProps = {};

// Typechecking props of the CalendarDayModal
AIInterviewChatWindowMessageDetails.propTypes = {};

export default AIInterviewChatWindowMessageDetails;
