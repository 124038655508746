import { baseAxios } from "config";

export const updateYardstikStatusToProceed = async ({ body }) => {
  try {
    const res = await baseAxios.post(`/yardstik/proceedReport`, body);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};
