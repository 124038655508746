import axios from "axios";

import { baseAxios } from "config";

const generateResumeAIQuestions = async ({
  jobSlug,
  applicantId,
  questions,
  numberOfQuestions,
  educationLevel,
  saveToDb,
}) => {
  try {
    const res = await baseAxios.post(`/jobs/${jobSlug}/ai/${applicantId}/resume/generate/`, {
      questions,
      numberOfQuestions,
      educationLevel,
      saveToDb,
    });
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default generateResumeAIQuestions;
