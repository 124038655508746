import { FormControlLabel, List, ListItem, Radio, RadioGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import CommonTaxFields from "components/FormFields/CommonTaxFields";
import CurrencyField from "components/FormFields/CurrencyField";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { NumericFormat } from "react-number-format";
import { californiaStateFormSchema } from "data/californiaStateTaxForm";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },

  displayTextDiv: {
    border: "solid 1px black",
    fontSize: "1rem",
    borderRadius: "10px",
    backgroundSize: "1rem 1rem",
    backgroundColor: "rgba(0, 0, 0, 0.10)",
  },
  displayText: {
    font: "inherit",
    letterSpacing: "inherit",
    color: "currentColor",
    justifyContent: "space-between",
    boxSizing: "content-box",
    background: "none",
    height: "1.4375em",
    margin: 0,
    display: "flex",
    minWidth: 0,
    padding: "20px 12px",
    textAlign: "end",
  },
});

const CaliforniaForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(californiaStateFormSchema);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
    reset,
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: {
      filingStatus:
        applicant?.stateTaxForm?.filingStatus || applicant?.w4Tax?.filingStatus || "Single",
    },
  });

  console.log("watch", watch("filingStatus"));
  const onSubmit = async (data) => {
    const parsedData = {
      stateTaxForm: { state: "CA", ...data },
    };
    updateApplicantAction(applicant._id, parsedData);
  };

  const handleClaimException = (e) => {
    if (e) {
      setValue("claimExemption", e.target.id, { shouldDirty: true });
    } else {
      setValue("claimExemption", null, { shouldDirty: true });
    }
  };

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.value, { shouldDirty: true });
  };

  const [canContinue, setCanContinue] = useState(false);
  const renderKey = useRef(0); // Used to prevent race condition when running useEffect with promises

  useEffect(() => {
    const currentRenderKey = Math.round(Math.random() * 10000);
    renderKey.current = currentRenderKey;
    if (applicant && applicant.stateTaxForm) {
      californiaStateFormSchema
        .validate(applicant.stateTaxForm)
        .then(() => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(true);
          }
        })
        .catch((error) => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(false);
          }
        });
    } else if (renderKey.current === currentRenderKey) {
      setCanContinue(false);
    }
  }, [applicant]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      next: {
        show: true,
        disabled: !canContinue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canContinue]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: !canContinue,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (applicant?.stateTaxForm) {
      reset({
        filingStatus:
          applicant?.stateTaxForm?.filingStatus || applicant?.w4Tax?.filingStatus || "Single",
        ...applicant.stateTaxForm,
      });
    }
  }, [applicant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} selectedState="SC" />
        <Grid item sm={12}>
          <MDTypography variant="body2" mb={2}>
            Filling Status
          </MDTypography>

          <RadioGroup
            aria-labelledby={`filingStatus_${applicant?.venueSlug}`}
            name="filingStatus"
            value={watch("filingStatus")}
            onChange={handleFilingStatusChange}
          >
            <Grid container>
              <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <FormControlLabel value="Single" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Single or Married (with two or more incomes)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <FormControlLabel value="Married" control={<Radio />} sx={{ marginRight: "0" }} />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Married (one income)
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" alignItems="center">
                <FormControlLabel
                  value=" Head of Household"
                  control={<Radio />}
                  sx={{ marginRight: "0" }}
                />
                <MDTypography fontWeight="bold" fontSize="0.875rem">
                  Head of Household
                </MDTypography>
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={12} mt={2}>
          <MDTypography variant="body2">
            1. Use Worksheet A for Regular Withholding allowances. use other worksheets on the
            following pages as applicable
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2">1a. Number of Regular Withholding Allowances</MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="regularWithholdingAllowances"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    type: "number",
                    min: 0,
                    max: 20,
                  },
                }}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                defaultValue={0}
                customInput={CustomTextField}
                decimalScale={2}
                label=""
                value={field.value}
                onChange={(e, v) => {
                  setValue("regularWithholdingAllowances", e.target.value, { shouldDirty: true });
                  setValue(
                    "totalAllowances",
                    parseFloat(e.target.value) +
                      parseFloat(watch("estimatedDeductionsAllowances") || 0),
                    { shouldDirty: true }
                  );
                  handleClaimException(null);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2">
            1b. Number of allowances from the Estimated Deductions
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="estimatedDeductionsAllowances"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    type: "number",
                    min: 0,
                    max: 20,
                  },
                }}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                customInput={CustomTextField}
                decimalScale={2}
                label=""
                defaultValue={0}
                value={field.value}
                onChange={(e, v) => {
                  setValue("estimatedDeductionsAllowances", e.target.value, { shouldDirty: true });
                  setValue(
                    "totalAllowances",
                    parseFloat(e.target.value) +
                      parseFloat(watch("regularWithholdingAllowances") || 0),
                    { shouldDirty: true }
                  );
                  handleClaimException(null);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2">
            1c. Total number of Allowances you are claiming
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="totalAllowances"
            control={control}
            render={({ field }) => (
              <NumericFormat
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    type: "number",
                    min: 0,
                  },
                }}
                disabled
                tabIndex={-1}
                sx={{ input: { textAlign: "end" } }}
                variant="outlined"
                customInput={CustomTextField}
                decimalScale={2}
                defaultValue={0}
                label=""
                value={field.value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2">
            2. Additional amount, if any, you want withheld each pay period
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="additionalWithholding"
            control={control}
            render={({ field }) => (
              <CurrencyField
                label=""
                value={field.value}
                defaultValue={0}
                onChange={(e, v) => {
                  setValue("additionalWithholding", e.target.value, { shouldDirty: true });
                  handleClaimException(null);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">OR</MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h5">Exemption from Withholding</MDTypography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2">
            3. I claim exemption from withholding for 2024, and I certify I meet both of the
            condition for exemption.
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="end">
          <Checkbox
            id="exempt"
            checked={watch("claimExemption") === "exempt"}
            onChange={(e) => {
              handleClaimException(e);
              setValue("regularWithholdingAllowances", 0);
              setValue("estimatedDeductionsAllowances", 0);
              setValue("totalAllowances", 0);
              setValue("additionalWithholding", 0);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6">OR</MDTypography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2">
            4. I certify under penalty of perjury that I am <strong>not subject</strong> to
            California withholding. I meet the conditions set forth under the Service Member Civil
            Relief Act, as amended by the Military Spouses Residency Relief Act and the Veterans
            Benefits and Transition Act of 2018.
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="end">
          <Checkbox
            id="exemptFromCalifornia"
            checked={watch("claimExemption") === "exemptFromCalifornia"}
            onChange={(e) => {
              handleClaimException(e);
              setValue("regularWithholdingAllowances", 0);
              setValue("estimatedDeductionsAllowances", 0);
              setValue("totalAllowances", 0);
              setValue("additionalWithholding", 0);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3} borderTop="1px solid black">
        <MDTypography variant="body2">
          Under the penalties of perjury, I certify that the number of withholding allowances
          claimed on this certificate does not exceed the number to which I am entitled or, if
          claiming exemption from withholding, that I am entitles to claim the exempt status.
        </MDTypography>
      </Grid>
      <FormErrors errors={errors} />
    </form>
  );
};

export default CaliforniaForm;
