import { Autocomplete, Button, Card, Fade, Grid, Modal, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import { Save } from "@mui/icons-material";
import useDropdowns from "hooks/useDropdowns";
import CustomTextField from "components/Form/CustomTextField";
import CircularLoader from "components/CircularLoader";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "30%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropzone: {
    height: 170,
  },
});
const BGCheckPackageSelectionYardstik = ({ open, setOpen, onSubmitAction }) => {
  const classes = useStyle();
  const { dropdowns: bgCheckPackages, isLoadingDropdown } = useDropdowns({
    entity: "yardstikPricingTable",
  });

  const [selectedPackage, setSelectedPackage] = useState(null);
  const { currentApplicant } = useAppContextController();

  const filteredPackages = useMemo(() => {
    return (
      bgCheckPackages?.filter(
        (item) => item.stateCode === "national" || item.stateCode === currentApplicant?.state
      ) ?? []
    );
  }, [bgCheckPackages, currentApplicant]);

  const defaultPackage = useMemo(() => {
    const dPKg =
      filteredPackages.find((item) => item.stateCode === currentApplicant?.state) ||
      filteredPackages.find((item) => item.stateCode === "national") ||
      null;
    setSelectedPackage(dPKg);
    return dPKg;
  }, [filteredPackages, currentApplicant]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Card className={classes.container}>
          <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
            Select a background check search type
          </MDTypography>
          {isLoadingDropdown ? (
            <CircularLoader
              sx={{ mb: 1, ml: 2, mr: 2 }}
              size="2rem"
              color="info"
              hasBackdrop={false}
              isLoading
            />
          ) : (
            <Autocomplete
              sx={{ mb: 1, ml: 2, mr: 2 }}
              options={filteredPackages || []}
              autoSelect
              defaultValue={defaultPackage}
              getOptionLabel={(option) => option.name || option}
              // value={selectedPackage}
              onChange={(e, v) => {
                setSelectedPackage(v);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} type="text" label="Search type" showError />
              )}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} marginY={3} mr={3} textAlign="end">
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(false)}
                  sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selectedPackage}
                  type="button"
                  variant="contained"
                  startIcon={<Save />}
                  style={{ color: "white" }}
                  sx={{ padding: "0.5rem 1rem", borderRadius: 1 }}
                  onClick={() => {
                    onSubmitAction(selectedPackage);
                    setOpen(false);
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </Modal>
  );
};

export default BGCheckPackageSelectionYardstik;
