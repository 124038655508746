import { useEffect } from "react";

import W4TaxFormFields from "components/FormFields/W4TaxFormFields";
import { useNewApplicantContext } from "context/NewApplicantContext";

const FederalW4Tax = ({ scrollToTop = true }) => {
  const { applicant, updateApplicantAction, updateButtons, buttonState } = useNewApplicantContext();

  const onSubmit = async (data) => {
    const parsedData = {
      w4Tax: data,
    };
    updateApplicantAction(applicant._id, parsedData);
    if (scrollToTop) {
      setTimeout(
        () =>
          window.scroll({
            top: 500,
          }),
        1000
      );
    }
  };

  return <W4TaxFormFields onSubmit={onSubmit} />;
};

export default FederalW4Tax;
