import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  AutoAwesome,
  FileDownload,
  Mail,
  Phone,
  PublishedWithChanges,
  SpeakerNotesOff,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import VenueStatusButton from "components/VenueStatusButton";
import { useAppContextController } from "context/AppContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import ApplicantsFilterCards from "layouts/pages/applicants/components/ApplicantFilterCards";
import JobsApplicantsTableActions from "layouts/pages/jobs/components/JobsApplicantsTableActions";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import {
  getApplicantJobAiScreenedFlag,
  getApplicantJobCallMeFlag,
  getApplicantJobHasFinishedAIScreening,
  getApplicantVenueStatus,
} from "utils/helpers/applicant";
import useSort from "utils/useSort";
// import { applicantFields } from "components/StaffingPoolExportModal/ApplicantExportFields";
import StaffingPoolFilterCards from "layouts/pages/employees/components/StaffingPoolFilterCards";
import TagSearchAndToggle from "components/TagSearchAndToggle";
import { isEmpty } from "lodash";
import { BulkApplicantStageChangeModal } from "layouts/pages/events/components/BulkApplicantStageChangeModal";
import NoApplicantsModal from "layouts/pages/applicants/components/NoApplicantsModal";
import OnboardingStatus from "components/OnboardingStatus";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ApplicantStatus from "components/ApplicantStatus";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import useSessionAuth from "hooks/useSessionAuth";
import {
  generateJobApplicantChartReport,
  generateJobApplicantTableReport,
  generateJobStaffingPoolChartReport,
  generateJobStaffingPoolTableReport,
} from "api/reports/applicantReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";
import ApplicantFlagsForJob from "components/ApplicantFlagsForJob";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const JobsApplicantsTable = ({
  type = "Staffing",
  fetchAll = false,
  title = "Job Applicants",
  filters,
  pageSize = 5,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  jobSlug = null,
  showEventStatus = false,
  setSelectedId,
  staffingPoolName,
  setToastAlert,
  isJobStaffingPool,
  name,
  venue,
  parentPage,
  parentFilters,
  parentNavigateWithBack,
}) => {
  const queryClient = useQueryClient();
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const classes = useStyle();
  const {
    currentApplicant,
    setCurrentVenue,
    setCurrentApplicant,
    currentVenue,
    company,
    venues,
    currentJob,
    userType,
  } = useAppContextController();
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [venueId, setVenueId] = useState(null);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isBulkApplicantStageChangeModalOpen, toggleBulkApplicantStageChangeModal] =
    useState(false);
  const [openNoApplicantsModal, toggleNoApplicantsModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [searchTagList, setSearchTagList] = useState([]);
  const [useOrTags, setUseOrTags] = useState(true);

  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { logoutAuth0User } = useSessionAuth();

  const { mutateAsync: createJobApplicantTableReport } = useMutation(
    generateJobApplicantTableReport
  );
  const { mutateAsync: createJobApplicantChartReport } = useMutation(
    generateJobApplicantChartReport
  );
  const { mutateAsync: createJobStaffingPoolTableReport } = useMutation(
    generateJobStaffingPoolTableReport
  );
  const { mutateAsync: createJobStaffingPoolChartReport } = useMutation(
    generateJobStaffingPoolChartReport
  );
  const { mutateAsync: exportJobApplicantReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveJobApplicantReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const defaultFilters = {
    status: type === "Staffing" ? "Employee" : "Applicant",
  };
  if (type !== "Staffing") defaultFilters.applicantStatus = "New";

  const [applicantsFilters, setApplicantsFilters] = useState(defaultFilters);
  const [recipientText, setRecipientText] = useState("All");

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(pageSize);

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters.tags;
    setApplicantsFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(true);
  };

  // Handle back button from parent screens
  const { navigateWithBack } = useHandleBackButtonSubGrid({
    parentPage,
    parentFilters,
    setPage,
    setFilters: setApplicantsFilters,
    page,
    filters: applicantsFilters,
    navigateFunc: setNavigateUrl,
    navigateWithBackFunc: parentNavigateWithBack,
  });
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const additionalOptions = {
    includeVerified: true,
    filters: {
      status: type === "Staffing" ? "Employee" : "Applicant",
      ...filters,
      ...applicantsFilters,
    },
    useOrTags,
    useElemMatch: true,
  };
  const allOptions = {
    ...options,
    ...additionalOptions,
  };
  const fetchAllOptions = {
    fetchAll: true,
    ...additionalOptions,
  };

  const { data: allApplicants } = useQuery(["jobsapplicants", fetchAllOptions], () =>
    fetchApplicants(fetchAllOptions)
  );

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["jobsapplicants", allOptions], () => fetchApplicants(allOptions));

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      // setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);
  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, id, applicants?.data]);

  useEffect(() => {
    if (venueSlug && venues) {
      setVenueId(venues?.[venueSlug]?._id);
    }
    if (venueSlug && (!currentVenue || currentVenue?.slug !== venueSlug)) {
      const _venue = venues?.[venueSlug];
      if (_venue) setCurrentVenue(_venue);
    }
  }, [venueSlug, venues]);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialJobApplicantPoolReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const slug = filters["jobs.jobSlug"] || jobSlug;

        const response = await fetchModuleReports(
          isJobStaffingPool ? `job-staffing-pool:${slug}` : `job-applicant:${slug}`
        );

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialJobApplicantPoolReport();
  }, [handleLoadInitialJobApplicantPoolReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportJobApplicantReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Job ${isJobStaffingPool ? "Staffing Pool" : "Applicant"} ${
                reportType === "table" ? "Table" : "Chart"
              } ${fileType === "csv" ? " CSV" : " Excel"} Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [
      chartQueryDetails?.id,
      exportJobApplicantReport,
      isJobStaffingPool,
      setToastAlert,
      tableQueryDetails?.id,
    ]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        const slug = filters["jobs.jobSlug"] || jobSlug;

        if (slug) {
          reportPayload = {
            ...reportPayload,
            jobSlug: slug,
          };

          if (isJobStaffingPool) {
            await createJobStaffingPoolTableReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartTableSrc(response?.iframe_url);
                  setTableQueryDetails(response?.queryDetails);
                  setTableReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: `Job ${
                    isJobStaffingPool ? "Staffing Pool" : "Applicant"
                  } Table Report has been successfully generated!`,
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          } else {
            await createJobApplicantTableReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartTableSrc(response?.iframe_url);
                  setTableQueryDetails(response?.queryDetails);
                  setTableReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: `Job ${
                    isJobStaffingPool ? "Staffing Pool" : "Applicant"
                  } Table Report has been successfully generated!`,
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          }
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      if (values?.chartFilters) {
        reportPayload = {
          ...reportPayload,
          chartFilters: values?.chartFilters,
        };
      }

      if (values?.chartDateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.chartStartDate, // ISO formatted date already handled
            end: values?.chartEdDate, // ISO formatted date already handled
            field: values?.chartDateField,
            range: values?.chartDateRange,
          },
        };
      }

      try {
        const slug = filters["jobs.jobSlug"] || jobSlug;

        if (slug) {
          reportPayload = {
            ...reportPayload,
            jobSlug: slug,
          };

          if (isJobStaffingPool) {
            await createJobStaffingPoolChartReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartSrc(response?.iframe_url);
                  setChartQueryDetails(response?.queryDetails);
                  setChartReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: `Job ${
                    isJobStaffingPool ? "Staffing Pool" : "Applicant"
                  } Chart Report has been successfully generated!`,
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          } else {
            await createJobApplicantChartReport(reportPayload, {
              onSuccess: async (response) => {
                if (response?.iframe_url) {
                  setChartSrc(response?.iframe_url);
                  setChartQueryDetails(response?.queryDetails);
                  setChartReportData(response?.reportData);
                }

                setToastAlert({
                  isShow: true,
                  message: `Job ${
                    isJobStaffingPool ? "Staffing Pool" : "Applicant"
                  } Chart Report has been successfully generated!`,
                  status: "success",
                });
              },
              onError: (err) => {
                setToastAlert({
                  isShow: true,
                  message: `Something went wrong! ${err}`,
                  status: "error",
                });
              },
            });
          }
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        const slug = filters["jobs.jobSlug"] || jobSlug;

        if (slug) {
          if (isJobStaffingPool) {
            _tableReportData = {
              ..._tableReportData,
              feature: `job-staffing-pool:${slug}`,
            };

            _chartReportData = {
              ..._chartReportData,
              feature: `job-staffing-pool:${slug}`,
            };
          } else {
            _tableReportData = {
              ..._tableReportData,
              feature: `job-applicant:${slug}`,
            };

            _chartReportData = {
              ..._chartReportData,
              feature: `job-applicant:${slug}`,
            };
          }
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveJobApplicantReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Job ${isJobStaffingPool ? "Staffing Pool" : "Applicant"} ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error(
          `Error saving job ${isJobStaffingPool ? "Staffing Pool" : "Applicant"} report:`,
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveJobApplicantReport, tableReportData]
  );

  const columns = [
    ...(company?.companyType === "Company"
      ? []
      : [
          {
            title: "Avatar",
            field: "profileImg",
            customCell: (image, field, row) => (
              <PictureAvatar
                image={row?.profileImg}
                firstName={row?.firstName}
                lastName={row?.lastName}
                userId={row?.userRecordId ? row.userRecordId : row?._id}
                size="md"
              />
            ),
          },
        ]),
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, field, row) => (
        <ApplicantFlagsForJob flagged={flagged} row={row} jobSlug={jobSlug} />
      ),
    },
    {
      title: "Onboard",
      field: "profileImg",
      customCell: (image, field, row) => <OnboardingStatus applicant={row} size="md" />,
    },
    { title: "Type", field: "status" },
    {
      title: "Applicant Status",
      field: "applicantStatus",
      customCell: (image, field, row) => <ApplicantStatus applicant={row} size="lg" />,
    },
    ...(venueSlug && currentVenue
      ? [
          {
            title: "Venue Status",
            field: "slug",
            customCell: (_id, field, row) => (
              <>
                <VenueStatusButton
                  status={getApplicantVenueStatus(row, venueSlug)}
                  venue={currentVenue}
                  onUpdate={() => refetchApplicants()}
                  applicant={row}
                />
              </>
            ),
          },
        ]
      : []),
    {
      title: "Last Name",
      field: "lastName",
      customCell: (_name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{_name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      customCell: (_name, field, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{_name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    ...(type === "Staffing" ? [{ title: "Login", field: "loginVerified" }] : []),
    // {
    //   title: "Hire Date",
    //   field: "hireDate",
    //   customCell: (date) => moment(date).format("YYYY-MM-DD"),
    // },
    // { title: "Rep", field: "" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Rank", field: "rank" },
    { title: "Source", field: "referralSource" },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Applicant Actions",
      field: "_id",
      customCell: (rowId, field, row) => (
        <JobsApplicantsTableActions
          id={rowId}
          row={row}
          type={type}
          setCurrentApplicant={setCurrentApplicant}
          setModalInfo={setModalInfo}
          setNavigateUrl={navigateWithBack}
          setGenericModalOpen={setGenericModalOpen}
          parentTable="jobs"
          onRefresh={refetchApplicants}
        />
      ),
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    navigateWithBack(
      type === "Staffing"
        ? `/employees/${applicant._id}/action/employeeinfo`
        : `/applicants/${applicant._id}/action/applicantinfo`
    );
  };

  const addNew = (e) => {
    if (!currentApplicant) {
      setCurrentApplicant({});
    }
    navigateWithBack("/applicants/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  useEffect(() => {
    if (applicantsFilters?.["licenses.licenseType.value"])
      setRecipientText(`Licenses: ${applicantsFilters?.["licenses.licenseType.value"]}`);
    if (applicantsFilters?.status) {
      if (applicantsFilters?.status?.includes("Applicant")) setRecipientText("All");
      else setRecipientText(applicantsFilters?.status);
    }
  }, [applicantsFilters]);

  const handleBulkStageChangeModal = () => {
    if (allApplicants?.data?.length > 0) {
      toggleBulkApplicantStageChangeModal(true);
    } else {
      toggleNoApplicantsModal(true);
    }
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  const renderTitle = () =>
    isJobStaffingPool
      ? `Job Staffing Pool Report for ${name} at ${venue}`
      : `Job Applicant Report for ${name} at ${venue}`;

  return (
    <>
      <Card className={classes.box}>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor={type === "Staffing" ? "error" : "warning"}
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">people</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {title}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <TagSearchAndToggle
              filters={applicantsFilters}
              setFilters={setApplicantsFilters}
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              useOrTags={useOrTags}
              setUseOrTags={setUseOrTags}
            />
            {showSearchBar && (
              <Searchbar
                fetch={fetchApplicants}
                fetchAll={false}
                placeholder="Search Applicants"
                columns={columns.slice(1, columns.length - 1)}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "email", "altEmail"]}
                filterBy="_id"
                scrollLimit={200}
                sortFunction={sortLastFirst}
                // onRowClick={(item) => {
                //   if (item?._id) setSelectedId(item._id);
                // }}
              />
            )}
            <MDBox>
              <Tooltip title="Bulk Stage Change">
                <IconButton color="success" onClick={() => handleBulkStageChangeModal()}>
                  <PublishedWithChanges fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bulk Message">
                <IconButton color="error" onClick={() => toggleBulkMessageModal(true)}>
                  <Mail fontSize="large" />
                </IconButton>
              </Tooltip>
              {currentVenue?.slug && (
                <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                  <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                    <FileDownload />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Create a New Employee">
                <IconButton className={classes.addButton} color="info" onClick={addNew}>
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>

              {userType !== "User" && userType !== "Client" && (
                <Tooltip title="Create Report">
                  <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                    <ViewComfyIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              )}
            </MDBox>
          </Grid>
        </Grid>
        {showFiltersList && !isEmpty(searchTagList) && (
          <MDBox ml={3} mb={2}>
            <Grid item xs={6}>
              <FiltersList
                filters={applicantsFilters}
                setFilters={setApplicantsFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                clearTagsHandler={clearTagsHandler}
                onlyDisplayTags
              />
            </Grid>
          </MDBox>
        )}
        <MDBox ml={3}>
          {type === "Staffing" ? (
            <StaffingPoolFilterCards
              setPage={setPage}
              parent="Venues"
              setFilters={setApplicantsFilters}
              filters={{ ...filters, ...applicantsFilters }}
              doReplace
              useElemMatch
              baseFilters={{ status: "Employee", ...filters }}
            />
          ) : (
            <ApplicantsFilterCards
              parent="Jobs"
              setFilters={setApplicantsFilters}
              filters={{ ...filters, ...applicantsFilters }}
              doReplace
              useElemMatch
              baseFilters={{ status: "Applicant", ...filters }}
            />
          )}
        </MDBox>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={applicants || { data: null, count: 0 }}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={fetchAll}
            defaultSort="createdDate:desc"
            idField="_id"
            // returnAfterClick
          />
        </MDBox>
        <ApplicantActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
          currentApplicant={currentApplicant}
          messageContext="Venue"
          venueId={venueId}
        />
      </Card>
      {isBulkApplicantStageChangeModalOpen && (
        <BulkApplicantStageChangeModal
          applicants={allApplicants?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkApplicantStageChangeModalOpen}
          toggleModal={toggleBulkApplicantStageChangeModal}
          isLoading={false}
          status={allOptions.filters.status}
          jobSlug={jobSlug}
        />
      )}
      {openNoApplicantsModal && (
        <NoApplicantsModal open={openNoApplicantsModal} setOpen={toggleNoApplicantsModal} />
      )}
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={recipientText}
          applicants={allApplicants?.data?.map((item) => ({ ...item, id: item._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          attachmentContext="Job"
          messageContext="Venue"
          venueId={venueId}
          venueSlug={currentVenue?.slug}
          jobId={currentJob?._id}
        />
      )}
      {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          jobSlug={currentJob?.jobSlug}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
          filters={additionalOptions.filters}
          useElemMatch
          useOrTags
        />
      )}
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="applicants"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          title={renderTitle()}
          filterCardList={
            isJobStaffingPool
              ? [
                  "Active",
                  "Partner",
                  "LoggedIn",
                  "No Login",
                  "Inactive",
                  "Terminated",
                  "Do Not Use",
                ]
              : [
                  "New",
                  "ATC",
                  "Screened",
                  "Pre-Hire",
                  "Declined",
                  "Security License",
                  "Gaming License",
                  "TABC License",
                  "Do Not Use",
                  "All",
                ]
          }
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </>
  );
};

// Setting default values for the props
JobsApplicantsTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
JobsApplicantsTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default JobsApplicantsTable;
