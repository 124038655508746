import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import CheckIcon from "@mui/icons-material/Check";
import { useAppContextController } from "context/AppContext";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const MyVenuesActions = ({ slug, status, isFavorite, setFavorite, openVenuePreview }) => {
  const navigate = useNavigate();
  const { userType } = useAppContextController();

  const onClickHandler = useCallback(
    (action, e) => {
      e.stopPropagation();
      if (action === "venueEvents")
        navigate(`/${userType === "Client" ? "clientevents" : "events"}/venue/${slug}`);
      else navigate(`/venues/${slug}/action/${action}`);
    },
    [navigate, slug]
  );

  const actions = useMemo(
    () => [
      {
        name: "viewVenue",
        color: "info",
        icon: "preview",
        clickAction: () => openVenuePreview(),
        tooltip: "View Venue",
      },
      {
        name: "venueEmployees",
        color: "error",
        icon: "people",
        showBadge: true,
        clickAction: (e) => onClickHandler("venueemployees", e),
        tooltip: "Staffing Pool",
      },
      {
        name: "venueEvents",
        color: "success",
        icon: "event-seat",
        clickAction: (e) => onClickHandler("venueEvents", e),
        tooltip: "Venue Events",
      },
      {
        name: "venueInfo",
        color: "secondary",
        icon: "edit",
        clickAction: (e) => onClickHandler("venueinfo", e),
        tooltip: "Edit Venue",
      },
      {
        name: "favorite",
        color: isFavorite() ? "warning" : "light",
        icon: "star",
        clickAction: () => setFavorite(slug),
        tooltip: "Add/Remove From MyVenues",
      },
    ],
    [onClickHandler, setFavorite, isFavorite, openVenuePreview, slug]
  );

  const filteredActions = useMemo(() => {
    const updatedActions = [...actions];
    switch (status) {
      case "Pending":
        updatedActions.unshift({
          name: "venueStatus",
          color: "warning",
          icon: <CheckIcon fontSize="large" />,
          clickAction: () => "",
          tooltip: `Venue Status: ${status}`,
        });
        break;
      default:
        break;
    }
    if (userType === "User") {
      const userActions = ["viewVenue", "venueStatus", "venueEvents"];
      return updatedActions.filter((action) => userActions.includes(action.name));
    }
    if (userType === "Client") {
      const userActions = ["viewVenue", "venueEmployees", "venueEvents"];
      return updatedActions.filter((action) => userActions.includes(action.name));
    }
    return actions;
  }, [userType, actions]);

  return (
    <MDBox display="flex" justifyContent={{ xs: "center", tablet: "end" }} flexWrap="wrap">
      {filteredActions.map((prop) => (
        <TableAction {...prop} key={`myvenuesactions_${prop.name}`} />
      ))}
    </MDBox>
  );
};
export default MyVenuesActions;

MyVenuesActions.defaultProps = {
  slug: "",
  isFavorite: false,
};

MyVenuesActions.propTypes = {
  slug: PropTypes.string,
  isFavorite: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};
