import { baseAxios } from "config";

export const fetchPrimaryCompanyCustomFields = async () => {
  try {
    const res = await baseAxios.get(`/companies/primary/custom-fields`);
    return res.data;
  } catch (err) {
    console.error(`Error fetching primary-company custom field:`, err);
    throw new Error(err.toString());
  }
};
