import { useEffect, useMemo, useState } from "react";
import { Button, Card, Fade, Grid, Modal } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { Cancel as CancelIcon, Save as SaveIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { accordionSummaryClasses } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import AIQuestionsPanel from "components/AIQuestionsPanel";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import updateApplicantJobAIInterviews from "layouts/pages/applicants/actions/updateApplicantJobAIInterviews";
import generateResumeAIQuestions from "layouts/pages/companyjobs/actions/generateResumeAIQuestions";
import updateApplicantJobAISettings from "layouts/pages/applicants/actions/updateApplicantJobAISettings";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { getDirtyFields } from "utils/helpers/formHelpers";
import aiInterviewsSchema from "./AISettingsSchema";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    maxWidth: 1170,
    padding: "2rem",
    overflow: "auto",
  },
};

const defaultValues = {
  aiSettings: {
    questions: [],
  },
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: "1rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  marginLeft: 0,
  paddingLeft: 0,
  [`& .${accordionSummaryClasses.content}`]: {
    padding: 0,
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingTop: 0,
}));

const JobsStatusAIQuestionsModal = ({
  open,
  onClose,
  applicant,
  onUpdate,
  currentVenue,
  currentCustomer,
}) => {
  const validationSchema = aiInterviewsSchema();
  const resolver = useYupValidationResolver(validationSchema);
  const [questionIndex, setQuestionIndex] = useState(null);
  const { currentLoggedUser, userType, company, currentJob } = useAppContextController();
  const isVenue = company?.companyType === "Venue";

  const { mutateAsync: generateQuestionsMutation, isLoading: isLoadingGenerateQuestion } =
    useMutation(generateResumeAIQuestions);

  const applicantJob = useMemo(() => {
    return applicant?.jobs?.find((jb) => jb.jobSlug === currentJob.jobSlug);
  }, [applicant, currentJob.jobSlug]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultValues,
      aiSettings: {
        ...defaultValues.aiSettings,
        ...(applicantJob?.aiSettings ?? {}),
      },
    },
  });

  const {
    fields: questions,
    append: addQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "aiSettings.questions",
  });

  const isDirty = dirtyFields && !!Object.keys(dirtyFields).length;

  const { mutateAsync: updateJobMutation, isLoading } = useMutation(updateApplicantJobAISettings);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const shouldShowParentQuestions =
    currentJob?.aiSettings?.displayCustomerQuestions === "Yes" ||
    (currentJob?.aiSettings?.displayCustomerQuestions !== "No" &&
      (currentVenue?.aiSettings?.displayCustomerQuestions === "Yes" ||
        currentCustomer?.aiSettings?.displayCustomerQuestions === "Yes"));

  const handleUpdateApplicant = (applId, jobSlug, updatedValues) => {
    updateJobMutation(
      { applicantId: applId, jobSlug, data: updatedValues },
      {
        onSuccess: async (res) => {
          queryClient.invalidateQueries(["jobsStatusApplicants"]);
          onUpdate?.({ applicantId: applId, data: updatedValues });
          onClose();
          enqueueSnackbar("Applicant has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };

  const handlePressGenerate = (educationLevel) => {
    // Get locked questions
    const currentQuestions = getValues().aiSettings?.questions ?? [];
    if (currentQuestions.find((q) => q.locked)) {
      generateQuestionsMutation(
        {
          jobSlug: currentJob.jobSlug,
          applicantId: applicant._id,
          questions: currentQuestions,
          educationLevel,
        },
        {
          onSuccess: async (res) => {
            setValue("aiSettings.questions", res.data.aiInterview.questions, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.aiModel", res.data.aiInterview.aiModel, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.lastGenerationDate", res.data.aiInterview.lastGenerationDate, {
              shouldDirty: true,
              shouldTouch: true,
            });

            enqueueSnackbar("New questions have been generated.", { variant: "success" });
          },
          onError: (err) =>
            enqueueSnackbar("An error has ocurred generating questions.", { variant: "error" }),
        }
      );
    } else {
      generateQuestionsMutation(
        {
          jobSlug: currentJob.jobSlug,
          applicantId: applicant._id,
          educationLevel,
        },
        {
          onSuccess: async (res) => {
            setValue("aiSettings.questions", res.data.aiInterview.questions, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.aiModel", res.data.aiInterview.aiModel, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.lastGenerationDate", res.data.aiInterview.lastGenerationDate, {
              shouldDirty: true,
              shouldTouch: true,
            });

            enqueueSnackbar("New questions have been generated.", { variant: "success" });
          },
          onError: (err) =>
            enqueueSnackbar("An error has ocurred generating questions.", { variant: "error" }),
        }
      );
    }
  };

  useEffect(() => {
    reset({
      ...defaultValues,
      aiSettings: {
        ...defaultValues.aiSettings,
        ...(applicantJob?.aiSettings ?? {}),
      },
    });
    setQuestionIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant]);

  const onSubmit = (data) => {
    const temp = getDirtyFields(data, dirtyFields);
    if (temp?.aiSettings?.questions?.length) {
      temp.aiSettings.questions = temp.aiSettings.questions.map((q) => {
        const newQ = { ...q };
        delete newQ.locked;
        return newQ;
      });
    }

    let payload = {};

    const job = (applicant?.jobs ?? []).find((jb) => jb.jobSlug === currentJob.jobSlug);

    if (job && applicant?._id) {
      payload = {
        ...(job.aiSettings ?? {}),
        ...temp.aiSettings,
      };

      handleUpdateApplicant(applicant._id, currentJob.jobSlug, payload);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card style={styles.container}>
            <Button
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                m: 2,
                p: 0,
              }}
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <CancelIcon color="secondary" />
            </Button>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="dark">
                    Interview Questions
                  </MDTypography>
                </Grid>
                <Grid item pt={2} xs={12}>
                  {shouldShowParentQuestions && (
                    <Accordion>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <MDTypography
                          component="label"
                          variant="button"
                          fontWeight="regular"
                          color="secondary"
                          sx={{ fontSize: "0.74rem" }}
                        >
                          {isVenue ? "Venue Questions" : "Customer Questions"}
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AIQuestionsPanel
                          questions={
                            currentVenue?.aiSettings?.questions ??
                            currentCustomer?.aiSettings?.questions ??
                            []
                          }
                          control={control}
                          watch={watch}
                          errors={errors}
                          setValue={setValue}
                          isReadOnly
                          title=""
                          noHeader
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="secondary"
                        sx={{ fontSize: "0.74rem" }}
                      >
                        Job Questions
                      </MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AIQuestionsPanel
                        questions={currentJob?.aiSettings?.questions ?? []}
                        control={control}
                        watch={watch}
                        errors={errors}
                        setValue={setValue}
                        isReadOnly
                        title=""
                        noHeader
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <AIQuestionsPanel
                    questions={watch("aiSettings.questions") ?? []}
                    questionKey="aiSettings.questions"
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    questionIndex={questionIndex}
                    setQuestionIndex={setQuestionIndex}
                    control={control}
                    watch={watch}
                    errors={errors}
                    setValue={setValue}
                    title="Resume Questions"
                    noTopPadding
                    onPressGenerate={handlePressGenerate}
                    isLoadingGenerateQuestion={isLoadingGenerateQuestion}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end" pt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of CalendarDayModal
JobsStatusAIQuestionsModal.defaultProps = {
  onUpdate: null,
  currentVenue: null,
  currentCustomer: null,
};

// Typechecking props of the CalendarDayModal
JobsStatusAIQuestionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  applicant: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  currentVenue: PropTypes.object,
  currentCustomer: PropTypes.object,
};

export default JobsStatusAIQuestionsModal;
