import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import { Diversity3 } from "@mui/icons-material";

const EventsTimeMembersActions = ({
  id,
  row,
  currentEvent,
  setCurrentEvent,
  setGenericModalOpen,
  setModalInfo,
}) => {
  const { userType } = useAppContextController();

  const numberNotes = row?.notes?.length ?? 0;

  const onClickHandler = (action, e) => {
    switch (action) {
      case "applicantMail":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      case "applicantComment":
        setModalInfo({ type: action, data: row, showAll: true });
        setGenericModalOpen(true);
        break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <span>
      {userType !== "Client" && userType !== "User" && (
        <>
          <TableAction
            color="primary"
            icon="mail"
            tooltip={
              !!row?.email || !!row?.phone
                ? `Send a Message to ${row?.email ?? row?.phone}`
                : "No email or phone available"
            }
            clickAction={(e) => onClickHandler("applicantMail", e)}
            disabled={!row?.email && !row?.phone}
          />
          &nbsp;&nbsp;
        </>
      )}
      <TableAction
        color="info"
        icon="description"
        showBadge
        badgeNumber={numberNotes}
        tooltip="Add a Note"
        clickAction={(e) => onClickHandler("applicantComment", e)}
      />
    </span>
  );
};
export default EventsTimeMembersActions;

EventsTimeMembersActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
EventsTimeMembersActions.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
