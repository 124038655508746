import PreviewIcon from "@mui/icons-material/Preview";
import { useAppContextController } from "context/AppContext";
import TableAction from "components/TableAction";
import updateUser from "layouts/pages/users/actions/updateUser";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { QueryClient, useMutation } from "react-query";

let favoriteVenues = [];
const ACTION_NAMES = ["venueEvents", "viewVenue", "favorite"];
const ACTION_COLORS = {
  info: "info",
  warning: "warning",
  success: "success",
  error: "error",
  secondary: "secondary",
  dark: "dark",
  light: "light",
};
const USER_TYPE = "User";

const VenuesTableActions = ({ slug, row, setNavigateUrl, setPreviewOpen }) => {
  const { currentLoggedUser, setCurrentLoggedUser, userType } = useAppContextController();
  const queryClient = new QueryClient();

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("myVenues");
      localStorage.setItem("myVenues", data.venues);
      setCurrentLoggedUser(data);
    },
  });

  const isFavorite = (venueSlug) =>
    currentLoggedUser?.venues &&
    currentLoggedUser?.venues?.length &&
    currentLoggedUser.venues.includes(venueSlug);

  const setFavoritesAndRequery = (venueSlug, e) => {
    e.stopPropagation();

    if (favoriteVenues.includes(venueSlug)) {
      favoriteVenues = favoriteVenues.filter((favorite) => favorite !== venueSlug);
    } else favoriteVenues.push(venueSlug);

    const loggedUser = { ...currentLoggedUser };
    loggedUser.venues = favoriteVenues;

    updateUserMutation.mutate({ userId: loggedUser.userId, data: loggedUser });
  };

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    if (userType === USER_TYPE && action === "venueEvents") setNavigateUrl(`/events/venue/${slug}`);
    else setNavigateUrl(`/venues/${slug}/action/${action}`);
  };

  const [actions] = useState([
    ...(userType !== "Client"
      ? [
          {
            color: ACTION_COLORS.info,
            icon: "info",
            clickAction: (e) => onClickHandler("venueinfo", e),
            tooltip: "Venue Info",
          },
          {
            color: ACTION_COLORS.warning,
            icon: "work",
            clickAction: (e) => onClickHandler("venuejobs", e),
            tooltip: "Venue Jobs",
          },
        ]
      : []),
    {
      name: ACTION_NAMES[0],
      color: ACTION_COLORS.success,
      icon: "event-seat",
      clickAction: (e) => onClickHandler(ACTION_NAMES[0], e),
      tooltip: "Venue Events",
    },
    ...(currentLoggedUser?.employeeType !== "Event Admin"
      ? [
          {
            color: ACTION_COLORS.error,
            icon: "people",
            showBadge: true,
            clickAction: (e) => onClickHandler("venueemployees", e),
            tooltip: "Staffing Pool",
          },
        ]
      : []),
    ...(userType !== "Client"
      ? [
          {
            color: ACTION_COLORS.secondary,
            icon: "movie_filter",
            clickAction: (e) => onClickHandler("venuemedia", e),
            tooltip: "Venue Media",
          },
        ]
      : []),
    {
      name: ACTION_NAMES[1],
      color: ACTION_COLORS.info,
      icon: <PreviewIcon />,
      clickAction: (e) => setPreviewOpen(row, e),
      tooltip: "Venue Preview",
    },
    ...(userType !== "Client"
      ? [
          {
            name: ACTION_NAMES[2],
            icon: "staroutline",
            clickAction: (e) => setFavoritesAndRequery(slug, e),
            tooltip: "Add/Remove from My Venues",
          },
          // {
          //   color: ACTION_COLORS.dark,
          //   icon: "public",
          //   clickAction: (e) => onClickHandler("venueall", e),
          //   tooltip: "All Venue Information",
          // },
        ]
      : []),
  ]);

  const filteredActions = useMemo(() => {
    if (userType === USER_TYPE) {
      const userActions = ["viewVenue", "venueEvents"];
      return actions.filter((action) => userActions.includes(action.name));
    }

    return actions;
  }, [userType, actions]);

  const actionColorHandler = (actionName, color) => {
    if (actionName === ACTION_NAMES[2]) {
      return isFavorite(slug) ? ACTION_COLORS.warning : ACTION_COLORS.light;
    }

    return color;
  };

  useEffect(() => {
    if (currentLoggedUser?.venues) {
      favoriteVenues = currentLoggedUser?.venues;
    }
  }, [currentLoggedUser?.venues]);

  return (
    <span key={`table_actions_span_${slug}`}>
      {filteredActions.map((prop) => (
        <TableAction
          {...prop}
          key={`myevents_actions_${prop.name || prop.tooltip}`}
          color={actionColorHandler(prop.name, prop.color)}
        />
      ))}
    </span>
  );
};
export default VenuesTableActions;

VenuesTableActions.defaultProps = {
  slug: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
VenuesTableActions.propTypes = {
  slug: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
