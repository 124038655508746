import { baseAxios } from "config";

export const updatePrimaryCompanyCustomFields = async (data) => {
  try {
    const res = await baseAxios.put(`/companies/primary/custom-fields`, data);
    return res.data;
  } catch (err) {
    console.error(`Error fetching primary-company custom field:`, err);
    throw new Error(err.toString());
  }
};
