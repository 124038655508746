import { baseAxios } from "config";

export const fetchEventCustomFields = async (eventUrl) => {
  try {
    const res = await baseAxios.get(`/events/${eventUrl}/custom-fields`);
    return res.data;
  } catch (err) {
    console.error(`Error fetching event custom field with ID ${eventUrl}:`, err);
    throw new Error(err.toString());
  }
};
