import axios from "axios";
import { baseAxios } from "config";
import queryString from "query-string";

import createApiParams from "utils/createApiParams";

const fetchInterviewsForDashboard = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  venues,
  useOr = false,
  startDate = "",
  endDate = "",
}) => {
  if (
    filters &&
    Object.prototype.hasOwnProperty.call(filters, "venueSlug") &&
    filters.venueSlug === undefined
  )
    return null;
  const { paginationQuery, sortQuery, filterQuery } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  const url = queryString.stringifyUrl({
    url: `/events/interviews/dashboard${paginationQuery}${sortQuery}${filterQuery}${
      useOr ? "&useOr=true" : ""
    }`,
    query: {
      startDate,
      endDate,
    },
  });

  try {
    const res = await baseAxios.get(url);
    // we need to preprocess the data to format the image URLs and other fields
    if (res?.data?.data?.length) {
      const retData = res.data.data.map((event) => ({
        ...event,
        logoUrl: venues?.[event.venueSlug]?.logoUrl,
      }));
      res.data = { ...res.data, data: [...retData] };
    }
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchInterviewsForDashboard;
