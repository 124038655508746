import { baseAxios } from "config";

export const updateEventCustomFields = async (eventUrl, data) => {
  try {
    const res = await baseAxios.put(`/events/${eventUrl}/custom-fields`, data);
    return res.data;
  } catch (err) {
    console.error(`Error fetching event custom field with ID ${eventUrl}:`, err);
    throw new Error(err.toString());
  }
};
