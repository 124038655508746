import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import postNote from "layouts/pages/applicants/actions/postNote";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Article,
  Block,
  ConnectWithoutContact,
  FolderShared,
  PersonAdd,
  Public,
  QuestionAnswer,
  ThumbDown,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";

import EventsTable from "layouts/pages/events/components/EventsTable";
import JobsTable from "layouts/pages/jobs/components/JobsTable";

import { NewApplicantContextProvider } from "context/NewApplicantContext";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import ApplicantActivities from "layouts/pages/applicants/components/ApplicantActivities";
import ApplicantAttachments from "layouts/pages/applicants/components/ApplicantAttachments";
import ApplicantOnboardingEvents from "layouts/pages/applicants/components/ApplicantEvents";
import ApplicantsFilterCards from "layouts/pages/applicants/components/ApplicantFilterCards";
// import from "layouts/pages/jobs/components/JobsFilterCards";
import ApplicantNotes from "layouts/pages/applicants/components/ApplicantNotes";
import ApplicantOnboardingPanel from "layouts/pages/applicants/components/ApplicantOnboardingPanel";
import ApplicantSendMessagePanel from "layouts/pages/applicants/components/ApplicantSendMessagePanel";
import ApplicantsTable from "layouts/pages/applicants/components/ApplicantsTable";
import ApplicantSourceFilterCards from "layouts/pages/applicants/components/ApplicantSourceFilterCards";
import ApplicantVenues from "layouts/pages/applicants/components/ApplicantVenues";
import ApplicantVenuesTable from "layouts/pages/applicants/components/ApplicantVenuesTable";
import CustomersTable from "layouts/pages/customers/components/CustomerTable";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import { checkIfApplicantVenuePending } from "utils/helpers/applicant";
import { isEmployeeUser } from "utils/helpers/roles";
import { SnackbarProvider } from "notistack";
import { useRefreshApplicant } from "hooks/useRefreshApplicant";
import useGetBackButton from "hooks/useGetBackButton";
import useHandleBackButtonMainPage from "hooks/useHandleBackButtonMainPage";
import generateFiltersFromString from "utils/generateFiltersFromString";
import ApplicantStatusSubFilters from "layouts/pages/applicants/components/ApplicantStatusSubFilters";
import ApplicantCompanies from "./components/ApplicantCompanies";
import ApplicantMainInfoPanel from "./components/ApplicantMainInfoPanel";
import ApplicantProcessStepper from "./components/ApplicantProcessStepper";
import ApplicantAdditionalInfo from "./components/ApplicantAdditionalInfo";
import ApplicantJobs from "./components/ApplicantJobs";
import UserPanel from "./components/UserPanel";
import CompanyJobsTable from "../companyjobs/components/CompanyJobsTable";
import AdditionalInformation from "./components/AdditionalInformation";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

function Applicants({ mode = "edit" }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Handle back button for this screen
  const {
    backListUrl,
    filterParam,
    pageParam,
    subFilterParam,
    subPageParam,
    pageFromUrl,
    filtersFromUrl,
    subPageFromUrl,
    subFiltersFromUrl,
    navigateToListWithBack,
    navigateToScreenWithBack,
  } = useHandleBackButtonMainPage({
    baseUrl: "/applicants",
    screenName: "Applicants",
  });

  // Get back button generated by other screens
  const { backParam, backToTitle, backToUrl, backButton } = useGetBackButton();

  const {
    currentApplicant,
    setCurrentApplicant,
    currentLoggedUser,
    company,
    companyType,
    userType,
  } = useAppContextController();

  const initialCards = [
    {
      icon: <PersonAdd {...CommonIconProps} color="secondary" fontSize="large" />,
      title: "New",
      color: "secondary",
      tooltip: "New Applicants",
      number: 0,
      default: true,
      filterField: "applicantStatus",
      filterValue: "New",
    },
    {
      icon: <ConnectWithoutContact {...CommonIconProps} color="warning" fontSize="large" />,
      title: "ATC",
      color: "warning",
      tooltip: "Applicants Attempted to Contact",
      number: 0,
      filterField: "applicantStatus",
      filterValue: "ATC",
    },
    {
      icon: <QuestionAnswer {...CommonIconProps} color="info" fontSize="large" />,
      title: "Screened",
      color: "info",
      tooltip: "Screened Applicants",
      number: 0,
      filterField: "applicantStatus",
      filterValue: "Screened",
    },
    {
      icon: <FolderShared {...CommonIconProps} color="success" fontSize="large" />,
      title: "Pre-Hire",
      color: "success",
      tooltip: "Pre-Hire Applicants",
      number: 0,
      filterField: "applicantStatus",
      filterValue: "Pre-Hire",
    },
    {
      icon: <ThumbDown {...CommonIconProps} color="error" fontSize="large" />,
      title: "Declined",
      color: "primary",
      tooltip: "Declined Applicants",
      number: 0,
      filterField: "applicantStatus",
      filterValue: "Declined",
    },
    {
      icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
      title: "All",
      color: "info",
      tooltip: "All Applicants",
      number: 0,
      filterField: "applicantStatus",
      filterValue: "New;ATC;Screened;Pre-Hire;Declined",
    },
    {
      icon: <Article {...CommonIconProps} fontSize="large" />,
      color: "secondary",
      title: "Licenses",
      tooltip: "Licenses",
      number: 0,
      filterField: "licenses.licenseType.value",
      filterValue: "gamingLicense;securityLicense;tabcLicense",
    },
    {
      icon: <Block {...CommonIconProps} color="error" fontSize="large" />,
      title: "Do Not Use",
      color: "error",
      tooltip: "Do Not Use",
      number: 0,
      filterField: "isDnu",
      filterValue: "Yes",
    },
  ];

  const activePanel = searchParams.get("panel");
  const { id, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [filters, setFilters] = useState({ applicantStatus: "New" });
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [panelSelect, setPanelSelect] = useState(activePanel ?? "activities");
  const [boarding, setBoarding] = useState(false);
  const [useOrTags, setUseOrTags] = useState(true);

  const { refreshApplicant } = useRefreshApplicant();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (filterParam != null) {
      if (filterParam) {
        const newFilters = generateFiltersFromString(filterParam);
        setFilters(newFilters);
      } else {
        setFilters({});
      }
    }
  }, [filterParam]);

  // const deleteApplicantHandler = async (_id, applicantName) => {
  //   const res = await deleteApplicant({ _id });

  //   if (res?.status === 200) {
  //     await queryClient.invalidateQueries("applicants");

  //     setToastAlert({
  //       isShow: true,
  //       message: `Applicant ${applicantName} has been deleted`,
  //       status: "success",
  //     });
  //     return { success: true };
  //   }

  //   setToastAlert({
  //     isShow: true,
  //     message: `Something went wrong -- ${res?.data?.message}`,
  //     status: "error",
  //   });
  //   return { success: false, message: res?.data?.message };
  // };

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };
    const updateNewNote = async () => {
      let res;
      await postNote({ applicantId: currentApplicant?._id, data: newNote })
        .then((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: "Notes Have Been Successfully Updated",
            status: "success",
          });
        })
        .catch((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: `Something went wrong! ${response}`,
            status: "error",
          });
        });
      if (res?.data?.modifiedCount) {
        refreshApplicant();
      }

      return res;
    };

    return updateNewNote();
    // const newNotesArr = currentApplicant.attachments ? [...currentApplicant.attachments, newAtt] : [newAtt];
    // await updateApplicantMutation.mutateAsync({
    //     applicantId: currentApplicant._id,
    //     data: {
    //         notes: [...newNotesArr],
    //     }
    // });
  };

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  const fetchApplicant = async () => {
    if (!selectedId) return;
    const appl = await fetchApplicants({ filters: { _id: selectedId }, useOr: useOrTags });
    if (appl) setCurrentApplicant(appl.data[0]);
  };

  useEffect(() => {
    if (id && !filters._id && action) {
      setFilters({ _id: id });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }

    if (id && (!currentApplicant || currentApplicant._id !== id)) {
      fetchApplicant();
    }
  }, [id, action, currentApplicant]);

  // Default cards on mount
  // causes filter to default to new even if selecting different filter card
  // useEffect(() => {
  //   if (isEmpty(filters) || (!id && filters._id)) {
  //     let newFilters = {};
  //     const defaultCard = initialCards.find((card) => card.default);
  //     if (defaultCard) {
  //       newFilters = {
  //         ...newFilters,
  //         [defaultCard.filterField]: defaultCard.filterValue,
  //       };
  //     }

  //     const defaultSourceFilterCardIndex = initialSourceCards.findIndex((card) => card.default);
  //     if (defaultSourceFilterCardIndex >= 0) {
  //       const defaultSourceCard = initialSourceCards[defaultSourceFilterCardIndex];
  //       newFilters = {
  //         ...newFilters,
  //         [defaultSourceCard.filterField]: defaultSourceCard.filterValue,
  //       };
  //     }

  //     setFilters(newFilters);
  //   }
  // }, [filters, id]);

  // useEffect(() => {
  //   if (id && action && !filters._id) {
  //     navigate(`/applicants/${id}`);
  //   }
  // }, [filters]);

  function panelSelector(selection) {
    switch (selection) {
      case "activities":
        return <ApplicantActivities />;
      case "companies":
        return <ApplicantCompanies />;
      case "venues":
        return <ApplicantVenues />;
      case "jobs":
        return <ApplicantJobs refresh={refreshApplicant} />;
      case "note":
        return <ApplicantNotes setOpen={setNoteModalOpen} currentApplicant={currentApplicant} />;
      case "user":
        return <UserPanel currentApplicant={currentApplicant} />;
      case "message":
        return <ApplicantSendMessagePanel />;
      case "orientation":
        return <ApplicantOnboardingEvents fetchAll={false} />;
      case "attachments":
        return <ApplicantAttachments setOpen={setOpen} refresh={refreshApplicant} />;
      case "additionalInfo":
        return companyType === "Company" ? (
          <ApplicantAdditionalInfo />
        ) : (
          <AdditionalInformation setToastAlert={setToastAlert} />
        );
      default:
        return null;
    }
  }

  const setNavigateUrl = (url) => navigate(url);

  const [eventPreview, setEventPreview] = useState(null);
  const [interviewPreview, setInterviewPreview] = useState(null);
  const [isMessageModalOpen, toggleMessageModal] = useState(false);
  const [previewContent, setPreviewContent] = useState("all");
  const [openEventModal, setOpenEventModal] = useState(false);

  const refetchEventsFuncRef = useRef(null);
  const bindRefetchEvents = (callback) => {
    refetchEventsFuncRef.current = callback;
  };
  const refetchEvents = refetchEventsFuncRef.current;

  const isApplicantEventVenuePending = useMemo(() => {
    const eventVenueSlug = eventPreview?.venueSlug;
    const applicantVenues = currentApplicant?.venues;
    const isPending = checkIfApplicantVenuePending(applicantVenues, eventVenueSlug);
    return isPending;
  }, [eventPreview, currentApplicant]);

  const toggleEventPreviewModal = (isOpen) => {
    if (isApplicantEventVenuePending && isEmployeeUser(userType)) {
      toggleMessageModal(true);
    } else {
      setOpenEventModal(isOpen);
    }
  };

  const applicantCompanyInterviews = useMemo(
    () => (currentApplicant?.interviews ?? []).map((int) => int.eventUrl),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentApplicant?._id, currentApplicant?.interviews?.length]
  );

  const companyFilters = useMemo(
    () =>
      currentApplicant?.companies
        ? {
            slug: currentApplicant.companies.map((comp) => comp.companySlug).join(";"),
          }
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentApplicant?._id, currentApplicant?.companies?.length]
  );

  const venueFilters = useMemo(
    () =>
      currentApplicant?.venues
        ? { slug: currentApplicant.venues.map((venue) => venue.venueSlug).join(";") }
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentApplicant?._id, currentApplicant?.venues?.length]
  );

  const jobFilters = useMemo(
    () =>
      currentApplicant?.jobs
        ? { jobSlug: currentApplicant.jobs.map((job) => job.jobSlug).join(";") }
        : {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentApplicant?._id, currentApplicant?.jobs?.length]
  );

  const eventFilters = useMemo(() => {
    if (currentApplicant) {
      if (company?.companyType === "Company")
        return {
          eventUrl: applicantCompanyInterviews.join(";"),
        };
      return {
        "applicants.id": currentApplicant?._id,
      };
    }
    return {
      eventUrl: "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantCompanyInterviews, company?.companyType, currentApplicant?._id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {backToUrl ? (
            <Grid item xs={12} sm={12} md={12} pb={1} pt={3}>
              {backButton}
            </Grid>
          ) : (
            <>
              <ApplicantsFilterCards
                setFilters={setFilters}
                setPage={setPage}
                filters={filters}
                initialCards={initialCards}
                baseFilters={{ status: "Applicant;Partner;Leader" }}
              />
              {(filters?.applicantStatus === "Screened" ||
                filters?.applicantStatus === "Pre-Hire") && (
                <ApplicantStatusSubFilters
                  setFilters={setFilters}
                  setPage={setPage}
                  filters={filters}
                  baseFilters={{ status: "Applicant;Partner;Leader" }}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {!backToUrl && (
            <ApplicantSourceFilterCards
              setFilters={setFilters}
              setPage={setPage}
              filters={filters}
              baseFilters={{ status: "Applicant;Partner;Leader" }}
            />
          )}
          <ApplicantsTable
            defaultSort="createdDate"
            filters={filters}
            setNavigateUrl={setNavigateUrl}
            setFilters={setFilters}
            showFiltersList
            setSelectedId={setSelectedId}
            page={page}
            setPage={setPage}
            pageSize={5}
            useOrTags={useOrTags}
            setUseOrTags={setUseOrTags}
            status="Applicant;Partner;Leader"
            setToastAlert={setToastAlert}
            parentPage={pageFromUrl}
            parentNavigateWithBack={navigateToListWithBack}
          />
        </Grid>
        {backListUrl && (
          <Grid item xs={12} sm={12} md={12} mt={2}>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(backListUrl);
              }}
            >
              Back to Applicant List
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {["applicantinfo", "applicantall", ""].includes(actionSelected) &&
            (id || mode === "create") && (
              <>
                {currentApplicant && ["Applicant"].includes(currentApplicant.status) && (
                  <Grid container sx={12} spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <ApplicantProcessStepper applicant={currentApplicant} />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={1} mt={2}>
                  <Grid item xs={12} sm={panelSelect === "" ? 12 : 6}>
                    <ApplicantMainInfoPanel
                      setPanelSelect={setPanelSelect}
                      panelSelect={panelSelect}
                      setBoarding={setBoarding}
                      boarding={boarding}
                      setNavigateUrl={setNavigateUrl}
                      setFilters={setFilters}
                      filters={filters}
                      parent="Applicant"
                      defaultPanel={activePanel}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {panelSelector(panelSelect)}
                  </Grid>
                  {boarding && currentApplicant?._id && (
                    <Grid item xs={12} sm={12}>
                      <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        key="top-center"
                      >
                        <NewApplicantContextProvider>
                          <ApplicantOnboardingPanel refetchApplicant={fetchApplicant} />
                        </NewApplicantContextProvider>
                      </SnackbarProvider>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
        </Grid>
        {["applicantvenues", "applicantall"].includes(actionSelected) &&
          company?.companyType === "Venue" && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <ApplicantVenuesTable
                  title={`Venues for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={venueFilters}
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="slug"
                  showVenueStatus
                  name={`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  currentApplicant={currentApplicant}
                  setToastAlert={setToastAlert}
                  parentPage={subPageFromUrl}
                  parentNavigateWithBack={navigateToScreenWithBack}
                />
              </Grid>
            </>
          )}
        {["applicantcustomers", "applicantall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CustomersTable
                title={`Customers for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                filters={companyFilters}
                showFiltersList={false}
                showSearchBar={false}
                defaultSort="slug"
                showVenueStatus
                currentApplicant={currentApplicant}
                useOrTags={useOrTags}
                setToastAlert={setToastAlert}
                isApplicantCustomer
                name={`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                parentPage={subPageFromUrl}
                parentNavigateWithBack={navigateToScreenWithBack}
              />
            </Grid>
          </>
        )}
        {["applicantjobs", "applicantall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              {company?.companyType === "Venue" ? (
                <JobsTable
                  title={`Jobs for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={jobFilters}
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="title"
                  parent="applicants"
                  currentApplicant={currentApplicant}
                  setToastAlert={setToastAlert}
                  name={`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  parentPage={subPageFromUrl}
                  parentNavigateWithBack={navigateToScreenWithBack}
                />
              ) : (
                <CompanyJobsTable
                  title={`Jobs for: ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  filters={jobFilters}
                  showFiltersList={false}
                  showSearchBar={false}
                  defaultSort="title"
                  parent="applicants"
                  currentApplicant={currentApplicant}
                  setToastAlert={setToastAlert}
                  name={`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                  parentPage={subPageFromUrl}
                  parentNavigateWithBack={navigateToScreenWithBack}
                />
              )}
            </Grid>
          </>
        )}
        {["applicantevents", "applicantall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <EventsTable
                showFiltersList={false}
                showSearchBar={false}
                title={`${company?.companyType === "Venue" ? "Events" : "Interviews"} for: ${
                  currentApplicant?.firstName
                } ${currentApplicant?.lastName} `}
                filters={eventFilters}
                defaultSort="eventDate"
                setFilters={() => {}}
                parent="Applicants"
                navigateToAction="eventsinfo"
                setOpen={toggleEventPreviewModal}
                setEventPreview={setEventPreview}
                setInterviewPreview={setInterviewPreview}
                setPreviewContent={setPreviewContent}
                bindRefetchEvents={bindRefetchEvents}
                applicantId={currentApplicant?._id}
                isApplicantInterviews
                setToastAlert={setToastAlert}
                name={`${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
                parentPage={subPageFromUrl}
                parentNavigateWithBack={navigateToScreenWithBack}
              />
            </Grid>
          </>
        )}
        <UploadFileModal
          currentApplicant={currentApplicant}
          setCurrentApplicant={setCurrentApplicant}
          open={open}
          setOpen={setOpen}
          refresh={refreshApplicant}
        />
      </Grid>
      <AddANoteModal
        currentApplicant={currentApplicant}
        open={notesModalOpen}
        toastAlert={toastAlert}
        setToastAlert={setToastAlert}
        setOpen={setNoteModalOpen}
        saveNote={saveNote}
        isCreateTaskAllowed
      />
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
Applicants.defaultProps = {
  // pageName: "Applicants",
};

// Typechecking props for BaseLayout
Applicants.propTypes = {
  // pageName: PropTypes.string,
};

export default Applicants;
