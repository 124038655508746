import { Grid } from "@mui/material";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    maxWidth: 1170,
    padding: "2rem",
  },
};

const AIInterviewSummarySection = ({ score, notes, title }) => {
  const scoreRounded = Math.round(score * 100);

  return (
    <Grid container flexDirection="column" pb={2}>
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          borderBottom: "2px solid gray",
        }}
      >
        <MDBox flexGrow={1}>
          <MDTypography
            component="label"
            variant="h5"
            fontWeight="bold"
            color="dark"
            textAlign="right"
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          flexBasis="auto"
          alignItems="center"
          flexShrink={0}
          flexDirection="row"
        >
          <MDBox width="10rem" overflow="hidden">
            <JobsStatusRankStars rank={score} color="#8935f4" linear />
          </MDBox>
          <MDBox width="5rem">
            <MDBox display="flex" flexDirection="row" alignItems="center" pl={1}>
              <MDTypography
                component="label"
                variant="h6"
                fontWeight="bold"
                color="info"
                textAlign="right"
                sx={{ paddingLeft: "0.5rem" }}
              >
                {scoreRounded}
              </MDTypography>
              <MDTypography component="label" variant="h6" fontWeight="bold" textAlign="right">
                /100
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox item>
        <MDTypography
          component="label"
          variant="body2"
          fontWeight="normal"
          color="dark"
          textAlign="right"
          sx={{
            lineHeight: "8px",
          }}
        >
          {notes}
        </MDTypography>
      </MDBox>
    </Grid>
  );
};

// Setting default values for the props of CalendarDayModal
AIInterviewSummarySection.defaultProps = {};

// Typechecking props of the CalendarDayModal
AIInterviewSummarySection.propTypes = {};

export default AIInterviewSummarySection;
