import React from "react";
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Autocomplete,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import FormField from "layouts/applications/wizard/components/FormField";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CheckBox } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SortableCustomField = ({
  field,
  handleDeleteField,
  handleEditField,
  handleToggleVisibility,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: field._id,
  });

  const style = React.useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition: transform ? transition : undefined,
    }),
    [transform, transition]
  );

  return (
    <Grid item xs={12} md={6} ref={setNodeRef} style={style}>
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        border="1px solid #ddd"
        borderRadius="8px"
        bgcolor="#fff"
        sx={{
          position: "relative",
          transform: isDragging ? "scale(1.02)" : "scale(1)",
          zIndex: isDragging ? 999 : 1,
          opacity: isDragging ? 0.9 : 1,
          boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.1)" : "none",
          transition: "transform 150ms ease, box-shadow 150ms ease",
          willChange: "transform",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: isDragging ? "#1976d2" : "inherit",
            }}
          >
            {field.label}
            {field.isRequired && field.type !== "Checkbox" && (
              <span style={{ color: "red" }}> *</span>
            )}
          </Typography>
          <Box display="flex" gap={1} sx={{ opacity: isDragging ? 0.7 : 1 }}>
            <IconButton
              {...listeners}
              {...attributes}
              sx={{
                cursor: isDragging ? "grabbing" : "grab",
                p: 0.5,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <DragIndicatorIcon />
            </IconButton>
            <IconButton
              onClick={() => handleEditField(field)}
              sx={{ p: 0.5 }}
              disabled={isDragging}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleToggleVisibility(field)}
              sx={{ p: 0.5 }}
              disabled={isDragging}
            >
              {field.isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton
              onClick={() => handleDeleteField(field._id)}
              sx={{ p: 0.5 }}
              disabled={isDragging}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        {/* Render field content here */}
        {/* Field Content */}
        {field.type === "Text" && <FormField label={field.placeholder || "Enter text"} disabled />}
        {field.type === "Price" && (
          <NumericFormat
            InputProps={{
              startAdornment: "$",
              inputProps: {
                inputMode: "numeric",
              },
            }}
            label={field.placeholder || "Enter price"}
            decimalScale={2}
            disabled
            customInput={FormField}
          />
        )}
        {field.type === "Date" && (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={field.placeholder || "Enter date"}
              value={null}
              renderInput={(params) => <FormField {...params} />}
              disabled
            />
          </LocalizationProvider>
        )}
        {field.type === "Dropdown" && (
          <Autocomplete
            options={field.options || []}
            disabled
            renderInput={(params) => (
              <FormField
                {...params}
                label={field.placeholder || "Enter dropdown options"}
                variant="standard"
              />
            )}
          />
        )}
        {field.type === "Quantity" && (
          <FormField label={field.placeholder || "Enter quantity"} disabled type="number" />
        )}
        {field.type === "Checkbox" && (
          <Stack direction="row" alignItems="center" display="flex" gap={2} minHeight="45.13px">
            <CheckBox id="custom-checkbox" checked disabled />
            <MDTypography variant="body2">Select Checkbox</MDTypography>
          </Stack>
        )}
        {field.type === "Radio Group" && (
          <RadioGroup defaultValue="1" name="radio-buttons-group">
            {field.options?.map((option, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item sm={6} key={index}>
                <FormControlLabel value={option} control={<Radio />} label={option} disabled />
              </Grid>
            ))}
          </RadioGroup>
        )}
        {field.type === "Textarea" && (
          <TextareaAutosize
            disabled
            style={{ minHeight: "41px", resize: "none" }}
            placeholder={field.placeholder || "Enter long text here"}
          />
        )}
      </Box>
    </Grid>
  );
};

export default SortableCustomField;
