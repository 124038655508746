import { Button } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { useCallback } from "react";

const UnsavedChangesModal = ({
  clickDirection,
  openUnsavedChangesModal,
  setOpenUnsavedChangesModal,
  stepToGoId,
}) => {
  const { onNextStep, onPreviousStep, updateCurrentFormState, currentFormState, setActiveStep } =
    useNewApplicantContext();

  const handleButtonClick = useCallback(() => {
    if (clickDirection === "next") {
      updateCurrentFormState({
        ...currentFormState,
        isDirty: false,
      });
      onNextStep();
    } else if (clickDirection === "previous") {
      updateCurrentFormState({
        ...currentFormState,
        isDirty: false,
      });
      onPreviousStep();
    } else if (clickDirection === "setStep") {
      updateCurrentFormState({
        ...currentFormState,
        isDirty: false,
      });
      setActiveStep(stepToGoId);
    }
  }, [clickDirection, stepToGoId, currentFormState]);

  const modalHeader = (
    <MDTypography variant="h5" color="dark">
      Information has changed
    </MDTypography>
  );

  const modalBody = (
    <MDTypography variant="body2">
      You have unsaved information. Do you wish to save before going to the {clickDirection} form?
    </MDTypography>
  );

  const modalButtons = (
    <>
      <Button
        variant="contained"
        style={{ color: "white" }}
        onClick={async () => {
          await document
            .getElementById("current-form")
            .dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
          setOpenUnsavedChangesModal(false);
          handleButtonClick();
        }}
      >
        Save and Continue
      </Button>
      <Button
        variant="contained"
        style={{ color: "white" }}
        color="error"
        onClick={() => {
          setOpenUnsavedChangesModal(false);
          handleButtonClick();
        }}
      >
        Discard Changes and Continue
      </Button>
    </>
  );
  return (
    <GenericModal
      open={openUnsavedChangesModal}
      setOpen={setOpenUnsavedChangesModal}
      header={modalHeader}
      body={modalBody}
      buttons={modalButtons}
    />
  );
};

export default UnsavedChangesModal;
