import { Autocomplete, Checkbox, Grid, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import CustomTextField from "components/Form/CustomTextField";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { Circle, CircleOutlined } from "@mui/icons-material";

const MAX_ACCOUNT_NUMBER_DIGIT = 100000000000000000;
const MAX_ROUTING_DIGIT = 1000000000;

const DirectDepositCommonFields = ({
  control,
  isDirectDeposit,
  accountTypes,
  setValue,
  title,
  trigger,
  number,
  watch,
}) => {
  const { user, userType } = useAppContextController();
  const isAdmin = userType === "Master" || userType === "Admin";

  return (
    <>
      <Grid item sm={12}>
        <MDTypography variant="h5">{title}</MDTypography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name={`bankName${number}`}
          control={control}
          render={({ field }) => (
            <CustomTextField
              variant="outlined"
              label="Bank Name"
              disabled={isDirectDeposit}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Controller
          name={`routing${number}`}
          control={control}
          render={({ field }) => (
            <NumericFormat
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              sx={{ input: { textAlign: "end" } }}
              variant="outlined"
              label="Routing #"
              customInput={CustomTextField}
              disabled={isDirectDeposit}
              isAllowed={({ value }) => {
                return value < MAX_ROUTING_DIGIT || isEmpty(value);
              }}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Controller
          name={`account${number}`}
          control={control}
          render={({ field }) => (
            <NumericFormat
              variant="outlined"
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              label="Account #"
              disabled={isDirectDeposit}
              isAllowed={({ value }) => {
                return value < MAX_ACCOUNT_NUMBER_DIGIT || isEmpty(value);
              }}
              value={field.value}
              onChange={field.onChange}
              customInput={CustomTextField}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Controller
          name={`accountType${number}`}
          control={control}
          render={({ field }) => (
            <Autocomplete
              options={accountTypes?.length ? accountTypes : []}
              defaultValue={null}
              value={field.value || null}
              isOptionEqualToValue={(option, value) => option.toUpperCase() === value.toUpperCase()}
              onChange={(e, v) => {
                setValue(`accountType${number}`, v?.toUpperCase(), { shouldDirty: true });
                trigger(`accountType${number}`);
              }}
              disabled={isDirectDeposit}
              sx={{ maxHeight: "44px" }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  variant="outlined"
                  type="text"
                  label="Account Type"
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#f0f0f0",
                      py: "5px",
                    },
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Controller
          name={`amountPercentage${number}`}
          control={control}
          render={({ field }) => (
            <NumericFormat
              variant="outlined"
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              label={`Amount ${watch(`notPercent${number}`) === "Yes" ? "$" : "%"}`}
              type="number"
              disabled={isDirectDeposit}
              isAllowed={({ value }) => {
                return value <= 100 || isEmpty(value);
              }}
              customInput={CustomTextField}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Grid>
      {isAdmin && (
        <Grid container item xs={12} justifyContent="flex-end">
          <Controller
            name={`notPercent${number}`}
            control={control}
            render={({ field }) => (
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  checked={field.value !== "Yes"}
                  icon={<CircleOutlined />}
                  checkedIcon={<Circle />}
                  onChange={(e) => {
                    if (field.value === "Yes")
                      setValue(`notPercent${number}`, "No", { shouldDirty: true });
                    else setValue(`notPercent${number}`, "Yes", { shouldDirty: true });
                  }}
                />
                <MDTypography fontWeight="bold" variant="body2">
                  Percentage amount
                </MDTypography>
              </Stack>
            )}
          />
        </Grid>
      )}
    </>
  );
};

export default DirectDepositCommonFields;
