import { useAppContextController } from "context/AppContext";
import updateCompanyJob from "layouts/pages/companyjobs/actions/updateJob";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { getDirtyFields } from "utils/helpers/formHelpers";

import { Try } from "@mui/icons-material";
import { Autocomplete, Grid, Stack, Switch, Tooltip } from "@mui/material";
import AIQuestionsPanel from "components/AIQuestionsPanel";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PanelActions from "components/PanelActions";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { cardStyles as styles } from "layouts/pages/customers/styles";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";
import useCompanyStore from "stores/useCompanyStore";
import aiSettingsSchema from "./AISettingsSchema";
import generateJobAIQuestions from "../../actions/generateJobAIQuestions";

const defaultValues = {
  aiSettings: {
    aiInterviewsEnabled: "No",
    displayCustomerQuestions: "",
    autoInvite: "No",
    autoGenerateResumeQuestions: "No",
    autoScreening: "",
    autoUpdateStatus: "No",
    sendApplicantReport: "No",
    questions: [],
  },
};

const dropdownOptionsVenues = [
  {
    label: "Use Venue Settings",
    value: "",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];

const dropdownOptionsCustomers = [
  {
    label: "Use Customer Settings",
    value: "",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];

const CompanyJobsAISettings = ({ isVenue = false, currentCustomer, currentVenue }) => {
  const {
    currentJob,
    setCurrentJob,
    isMobile,
    setCurrentFormDirty,
    changePanel,
    currentLoggedUser,
    company,
    companyType,
  } = useAppContextController();
  const navigate = useNavigate();
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const dropdownOptions = !isVenue ? dropdownOptionsCustomers : dropdownOptionsVenues;

  const validationSchema = aiSettingsSchema();
  const resolver = useYupValidationResolver(validationSchema);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: { dirtyFields, errors },
  } = useForm({ resolver, defaultValues });
  const {
    fields: questions,
    append: addQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "aiSettings.questions",
  });

  const isDirty = dirtyFields && !!Object.keys(dirtyFields).length;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateJobMutation } = useMutation(isVenue ? updateJob : updateCompanyJob);
  const { mutateAsync: generateQuestionsMutation, isLoading: isLoadingGenerateQuestion } =
    useMutation(generateJobAIQuestions);
  const idName = isVenue ? "_id" : "jobId";
  const [questionIndex, setQuestionIndex] = useState(null);

  const shouldShowParentQuestions =
    currentJob?.aiSettings?.displayCustomerQuestions === "Yes" ||
    (currentJob?.aiSettings?.displayCustomerQuestions !== "No" &&
      (currentVenue?.aiSettings?.displayCustomerQuestions === "Yes" ||
        currentCustomer?.aiSettings?.displayCustomerQuestions === "Yes"));

  const aiMainSwitchEnabled =
    company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
    (currentVenue?.aiSettings?.aiInterviewsEnabled === "Yes" ||
      currentCustomer?.aiSettings?.aiInterviewsEnabled === "Yes");

  const aiInterviewsEnabled =
    watch("aiSettings.aiInterviewsEnabled") === "Yes" && aiMainSwitchEnabled;

  const disabledTooltip = useMemo(() => {
    if (company?.aiSettings?.aiInterviewsEnabled !== "Yes")
      return "AI Interviews are disabled for the primary company";
    if (isVenue && currentVenue?.aiSettings?.aiInterviewsEnabled !== "Yes")
      return "AI Interviews are disabled for this venue";
    if (!isVenue && currentCustomer?.aiSettings?.aiInterviewsEnabled !== "Yes")
      return "AI Interviews are disabled for this customer";
    return "";
  }, [
    company?.aiSettings?.aiInterviewsEnabled,
    currentCustomer?.aiSettings?.aiInterviewsEnabled,
    currentVenue?.aiSettings?.aiInterviewsEnabled,
    isVenue,
  ]);

  const handlePressGenerate = (educationLevel) => {
    // Get locked questions
    const currentQuestions = getValues().aiSettings?.questions ?? [];
    if (currentQuestions.find((q) => q.locked)) {
      generateQuestionsMutation(
        {
          jobSlug: currentJob.jobSlug,
          questions: currentQuestions,
          educationLevel,
        },
        {
          onSuccess: async (res) => {
            setValue("aiSettings.questions", res.data.aiInterview.questions, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.aiModel", res.data.aiInterview.aiModel, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.lastGenerationDate", res.data.aiInterview.lastGenerationDate, {
              shouldDirty: true,
              shouldTouch: true,
            });

            enqueueSnackbar("New questions have been generated.", { variant: "success" });
          },
          onError: (err) =>
            enqueueSnackbar("An error has ocurred generating questions.", { variant: "error" }),
        }
      );
    } else {
      generateQuestionsMutation(
        {
          jobSlug: currentJob.jobSlug,
          educationLevel,
        },
        {
          onSuccess: async (res) => {
            setValue("aiSettings.questions", res.data.aiInterview.questions, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.aiModel", res.data.aiInterview.aiModel, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue("aiSettings.lastGenerationDate", res.data.aiInterview.lastGenerationDate, {
              shouldDirty: true,
              shouldTouch: true,
            });

            enqueueSnackbar("New questions have been generated.", { variant: "success" });
          },
          onError: (err) =>
            enqueueSnackbar("An error has ocurred generating questions.", { variant: "error" }),
        }
      );
    }
  };

  const handleUpdateJob = (updatedValues) => {
    updateJobMutation(
      { [idName]: currentJob?._id, data: updatedValues },
      {
        onSuccess: async (res) => {
          queryClient.invalidateQueries("company_jobs_table");
          queryClient.invalidateQueries("jobs");
          queryClient.invalidateQueries("useBadgeValues");
          setCurrentJob({ ...currentJob, ...updatedValues });
          enqueueSnackbar("Job has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };

  const onSubmit = (data) => {
    const temp = getDirtyFields(data, dirtyFields);
    if (temp?.aiSettings?.questions?.length) {
      temp.aiSettings.questions = temp.aiSettings.questions.map((q) => {
        const newQ = { ...q };
        delete newQ.locked;
        return newQ;
      });
    }
    handleUpdateJob(temp);
  };

  const handleCancelChanges = () => {
    reset({ ...(currentJob?.aiSettings && { aiSettings: currentJob?.aiSettings }) });
    setQuestionIndex(null);
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  const handleEditParentQuestions = () => {
    if (isVenue) {
      navigate(`/venues/${currentVenue?.slug}/action/venueinfo`);
    } else {
      navigate(`/venues/${currentCustomer?.slug}/action/venueinfo`);
    }
  };

  useEffect(() => {
    reset({ ...((currentJob?.aiSettings ?? {}) && { aiSettings: currentJob?.aiSettings }) });
    setQuestionIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJob]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------------------------------------------

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Try color="white" />}
        iconContainerSx={{ backgroundColor: "#8935f4" }}
        cardTitle={`AI Settings: ${currentJob?.title}`}
        cardSx={{ overflow: "visible !important", borderRadius: 0, height: 850 }}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        actionsSx={isMobile ? {} : { mt: -4 }}
      >
        <MDBox sx={{ ...styles.container, height: 849 }}>
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12}>
              <Tooltip title={disabledTooltip} arrow={false}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                      AI Configuration
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Enable AI Interviews</MDTypography>
                      <Controller
                        name="aiSettings.aiInterviewsEnabled"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            color="info"
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={!aiMainSwitchEnabled}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">
                        Auto-invite Candidate to Interview
                      </MDTypography>
                      <Controller
                        name="aiSettings.autoInvite"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={!aiInterviewsEnabled}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Auto-generate Resume Questions</MDTypography>
                      <Controller
                        name="aiSettings.autoGenerateResumeQuestions"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            disabled={!aiInterviewsEnabled}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Update Status Automatically</MDTypography>
                      <Controller
                        name="aiSettings.autoUpdateStatus"
                        control={control}
                        render={({ field }) => (
                          <Tooltip title="New applicants will be moved to ATC after 72 hours. ATC applicants will be moved to Declined after sending multiple reminder messages.">
                            <Switch
                              checked={field.value === "Yes"}
                              onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              disabled={!aiInterviewsEnabled}
                            />
                          </Tooltip>
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Send Applicant Report</MDTypography>
                      <Controller
                        name="aiSettings.sendApplicantReport"
                        control={control}
                        render={({ field }) => (
                          <Tooltip title="If enabled, all job notification recipients will get a report email every day.">
                            <Switch
                              checked={field.value === "Yes"}
                              onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              disabled={!aiInterviewsEnabled}
                            />
                          </Tooltip>
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={0} sm={6} />
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="aiSettings.displayCustomerQuestions"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={dropdownOptions}
                          name="aiSettings.displayCustomerQuestions"
                          autoSelect
                          getOptionLabel={(option) => option.label || option}
                          value={
                            field.value != null
                              ? dropdownOptions?.find((option) => option.value === field.value)
                              : dropdownOptions[0]
                          }
                          isOptionEqualToValue={(option, value) => option.value === value}
                          onChange={(e, v) => {
                            setValue("aiSettings.displayCustomerQuestions", v.value, {
                              shouldDirty: true,
                            });
                          }}
                          sx={{ width: "100%" }}
                          disableClearable
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label={`Display ${isVenue ? "Venue" : "Customer"} Questions`}
                              showError
                              errorMessage={error?.message}
                            />
                          )}
                          disabled={!aiInterviewsEnabled}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="aiSettings.autoScreening"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          options={dropdownOptions}
                          name="aiSettings.autoScreening"
                          autoSelect
                          getOptionLabel={(option) => option.label || option}
                          value={
                            field.value != null
                              ? dropdownOptions?.find((option) => option.value === field.value)
                              : dropdownOptions[0]
                          }
                          isOptionEqualToValue={(option, value) => option.value === value}
                          onChange={(e, v) => {
                            setValue("aiSettings.autoScreening", v.value, {
                              shouldDirty: true,
                            });
                          }}
                          sx={{ width: "100%" }}
                          disableClearable
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Auto-Screen Applicants"
                              showError
                              errorMessage={error?.message}
                            />
                          )}
                          disabled={!aiInterviewsEnabled}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Tooltip>
              {aiInterviewsEnabled && (
                <>
                  {shouldShowParentQuestions && (
                    <AIQuestionsPanel
                      questions={
                        isVenue
                          ? currentVenue?.aiSettings?.questions ?? []
                          : currentCustomer?.aiSettings?.questions ?? []
                      }
                      control={control}
                      watch={watch}
                      errors={errors}
                      setValue={setValue}
                      isReadOnly
                      title={isVenue ? "Venue Questions" : "Customer Questions"}
                      onAdd={handleEditParentQuestions}
                    />
                  )}
                  <AIQuestionsPanel
                    questions={watch("aiSettings.questions") ?? []}
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    questionIndex={questionIndex}
                    setQuestionIndex={setQuestionIndex}
                    control={control}
                    watch={watch}
                    errors={errors}
                    setValue={setValue}
                    title="Job Questions"
                    onPressGenerate={handlePressGenerate}
                    isLoadingGenerateQuestion={isLoadingGenerateQuestion}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </CustomCard>
      <ReminderToSaveChangesModal
        open={reminderToSaveChangesModalOpen}
        setOpen={setReminderToSaveChangesModalOpen}
      />
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default CompanyJobsAISettings;
