import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import omit from "lodash/omit";
import has from "lodash/has";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import FilterCardBar from "components/FilterCardBar";
import {
  Assignment,
  AssignmentOutlined,
  Block,
  Cancel,
  CheckCircle,
  CheckCircleOutline,
  DoNotDisturbAlt,
  ErrorOutline,
  Gavel,
  HourglassEmpty,
  HourglassFull,
  HourglassTop,
  InfoOutlined,
  ReportProblemOutlined,
  ThumbUp,
} from "@mui/icons-material";

export const initialApplicantStatusScreenedSubFilterCards = [
  {
    icon: <CheckCircleOutline fontSize="large" />,
    title: "Docs Complete",
    color: "success",
    tooltip: "All required documents are complete",
    number: 0,
    filterField: "onboardingDocsComplete.complete",
    filterValue: "Yes",
  },
  {
    icon: <ErrorOutline fontSize="large" />,
    title: "Missing Docs",
    color: "error",
    tooltip: "Some documents are missing",
    number: 0,
    filterField: "onboardingDocsComplete.complete",
    filterValue: "No",
  },
  {
    icon: <InfoOutlined fontSize="large" />,
    title: "Acknowledged",
    color: "warning",
    tooltip: "Acknowledgement exists",
    number: 0,
    filterField: "acknowledged.signature",
    filterValue: "signatureFile.png",
  },
];

export const initialApplicantPreHireStatusSubFilterCards = [
  {
    icon: <AssignmentOutlined fontSize="large" />,
    title: "BG Info Requested",
    color: "info",
    tooltip: "Background Info Requested",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "info_requested",
  },
  {
    icon: <HourglassEmpty fontSize="large" />,
    title: "BG Queued",
    color: "violet",
    tooltip: "Background Check Request Queued",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "queued",
  },
  {
    icon: <HourglassTop fontSize="large" />,
    color: "warning",
    title: "BG Pending",
    tooltip: "Background Pending",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "pending",
  },
  {
    icon: <HourglassFull fontSize="large" />,
    color: "warning",
    title: "BG processing",
    tooltip: "Background Processing",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "processing",
  },
  {
    icon: <Gavel fontSize="large" />,
    title: "BG Consider",
    color: "warning",
    tooltip: "Background Consider",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "consider",
  },
  {
    icon: <ReportProblemOutlined fontSize="large" />,
    title: "BG Pre Adverse",
    color: "warning",
    tooltip: "Background Pre Adverse",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "pre_adverse",
  },
  {
    icon: <DoNotDisturbAlt fontSize="large" />,
    title: "BG Final Adverse",
    color: "error",
    tooltip: "Background Final Adverse",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "final_adverse",
  },
  {
    icon: <Block fontSize="large" />,
    title: "BG Dispute",
    color: "warning",
    tooltip: "Background Dispute",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "dispute",
  },
  {
    icon: <Cancel fontSize="large" />,
    title: "BG Canceled",
    color: "error",
    tooltip: "Background Canceled",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "canceled",
  },
  {
    icon: <CheckCircle fontSize="large" />,
    title: "BG Clear",
    color: "success",
    tooltip: "Background Clear",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "clear",
  },
  {
    icon: <ThumbUp fontSize="large" />,
    title: "BG Proceed",
    color: "success",
    tooltip: "Background Proceed",
    number: 0,
    filterField: "yardstik.report.status",
    filterValue: "proceed",
  },
];

const ApplicantStatusSubFilters = ({ setFilters, setPage, filters, baseFilters }) => {
  const { setCurrentApplicant, company } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const initialCards = useMemo(() => {
    return [
      {
        icon: <Assignment fontSize="large" />,
        title: "Applied",
        color: "info",
        tooltip: "Applicants Applied",
        number: 0,
        filterField: "applicantStatus",
        filterValue: filters.applicantStatus ?? "",
        default: true,
      },
      ...(filters?.applicantStatus === "Screened"
        ? initialApplicantStatusScreenedSubFilterCards
        : []),
      ...(company?.companyType === "Venue" && filters?.applicantStatus === "Pre-Hire"
        ? initialApplicantPreHireStatusSubFilterCards
        : []),
    ];
  }, [filters]);

  const { cards } = useBadgeValues({
    initialCards,
    filters: { ...(baseFilters ?? {}), ...filters },
    entity: "applicants",
    refreshBadges,
  });

  const navigate = useNavigate();

  const setApplicantFilters = (filter) => {
    navigate("/applicants");
    setCurrentApplicant(null);
    const newFilters = omit(
      filters,
      has(filter, "systemSource") ? "referralSource" : "systemSource"
    );
    delete newFilters._id;
    setFilters({
      ...newFilters,
      ...(filters?.tags ? { tags: filters.tags } : filter),
    });
    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  const initialIndex = cards.findIndex((card) => card.default);

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setApplicantFilters}
        setPage={setPage}
        initialIndex={initialIndex || 0}
      />
    </span>
  );
};

export default ApplicantStatusSubFilters;

ApplicantStatusSubFilters.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
};

ApplicantStatusSubFilters.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
};
