import { baseAxios } from "config";

const jobAIInterviewProcessConversation = ({ messageArray, jobSlug, outsideMode, id }) => {
  const outsideModeQuery = outsideMode ? `outside-${outsideMode}/` : "";

  return baseAxios.post(`/${outsideModeQuery}applicants/${id}/job/${jobSlug}/ai/renderprescreen/`, {
    messageArray,
  });
};

export default jobAIInterviewProcessConversation;
