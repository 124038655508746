import { Box, styled, useMediaQuery } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useState } from "react";
import {
  Assessment,
  AttachFile,
  Badge,
  CalendarMonth,
  Description,
  FolderCopy,
  Info,
  Settings,
  Timeline,
  Try,
} from "@mui/icons-material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import CopyJobURLModal from "layouts/pages/jobs/components/CopyJobURLModal";
import CompanyJobForm from "layouts/pages/companyjobs/components/CompanyJobInfoPanel/CompanyJobForm";

function CompanyJobInfoPanel({
  setRefetchJobs,
  setPreviewContent,
  previewContent,
  selectedPanel = "",
  onPanelSelect = () => null,
}) {
  const { currentJob, company, isCurrentFormDirty, setPanelChangesOpen, setChangePanel } =
    useAppContextController();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [CopyJobURLModalOpen, setCopyJobURLModalOpen] = useState(false);

  const isEdit = !!currentJob?._id;

  const copyUrlHandler = () => setCopyJobURLModalOpen(!CopyJobURLModalOpen);

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => onPanelSelect(panel));
    } else {
      onPanelSelect(panel);
    }
  };

  const panelOptions = [
    {
      title: "Activities",
      icon: <Timeline />,
      hasShadow: selectedPanel === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handlePanelChange("activities"),
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: selectedPanel === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handlePanelChange("additionalInfo"),
    },
    {
      title: "Job Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "content" ? "emd" : "",
      color: "primary",
      ariaLabel: "job content",
      fn: () => handlePanelChange("content"),
    },
    {
      title: "Job Interviews",
      icon: <CalendarMonth />,
      hasShadow: selectedPanel === "interviews" ? "emd" : "",
      color: "success",
      ariaLabel: "job interviews",
      fn: () => handlePanelChange("interviews"),
    },
    {
      title: "Job Attachments",
      icon: <AttachFile />,
      hasShadow: selectedPanel === "attachments" ? "emd" : "",
      color: "info",
      ariaLabel: "job attachments",
      fn: () => handlePanelChange("attachments"),
    },
    {
      title: "Job Status",
      icon: <Badge />,
      hasShadow: selectedPanel === "status" ? "emd" : "",
      color: "success",
      ariaLabel: "job status",
      fn: () => handlePanelChange("status"),
    },
    {
      title: "Job Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "job settings",
      fn: () => handlePanelChange("settings"),
    },
    {
      title: "Job Notes",
      icon: <Description />,
      hasShadow: selectedPanel === "notes" ? "emd" : "",
      color: "warning",
      ariaLabel: "job notes",
      fn: () => handlePanelChange("notes"),
    },
    ...(company?.aiInterviewsAvailable === "Yes"
      ? [
          {
            title: "AI Settings",
            icon: <Try />,
            hasShadow: selectedPanel === "aiSettings" ? "emd" : "",
            color: "violet",
            ariaLabel: "ai settings",
            fn: () => handlePanelChange("aiSettings"),
          },
        ]
      : []),
    {
      title: "Job Report Data",
      icon: <Assessment />,
      hasShadow: selectedPanel === "reportdata" ? "emd" : "",
      color: "violet",
      ariaLabel: "job report data",
      fn: () => handlePanelChange("reportdata"),
    },
  ];

  return (
    // currentJob && (
    <CustomCard
      icon={
        isEdit ? (
          <CompanyLogoSquare company={company} />
        ) : (
          <StyledImageContainer>
            <Icon fontSize="large">work</Icon>
          </StyledImageContainer>
        )
      }
      iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "-1.5rem" }}
      cardSx={{ width: "100%", height: 850, overflow: "visible !important", p: 0 }}
    >
      <MDBox mt={!isSmallScreen && -9}>
        <CompanyJobForm
          setRefetchJobs={setRefetchJobs}
          copyUrlHandler={copyUrlHandler}
          setPreviewContent={setPreviewContent}
          previewContent={previewContent}
          panelOptions={panelOptions}
        />
      </MDBox>

      {CopyJobURLModalOpen && (
        <CopyJobURLModal
          jobSlug={currentJob?.jobSlug ? currentJob?.jobSlug : ""}
          open={CopyJobURLModalOpen}
          setOpen={setCopyJobURLModalOpen}
        />
      )}
      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}
    </CustomCard>
  );
  // );
}

export default CompanyJobInfoPanel;

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "4rem",
  height: "4rem",
  background: theme.palette.warning.main,
  color: "white",
  borderRadius: "0.8rem",
}));
