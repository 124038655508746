import * as yup from "yup";

export const customFieldSchema = yup.object({
  label: yup.string().required("Label is required"),
  type: yup
    .string()
    .required("Type is required")
    .oneOf(["Text", "Textarea", "Date", "Dropdown", "Radio Group", "Price", "Quantity", "Checkbox"])
    .label("Type"),
  placeholder: yup.string(),
  options: yup
    .array()
    .of(yup.string().required("Option cannot be empty"))
    .when("type", {
      is: (val) => ["Dropdown", "Radio Group"].includes(val),
      then: yup.array().min(1, "Options are required for Dropdown or Radio Group"),
      otherwise: yup.array().notRequired(),
    }),
  isVisible: yup.boolean(),
  isRequired: yup.boolean(),
  isEditable: yup.boolean(),
});
