import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import { Box, Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import PictureAvatar from "components/PictureAvatar";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import moment from "moment";
import MDBox from "components/MDBox";
import DataTable from "components/DataTable";
import MDTypography from "components/MDTypography";
import FiltersList from "components/FiltersList";
import useSort from "utils/useSort";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import GetIcons from "components/GetIcons";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import fetchTasks from "layouts/pages/tasks/actions/fetchTasks";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import { generateTaskChartReport, generateTaskTableReport } from "api/reports/taskReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import useSessionAuth from "hooks/useSessionAuth";
import TaskDetailsModal from "../TaskDetailsModal";
// import TasksActionModal from "../TasksActionModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const TasksTable = ({
  fetchAll = false,
  setActionSelected,
  title = "Title",
  filters,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setToastAlert,
}) => {
  const { setCurrentTask, currentTask, userType } = useAppContextController();

  const setNavigateUrl = useNavigate();
  const classes = useStyle();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters };
  const [taskMode, setTaskMode] = useState("edit");
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);

  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { mutateAsync: createTaskTableReport } = useMutation(generateTaskTableReport);
  const { mutateAsync: createTaskChartReport } = useMutation(generateTaskChartReport);
  const { mutateAsync: exportTaskReport, isLoading: isLoadingExport } = useMutation(exportReport);
  const { mutateAsync: saveTaskReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const { logoutAuth0User } = useSessionAuth();

  const {
    data: tasks,
    isLoading,
    refetch: refetchTasks,
  } = useQuery(["tasks", allOptions], () => fetchTasks(allOptions));

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialTaskReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const response = await fetchModuleReports("tasks");

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialTaskReport();
  }, [handleLoadInitialTaskReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportTaskReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Task ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportTaskReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate,
            end: values?.endDate,
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        await createTaskTableReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartTableSrc(response?.iframe_url);
              setTableQueryDetails(response?.queryDetails);
              setTableReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Task Table Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      if (values?.chartFilters) {
        reportPayload = {
          ...reportPayload,
          chartFilters: values?.chartFilters,
        };
      }

      if (values?.chartDateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.chartStartDate, // ISO formatted date already handled
            end: values?.chartEdDate, // ISO formatted date already handled
            field: values?.chartDateField,
            range: values?.chartDateRange,
          },
        };
      }

      try {
        await createTaskChartReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Task Chart Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveTaskReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData, formType: "chart" };
        }

        await saveTaskReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Task ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving task report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveTaskReport, tableReportData]
  );

  const columns = [
    {
      title: "Icon",
      field: "type",
      customCell: (image, field, row) => <GetIcons category={row?.category} />,
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Type",
      field: "taskType",
    },
    {
      title: "Date Opened",
      field: "createdDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    {
      title: "Assigned By",
      field: "assignedByName",
      customCell: (assignedByName, field, row) => (
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item>
            <PictureAvatar
              image={assignedByName?.profileImg}
              firstName={assignedByName?.firstName}
              lastName={assignedByName?.lastName}
              userId={assignedByName?.userId}
              size="md"
            />
          </Grid>
          <Grid item>
            <Tooltip title={assignedByName.email}>
              <MDTypography variant="body">{`${assignedByName?.firstName} ${assignedByName?.lastName}`}</MDTypography>
            </Tooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      title: "Assigned To",
      field: "assignedToList",
      customCell: (assignedToList, field, row) => {
        // this needs to accommodate an array
        if (Array.isArray(assignedToList) && assignedToList?.length)
          return (
            <Grid container spacing={1} direction="row" alignItems="center">
              {assignedToList?.length > 0 &&
                assignedToList.map((assignedTo, index) => (
                  <Tooltip
                    key={`${assignedTo?.userId}_${index.toString()}`}
                    title={
                      <div>
                        {[assignedTo?.firstName, assignedTo?.lastName].join(" ")}
                        <br />
                        {assignedTo?.email}
                      </div>
                    }
                    placement="bottom-end"
                  >
                    <Grid item>
                      <PictureAvatar
                        image={assignedTo?.profileImg}
                        firstName={assignedTo?.firstName}
                        lastName={assignedTo?.lastName}
                        userId={assignedTo?.userId}
                        size="md"
                      />
                    </Grid>
                  </Tooltip>
                ))}
              {/* <Grid item> */}
              {/*  <Tooltip title={assignedToList[0].email}> */}
              {/*    <MDTypography variant="body">{assignedToList[0]?.fullName}</MDTypography> */}
              {/*  </Tooltip> */}
              {/* </Grid> */}
            </Grid>
          );
        return "";
      },
    },
    {
      title: "Applicant",
      field: "applicant",
      customCell: (applicant, field, row) => {
        if (!applicant) return "";
        return (
          <Grid container spacing={1} direction="row" alignItems="center">
            <Grid item>
              <PictureAvatar
                image={null}
                firstName={applicant?.firstName}
                lastName={applicant?.lastName}
                userId={applicant?._id}
                size="md"
              />
            </Grid>
            <Grid item>
              <Tooltip title={applicant.email}>
                <MDTypography variant="body">{`${applicant?.firstName} ${applicant?.lastName}`}</MDTypography>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: "Task Title",
      field: "taskName",
      customCell: (subject) => (
        <Tooltip title={subject}>
          <MDTypography variant="body">
            {subject?.length > 20 ? `${subject.substring(0, 20)}...` : subject}
          </MDTypography>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      field: "taskDescription",
      customCell: (desc) => {
        return (
          <Tooltip title={desc ? parse(desc) : ""} arrow>
            <Box
              sx={{
                typography: "body1",
                width: "10rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
              }}
            >
              {desc ? parse(desc) : ""}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      title: "Last Updated",
      field: "modifiedDate",
      customCell: (date) => moment(date).format("MM-DD-YYYY hh:mm A"),
    },
    // {
    //   title: "Tasks Actions",
    //   field: "_id",
    //   customCell: (id, field, row) => (
    //     <TasksTableActions
    //       id={id}
    //       row={row}
    //       setCurrentTask={setCurrentTask}
    //       setNavigateUrl={setNavigateUrl}
    //       setTaskModalOpen={setTaskModalOpen}
    //       setTaskMode={setTaskMode}
    //     />
    //   ),
    // },
  ];
  const columnsForSearch = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email/User ID", field: "emailAddress" },
    { title: "User Type", field: "userType" },
  ];

  const columnsForTasks = [
    { title: "First Name", field: "userFirstName" },
    { title: "Last Name", field: "userLastName" },
    { title: "Title", field: "title" },
    { title: "Status", field: "status" },
  ];

  const handleRowClick = (task, e) => {
    e.stopPropagation();
    setCurrentTask({ ...task });
    setTaskMode("edit");
    setTaskModalOpen(true);
  };

  const addNew = (e) => {
    if (!currentTask) {
      setCurrentTask({});
    }
    setTaskMode("create");
    setTaskModalOpen(true);
    e.stopPropagation();
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
    }
  }, [openReportMessageModal]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="success"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">checklistIcon</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              Tasks
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={6}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {showSearchBar && (
            <Searchbar
              fetch={fetchTasks}
              placeholder="Search Tasks"
              columns={columnsForTasks}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              fetchUsers
              setPage={setPage}
              searchBy={["title", "description"]}
              // filterBy="applicantId"
              filterBy="_id"
              filterByColumn="_id"
            />
          )}
          {showSearchBar && (
            <Searchbar
              fetch={fetchUsers}
              placeholder="Search Users"
              columns={columnsForSearch}
              queryCharacterLimit={3}
              resultsLimit={15}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["firstName", "lastName", "emailAddress"]}
              filterBy="userId"
              filterByColumn="_id"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>

          {userType !== "User" && userType !== "Client" && (
            <Tooltip title="Create Report">
              <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                <ViewComfyIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={tasks}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          isLoading={isLoading}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={false}
          defaultSort="createdDate:desc"
        />
      </MDBox>
      {taskModalOpen && (
        <TaskDetailsModal
          mode={taskMode}
          setTaskModalOpen={setTaskModalOpen}
          taskModalOpen={taskModalOpen}
          currentTask={currentTask}
          setToastAlert={setToastAlert}
          currentApplicant={taskMode === "edit" ? currentTask?.applicant : null}
        />
      )}
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="tasks"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          title="Task Report"
          chartSrc={chartSrc}
          filterCardList={["To Do", "In Progress", "Blocked", "Done", "All"]}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveTaskReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
          setToastAlert={setToastAlert}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
TasksTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Tasks",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
TasksTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default TasksTable;
