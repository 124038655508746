import { Box, Card, Grid, IconButton, Stack, Tooltip, styled, useMediaQuery } from "@mui/material";
import {
  Stadium,
  DateRange,
  FolderShared as FolderSharedIcon,
  Handshake,
  Timeline as TimelineIcon,
  AccountCircle,
  Email,
  AttachFile,
  Info,
} from "@mui/icons-material";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";

import PartnerForm from "layouts/pages/partners/components/PartnersInformation/PartnerForm";
import Description from "@mui/icons-material/Description";

function PartnersInformation({ setPanelSelect, panelSelect, refreshPartner }) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentPartner, setCurrentPartner } = useAppContextController();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const isEdit = !!currentPartner?._id;

  const handleClick = (selection) => {
    setPanelSelect(selection);
  };

  const partnerTabs = [
    {
      title: "Activities",
      icon: <TimelineIcon />,
      hasShadow: panelSelect === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handleClick("activities"),
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: panelSelect === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handleClick("additionalInfo"),
    },
    {
      title: "Contacts",
      icon: <AccountBoxIcon />,
      hasShadow: panelSelect === "contacts" ? "emd" : "",
      color: "warning",
      ariaLabel: "contacts",
      fn: () => handleClick("contacts"),
    },
    {
      title: "Sales",
      icon: <SignalCellularAltIcon />,
      hasShadow: panelSelect === "sales" ? "emd" : "",
      color: "success",
      ariaLabel: "chart",
      fn: () => handleClick("sales"),
    },
    {
      title: "Members",
      icon: <Diversity3Icon />,
      hasShadow: panelSelect === "members" ? "emd" : "",
      color: "warning",
      ariaLabel: "diversify",
      fn: () => handleClick("members"),
    },
    {
      title: "Venues",
      icon: <Stadium />,
      hasShadow: panelSelect === "venues" ? "emd" : "",
      color: "info",
      ariaLabel: "stadium",
      fn: () => handleClick("venues"),
    },
    {
      title: "Events",
      icon: <DateRange />,
      hasShadow: panelSelect === "events" ? "emd" : "",
      color: "success",
      ariaLabel: "date",
      fn: () => handleClick("events"),
    },
    {
      title: "Notes",
      icon: <Description />,
      hasShadow: panelSelect === "notes" ? "emd" : "",
      color: "secondary",
      ariaLabel: "notes",
      fn: () => handleClick("notes"),
    },
    {
      title: "User Account",
      icon: <AccountCircle color="info" />,
      hasShadow: panelSelect === "user" ? "emd" : "",
      color: "",
      ariaLabel: "userPanel",
      fn: () => handleClick("user"),
    },
    {
      title: "Messages",
      icon: <Email />,
      hasShadow: panelSelect === "message" ? "emd" : "",
      color: "error",
      ariaLabel: "Email",
      fn: () => handleClick("message"),
    },
    {
      title: "Attachments",
      icon: <AttachFile />,
      hasShadow: panelSelect === "attachments" ? "emd" : "",
      color: "warning",
      ariaLabel: "attachments",
      fn: () => handleClick("attachments"),
    },
  ];

  return (
    // currentPartner && (
    <CustomCard
      icon={
        <StyledImageContainer>
          <Handshake fontSize="large" />
        </StyledImageContainer>
      }
      currentData={currentPartner}
      cardTitle={
        <MDBox display="flex" alignItems="center">
          <MDTypography sx={{ mr: 2 }} variant="h5" color="dark">
            {isEdit ? `Edit Partner: ${currentPartner?.name}` : `Create Partner `}
          </MDTypography>
        </MDBox>
      }
      iconContainerSx={{ backgroundColor: "none", marginTop: "-2rem" }}
      cardSx={{ width: "100%", overflow: "visible !important", height: 850 }}
    >
      {isEdit && (
        <StyledStack>
          {partnerTabs
            .filter((tab) => !tab?.hidden)
            .map((tab, i) => {
              const index = i;
              return (
                <Tooltip title={tab?.title} key={`${tab?.title}_index`}>
                  <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow}>
                    <IconButton color={tab.color} aria-label={tab.ariaLabel} onClick={tab.fn}>
                      {tab.icon}
                    </IconButton>
                  </MDBox>
                </Tooltip>
              );
            })}
        </StyledStack>
      )}
      <MDBox>
        <PartnerForm refreshPartner={refreshPartner} />
      </MDBox>

      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}
    </CustomCard>
  );
  // );
}

export default PartnersInformation;

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "4rem",
  height: "4rem",
  background: theme.palette.secondary.dark,
  color: "white",
  borderRadius: "0.8rem",
}));
const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  gap: 5,
  justifyContent: "flex-end",
  marginRight: 20,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  overflow: "visible",
  height: 850,
}));
