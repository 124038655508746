import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import SelectedApplicantActions from "../SelectedApplicantActions";

const SelectedApplicant = (row, idx, onRemove, applicants, onChangeFeedback) => {
  const { id, firstName, lastName, email, phone } = row;

  const isNew = !applicants?.find((applicant) => {
    const applicantId = applicant.id || applicant._id;
    return applicantId === id;
  });

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item sm={8.5}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item sm={3}>
              <MDTypography variant="body2">{`${firstName} ${lastName}`}</MDTypography>
            </Grid>
            <Grid item sm={5}>
              <MDTypography variant="body2">{email}</MDTypography>
            </Grid>
            <Grid item sm={4}>
              <MDTypography textAlign="start" variant="body2">
                {phone}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3.5}>
          {!isNew && (
            <SelectedApplicantActions
              id={id}
              parentComponent="Applicant"
              row={row}
              onRemove={() => onRemove(idx)}
              onChangeFeedback={(status) => onChangeFeedback(idx, status)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SelectedApplicant;
