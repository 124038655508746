import axios from "axios";
import { baseAxios } from "config";

const updateApplicant = async ({ applicantId, data, outsideMode = "", sendOnboardingMessage }) => {
  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;

    const res = await baseAxios.put(`/${outsideModeQuery}applicants/${applicantId}`, {
      ...data,
      sendOnboardingMessage,
    });
    return res.data;
  } catch (error) {
    if (error.message === "Request failed with status code 404") return [];
    throw error;
  }
};

export default updateApplicant;
