import { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import { useAppContextController } from "context/AppContext";
import useCompanyCache, { useOutsideCompanyCache } from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import fetchCurrentApplicant from "layouts/pages/applicants/actions/fetchCurrentApplicant";
import CircularLoader from "components/CircularLoader";

import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import { NewApplicantContextProvider } from "context/NewApplicantContext";
import FormContainer from "../FormContainer";
import MessageSection from "../MessageSection";
import NewApplicantHeader from "../NewApplicantHeader";

const NewOnboarding = () => {
  const { venues, setVenues, company, setCompany, setCompanyType, user } =
    useAppContextController();
  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "public",
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({
    venues,
    setVenues,
    company,
    outsideMode: "public",
  });

  const { step: urlStep } = useParams();

  const {
    data: currentApplicantData,
    isLoading,
    refetch,
  } = useQuery(
    ["getCurrentApplicantData"],
    () => {
      return fetchCurrentApplicant({ outsideMode: "protected" });
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!urlStep,
    }
  );

  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    if (!venues || !Object.keys(venues)?.length) {
      refetchVenues();
    }
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      key="top-center"
    >
      <NewApplicantContextProvider outsideMode={user ? "" : "protected"}>
        <ContainerWrapper sx={{ px: "20px", pt: "15px" }} position="relative">
          <NewApplicantHeader isAvailable={isAvailable} setIsAvailable={setIsAvailable} />
          <MessageSection isAvailable={isAvailable} />
          {isAvailable && (!urlStep || !isLoading) && (
            <FormContainer currentApplicant={currentApplicantData} />
          )}
          {!!urlStep && isLoading && <CircularLoader hasBackdrop={false} isLoading={isLoading} />}
        </ContainerWrapper>
      </NewApplicantContextProvider>
    </SnackbarProvider>
  );
};

export default NewOnboarding;
