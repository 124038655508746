import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Save as SaveIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormField from "layouts/applications/wizard/components/FormField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import GenericModal from "components/GenericModal";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { taskSchema } from "data/task";
import useDropdowns from "hooks/useDropdowns";
import Loading from "components/Loading";
import MDEditor from "components/MDEditor";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { getDirtyFields } from "utils/helpers/formHelpers";
import updateTask from "layouts/pages/tasks/actions/updateTask";
import createTask from "layouts/pages/tasks/actions/createTask";
import MDTypography from "components/MDTypography";
import ScrolledList from "components/ScrolledList";
import { useAppContextController } from "context/AppContext";
import MDBox from "components/MDBox";
import useGetUsersForTaskAssignemnt from "hooks/useGetUsersForTaskAssignment";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
    height: "100%",
  },
  addButton: {
    fontSize: 40,
  },
  root: {
    height: "300px",
  },
  list: {
    width: "70%",
    height: "300px",
    overflowY: "scroll",
    border: "solid black 1px",
    padding: 2,
  },
  button: {
    margin: "8px",
  },
  leftListStyle: {
    display: "flex",
    justifyContent: "center",
  },
});

const TaskDetailsModal = ({
  mode = "edit",
  taskModalOpen,
  setTaskModalOpen,
  currentTask,
  setToastAlert,
  currentApplicant,
  parent = "Tasks",
}) => {
  const classes = useStyle();
  const { currentLoggedUser } = useAppContextController();

  const navigate = useNavigate();
  const [rightList, setRightList] = useState([]);
  const [selectedLeftItem, setSelectedLeftItem] = useState(null);
  const [selectedRightItem, setSelectedRightItem] = useState(null);
  const [leftList, setLeftList] = useState([]);
  const [editorValue, setEditorValue] = useState(
    currentTask ? currentTask?.taskDescription : `<p></p>`
  );
  const [assignedList, setAssignedList] = useState([]);
  const resolver = useYupValidationResolver(taskSchema);

  const filteredUsers = useGetUsersForTaskAssignemnt();

  const taskDefaultValues = {
    taskName: "",
    assignedByName: {
      userId: currentLoggedUser?._id,
      applicantId: currentLoggedUser?.applicantId,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      fullName: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      email: currentLoggedUser?.emailAddress,
      profileImg: currentLoggedUser.profileImg,
    },
    status: "",
    taskType: "",
    taskDescription: "",
    createdDate: new Date(),
    dueDate: new Date(),
    assignedToList: [],
  };
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,

    reset,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm({
    resolver,
    mode: "onBlur",
    defaultValues: mode === "edit" ? currentTask : taskDefaultValues,
  });

  useEffect(() => {
    if (mode === "edit") {
      reset(currentTask, { keepErrors: true });
      setEditorValue(currentTask?.taskDescription);
    } else {
      reset(taskDefaultValues);
      setEditorValue(`<p></p>`);
    }
  }, [mode, currentTask, taskModalOpen]);

  const { dropdowns: taskStatuses } = useDropdowns({ entity: "taskStatuses" });
  const { dropdowns: taskTypes } = useDropdowns({ entity: "taskTypes" });

  const moveItem = (index, sourceList, targetList, direction) => {
    const source = [...sourceList];
    const target = [...targetList];
    const item = source.splice(index, 1);
    target.push(...item);
    source.sort();
    target.sort();
    if (direction === "left") {
      setLeftList(source);
      setRightList(target);
      setValue("assignedToList", target, { shouldDirty: true });
    } else {
      setRightList(source);
      setLeftList(target);
      setValue("assignedToList", source, { shouldDirty: true });
    }
  };
  const handleLeftItemClick = (index) => {
    setSelectedLeftItem(index);
    setSelectedRightItem(null);
  };

  const handleRightItemClick = (index) => {
    setSelectedRightItem(index);
    setSelectedLeftItem(null);
  };

  const handleButtonClick = (direction) => {
    if (direction === "left") {
      if (selectedLeftItem !== null) {
        moveItem(selectedLeftItem, leftList, rightList, direction);
        setSelectedLeftItem(null);
      }
    } else if (direction === "right") {
      if (selectedRightItem !== null) {
        moveItem(selectedRightItem, rightList, leftList, direction);
        setSelectedRightItem(null);
      }
    }
  };

  const handleButtonDoubleClick = (index, direction) => {
    if (direction === "left") {
      moveItem(index, leftList, rightList, direction);
      setSelectedLeftItem(null);
    } else if (direction === "right") {
      moveItem(index, rightList, leftList, direction);
      setSelectedRightItem(null);
    }
  };

  useEffect(() => {
    setAssignedList(getValues().assignedToList);
  }, [taskModalOpen]);

  useEffect(() => {
    setRightList(assignedList || []);
    const currentList = filteredUsers.filter((item) => {
      return !assignedList?.find((e) => e.userId === item.userId);
    });
    setLeftList(currentList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedList, taskModalOpen, filteredUsers]);

  const queryClient = useQueryClient();

  const updateTaskMutation = useMutation(updateTask, {
    onError: (err) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong!  ${err.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("tasks");
      if (data.status === "Deleted") {
        navigate(`/tasks`);
        setToastAlert({ isShow: true, message: "Task was archived", status: "success" });
      } else {
        setToastAlert({ isShow: true, message: "Task has been updated!", status: "success" });
      }
      setTaskModalOpen(false);
    },
  });

  const createTaskMutation = useMutation(createTask, {
    onError: (err) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong!  ${err.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("tasks");
      setToastAlert({ isShow: true, message: "Task has been created!", status: "success" });
      setTaskModalOpen(false);
      // navigate(`/jobs/${data?.jobSlug}`);
    },
  });

  const saveChanges = async (values) => {
    if (!currentTask._id) {
      setToastAlert({
        isShow: true,
        message: "Cannot Update task -- No ID Present!",
        status: "error",
      });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    if (modifiedFields?.payRate) {
      modifiedFields.payRate = parseFloat(modifiedFields.payRate);
    }
    await updateTaskMutation.mutateAsync({
      taskId: currentTask._id,
      data: { ...modifiedFields },
    });
  };

  const createTaskHandler = async (values) => {
    const modifiedValues = { ...values };
    if (currentApplicant && parent === "Notes") {
      modifiedValues.applicant = {
        _id: currentApplicant._id,
        firstName: currentApplicant.firstName,
        lastName: currentApplicant.lastName,
        email: currentApplicant.email,
      };
    }
    await createTaskMutation.mutateAsync({
      data: modifiedValues,
    });
  };

  const header = (
    <Box display="flex">
      <Box sx={{ mt: 2, mb: 1, ml: 2 }}>
        <Typography variant="h5" color="dark">
          Task Details {currentTask?.taskName && ` - ${currentTask?.taskName}`}{" "}
          {currentApplicant && ` for ${currentApplicant?.firstName} ${currentApplicant?.lastName}`}
        </Typography>
      </Box>
    </Box>
  );
  const modalBody = (
    <>
      {mode === "edit" && !currentTask ? (
        <Loading />
      ) : (
        <form
          onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createTaskHandler)}
        >
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Controller
                name="taskName"
                control={control}
                defaultValue=""
                render={({ field }) => <FormField label="Task Name" {...field} />}
              />
              {errors?.taskName && (
                <MDTypography className={classes.error} color="error">
                  {errors?.taskName.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="createdDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disablePast
                      label="Date Assigned"
                      value={!field.value ? null : field.value}
                      onChange={(value) => {
                        if (moment(value).isValid()) {
                          field.onChange(new Date(value));
                        } else {
                          field.onChange(value);
                        }
                      }}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors?.createdDate && (
                <MDTypography className={classes.error} color="error">
                  {errors?.createdDate.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disablePast
                      label="Due Date"
                      value={!field.value ? null : field.value}
                      onChange={(value) => {
                        if (moment(value).isValid()) {
                          field.onChange(new Date(value));
                        } else {
                          field.onChange(value);
                        }
                      }}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors?.dueDate && (
                <MDTypography className={classes.error} color="error">
                  {errors?.dueDate.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    defaultValue={null}
                    value={field.value || ""}
                    options={taskStatuses || []}
                    onChange={(e, v) => {
                      field.onChange(v);
                    }}
                    renderInput={(params) => <FormField {...params} type="text" label="Status" />}
                  />
                )}
              />
              {errors?.status && (
                <MDTypography className={classes.error} color="error">
                  {errors?.status.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="assignedByName"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    defaultValue={null}
                    value={field.value ? field.value.fullName || null : null}
                    options={filteredUsers || []}
                    onChange={(e, v) => {
                      field.onChange(v);
                    }}
                    renderInput={(params) => (
                      <FormField {...params} type="text" label="Assigned By" />
                    )}
                  />
                )}
              />
              {errors?.assignedByName && (
                <MDTypography className={classes.error} color="error">
                  {errors?.assignedByName.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={4}>
              <Controller
                name="taskType"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    value={field.value || ""}
                    options={taskTypes || []}
                    onChange={(e, v) => {
                      field.onChange(v);
                    }}
                    renderInput={(params) => (
                      <FormField {...params} type="text" label="Task Type" />
                    )}
                  />
                )}
              />
              {errors?.taskType && (
                <MDTypography className={classes.error} color="error">
                  {errors?.taskType.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item sm={4} />

            <Grid item sm={12}>
              <Typography variant="body2" color="dark">
                Task Description
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <MDBox
                sx={{
                  "& .ql-editor": {
                    minHeight: "80px !important",
                    maxHeight: "130px",
                    overflow: "hidden",
                    overflowY: "auto",
                    overflowX: "auto",
                  },
                }}
              >
                <Controller
                  name="taskDescription"
                  control={control}
                  render={({ field }) => (
                    <MDEditor
                      name="taskDescription"
                      className={classes.editor}
                      style={{ height: "100%" }}
                      value={editorValue || ""}
                      onChange={(e) => {
                        setEditorValue(e);
                        setValue("taskDescription", e, { shouldDirty: true });
                      }}
                    />
                  )}
                />
                {errors?.taskDescription && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.taskDescription.message}
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
            <Grid item sm={12}>
              <Box className={classes.root} mt={5}>
                <Grid container>
                  <Grid item sm={5} className={classes.leftListStyle}>
                    <ScrolledList
                      data={leftList}
                      selected={selectedLeftItem}
                      onClick={handleLeftItemClick}
                      onDoubleClick={handleButtonDoubleClick}
                    />
                  </Grid>
                  <Grid item sm={2} display="flex" p={3}>
                    <Grid container direction="column">
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="success"
                        onClick={() => handleButtonClick("left")}
                      >
                        &gt;
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="error"
                        onClick={() => handleButtonClick("right")}
                      >
                        &lt;
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sm={5} className={classes.leftListStyle}>
                    <List className={classes.list}>
                      {rightList?.map((item, index) => (
                        <ListItemButton
                          key={item?.userId}
                          selected={index === selectedRightItem}
                          onClick={() => handleRightItemClick(index)}
                          onDoubleClick={() => {
                            handleButtonDoubleClick(index, "right");
                          }}
                        >
                          <ListItemText primary={item?.fullName} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={9} />
            <Grid item sm={3}>
              <Grid container spacing={3} display="flex">
                <Grid item sm={6}>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    style={{ color: "error" }}
                    fullWidth
                    onClick={() => {
                      reset(taskDefaultValues);
                      setEditorValue(`<p></p>`);
                      setTaskModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isSubmitting}
                    sx={{ padding: "0.5rem 1rem" }}
                    fullWidth
                    onClick={(e) => e.stopPropagation()}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );

  // const modalButtons = (
  //     <>

  //     </>
  // );

  return (
    <>
      <GenericModal
        open={taskModalOpen}
        setOpen={setTaskModalOpen}
        header={header}
        body={modalBody}
        // buttons={modalButtons}
        width="60%"
        overflow="auto"
      />
    </>
  );
};

export default TaskDetailsModal;
