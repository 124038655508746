import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PublicIcon from "@mui/icons-material/Public";
import BoltIcon from "@mui/icons-material/Bolt";
import HistoryIcon from "@mui/icons-material/History";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { useAppContextController } from "context/AppContext";
import { EventContextProvider } from "context/EventContext";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import useHandleBackButtonMainPage from "hooks/useHandleBackButtonMainPage";
import useGetBackButton from "hooks/useGetBackButton";
import generateFiltersFromString from "utils/generateFiltersFromString";
import { useSnackbar } from "notistack";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MessageModal from "components/MessageModal";
import EventRosterAndTime from "layouts/pages/events/components/EventRosterAndTime";
import EventsFilterCards from "layouts/pages/events/components/EventsFilterCards";
import ExportFieldsModal from "layouts/pages/events/components/EventsInformation/ExportFieldsModal";
import EventsPreviewModal from "layouts/pages/events/components/EventsPreviewModal";
import EventsTable from "layouts/pages/events/components/EventsTable";
import { checkIfApplicantVenuePending } from "utils/helpers/applicant";
import { isEmployeeUser } from "utils/helpers/roles";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import InterviewEditor from "layouts/pages/events/components/InterviewEditor";
import { useMutation, useQueryClient } from "react-query";
import InterviewsFilterCards from "./components/InterviewsFilterCards";
import InterviewPreviewModal from "./components/InterviewPreviewModal";
import InterviewApplicants from "./components/InterviewApplicants";
import NewEventInfoPanel from "./components/NewEventInfoPanel";
import EventActivities from "./components/EventActivities";
import EventContent from "./components/EventContent";
import EventAttachments from "./components/EventAtachments";
import EventAdditionalPositions from "./components/EventAdditionalPositions";
import EventSettings from "./components/EventSettings";
import EventNotes from "./components/EventNotes";
import EventGeofencing from "./components/EventGeofencing";
import EventReportData from "./components/EventReportData";
import AddANoteModal from "../applicants/components/AddANoteModal";
import fetchPartners from "../partners/actions/fetchPartners";
import fetchEventMembers from "./actions/fetchEventMembers";
import AdditionalInformation from "./components/AdditionalInformation";

function Events({ mode = "edit" }) {
  const {
    currentEvent,
    setCurrentEvent,
    currentLoggedUser,
    venues,
    setVenues,
    userType,
    isMobile,
    currentApplicant,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const { enqueueSnackbar } = useSnackbar();

  const refetchEventsFuncRef = useRef(null);

  const bindRefetchEvents = (callback) => {
    refetchEventsFuncRef.current = callback;
  };

  const refetchEvents = refetchEventsFuncRef.current;

  const isNotUser = userType !== "User";
  const isEventAdmin = currentLoggedUser?.employeeType === "Event Admin" && userType === "User";

  const eventAdminCanManageThisEvent = useMemo(() => {
    if (!isEventAdmin) return false;
    return !!currentLoggedUser?.clientOrgs?.find((org) => org.slug === currentEvent?.venueSlug);
  }, [currentEvent?.venueSlug, currentLoggedUser?.clientOrgs, isEventAdmin]);

  const [searchParams] = useSearchParams();
  const myVenues = searchParams.get("myVenues");
  const timeFrame = searchParams.get("timeFrame");
  const eventType = searchParams.get("eventType");
  const filterCardVenues = currentLoggedUser?.venues?.length
    ? currentLoggedUser.venues.join(";")
    : "";

  // Handle back button for this screen
  const {
    backListUrl,
    filterParam,
    pageParam,
    subFilterParam,
    subPageParam,
    pageFromUrl,
    filtersFromUrl,
    subPageFromUrl,
    subFiltersFromUrl,
    navigateToListWithBack,
    navigateToScreenWithBack,
  } = useHandleBackButtonMainPage({
    baseUrl: "/events",
    screenName: "Events",
  });

  // Get back button generated by other screens
  const { backParam, backToTitle, backToUrl, backButton } = useGetBackButton();

  const eventListNavigateFuncRef = useRef(null);
  const bindEventListNavigateFunc = (func) => {
    eventListNavigateFuncRef.current = func;
  };

  const queryClient = useQueryClient();
  const isEdit = !!currentEvent?._id;
  const initialCards = [
    {
      color: "info",
      icon: <EventAvailableIcon fontSize="large" color="info" />,
      title: "Event",
      tooltip: "A Regular Event",
      number: 0,
      default: company?.companyType === "Venue",
      filterField: "eventType",
      filterValue: "Event",
      canCombine: true,
    },
    {
      color: "warning",
      icon: <MeetingRoomIcon fontSize="large" color="warning" />,
      title: "Interview",
      tooltip: "Type of Event is Interview",
      number: 0,
      default: company?.companyType === "Company",
      filterField: "eventType",
      filterValue: "Interview",
      canCombine: true,
    },
    {
      color: "info",
      icon: <EventAvailableIcon fontSize="large" color="info" />,
      title: "MyEvents",
      tooltip: "Events For My Venues",
      number: 0,
      filterField: "venueSlug",
      filterValue: filterCardVenues || "N/A",
      canCombine: true,
    },
    {
      color: "success",
      icon: <BoltIcon fontSize="large" color="success" />,
      title: "Current",
      tooltip: "Events Today and Behind",
      number: 0,
      filterField: "timeFrame",
      filterValue: "Current",
      canCombine: true,
    },
    {
      color: "error",
      icon: <HistoryIcon fontSize="large" color="error" />,
      title: "Past",
      tooltip: "Events That Happened in the Past",
      number: 0,
      filterField: "timeFrame",
      filterValue: "Past",
      canCombine: true,
    },
    {
      color: "dark",
      icon: <PublicIcon fontSize="large" color="black" />,
      title: "All",
      tooltip: "All Events",
      number: 0,
      filterField: "eventType",
      filterValue: "Event;Interview",
    },
  ];

  const { eventUrl, action, venueSlug } = useParams();
  const [open, setOpen] = useState(false);
  const [eventPreview, setEventPreview] = useState(null);
  const [interviewPreview, setInterviewPreview] = useState(null);

  const [filters, setFilters] = useState(
    company?.companyType === "Company"
      ? { timeFrame: timeFrame || "Current" }
      : { eventType: eventType || "Event", timeFrame: timeFrame || "Current" }
  );
  const [actionSelected, setActionSelected] = useState(action || "");
  const [mongoEventId, setMongoEventId] = useState(null);
  const [roster, setRoster] = useState("signups");
  const [exportFieldModalOpen, setExportFieldModalOpen] = useState(false);
  const [exportRosterStatus, setExportRosterStatus] = useState(null);
  const [isMessageModalOpen, toggleMessageModal] = useState(false);
  const [applicantsData, setApplicantsData] = useState([]);
  const [rosterData, setRosterData] = useState([]);
  const [partnerRosterData, setPartnerRosterData] = useState([]);
  const [previewContent, setPreviewContent] = useState("all");
  const [selectedPanel, setSelectedPanel] = useState("activities");
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  useEffect(() => {
    if (filterParam != null) {
      if (filterParam) {
        const newFilters = generateFiltersFromString(filterParam);
        setFilters(newFilters);
      } else {
        setFilters({});
      }
    }
  }, [filterParam]);

  const navigate = useNavigate();

  const getVenueField = useCallback(
    (slug, field) => {
      if (venues && venues[slug]) return venues[slug][field];
      return null;
    },
    [venues]
  );

  const isApplicantEventVenuePending = useMemo(() => {
    const eventVenueSlug = eventPreview?.venueSlug;
    const applicantVenues = currentApplicant?.venues;
    const isPending = checkIfApplicantVenuePending(applicantVenues, eventVenueSlug);
    return isPending;
  }, [eventPreview, currentApplicant]);

  const toggleEventPreviewModal = (isOpen) => {
    if (isApplicantEventVenuePending && isEmployeeUser(userType)) {
      toggleMessageModal(true);
    } else {
      setOpen(isOpen);
    }
  };

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar("Event has been updated!", { variant: "success" });
      await queryClient.invalidateQueries("events");
    },
  });

  const saveNote = (noteRec) => {
    const newNote = {
      type: noteRec.type,
      text: noteRec.text,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };

    const allNotes = currentEvent?.notes || [];
    const notes = [...allNotes, newNote];

    const updateNewNote = async () => {
      let res;

      updateEventMutation.mutateAsync({
        venueSlug: currentEvent.venueSlug,
        eventId: currentEvent._id,
        data: { notes },
      });

      await updateEvent({ eventId: currentEvent._id, data: newNote })
        .then((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: "Notes Have Been Successfully Updated",
            status: "success",
          });
        })
        .catch((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: `Something went wrong! ${response}`,
            status: "error",
          });
        });

      return res;
    };

    return updateNewNote();
    // const newNotesArr = currentApplicant.attachments ? [...currentApplicant.attachments, newAtt] : [newAtt];
    // await updateApplicantMutation.mutateAsync({
    //     applicantId: currentApplicant._id,
    //     data: {
    //         notes: [...newNotesArr],
    //     }
    // });
  };

  const renderPanel = (selection) => {
    switch (selection) {
      case "activities":
        return <EventActivities />;
      case "additionalInfo":
        return <AdditionalInformation setToastAlert={setToastAlert} />;
      case "content":
        return (
          <EventContent
            setOpen={toggleEventPreviewModal}
            setEventPreview={setEventPreview}
            setPreviewContent={setPreviewContent}
          />
        );
      case "attachments":
        return <EventAttachments />;
      case "additionalpositions":
        return <EventAdditionalPositions />;
      case "settings":
        return <EventSettings />;
      case "notes":
        return <EventNotes setOpen={setNoteModalOpen} currentEvent={currentEvent} />;
      case "geofencing":
        return <EventGeofencing getVenueField={getVenueField} />;
      case "reportdata":
        return <EventReportData />;
      default:
        return null;
    }
  };

  useEffect(() => {
    // this check added as default added when company loaded
    if (isLoadingCompany) return;

    const defaultCardIndex = initialCards.findIndex((card) => card.default);
    let newFilters = { ...filters };
    if (venueSlug) {
      newFilters = { ...newFilters, venueSlug };
      if (eventUrl && action) newFilters = { ...newFilters, eventUrl };
    }

    if (defaultCardIndex) {
      const defaultCard = initialCards[defaultCardIndex];
      newFilters = {
        ...newFilters,
        [defaultCard?.filterField]: defaultCard?.filterValue,
      };
    }

    if (defaultCardIndex !== -1) {
      setFilters(newFilters);
    }
  }, [isLoadingCompany]);

  useEffect(() => {
    if (eventUrl && (actionSelected || action)) {
      setFilters({ eventUrl });
    }
    if (action) {
      setActionSelected(action);
    } else {
      setActionSelected("");
    }

    if (venueSlug) {
      // getSelectedEvents(null, venueSlug);
      const tempTimeFrame = filters?.timeFrame || timeFrame || "Current";
      const tempEventType = filters?.eventType || eventType || "Event";
      let filt = { eventType: tempEventType, venueSlug, timeFrame: tempTimeFrame };
      if (eventUrl && action) filt = { eventUrl };
      setFilters(filt);
    }
    // not needed anymore. if proven needed, uncomment
    // else if (eventUrl && (!currentEvent || currentEvent.eventUrl !== eventUrl)) {
    //   // getSelectedEvents(eventUrl);
    //   setFilters({ eventUrl });
    // } else {
    //   // don't filter by timeFrame if specific event
    //   const filt = eventUrl ? { eventUrl } : filters;
    //   if (eventUrl && action) filt.eventUrl = eventUrl;
    //   if (myVenues && currentLoggedUser?.venues?.length)
    //     filt.venueSlug = currentLoggedUser.venues.join(";");
    //   setFilters({ ...filt });
    // }
    if (currentEvent) {
      setMongoEventId(currentEvent._id);
    }
  }, [eventUrl, action, venueSlug]);

  // useEffect(() => {
  //   if(Object.keys(filters).length <= 0 && eventUrl) {
  //     navigate("/events")
  //   }
  // }, [eventUrl, filters])

  const setNavigateUrl = (url) => navigate(url);

  // const deleteEventHandler = async (eventId, name) => {
  //   const res = await deleteEvent(eventId);
  //   if (res?.data?.success) {
  //     setToastAlert({ isShow: true, message: `Event ${name} has been deleted`, status: "success" });
  //     return { success: true };
  //   }
  //   setToastAlert({
  //     isShow: true,
  //     message: `Something went wrong -- ${res?.data?.message}`,
  //     status: "error",
  //   });
  //   return { success: false, message: res?.data?.message };
  // };

  const fetchAllPartnersMutation = useMutation(fetchPartners);
  const fetchAllMembersMutation = useMutation(fetchEventMembers);

  // Fetch all partners for exporting
  const handleFetchAllPartners = () => {
    const allPartnerOptions = {
      fetchAll: true,
      useElemMatch: true,
      filters: { "venues.venueSlug": currentEvent?.venueSlug, "venues.status": "StaffingPool" },
    };
    return fetchAllPartnersMutation.mutateAsync(allPartnerOptions);
  };

  // Fetch all members for exporting
  const handleFetchAllMembers = () => {
    const applicantOptions = {
      fetchAll: true,
      eventUrl: currentEvent?.eventUrl,
    };
    return fetchAllMembersMutation.mutateAsync(applicantOptions);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <EventContextProvider>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {backToUrl ? (
              <Grid item xs={12} sm={12} md={12} pb={5} pt={3}>
                {backButton}
              </Grid>
            ) : (
              <>
                {isNotUser && company?.companyType === "Venue" && (
                  <EventsFilterCards
                    filters={filters}
                    setFilters={setFilters}
                    initialCards={initialCards}
                    // setPage={setPage}
                  />
                )}
                {isNotUser && company?.companyType === "Company" && (
                  <InterviewsFilterCards
                    filters={filters}
                    setFilters={setFilters}
                    // setPage={setPage}
                  />
                )}
              </>
            )}
            <EventsTable
              eventUrl={eventUrl}
              setActionSelected={setActionSelected}
              showFiltersList
              filters={filters}
              title={company?.companyType === "Venue" ? "Events" : "Interviews"}
              setFilters={setFilters}
              setNavigateUrl={setNavigateUrl}
              defaultSort="eventDate"
              // setOpen={setOpen}
              parent="Events"
              setOpen={toggleEventPreviewModal}
              setEventPreview={setEventPreview}
              setInterviewPreview={setInterviewPreview}
              setPreviewContent={setPreviewContent}
              bindRefetchEvents={bindRefetchEvents}
              setToastAlert={setToastAlert}
              isInterview={company?.companyType === "Company"}
              parentPage={pageFromUrl}
              parentNavigateWithBack={navigateToListWithBack}
              bindNavigateFunc={bindEventListNavigateFunc}
            />
          </Grid>
          {backListUrl && (
            <Grid item xs={12} sm={12} md={12} mt={2}>
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  navigate(backListUrl);
                }}
              >
                Back to Event List
              </Button>
            </Grid>
          )}
          {["eventsinfo", "eventsall", ""].includes(actionSelected) &&
            company?.companyType === "Venue" &&
            (eventUrl || mode === "create") &&
            isNotUser && (
              <>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      {/* <EventInfoPanel
                        setOpen={toggleEventPreviewModal}
                        setEventPreview={setEventPreview}
                        setPreviewContent={setPreviewContent}
                      /> */}
                      <NewEventInfoPanel
                        setOpen={toggleEventPreviewModal}
                        setEventPreview={setEventPreview}
                        setPreviewContent={setPreviewContent}
                        selectedPanel={selectedPanel}
                        onPanelSelect={setSelectedPanel}
                        getVenueField={getVenueField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      {isEdit && renderPanel(selectedPanel)}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          {["eventsinfo", "eventsall", ""].includes(actionSelected) &&
            company?.companyType === "Company" &&
            (eventUrl || mode === "create") &&
            isNotUser && (
              <>
                <Grid item xs={12} sm={12}>
                  <InterviewEditor
                    setOpen={toggleEventPreviewModal}
                    setInterviewPreview={setInterviewPreview}
                    setPreviewContent={setPreviewContent}
                  />
                </Grid>
              </>
            )}
          {company?.companyType === "Company" &&
            ["eventsroster", "eventsall", ""].includes(actionSelected) &&
            eventUrl &&
            isNotUser && (
              <>
                <Grid item xs={12} sm={12}>
                  <InterviewApplicants
                    currentEvent={currentEvent}
                    showFiltersList={false}
                    fetchAll
                    showSearchBar={false}
                    staffingPoolName={currentEvent?.title}
                    setToastAlert={setToastAlert}
                    parentPage={subPageFromUrl}
                    parentNavigateWithBack={navigateToScreenWithBack}
                  />
                </Grid>
              </>
            )}
          {company?.companyType === "Venue" &&
            ["eventsroster", "eventstime", "eventsall"].includes(actionSelected) &&
            eventUrl &&
            (isNotUser || eventAdminCanManageThisEvent) && (
              <>
                <Grid item xs={12} sm={12}>
                  <EventRosterAndTime
                    id={mongoEventId}
                    roster={roster}
                    mode={mode}
                    setExportFieldModalOpen={setExportFieldModalOpen}
                    setApplicantsData={setApplicantsData}
                    setRosterData={setRosterData}
                    setPartnerRosterData={setPartnerRosterData}
                    rosterStatus={exportRosterStatus}
                    setRosterStatus={setExportRosterStatus}
                    showFiltersList={false}
                    setToastAlert={setToastAlert}
                    parentPage={subPageFromUrl}
                    parentFilters={subFiltersFromUrl}
                    parentNavigateWithBack={navigateToScreenWithBack}
                  />
                </Grid>
              </>
            )}
          {eventPreview && (
            <EventsPreviewModal
              open={open}
              setOpen={setOpen}
              eventPreview={eventPreview}
              setEventPreview={setEventPreview}
              content={previewContent}
              refetchEvents={refetchEvents}
              navigateWithBack={eventListNavigateFuncRef.current}
            />
          )}
          {interviewPreview && (
            <InterviewPreviewModal
              open={open}
              setOpen={setOpen}
              interviewPreview={interviewPreview}
              setInterviewPreview={setInterviewPreview}
              content={previewContent}
            />
          )}
          <ExportFieldsModal
            exportFieldModalOpen={exportFieldModalOpen}
            setExportFieldModalOpen={setExportFieldModalOpen}
            applicantsData={applicantsData}
            rosterData={exportRosterStatus === "Partner" ? partnerRosterData : rosterData}
            currentEvent={currentEvent}
            venues={venues}
            rosterStatus={exportRosterStatus}
            onFetchAllPartners={handleFetchAllPartners}
            onFetchAllMembers={handleFetchAllMembers}
          />
          {isMessageModalOpen && (
            <MessageModal
              message="You must be approved to work at this Venue before you can view and sign up for events. Check your email for an approval within the next 24-48 hours"
              isOpen={isMessageModalOpen}
              toggleOpen={toggleMessageModal}
            />
          )}
        </Grid>
        <AddANoteModal
          currentApplicant={currentEvent}
          open={notesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNoteModalOpen}
          saveNote={saveNote}
        />
      </EventContextProvider>
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
// Events.defaultProps = {
//   pageName: "Events",
// };

// // Typechecking props for BaseLayout
// Events.propTypes = {
//   pageName: PropTypes.string,
// };

export default Events;
