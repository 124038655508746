import { baseAxios } from "config";

export const fetchJobsCustomFields = async (jobSlug) => {
  try {
    const res = await baseAxios.get(`/jobs/${jobSlug}/custom-fields`);
    return res.data;
  } catch (err) {
    console.error(`Error fetching job custom field with ID ${jobSlug}:`, err);
    throw new Error(err.toString());
  }
};
