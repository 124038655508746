import { useEffect, useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import CustomCard from "components/CustomCard";
import TagList from "components/TagList";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import { getDirtyFields } from "utils/helpers/formHelpers";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { FolderCopy } from "@mui/icons-material";
import MDEditor from "components/MDEditor";
import MDBox from "components/MDBox";
import { Box } from "@mui/material";
import PanelActions from "components/PanelActions";
import MDTypography from "components/MDTypography";
import generateJobAIQuestions from "layouts/pages/companyjobs/actions/generateJobAIQuestions";
import generateJobAISkills from "layouts/pages/companyjobs/actions/generateJobAISkills";

const useStyle = makeStyles({
  editor: {
    height: 275,
  },
});

const JobsContent = ({ currentVenue }) => {
  const { currentJob, setCurrentJob, isMobile, company } = useAppContextController();

  const classes = useStyle();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateJobMutation } = useMutation(updateJob);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
  } = useForm();

  const { mutateAsync: generateQuestionsMutation, isLoading: isLoadingGenerateQuestion } =
    useMutation(generateJobAIQuestions);

  const { mutateAsync: generateJobAISkillsMutation, isLoading: isLoadingGenerateJobAISkills } =
    useMutation(generateJobAISkills);

  const handleUpdateJob = (values, method = "update" /* onSuccess */) => {
    const modifiedFields = getDirtyFields(values, dirtyFields);
    updateJobMutation(
      { _id: currentJob?._id, data: { ...modifiedFields } },
      {
        onSuccess: async (res) => {
          const extraAiSettings = {};
          enqueueSnackbar("Job has been updated.", { variant: "success" });

          if (
            company?.aiInterviewsAvailable === "Yes" &&
            company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
            currentVenue?.aiSettings?.aiInterviewsEnabled === "Yes" &&
            currentJob?.aiSettings?.aiInterviewsEnabled === "Yes" &&
            currentVenue?.aiSettings?.autoGenerateJobQuestions === "Yes" &&
            !currentJob?.aiSettings?.questions?.length
          ) {
            enqueueSnackbar("Generating AI questions...", { variant: "warning" });
            // Autogenerate questions
            try {
              const aiRes = await generateQuestionsMutation(
                {
                  jobSlug: currentJob.jobSlug,
                  saveToDb: true,
                  educationLevel: company?.aiSettings?.defaultEducationLevel ?? "High School",
                },
                {
                  onSuccess: async (r) => {
                    if (!extraAiSettings.aiSettings)
                      extraAiSettings.aiSettings = currentJob?.aiSettings ?? {};
                    extraAiSettings.aiSettings.questions = r.data.aiInterview.questions;
                    enqueueSnackbar("New questions have been generated.", { variant: "success" });
                  },
                  onError: (err) =>
                    enqueueSnackbar("An error has ocurred generating questions.", {
                      variant: "error",
                    }),
                }
              );
            } catch {
              //
            }
          }

          setCurrentJob({ ...currentJob, ...values, ...extraAiSettings });
          await queryClient.invalidateQueries("jobs");
          // onSuccess(method, values?.content);
        },
        onError: (err) => enqueueSnackbar(`An error occured: ${err}`, { variant: "error" }),
      }
    );
  };

  const handleGenerateSkills = async () => {
    const aiRes = await generateJobAISkillsMutation(
      {
        jobSlug: currentJob.jobSlug,
      },
      {
        onSuccess: async (r) => {
          if (r?.data?.tags) {
            setCurrentJob({ ...currentJob, tags: r?.data?.tags });
            enqueueSnackbar("New skills have been generated.", { variant: "success" });
          }
        },
        onError: (err) =>
          enqueueSnackbar("An error has ocurred generating skills.", {
            variant: "error",
          }),
      }
    );
  };

  const handleCancelChanges = () => {
    reset(currentJob);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
  };

  useEffect(() => {
    reset(currentJob);
  }, [currentJob]);

  return (
    <form onSubmit={handleSubmit(handleUpdateJob)}>
      <CustomCard
        icon={<FolderCopy color="white" />}
        cardTitle={`Job Content: ${currentJob?.name || currentJob?.title}`}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "primary")}
      >
        <Box sx={{ ...styles.container, mt: 2, height: 629 }}>
          <MDBox mb={10}>
            <Controller
              name="description"
              control={control}
              render={({ field: { value, onChange, ...props } }) => (
                <MDEditor
                  className={classes.editor}
                  theme="snow"
                  onChange={(text) => {
                    onChange(text);
                  }}
                  value={value || ""}
                  placeholder="Write Content"
                  {...props}
                />
              )}
            />
          </MDBox>
          <Box>
            <Controller
              name="tags"
              control={control}
              defaultValue={watch("tags")}
              render={({ field }) => (
                <TagList
                  {...field}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  currentComponent={currentJob}
                  label="Must-have skills"
                  onGenerateSkills={handleGenerateSkills}
                  isLoadingGenerateSkills={isLoadingGenerateJobAISkills}
                />
              )}
            />
            {errors?.invalidTag && (
              <MDTypography color="error">{errors?.invalidTag?.message}</MDTypography>
            )}
          </Box>
        </Box>
      </CustomCard>
    </form>
  );
};

export default JobsContent;
