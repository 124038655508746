import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";

const CustomMenu = ({
  anchorEl,
  handleClose,
  options,
  sx = {},
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = { vertical: "top", horizontal: "right" },
  slotProps,
  iconMargin,
  title,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{ ...sx }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={slotProps}
    >
      {!!title && (
        <MDTypography variant="h5" textAlign="center" color="info">
          {title}
        </MDTypography>
      )}
      {options.map(({ icon, text, handleClick, tooltip, disabled = false, color, selected }) => (
        <Tooltip title={tooltip} key={text?.replace(" ", "_")}>
          <span>
            <MenuItem
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
                handleClose(e);
              }}
              selected={selected}
            >
              {icon && (
                <ListItemIcon
                  key={`listItem_${text?.replace(" ", "_")}`}
                  sx={{ marginRight: iconMargin ?? "unset" }}
                >
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText
                primaryTypographyProps={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  textTransform: "capitalize",
                  color,
                }}
              >
                {text}
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      ))}
    </Menu>
  );
};

export default CustomMenu;
