import { baseAxios } from "config";

export const updateVenueCustomFields = async (venueSlug, data) => {
  try {
    const res = await baseAxios.put(`/venues/${venueSlug}/custom-fields`, data);
    return res.data;
  } catch (err) {
    console.error(`Error fetching venue custom field with ID ${venueSlug}:`, err);
    throw new Error(err.toString());
  }
};
