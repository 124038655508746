import { isNaN } from "lodash";
import moment from "moment";
import * as yup from "yup";

export const featureCustomFieldSchema = (customFields) => {
  const shape = {};

  customFields.forEach((field) => {
    if (field.isVisible === false) {
      return;
    }

    let fieldSchema;

    // Initialize fieldSchema based on dataType
    switch (field.dataType) {
      case "Date":
        fieldSchema = yup
          .mixed()
          .test(
            "is-date",
            `${field.label} must be a valid date`,
            (value) =>
              value === "" || value === null || moment(value, moment.ISO_8601, true).isValid()
          );
        break;

      case "String":
        if (field.type === "Dropdown" && Array.isArray(field.options)) {
          // Validate that the value is one of the dropdown options
          fieldSchema = yup
            .mixed()
            .test(
              "is-valid-dropdown",
              `${field.label} must be one of: ${field.options.join(", ")}`,
              (value) => value === "" || value === null || field.options.includes(value)
            );
        } else {
          // General string validation
          fieldSchema = yup.string(`${field.label} must be a string`).nullable();

          // Apply non-empty string check if required
          if (field.isRequired) {
            fieldSchema = fieldSchema.test(
              "is-non-empty",
              `${field.label} cannot be an empty string`,
              (value) => value !== ""
            );
          }
        }
        break;

      case "Number":
        fieldSchema = yup
          .mixed()
          .test(
            "is-valid-number",
            `${field.label} must be a valid number`,
            (value) => value === "" || value === null || !isNaN(Number(value))
          )
          .transform((value) => (value === "" ? null : Number(value))); // Convert empty strings to null
        break;

      case "Boolean":
        fieldSchema = yup
          .mixed()
          .test(
            "is-valid-boolean",
            `${field.label} must be a boolean value`,
            (value) => value === "" || value === null || typeof value === "boolean"
          )
          .nullable();
        break;

      default:
        fieldSchema = yup.mixed().nullable(); // Fallback for unsupported types
        break;
    }

    // Add required validation
    if (field.isRequired) {
      fieldSchema = fieldSchema
        .test(
          "is-required",
          `${field.label} is required`,
          (value) => value !== "" && value !== null && value !== undefined
        )
        .required(`${field.label} is required`);
    }

    // Add field to schema
    shape[field.field] = fieldSchema;
  });

  return yup.object().shape({
    customFields: yup.object().shape(shape), // Wrap in a customFields object for compatibility
  });
};
