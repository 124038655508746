import * as yup from "yup";
import { useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useAppContextController } from "context/AppContext";
import CustomCard from "components/CustomCard";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import { Controller, useForm } from "react-hook-form";
import { Info } from "@mui/icons-material";
import { Box, CircularProgress, Grid } from "@mui/material";
import PanelActions from "components/PanelActions";
import CustomField from "components/CustomField";
import { fetchJobsCustomFields } from "api/jobs/fetchJobCustomFields";
import { updateJobCustomFields } from "api/jobs/updateJobCustomFields";
import { getCustomFieldByFeature } from "api/customFields";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { featureCustomFieldSchema } from "data/featureCustomFieldSchema";

const AdditionalInformation = ({ setToastAlert }) => {
  const { currentJob, isMobile } = useAppContextController();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  // Fetch global custom fields
  const { data: customFieldsResponse, isLoading: isLoadingFields } = useQuery(
    ["fetchCustomFieldsByFeature", "Jobs"],
    () => getCustomFieldByFeature("Jobs"),
    {
      enabled: !!currentJob?.jobSlug,
      onError: (error) => {
        setToastAlert({
          isShow: true,
          message: `Error loading custom fields: ${error.message}`,
          status: "error",
        });
      },
    }
  );

  const customFields = useMemo(
    () => customFieldsResponse?.data || [],
    [customFieldsResponse?.data]
  );

  // Generate validation schema dynamically
  const validationSchema = useMemo(() => featureCustomFieldSchema(customFields), [customFields]);

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      customFields: {},
    },
  });

  useEffect(() => {
    if (currentJob?.jobSlug) {
      reset({ customFields: {} }); // Clear the form initially
    }
  }, [currentJob?.jobSlug, reset]);

  // Fetch job-specific custom fields
  const { data: jobCustomFieldsResponse, isLoading: isLoadingJobFields } = useQuery(
    ["fetchJobCustomFields", currentJob?.jobSlug],
    () => fetchJobsCustomFields(currentJob?.jobSlug),
    {
      enabled: !!currentJob?.jobSlug,
      onSuccess: (response) => {
        if (response.data) {
          reset({ customFields: response.data });
        }
      },
      onError: (error) => {
        setToastAlert({
          isShow: true,
          message: `Error loading job custom fields: ${error.message}`,
          status: "error",
        });
      },
    }
  );

  const jobCustomFields = jobCustomFieldsResponse?.data || {};

  // Mutation to update job custom fields
  const { mutate: updateCustomFields, isLoading: isUpdatingFields } = useMutation(
    (updatedFields) => {
      const data = { customFields: updatedFields };
      return updateJobCustomFields(currentJob?.jobSlug, data);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["fetchJobCustomFields", currentJob?.jobSlug]);
        setToastAlert({
          isShow: true,
          message: response.message || `Custom fields updated successfully`,
          status: "success",
        });
      },
      onError: (error) => {
        setToastAlert({
          isShow: true,
          message: `Error updating custom fields: ${error.message}`,
          status: "error",
        });
      },
    }
  );

  // Handle submit for updating custom fields
  const handleUpdateAdditionalInformation = (data) => {
    updateCustomFields(data.customFields); // Pass parsed fields to mutation
  };

  const handleCancelChanges = () => {
    reset({ customFields: jobCustomFieldsResponse?.data || {} });
    setToastAlert({
      isShow: true,
      message: `Changes have been abandoned!`,
      status: "success",
    });
  };

  return (
    <form onSubmit={handleSubmit(handleUpdateAdditionalInformation)}>
      <CustomCard
        icon={<Info color="white" />}
        cardTitle={`Additional Information: ${currentJob?.name || currentJob?.title}`}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "primary")}
      >
        {isLoadingFields || isLoadingJobFields ? (
          <Box display="flex" justifyContent="center" mt={3}>
            <CircularProgress color="info" />
          </Box>
        ) : (
          <Grid container spacing={4} p={3}>
            {customFields
              .sort((a, b) => a.order - b.order)
              .filter((field) => field.isVisible)
              .map((field) => (
                <Grid item xs={12} md={6} key={field.field}>
                  <CustomField
                    field={field}
                    control={control}
                    error={errors?.[`customFields.${field.field}`]?.message}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </CustomCard>
    </form>
  );
};

export default AdditionalInformation;
