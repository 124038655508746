import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Autocomplete, Box, Button, Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import FormField from "layouts/applications/wizard/components/FormField";
import useDropdowns from "hooks/useDropdowns";
import { baseAxios } from "config";
import MDEditor from "components/MDEditor";
import { useSnackbar } from "notistack";
import useSessionAuth from "hooks/useSessionAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "300px",
  },
  list: {
    width: "70%",
    height: "280px",
    overflow: "scroll",
  },
  button: {
    margin: "8px",
  },
}));

const ApplicantStageChangeModal = ({ open, setOpen, applicantId }) => {
  const sb = useSnackbar();
  const enqueueSnackbar = sb?.enqueueSnackbar || null;
  const { company, currentLoggedUser } = useAppContextController();
  const classes = useStyles();
  const [filters, setFilters] = useState({});
  const options = { fetchAll: true };
  const allOptions = { ...options, filters, order: "lastName", orderBy: "lastName" };
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [selectedLeftItem, setSelectedLeftItem] = useState(null);
  const [selectedRightItem, setSelectedRightItem] = useState(null);
  const [functionType, setFunctionType] = useState(null);
  const [editorValue, setEditorValue] = useState("");
  const { dropdowns: applicantStageFunctionTypes } = useDropdowns({
    entity: "applicantStageFunctionTypes",
  });
  const [validationError, setValidationError] = useState([]);

  useEffect(() => {
    setFilters({ spStatus: "Employee" });
  }, []);

  const filteredUsers = [];
  const { data: users } = useQuery(["users", allOptions], () => fetchUsers(allOptions));
  users?.data
    ?.filter((item) => !["Terminated", "Inactive"].includes(item.status))
    .map((user) => {
      // const obj = filteredUsers.push(`${user.firstName} ${user.lastName}`);
      filteredUsers.push({
        userId: user._id,
        applicantId: user.applicantId,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.emailAddress,
      });
      return filteredUsers;
    });

  filteredUsers.push({
    userId: "",
    applicantId: "",
    firstName: "Jobs",
    lastName: "",
    fullName: "Jobs",
    email: company?.companyEmail,
  });
  const sortUsers = (a, b) => {
    if (a.fullName > b.fullName) return 1;
    return -1;
  };
  filteredUsers.sort(sortUsers);

  const validation = () => {
    const errorArr = [];
    if (rightList?.length === 0) {
      const tempError = "noRecipient";
      errorArr.push(tempError);
    }

    if (functionType === null) {
      const tempError = "noFunctionType";
      errorArr.push(tempError);
    }

    setValidationError(errorArr);
    return errorArr;
  };

  const { logoutAuth0User } = useSessionAuth();

  const saveRecipients = async () => {
    validation();
    if (validation().length === 0) {
      setValidationError([]);

      const data = {
        function: functionType,
        message: editorValue,
        recipients: rightList,
        createAgent: currentLoggedUser?._id,
        firstName: currentLoggedUser?.firstName,
        lastName: currentLoggedUser?.lastName,
        agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
      };

      try {
        const res = await baseAxios.post(`/applicants/id/${applicantId}/stagechange`, data);

        if (res?.data?.results?.length > 0) {
          res?.data?.results?.map((result) => {
            return enqueueSnackbar(`${functionType} sent to ${result?.recipient}`, {
              variant: result?.status,
            });
          });
        }

        if (res?.status === 200 || res?.status === 201 || res?.acknowledged) {
          setFunctionType(null);
          setEditorValue("");
          setOpen(false);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  };

  useEffect(() => {
    if (validationError.length > 0) {
      validation();
    }
  }, [rightList, functionType]);

  const moveItem = (index, sourceList, targetList, direction) => {
    const source = [...sourceList];
    const target = [...targetList];
    const item = source.splice(index, 1);
    target.push(...item);
    source.sort();
    target.sort();
    if (direction === "left") {
      setLeftList(source);
      setRightList(target);
    } else {
      setRightList(source);
      setLeftList(target);
    }
  };

  const handleLeftItemClick = (index) => {
    setSelectedLeftItem(index);
    setSelectedRightItem(null);
  };

  const handleRightItemClick = (index) => {
    setSelectedRightItem(index);
    setSelectedLeftItem(null);
  };

  const handleButtonClick = (direction) => {
    if (direction === "left") {
      if (selectedLeftItem !== null) {
        moveItem(selectedLeftItem, leftList, rightList, direction);
        setSelectedLeftItem(null);
      }
    } else if (direction === "right") {
      if (selectedRightItem !== null) {
        moveItem(selectedRightItem, rightList, leftList, direction);
        setSelectedRightItem(null);
      }
    }
  };
  const handleButtonDoubleClick = (index, direction) => {
    if (direction === "left") {
      moveItem(index, leftList, rightList, direction);
      setSelectedLeftItem(null);
    } else if (direction === "right") {
      moveItem(index, rightList, leftList, direction);
      setSelectedRightItem(null);
    }
  };

  useEffect(() => {
    setRightList([]);
    const currentList = filteredUsers;
    setLeftList(currentList);
    setFunctionType(null);
    setEditorValue("");
    setValidationError([]);
  }, [users, open]);

  const header = (
    <>
      <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
        <MDTypography variant="h3">Applicant Stage Change</MDTypography>
        <MDBox display="flex" alignItems="center">
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            onClick={() => {
              setFunctionType(null);
              setEditorValue("");
              setValidationError([]);
              setOpen(false);
            }}
            sx={{ padding: "0.5rem 1rem" }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SaveIcon />}
            style={{ color: "white" }}
            sx={{ padding: "0.5rem 1rem" }}
            onClick={() => {
              saveRecipients();
            }}
          >
            Save
          </Button>
        </MDBox>
      </MDBox>
    </>
  );

  const modalBody = (
    <Box className={classes.root}>
      <MDTypography variant="h6" mb={3}>
        Add a task or message for another user here or cancel to continue
      </MDTypography>
      <Autocomplete
        options={applicantStageFunctionTypes || []}
        defaultValue={null}
        value={functionType}
        onChange={(e, v) => {
          setFunctionType(v);
        }}
        sx={{ maxHeight: "44px" }}
        renderInput={(params) => <FormField {...params} type="text" label="Select Function" />}
      />
      <MDTypography color="error">
        {validationError.includes("noFunctionType") && "You must select a function"}
      </MDTypography>
      <MDTypography mt={3} variant="h6">
        Select User(s)
      </MDTypography>
      <Grid container>
        <Grid item sm={5}>
          <List className={classes.list}>
            {leftList?.map((item, index) => (
              <ListItemButton
                key={item?.userId}
                selected={index === selectedLeftItem}
                onClick={() => handleLeftItemClick(index)}
                onDoubleClick={() => {
                  handleButtonDoubleClick(index, "left");
                }}
              >
                <ListItemText primary={item?.fullName} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item sm={2}>
          <Box display="flex" flexDirection="column">
            <Button
              className={classes.button}
              variant="contained"
              color="success"
              onClick={() => handleButtonClick("left")}
            >
              &gt;
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="error"
              onClick={() => handleButtonClick("right")}
            >
              &lt;
            </Button>
          </Box>
        </Grid>
        <Grid item sm={5}>
          <List className={classes.list}>
            {rightList?.map((item, index) => (
              <ListItemButton
                key={item?.userId}
                selected={index === selectedRightItem}
                onClick={() => handleRightItemClick(index)}
                onDoubleClick={() => {
                  handleButtonDoubleClick(index, "right");
                }}
              >
                <ListItemText primary={item?.fullName} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <MDTypography color="error">
          {validationError.includes("noRecipient") && "You must select at least one recipient"}
        </MDTypography>
      </Grid>
      <Box mt={3}>
        <MDEditor
          className={classes.editor}
          value={editorValue}
          onChange={(e) => setEditorValue(e)}
          style={{ height: "10rem" }}
        />
      </Box>
    </Box>
  );

  const buttons = <></>;

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        sx={(theme) => ({
          width: "50% !important",
          maxWidth: "70% !important",
          height: "80%",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default ApplicantStageChangeModal;
