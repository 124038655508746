import { Grid } from "@mui/material";
import AIInterviewSummarySection from "./components/AIInterviewSummarySection";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    maxWidth: 1170,
    padding: "2rem",
  },
};

const AIInterviewSummary = ({ areas }) => {
  return (
    <Grid
      container
      item
      flexDirection="column"
      overflow="auto"
      height="100%"
      flexWrap="nowrap"
      pb={2}
    >
      <AIInterviewSummarySection
        score={areas?.relevance?.score}
        notes={areas?.relevance?.notes}
        title="Relevance"
      />
      <AIInterviewSummarySection
        score={areas?.experience_skills?.score}
        notes={areas?.experience_skills?.notes}
        title="Experience Skills"
      />
      <AIInterviewSummarySection
        score={areas?.problem_solving?.score}
        notes={areas?.problem_solving?.notes}
        title="Problem Solving"
      />
      <AIInterviewSummarySection
        score={areas?.communication?.score}
        notes={areas?.communication?.notes}
        title="Communication"
      />
      <AIInterviewSummarySection
        score={areas?.situational_awareness?.score}
        notes={areas?.situational_awareness?.notes}
        title="Situational Awareness"
      />
    </Grid>
  );
};

// Setting default values for the props of CalendarDayModal
AIInterviewSummary.defaultProps = {};

// Typechecking props of the CalendarDayModal
AIInterviewSummary.propTypes = {};

export default AIInterviewSummary;
