import { baseAxios } from "config";

export const fetchVenueCustomFields = async (venueSlug) => {
  try {
    const res = await baseAxios.get(`/venues/${venueSlug}/custom-fields`);
    return res.data;
  } catch (err) {
    console.error(`Error fetching venue custom field with ID ${venueSlug}:`, err);
    throw new Error(err.toString());
  }
};
