import axios from "axios";
import { baseAxios } from "config";

// Create a new custom field
export const createCustomField = async (data) => {
  try {
    const res = await baseAxios.post(`/custom-fields`, data);
    return res.data;
  } catch (err) {
    console.error("Error creating custom field:", err);
    throw new Error(err.toString());
  }
};

// Get all custom fields
export const getCustomFields = async () => {
  try {
    const res = await baseAxios.get(`/custom-fields`);
    return res.data;
  } catch (err) {
    console.error("Error fetching custom fields:", err);
    throw new Error(err.toString());
  }
};

// Get a custom field by ID
export const getCustomFieldById = async (id) => {
  try {
    const res = await baseAxios.get(`/custom-fields/${id}`);
    return res.data;
  } catch (err) {
    console.error(`Error fetching custom field with ID ${id}:`, err);
    throw new Error(err.toString());
  }
};

// Update a custom field by ID
export const updateCustomField = async (id, data) => {
  try {
    const res = await baseAxios.put(`/custom-fields/${id}`, data);
    return res.data;
  } catch (err) {
    console.error(`Error updating custom field with ID ${id}:`, err);
    throw new Error(err.toString());
  }
};

// Delete a custom field by ID
export const deleteCustomField = async (id) => {
  try {
    const res = await baseAxios.delete(`/custom-fields/${id}`);
    return res.data;
  } catch (err) {
    console.error(`Error deleting custom field with ID ${id}:`, err);
    throw new Error(err.toString());
  }
};

// Reorder custom fields
export const reorderCustomFields = async ({ feature, reorderedData }) => {
  try {
    const res = await baseAxios.put("/custom-fields/reorder", {
      feature,
      reorderedData,
    });
    return res.data;
  } catch (err) {
    console.error("Error reordering custom fields:", err);
    throw err;
  }
};

// Get a custom field by feature
export const getCustomFieldByFeature = async (feature) => {
  try {
    const res = await baseAxios.get(`/custom-fields/${feature}`);
    return res.data;
  } catch (err) {
    console.error(`Error fetching custom field with ID ${feature}:`, err);
    throw new Error(err.toString());
  }
};

// Delete a custom field by ID
export const checkCustomFieldUsage = async (id) => {
  try {
    const res = await baseAxios.get(`/custom-fields/${id}/check-usage`);
    return res.data;
  } catch (err) {
    console.error(`Error check usage of custom field with ID ${id}:`, err);
    throw new Error(err.toString());
  }
};
