import React from "react";
import {
  TextField,
  Autocomplete,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextareaAutosize,
  Box,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { NumericFormat } from "react-number-format";
import CustomTextField from "components/Form/CustomTextField";
import FormField from "layouts/applications/wizard/components/FormField";
import MDTypography from "components/MDTypography";

const FieldWrapper = ({ label, error, children }) => (
  <>
    <FormControl>
      <FormLabel style={{ fontSize: 12, marginBottom: 8 }} disabled>
        {label}
      </FormLabel>
      {children}
      {error && (
        <Typography variant="caption" color="error" mt={0.5}>
          {error}
        </Typography>
      )}
    </FormControl>
  </>
);

const CustomField = ({ control, field, error }) => {
  return (
    <Controller
      name={`customFields.${field.field}`}
      control={control}
      rules={{ required: field.isRequired }}
      defaultValue={field.type === "Checkbox" ? false : ""}
      render={({ field: { onChange, value } }) => {
        switch (field.type) {
          case "Text":
            return (
              <Box>
                <FormField
                  label={field.label}
                  placeholder={field.placeholder || "Enter text"}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                />
                {error && (
                  <Typography variant="caption" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            );
          case "Price":
            return (
              <Box>
                <NumericFormat
                  label={field.label}
                  placeholder={field.placeholder || "Enter price"}
                  value={value}
                  onValueChange={(values) => onChange(values.floatValue)}
                  thousandSeparator
                  prefix="$"
                  customInput={FormField}
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                />
                {error && (
                  <Typography variant="caption" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            );
          case "Date":
            return (
              <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label={field.label}
                    value={value || null}
                    onChange={(newValue) => onChange(newValue)}
                    renderInput={(params) => (
                      <FormField {...params} InputLabelProps={{ shrink: true }} error={!!error} />
                    )}
                  />
                </LocalizationProvider>
                {error && (
                  <Typography variant="caption" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            );
          case "Dropdown":
            return (
              <Box>
                <Autocomplete
                  options={field.options || []}
                  value={value}
                  onChange={(_, newValue) => onChange(newValue)}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label={field.label}
                      placeholder={field.placeholder || "Enter text"}
                      value={value}
                      onChange={onChange}
                      InputLabelProps={{ shrink: true }}
                      error={!!error}
                    />
                  )}
                />
                {error && (
                  <Typography variant="caption" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            );
          case "Quantity":
            return (
              <Box>
                <FormField
                  type="number"
                  label={field.label}
                  placeholder={field.placeholder || "Enter quantity"}
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                />
                {error && (
                  <Typography variant="caption" color="error">
                    {error}
                  </Typography>
                )}
              </Box>
            );
          case "Checkbox":
            return (
              <FieldWrapper label={field.label} error={error}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      error={!!error}
                    />
                  }
                  label={field.label || ""}
                />
              </FieldWrapper>
            );
          case "Radio Group":
            return (
              <FieldWrapper label={field.label} error={error}>
                <RadioGroup value={value} onChange={(e) => onChange(e.target.value)}>
                  {field.options?.map((option, index) => (
                    <FormControlLabel
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
              </FieldWrapper>
            );
          case "Textarea":
            return (
              <FieldWrapper label={field.label} error={error}>
                <TextareaAutosize
                  minRows={3}
                  placeholder={field.placeholder || "Enter long text here"}
                  value={value}
                  onChange={onChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </FieldWrapper>
            );
          default:
            return null;
        }
      }}
    />
  );
};

export default CustomField;
