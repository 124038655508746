import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useVenueCache from "hooks/useVenueCache";
import useCompanyCache from "hooks/useCompanyCache";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MyEvents from "layouts/pages/dashboard/components/MyEvents";
import MyJobs from "layouts/pages/dashboard/components/MyJobs";
import MyNotifications from "layouts/pages/dashboard/components/MyNotifications";
import MyVenues from "layouts/pages/dashboard/components/MyVenues";
import MyEmployees from "./components/company/MyEmployees";
import { useAppContextController } from "../../../context/AppContext";
import CompanyMyJobs from "./components/company/CompanyMyJobs";
import MyInterviews from "./components/company/MyInterviews";
import Header from "./components/Header";
import MyActivities from "./components/MyActivities";
import MyTimeclock from "./components/MyTimeclock";
import MyCustomers from "./components/MyCustomers";
import MyTasks from "./components/MyTasks";

const defaultPanels = [0, 1, 2, 3];
const userDefaultPanels = [0, 1, 4, 6];
const clientDefaultPanels = [0, 1];

const useStyle = makeStyles({
  box: {
    overflow: "visible",
  },
});

function Dashboard() {
  // If there is no current user, let's route the user to login
  const navigate = useNavigate();
  const { user, isLoading } = useAuth0();

  const {
    currentLoggedUser,
    setCurrentLoggedUser,
    userType,
    companyType,
    isLoggedIn,
    venues,
    setVenues,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });

  const classes = useStyle();
  const [filters, setFilters] = useState("");
  const setNavigateUrl = (url) => navigate(url, { replace: true });

  // Andres, we will use companyType from the App Context.  Assume that it is companyType = "Venue"

  useEffect(() => {
    if (
      currentLoggedUser &&
      currentLoggedUser?.venues &&
      currentLoggedUser.venues?.length &&
      typeof currentLoggedUser.venues !== "string"
    )
      setFilters({ venueSlug: currentLoggedUser.venues?.join(";") });
    if (
      currentLoggedUser &&
      currentLoggedUser.clientOrgs &&
      currentLoggedUser.clientOrgs?.length &&
      typeof currentLoggedUser.clientOrgs !== "string"
    ) {
      setFilters({
        venueSlug: currentLoggedUser.clientOrgs
          .map((org) => (typeof org === "string" ? org : org.slug))
          .join(";"),
      });
    }
  }, [currentLoggedUser, currentLoggedUser?.venues, user]);

  const getPanel = useCallback(
    (panelId) => {
      switch (panelId) {
        case 0:
          return (
            <>
              {companyType === "Venue" ? (
                <MyVenues key="venue_panel" />
              ) : (
                <MyCustomers key="customer_panel" />
              )}
            </>
          );

        case 1:
          return (
            <>
              {companyType === "Venue" ? (
                <MyEvents
                  filters={filters}
                  setNavigateUrl={setNavigateUrl}
                  fetchAll
                  key="events_panel"
                />
              ) : (
                <MyInterviews key="interviews_panel" />
              )}
            </>
          );

        case 2:
          return (
            <>
              {companyType === "Venue" ? (
                <MyJobs filters={filters} fetchAll key="jobs_panel" />
              ) : (
                <CompanyMyJobs fetchAll key="companyjobs_panel" />
              )}
            </>
          );

        case 3:
          return <MyTasks currentLoggedUser={currentLoggedUser} key="tasks_panel" />;

        case 4:
          return <MyActivities filters={filters} fetchAll key="activities_panel" />;

        case 5:
          // Reports
          return null;

        case 6:
          return (
            <MyNotifications currentLoggedUser={currentLoggedUser} key="notifications_panel" />
          );

        default:
          return null;
      }
    },
    [companyType, currentLoggedUser, filters, setNavigateUrl]
  );

  const currentPanels = useMemo(() => {
    if (userType === "Client") return clientDefaultPanels;
    if (userType === "User") return userDefaultPanels;

    if (company?.dashboardPanelOrder) {
      return company?.dashboardPanelOrder
        .filter((p) => p.enabled)
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order === b.order) {
            return 0;
          }
          return 1;
        })
        .map((p) => p.id);
    }
    return defaultPanels;
  }, [company?.dashboardPanelOrder, userType]);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <MDBox mb={5}>
        <Header />
      </MDBox>
      <Grid container columnSpacing={3} rowSpacing={8}>
        {currentPanels.map((p) => (
          <Grid xs={12} laptop={6} item>
            <MDBox>{getPanel(p)}</MDBox>
          </Grid>
        ))}
        <Grid xs={12} laptop={6} item>
          <MDBox>{companyType === "Company" && userType === "User" && <MyTimeclock />}</MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Dashboard;
