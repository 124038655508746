/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useMemo, useState } from "react";

// store/api/helper
import { useAppContextController } from "context/AppContext";

// style
import { arrayMove } from "@dnd-kit/sortable";
import { AutoAwesome, Edit, PlaylistAdd } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack, Tooltip } from "@mui/material";

// components
import CircularLoader from "components/CircularLoader";
import DataListContainer from "components/DataListContainer";
import MDTypography from "components/MDTypography";

import AIQuestionsTemplateModal from "components/AIQuestionsTemplateModal";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import useDropdowns from "hooks/useDropdowns";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";

import AIQuestionsForm from "./AIQuestionsForm";
import AIQuestionsItem from "./AIQuestionsItem";
import AIQuestionsListWrapper from "./AIQuestionsListWrapper";

const { AI_EDUCATION_LEVEL } = COMPANY_ENTITIES;

const AIQuestionsPanel = ({
  questions,
  questionKey = "aiSettings.questions",
  addQuestion,
  removeQuestion,
  questionIndex,
  setQuestionIndex,
  control,
  watch,
  errors,
  setValue,
  isReadOnly,
  title,
  onAdd,
  noHeader,
  noTopPadding,
  onPressGenerate,
  isLoadingGenerateQuestion,
}) => {
  const { companyType, company } = useAppContextController();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);

  const handleAddQuestion = () => {
    addQuestion({
      text: "",
      locked: false,
    });
    setQuestionIndex(questions.length);
  };

  const handleAddFromTemplate = () => {
    setIsOpenTemplateModal(true);
  };

  const handleCloseTemplateModal = () => {
    setIsOpenTemplateModal(false);
  };

  const handleSubmitTemplateModal = (selectedQuestions) => {
    selectedQuestions.forEach((item) => {
      addQuestion({
        text: item,
        locked: false,
      });
    });
    setQuestionIndex(null);
  };

  const handleQuestion = (idx, row) => {
    setQuestionIndex(idx);
  };

  const handleEmptyQuestionReset = () => {
    setQuestionIndex(null);
  };

  const handleRemoveQuestion = (idx) => {
    removeQuestion(idx);
    if (idx === questionIndex) {
      setQuestionIndex(null);
    } else if (questionIndex != null && idx < questionIndex) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const handleToggleLocked = (idx) => {
    const currentValue = !!watch(`${questionKey}.${idx}.locked`);
    setValue(`${questionKey}.${idx}.locked`, !currentValue);
  };

  const defaultEducationLevel = company?.aiSettings?.defaultEducationLevel ?? "High School";

  const { dropdowns: educationLevels } = useDropdowns({ entity: AI_EDUCATION_LEVEL });

  const options = useMemo(
    () =>
      (educationLevels ?? []).map((option) => ({
        text: `${option}${defaultEducationLevel === option ? " (default)" : ""}`,
        color: defaultEducationLevel === option ? "#5f32a2" : undefined,
        handleClick: (e) => {
          onPressGenerate(option);
        },
      })),
    [educationLevels]
  );

  const renderQuestions = (row, idx) => (
    <AIQuestionsItem
      row={row}
      idx={idx}
      onEdit={handleQuestion}
      onRemove={handleRemoveQuestion}
      onToggleLocked={handleToggleLocked}
      readOnly={isReadOnly}
    />
  );

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = questions.findIndex((field) => field.text === active.id);
      const newIndex = questions.findIndex((field) => field.text === over.id);

      const reorderedFields = arrayMove(questions, oldIndex, newIndex);
      setValue(questionKey, reorderedFields, { shouldDirty: true });
    }
  };

  const header = useMemo(() => {
    if (noHeader || isReadOnly) return undefined;
    return (
      <>
        <Button
          disableRipple
          disabled={isLoadingGenerateQuestion}
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          {isLoadingGenerateQuestion && (
            <CircularLoader size="2rem" color="info" hasBackdrop={false} isLoading />
          )}
          <Grid container columnGap={1} alignItems="center">
            <AutoAwesome sx={{ color: "#8935f4" }} />
            <MDTypography
              component="label"
              variant="button"
              fontWeight="medium"
              color="violet"
              sx={{ fontSize: "0.8rem", color: "#8935f4", cursor: "inherit" }}
            >
              Generate Questions
            </MDTypography>
          </Grid>
        </Button>
        <Tooltip title="Add predefined questions">
          <IconButton
            color="info"
            onClick={handleAddFromTemplate}
            sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0, paddingRight: 1 }}
          >
            <PlaylistAdd />
          </IconButton>
        </Tooltip>
      </>
    );
  }, [noHeader, isReadOnly, isLoadingGenerateQuestion]);

  const addIcon = useMemo(() => {
    if (noHeader) return undefined;
    if (isReadOnly) return <Edit />;
    return undefined;
  }, [noHeader, isReadOnly]);

  return (
    <>
      <Stack spacing={1} sx={{ p: 0 }}>
        <Box pt={noHeader || noTopPadding ? 0 : 2}>
          <DataListContainer
            onAdd={onAdd ?? handleAddQuestion}
            data={questions}
            renderRow={renderQuestions}
            title={noHeader ? null : title}
            tableHeight={265}
            height={isReadOnly ? undefined : 267}
            selected={questionIndex}
            header={header}
            AddIcon={addIcon}
            noCreate={noHeader}
            ListWrapper={AIQuestionsListWrapper}
            listWrapperProps={{
              items: questions,
              onDragEnd: handleDragEnd,
            }}
            autoHeight={isReadOnly}
          />
        </Box>
        <MDTypography color="error">{(Object.values(errors ?? {})[0] ?? {}).message}</MDTypography>
        {questionIndex != null && (
          <Box>
            <AIQuestionsForm
              control={control}
              watch={watch}
              index={questionIndex}
              questionKey={questionKey}
            />
          </Box>
        )}
      </Stack>
      <CustomMenu
        title="Education Level"
        anchorEl={anchorEl}
        handleClose={(e) => {
          e?.stopPropagation();
          setAnchorEl(null);
        }}
        options={options}
        iconMargin="5px"
      />
      <AIQuestionsTemplateModal
        open={isOpenTemplateModal}
        onClose={handleCloseTemplateModal}
        onSubmit={handleSubmitTemplateModal}
      />
    </>
  );
};

export default AIQuestionsPanel;
