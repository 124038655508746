// import axios from "axios";
import { useAppContextController } from "context/AppContext";
import { baseAxios } from "config";
import { useQuery } from "react-query";
import useSessionAuth from "./useSessionAuth";
import useIsMountedReferenced from "./useIsMountedReferenced";

const useDropdowns = ({ entity, companyType, outsideMode = "", enabled = true }) => {
  const { company } = useAppContextController();
  const { logoutAuth0User } = useSessionAuth();
  const isMountedRef = useIsMountedReferenced();

  const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;
  const url = `/${outsideModeQuery}control/dropdowns/name/${entity}`;
  const { data: dropdowns, isLoading: isLoadingDropdown } = useQuery(
    ["useDropdowns", url],
    () => baseAxios.get(url),
    {
      cacheTime: 60000,
      staleTime: 30000,
      select: ({ data }) => {
        if (data) {
          const array = [...data.data.arrayValue];
          return array;
        }
        return null;
      },
      onError: (err) => {
        if (err.response && err.response.status === 404) {
          console.log("Received a 404 error for fetchDropdowns");
          // Handle 404 error as needed
        } else if (isMountedRef.current) {
          // Only log error if the component is still mounted
          console.error("error fetchDropdowns", err.message);
        }

        if (String(err).includes("401") || err?.response?.status === 401) {
          logoutAuth0User();
        }
      },
      enabled: enabled && entity && (!companyType || companyType === company?.companyType),
    }
  );

  return { dropdowns, isLoadingDropdown };
};

export default useDropdowns;
