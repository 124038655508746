import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

const horizontalMargin = 10;

const easeFunc = (x) => {
  // 0-0.7 => 0-2 stars
  // 0.7-1 => 2-5 stars
  if (x < 0.7) {
    return (x / 0.7) * 0.4;
  }
  return 0.4 + ((x - 0.7) / 0.3) ** 0.45 * 0.6;
};

const JobsStatusRankStars = (props) => {
  const { rank, color, linear, offsetTop = 0 } = props;
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const redrawStars = () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const context = canvas.getContext("2d");

    const containerWidth = container?.offsetWidth ?? 0;
    const containerHeight = container?.offsetHeight ?? 0;
    const starSize = (containerWidth - horizontalMargin * 2) / 5;

    context.clearRect(0, 0, canvas.width, canvas.height);

    function drawStar(cx, cy, spikes, outerRadius, innerRadius, percentageFilled) {
      let rot = (Math.PI / 2) * 3;
      let x = cx;
      let y = cy;
      const step = Math.PI / spikes;

      context.save();

      context.beginPath();
      context.strokeSyle = "#000";
      context.moveTo(cx, cy - outerRadius);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < spikes; i++) {
        x = cx + Math.cos(rot) * outerRadius;
        y = cy + Math.sin(rot) * outerRadius;
        context.lineTo(x, y);
        rot += step;

        x = cx + Math.cos(rot) * innerRadius;
        y = cy + Math.sin(rot) * innerRadius;
        context.lineTo(x, y);
        rot += step;
      }
      context.lineTo(cx, cy - outerRadius);
      context.closePath();
      context.lineWidth = 1;
      context.strokeStyle = color;
      context.stroke();

      context.clip();

      context.fillStyle = color;
      context.fillRect(
        horizontalMargin,
        cy - starSize / 2,
        (containerWidth - horizontalMargin * 2) * percentageFilled,
        starSize
      );

      context.restore();
    }

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < 5; index++) {
      drawStar(
        horizontalMargin + (index * starSize + starSize / 2),
        starSize / 2,
        5,
        starSize / 2,
        starSize / 2 / 2,
        linear ? rank : easeFunc(rank)
      );
    }
  };

  useEffect(redrawStars, [color, rank]);

  useEffect(() => {
    if (!containerRef.current) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      redrawStars();
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, rank]);

  return (
    <Box ref={containerRef} sx={{ height: "2rem", paddingTop: `${offsetTop}px` }}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <canvas ref={canvasRef} {...props} />
    </Box>
  );
};

export default JobsStatusRankStars;
