import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";

const fetchCurrentApplicant = async ({ outsideMode = "" }) => {
  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;

    const res = await baseAxios.get(`/${outsideModeQuery}applicants/current`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchCurrentApplicant;
