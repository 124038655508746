import { useNewApplicantContext } from "context/NewApplicantContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataListContainer from "components/DataListContainer";
import ApplicantAIInterviewModal from "components/ApplicantAIInterviewModal";
import ConfirmationModal from "components/ConfirmationModal";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { applicantSchema } from "data/applicant";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { AutoAwesome as AutoAwesomeIcon } from "@mui/icons-material";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import useSessionAuth from "hooks/useSessionAuth";
import { useNavigate } from "react-router-dom";
import ResultCard from "layouts/pages/web/components/ResultCard";
import OtherJobsItem from "../OtherJobsItem";
import ApplicationListItem from "../ApplicationListItem";

const JobApplicationAndInterview = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
    setActiveStep,
    createApplicant,
    setApplicantSteps,
    loadApplicantAction,
  } = useNewApplicantContext();

  const { venues, company, companyType } = useAppContextController();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(applicantSchema);
  const listRef = useRef(null);
  const [uniqueCompanies, setUniqueCompanies] = useState({});
  const [searchFilter, setSearchFilter] = useState(null);
  const [isAiChatbotModalOpen, setIsAiChatbotModalOpen] = useState(false);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const [page, setPage] = useState(1);
  const options = { page, limit: 5 };
  const allOptionsFiltered = {
    ...options,
    filters: {},
    venues,
    useOr: true,
  };
  const searchFilterWithDefault = useMemo(() => {
    if (searchFilter && Object.keys(searchFilter).length) {
      return searchFilter;
    }
    if (company) {
      return companyType === "Venue"
        ? { venueCity: "", venueState: "" }
        : { companyState: "", companyCity: "" };
    }
    return null;
  }, [company, companyType, searchFilter]);
  const { data: jobsFiltered, isLoading: isLoadingFiltered } = useQuery(
    ["jobresultsfiltered", { ...allOptionsFiltered, outsideMode: "public" }],
    () => fetchJobs({ ...allOptionsFiltered, outsideMode: "public" }),
    {
      refetchOnWindowFocus: false,
      enabled: searchFilterWithDefault != null,
      cacheTime: 0,
    }
  );

  const firstAvailableAIInterview = useMemo(() => {
    return (applicant?.aiInterviews ?? [])[0];
  }, [applicant]);

  const canStartAIInterview =
    !!firstAvailableAIInterview &&
    !firstAvailableAIInterview.status &&
    (applicant.applicantStatus === "New" || applicant.applicantStatus === "ATC") &&
    !(applicant.interviews ?? []).length;

  const handleOpenAiChatbotModal = () => setIsAiChatbotModalOpen(true);

  const handleCloseAiChatbotModal = () => setIsAiChatbotModalOpen(false);

  const handleAutoScreened = () => {
    setIsAiChatbotModalOpen(false);
    setTimeout(() => setIsOnboardingModalOpen(true), 500);
  };

  const allInterviews = useMemo(() => {
    const aiInterview = (applicant?.aiInterviews ?? []).find((int) => !!int.status);
    if (aiInterview) {
      return [
        ...(applicant.interviews ?? []),
        {
          venueName: aiInterview.venue,
          companyName: aiInterview.customer,
          jobName: "AI Chatbot screening",
          applyDate: aiInterview.interviewData?.interviewEndDate,
          status: aiInterview.status,
        },
      ];
    }
    return applicant.interviews;
  }, [applicant]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    trigger,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
  } = useForm({ resolver });

  const renderApplicationList = (row, idx) => (
    <ApplicationListItem
      row={row}
      idx={idx}
      parent="job"
      companyType={companyType}
      // onEdit={handleJobHistory}
      // onRemove={handleRemoveJobHistory}
    />
  );
  const renderInterviewList = (row, idx) => (
    <ApplicationListItem
      row={row}
      idx={idx}
      parent="interview"
      companyType={companyType}
      // onEdit={handleJobHistory}
      // onRemove={handleRemoveJobHistory}
    />
  );

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: false,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  const handleAddInterviewMessage = (messageData, isLast) => {
    const newApplicant = {
      ...applicant,
    };
    const firstAvailableIndex = (newApplicant?.aiInterviews ?? []).findIndex((int) => !int.status);
    if (firstAvailableIndex >= 0) {
      if (newApplicant.aiInterviews[firstAvailableIndex].interviewData == null)
        newApplicant.aiInterviews[firstAvailableIndex].interviewData = {
          screeningDecision: null,
          screeningDecisionUserId: null,
          interviewStartDate: new Date().toISOString(),
          interviewEndDate: null,
          score: null,
          questionsAndAnswers: [],
        };
      newApplicant.aiInterviews[firstAvailableIndex].interviewData.questionsAndAnswers = [
        ...(newApplicant.aiInterviews[firstAvailableIndex].interviewData?.questionsAndAnswers ??
          []),
        messageData,
      ];
      if (isLast) {
        newApplicant.aiInterviews[firstAvailableIndex].interviewData.interviewEndDate =
          new Date().toISOString();
        newApplicant.aiInterviews[firstAvailableIndex].status = "Pending";
      }
    }
    createApplicant(newApplicant);
  };

  const handlePressUpdateInfo = () => {
    handleCloseAiChatbotModal();
    setActiveStep(1);
  };

  const handlePressBackToHome = () => {
    handleCloseAiChatbotModal();
    navigate("/");
  };

  const cancelOnboarding = () => {
    setIsOnboardingModalOpen(false);
    handlePressBackToHome();
  };

  const startOnboarding = () => {
    setIsOnboardingModalOpen(false);
    handleCloseAiChatbotModal();
    setApplicantSteps(null, "Screened", false, true);
    loadApplicantAction({ ...applicant, applicantStatus: "Screened" });
  };

  return (
    <>
      <DataListContainer
        noCreate
        data={applicant?.jobs}
        renderRow={renderApplicationList}
        title="Application List"
      />
      {canStartAIInterview ? (
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", mt: 1, mb: "5px" }}
          >
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ fontSize: "0.74rem" }}
            >
              Interview List
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              height: "157px",
              width: "100%",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="button"
              variant="contained"
              startIcon={<AutoAwesomeIcon />}
              style={{ backgroundColor: "#8935f4", textTransform: "none", fontSize: "0.9rem" }}
              onClick={handleOpenAiChatbotModal}
            >
              Take AI Chatbot Screening Now
            </Button>
          </MDBox>
        </>
      ) : (
        <DataListContainer
          noCreate
          data={allInterviews}
          renderRow={renderInterviewList}
          title="Interview List"
        />
      )}
      {isAiChatbotModalOpen && (
        <ApplicantAIInterviewModal
          open={isAiChatbotModalOpen}
          onClose={handleCloseAiChatbotModal}
          applicant={applicant}
          questions={firstAvailableAIInterview.questions}
          history={firstAvailableAIInterview.interviewData?.questionsAndAnswers}
          jobSlug={firstAvailableAIInterview.jobSlug}
          onAddMessage={handleAddInterviewMessage}
          interviewEndDate={firstAvailableAIInterview.interviewData?.interviewEndDate}
          onPressUpdateInfo={handlePressUpdateInfo}
          onPressBackToHome={handlePressBackToHome}
          onAutoScreened={handleAutoScreened}
          listRef={listRef}
        />
      )}
      {isOnboardingModalOpen && (
        <ConfirmationModal
          isOpen={isOnboardingModalOpen}
          onClose={() => cancelOnboarding()}
          title="Congratulations! Your pre-screening submission has been approved. Do you want to start the onboarding process now?"
          onConfirm={(e) => {
            e.stopPropagation();
            startOnboarding();
          }}
        />
      )}
    </>
  );
};

export default JobApplicationAndInterview;
