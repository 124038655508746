import { Divider, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import pxToRem from "assets/theme/functions/pxToRem";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { georgiaStateFormSchema } from "data/georgiaStateTaxForm";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import CommonTaxFields from "components/FormFields/CommonTaxFields";
import CurrencyField from "components/FormFields/CurrencyField";

const useStyle = makeStyles({
  input: {
    fontSize: `${pxToRem(16)}`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    border: "solid 1px black",
    borderRadius: "10px",
  },
  label: {
    fontSize: "1rem!important",
  },

  displayTextDiv: {
    border: "solid 1px black",
    fontSize: "1rem",
    borderRadius: "10px",
    backgroundSize: "1rem 1rem",
    backgroundColor: "rgba(0, 0, 0, 0.10)",
  },
  displayText: {
    font: "inherit",
    letterSpacing: "inherit",
    color: "currentColor",
    justifyContent: "space-between",
    boxSizing: "content-box",
    background: "none",
    height: "1.4375em",
    margin: 0,
    display: "flex",
    minWidth: 0,
    padding: "20px 12px",
    textAlign: "end",
  },
});

const GeorgiaForm = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();

  const resolver = useYupValidationResolver(georgiaStateFormSchema);
  const classes = useStyle();
  const [boxesChecked, setBoxesChecked] = useState(0);
  const [boxesCheckedx1300, setBoxesCheckedx1300] = useState(0);
  const [selfAgeOver65, setSelfAgeOver65] = useState("no");
  const [selfBlind, setSelfBlind] = useState("no");
  const [spouseAgeOver65, setSpouseAgeOver65] = useState("no");
  const [spouseBlind, setSpouseBlind] = useState("no");
  const [pointC, setPointC] = useState(0);
  const [pointE, setPointE] = useState(0);
  const [pointG, setPointG] = useState(0);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful, isValid },
    reset,
  } = useForm({ resolver, mode: "onBlur" });

  const handleFilingStatusChange = (e) => {
    setValue("filingStatus", e.target.id, { shouldDirty: true });
  };

  const onSubmit = async (data) => {
    const parsedData = {
      stateTaxForm: { state: "georgia", ...data },
    };
    updateApplicantAction(applicant._id, parsedData);
    setTimeout(
      () =>
        document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
      1000
    );
  };

  useEffect(() => {
    const boxes = [selfAgeOver65, selfBlind, spouseAgeOver65, spouseBlind];
    setBoxesChecked(boxes.filter((value) => value === "Yes").length);
    setBoxesCheckedx1300(boxes.filter((value) => value === "Yes").length * 1300);
  }, [selfAgeOver65, selfBlind, spouseAgeOver65, spouseBlind]);

  const [canContinue, setCanContinue] = useState(false);
  const renderKey = useRef(0); // Used to prevent race condition when running useEffect with promises

  useEffect(() => {
    const currentRenderKey = Math.round(Math.random() * 10000);
    renderKey.current = currentRenderKey;
    if (applicant && applicant.stateTaxForm) {
      georgiaStateFormSchema
        .validate(applicant.stateTaxForm)
        .then(() => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(true);
          }
        })
        .catch((error) => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(false);
          }
        });
    } else if (renderKey.current === currentRenderKey) {
      setCanContinue(false);
    }
  }, [applicant]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      next: {
        show: true,
        disabled: !canContinue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canContinue]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: !canContinue,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    const value =
      Number(watch("federalEstimatedDeductions")) - Number(watch("georgiaStandardDeduction"));
    if (value > 0) {
      setPointC(value);
    } else {
      setPointC(0);
    }
  }, [watch("federalEstimatedDeductions"), watch("georgiaStandardDeduction")]);

  useEffect(() => {
    setPointE(
      Number(watch("allowableDeductionsToFederalAdjustedGrossIncome") || 0) +
        boxesCheckedx1300 +
        pointC
    );
  }, [watch("allowableDeductionsToFederalAdjustedGrossIncome"), boxesCheckedx1300, pointC]);

  useEffect(() => {
    const value = pointE - Number(watch("estimateOfTaxableIncomeNoWithholding"));

    if (value > 0) {
      setPointG(value);

      setValue("additionalAllowances", parseFloat(value / 3000).toFixed(2));
    } else {
      setPointG(0);
    }
  }, [watch("estimateOfTaxableIncomeNoWithholding"), pointE]);

  useEffect(() => {
    if (applicant?.stateTaxForm) {
      reset(applicant.stateTaxForm);
    }
  }, [applicant]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <CommonTaxFields applicant={applicant} selectedState="GA" />
        <Grid item>
          <MDTypography variant="h6">3. Marital Status</MDTypography>
          <Controller
            name="filingStatus"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="Single"
                        checked={field.value === "Single"}
                        onChange={(e, v) => handleFilingStatusChange(e)}
                      />
                      <MDTypography variant="body2"> A.Single</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="MarriedFilingJointlyBothSpouses"
                        checked={field.value === "MarriedFilingJointlyBothSpouses"}
                        onChange={(e, v) => handleFilingStatusChange(e)}
                      />
                      <MDTypography variant="body2">
                        B. Married Filing Joint, both spouses working
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="MarriedFilingJointlyOneSpouse"
                        checked={field.value === "MarriedFilingJointlyOneSpouse"}
                        onChange={(e, v) => handleFilingStatusChange(e)}
                      />
                      <MDTypography variant="body2">
                        C. Married Filing Joint, one spouse working
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="MarriedFilingJointlySeparate"
                        checked={field.value === "MarriedFilingJointlySeparate"}
                        onChange={(e, v) => handleFilingStatusChange(e)}
                      />
                      <MDTypography variant="body2">D. Married Filing Separate</MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="headOfHousehold"
                        checked={field.value === "headOfHousehold"}
                        onChange={(e, v) => handleFilingStatusChange(e)}
                      />
                      <MDTypography variant="body2">E. Head of Household</MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          />
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={8}>
              <MDTypography variant="h6">4. Dependent Allowances</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="dependentAllowances"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDTypography variant="h6">
                5. Additional Allowances (worksheet below must be completed)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyField label="" disabled value={watch("additionalAllowances")} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDTypography variant="h6">4. Additional Withholding</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="additionalWithholding"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              backgroundImage: "none !important",
              borderWidth: "1px",
              borderColor: "#00000066",
              my: "24px",
            }}
          />
          <MDTypography variant="h5" textAlign="center">
            Worksheet for Calculating Additional Allowances{" "}
          </MDTypography>
          <MDTypography variant="h6">
            1. Complete this line only if using standard deduction
          </MDTypography>

          <Grid container direction="row" spacing={2} alignItems="center" mb={2}>
            <Grid item xs={12} sm={4}>
              <MDTypography variant="body2">Yourself:</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" alignItems="center">
              <Checkbox
                id="selfAgeOver65"
                value={selfAgeOver65}
                onChange={(e) => {
                  setSelfAgeOver65(e.target.checked ? "Yes" : "No");
                  setValue("selfAgeOver65", e.target.checked ? "Yes" : "No", { shouldDirty: true });
                }}
              />
              <MDTypography variant="body2">Age 65 or over</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" alignItems="center">
              <Checkbox
                id="selfBlind"
                value={selfBlind}
                onChange={(e) => {
                  setSelfBlind(e.target.checked ? "Yes" : "No");
                  setValue("selfBlind", e.target.checked ? "Yes" : "No", { shouldDirty: true });
                }}
              />
              <MDTypography variant="body2">Blind</MDTypography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <MDTypography variant="body2">Spouse:</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" alignItems="center">
              <Checkbox
                id="spouseAgeOver65"
                value={spouseAgeOver65}
                onChange={(e) => {
                  setSpouseAgeOver65(e.target.checked ? "Yes" : "No");
                  setValue("spouseAgeOver65", e.target.checked ? "Yes" : "No", {
                    shouldDirty: true,
                  });
                }}
              />
              <MDTypography variant="body2">Age 65 or over</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" alignItems="center">
              <Checkbox
                id="spouseBlind"
                value={spouseBlind}
                onChange={(e) => {
                  setSpouseBlind(e.target.checked ? "Yes" : "No");
                  setValue("spouseBlind", e.target.checked ? "Yes" : "No", { shouldDirty: true });
                }}
              />
              <MDTypography variant="body2">Blind</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <CurrencyField label="" disabled value={boxesChecked} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyField label="" disabled value={boxesCheckedx1300} />
            </Grid>
          </Grid>

          <MDTypography variant="h6" sx={{ mt: 3 }}>
            2. Additional Allowances for Deductions
          </MDTypography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                A. Federal Estimated Itemized Deductions (if Itemizing Deductions)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="federalEstimatedDeductions"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                B. Georgia Standard Deduction (enter one): Single/Head of Household $4,600
              </MDTypography>
              <MDTypography variant="body2">Each Spouse $3,000</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="georgiaStandardDeduction"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                C. Subtract line B from Line A (if zero or less, enter zero)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyField label="" disabled value={pointC} />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                D. Allowable Deductions to Federal Adjusted Gross Income
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="allowableDeductionsToFederalAdjustedGrossIncome"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">E. Add the amounts on lines 1, 2c, and 2d</MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyField label="" disabled value={pointE} />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                F. Estimate of Taxable Income not Subject to Withholding
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="estimateOfTaxableIncomeNoWithholding"
                control={control}
                render={({ field }) => (
                  <CurrencyField label="" value={field.value} onChange={field.onChange} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                G. Subtract Line F from Line E (if zero or less, stop here)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyField label="" disabled value={pointG} />
            </Grid>
            <Grid item xs={12} sm={8} mt={2}>
              <MDTypography variant="body2">
                H. Divide the Amount on Line G by $3,000. Enter total here and on Line 5 Above
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyField label="" disabled value={watch("additionalAllowances")} />
            </Grid>
          </Grid>
          <MDTypography variant="body2" mt={2} textAlign="center">
            (This is the maximum number of additional allowances you can claim. If the remainder is
            over $1500 round up)
          </MDTypography>
        </Grid>
      </Grid>
      <FormErrors errors={errors} />
    </form>
  );
};

export default GeorgiaForm;
