import { Button, Tooltip } from "@mui/material";
import {
  AssignmentOutlined,
  Block,
  Cancel,
  CheckCircle,
  DoNotDisturbAlt,
  Gavel,
  Help,
  HourglassEmpty,
  HourglassFull,
  HourglassTop,
  ReportProblemOutlined,
  Sync,
  ThumbUp,
} from "@mui/icons-material";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";

export const getIconByStatus = (filterValue) => {
  const icons = {
    queued: <HourglassEmpty />,
    info_requested: <AssignmentOutlined />,
    pending: <HourglassTop />,
    processing: <HourglassFull />,
    consider: <Gavel />,
    pre_adverse: <ReportProblemOutlined />,
    final_adverse: <DoNotDisturbAlt />,
    dispute: <Block />,
    canceled: <Cancel />,
    clear: <CheckCircle />,
    proceed: <ThumbUp />,
  };

  return icons[filterValue] || <Help color="info" />;
};

const getButtonColor = (company, status) => {
  if (company?.backgroundCheckInfo?.enabled !== "Yes") {
    return "secondary";
  }
  switch (status) {
    case "queued":
      return "violet";
    case "info_requested":
      return "violet";
    case "pending":
    case "processing":
    case "consider":
    case "pre_adverse":
    case "dispute":
      return "warning";
    case "final_adverse":
    case "canceled":
      return "error";
    case "clear":
    case "proceed":
      return "success";
    default:
      return undefined;
  }
};

// Helper function to get the tooltip message
const getTooltipMessage = (company, yardstik) => {
  if (company?.backgroundCheckInfo?.enabled !== "Yes") return "Yardstik BG check is disabled";
  if (!yardstik || !yardstik.yardstikId) return "Check Background with Yardstik";
  return (
    yardstik?.report?.status?.replace(/_/g, " ")?.replace(/\b\w/g, (char) => char.toUpperCase()) ??
    ""
  );
};

const YardstikBGCheckButton = ({ setIsOpenBGCheckSelectionModal, isLoading }) => {
  const { currentApplicant, setCurrentApplicant, currentLoggedUser, userType, company } =
    useAppContextController();
  const { enqueueSnackbar } = useSnackbar();

  // helm data receiving here is modified from what was received from API
  const buttonColor = getButtonColor(company, currentApplicant?.yardstik?.report?.status);

  const createBGReport = async () => {
    if (company?.backgroundCheckInfo?.enabled !== "Yes") {
      enqueueSnackbar("Yardstik BG check is disabled", { variant: "error" });
      return;
    }
    if (currentApplicant?.yardstik?.yardstikId) {
      enqueueSnackbar("Yardstik BG check already exists", { variant: "info" });
      return;
    }
    setIsOpenBGCheckSelectionModal(true);
  };

  return (
    <Tooltip title={getTooltipMessage(company, currentApplicant?.yardstik)}>
      <Button
        variant="contained"
        size="small"
        disabled={isLoading}
        {...(buttonColor && { color: buttonColor })}
        startIcon={
          currentApplicant?.yardstik?.report?.status ? (
            getIconByStatus(currentApplicant?.yardstik?.report?.status)
          ) : (
            <Sync />
          )
        }
        onClick={() => createBGReport()}
      >
        BG
      </Button>
    </Tooltip>
  );
};

export default YardstikBGCheckButton;
