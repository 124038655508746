/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppContextController } from "context/AppContext";
import { useEffect, useState } from "react";
import useDropdowns from "hooks/useDropdowns";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { imageUrlFixer } from "utils/helpers/venues";

// @mui material components
import {
  Autocomplete,
  Grid,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  Icon,
  Stack,
  Switch,
  Box,
} from "@mui/material";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React components
import {
  Cancel as CancelIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Timeline,
  FmdGood,
  ContactPage,
  Email,
  TrendingUp,
  Image,
  FolderCopy,
  Settings,
  Language,
  Try,
  Info,
} from "@mui/icons-material";
import ConfirmDialog from "components/ConfirmDialog";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomCard from "components/CustomCard";

import { makeStyles } from "@mui/styles";
import { customPanelCard, cardStyles } from "layouts/pages/venues/styles";

import TagList from "components/TagList";
import FormField from "layouts/applications/wizard/components/FormField";

import createVenue from "layouts/pages/venues/actions/createVenue";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import {
  getDirtyFields,
  integratePrimaryToContacts,
  integratePrimaryToLocations,
  formatSlug,
  formatTitle,
} from "utils/helpers/formHelpers";

import useVenueCache from "hooks/useVenueCache";
import PhotoDropzone from "layouts/pages/venues/components/VenueMainInfoPanel/PhotoDropzone";
import { VENUE_DEFAULT_SETTINGS } from "utils/constants/venues";
import { isEmpty, isEqual } from "lodash";
import { PatternFormat } from "react-number-format";
import generateVenueAIQuestions from "../../actions/generateVenueAIQuestions";

const STATES_ENTITY_NAME = "stateCodes";
const STATUSES_ENTITY_NAME = "statuses";

const locFields = ["address", "city", "state", "zip"];

const useStyle = makeStyles((theme) => ({
  logoImage: {
    objectFit: "contain",
  },
  editor: {
    height: 113,
    [theme.breakpoints?.down("xxl")]: {
      height: 50,
    },
  },
  previewButton: {
    padding: 0,
  },
  dropzone: {
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
  },
  blockedDropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 122,
    maxHeight: 122,
    height: "100%",
    backgroundColor: "#d3d3d3",
  },
  text: {
    fontSize: "0.75rem!important",
  },
  error: {
    fontSize: "0.75rem",
  },
}));

function VenueMainInfoPanel({ mode = "edit", setRefreshVenues, selectedPanel, onPanelSelect }) {
  const {
    venues,
    setVenues,
    currentVenue,
    setCurrentVenue,
    currentLoggedUser,
    company,
    userType,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    isMobile,
  } = useAppContextController();

  const IMAGE_SERVER = company?.imageUrl;

  const { dropdowns: states } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const { dropdowns: statuses } = useDropdowns({ entity: STATUSES_ENTITY_NAME });

  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const classes = useStyle();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [logoNotUploaded, setLogoNotUploaded] = useState(true);
  const [slugDisable, setSlugDisable] = useState(false);

  const [isModified, setIsModified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState();
  const [bannerAnchorRef, setBannerAnchorRef] = useState();
  const [geocoder, setGeocoder] = useState(null);

  const geocoding = useMapsLibrary("geocoding");

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({ mode: "onBlur" });

  const watchStatus = watch("status");
  const isEdit = !!currentVenue?._id;

  const defaultValues = {
    name: "",
    contact: "",
    phone: "",
    phoneExt: "",
    title: "",
    email: "",
    address: "",
    city: "",
    state: null,
    zip: "",
    location: {
      coordinates: [0, 0],
      geoFenceRadius: 0,
      type: "Point",
    },
    slug: "",
    venueHelmID: "",
    venueUrl: "",
    status: "Active",
    description: "",
    stadiumPeopleInfo: "",
    venueContact1: null,
    venueContact2: null,
    payrollDeptContact: null,
    locations: [],
    tags: [],
  };

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => onPanelSelect(panel));
    } else {
      onPanelSelect(panel);
    }
  };

  const panelOptions = [
    {
      title: "Activities",
      icon: <Timeline />,
      hasShadow: selectedPanel === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handlePanelChange("activities"),
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: selectedPanel === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handlePanelChange("additionalInfo"),
    },
    {
      title: "Locations",
      icon: <FmdGood />,
      hasShadow: selectedPanel === "locations" ? "emd" : "",
      color: "success",
      ariaLabel: "locations",
      fn: () => handlePanelChange("locations"),
    },
    {
      title: "Contacts",
      icon: <ContactPage />,
      hasShadow: selectedPanel === "contacts" ? "emd" : "",
      color: "warning",
      ariaLabel: "contacts",
      fn: () => handlePanelChange("contacts"),
    },
    {
      title: "Messaging",
      icon: <Email />,
      hasShadow: selectedPanel === "message" ? "emd" : "",
      color: "error",
      ariaLabel: "message",
      fn: () => handlePanelChange("message"),
    },
    {
      title: "Sales",
      icon: <TrendingUp />,
      hasShadow: selectedPanel === "sales" ? "emd" : "",
      color: "success",
      ariaLabel: "sales",
      fn: () => handlePanelChange("sales"),
    },
    {
      title: "Media Gallery",
      icon: <Image />,
      hasShadow: selectedPanel === "media" ? "emd" : "",
      color: "",
      ariaLabel: "media gallery",
      fn: () => handlePanelChange("media"),
    },
    {
      title: "Custom Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "customcontent" ? "emd" : "",
      color: "primary",
      ariaLabel: "custom content",
      fn: () => handlePanelChange("customcontent"),
    },
    {
      title: "Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "settings",
      fn: () => handlePanelChange("settings"),
    },
    ...(company?.aiInterviewsAvailable === "Yes"
      ? [
          {
            title: "AI Settings",
            icon: <Try />,
            hasShadow: selectedPanel === "aiSettings" ? "emd" : "",
            color: "violet",
            ariaLabel: "ai settings",
            fn: () => handlePanelChange("aiSettings"),
          },
        ]
      : []),
  ];
  // Input Refs
  const name = {
    ...register("name", {
      required: "Venue name is required",
      validate: {
        // eslint-disable-next-line
        valid: (value) =>
          (value && /^[a-zA-Z0-9\s-]+$/.test(value)) ||
          "Only letters, numbers, spaces, and hyphens are allowed",
      },
    }),
  };
  const contact = register("contact");

  const phoneExt = {
    ...register("phoneExt", {
      pattern: {
        value: /^[0-9]{1,6}$/,
        message: "Phone extension can be up to 6 digits",
      },
    }),
  };
  const phone = {
    ...register("phone", {
      pattern: {
        value:
          /^(?=.*[0-9])((\+\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4})(?:\s?(?:#|x\.?|ext\.?|extension)[\s.-]?\d{4})?$/i,
        message: "Your phone is invalid",
      },
    }),
  };
  const title = register("title");
  const email = {
    ...register("email", {
      pattern: {
        // eslint-disable-next-line
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Your email address is invalid",
      },
    }),
  };
  const address = register("address");
  const city = { ...register("city", { required: "City is required" }) };
  const state = {
    ...register("state", {
      required: "State is required",
      validate: {
        exist: (value) => states?.includes(value) || "Insert a valid state",
      },
    }),
  };
  const zip = {
    ...register("zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Zip code is invalid" },
    }),
  };
  // const coordinatesLongitude = register("location.coordinates.0");
  // const coordinatesLatitude = register("location.coordinates.1");
  // const geoFenceRadius = register("location.geoFenceRadius");
  // const type = register("location.type");
  const displayVenueEvents = register("displayVenueEvents");

  const slug = {
    ...register("slug", {
      required: "Id is required",
      // eslint-disable-next-line
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      // pattern: { value: /^[a-z][a-z0-9]+$/, message: "Only lowercase letters are allowed" },
      validate: {
        // eslint-disable-next-line
        lowerCase: (value) =>
          (value && /^[a-z0-9 -]+$/.test(value)) || "Only lowercase letters are allowed",
        // eslint-disable-next-line
        endHyphen: (value) =>
          (value && /^[a-z0-9]+(?:[ -]*[a-z0-9]+)*$/.test(value)) ||
          "trailing hyphens and spaces is not allowed",
        // eslint-disable-next-line
        startNumber: (value) =>
          (value && /^[a-z][a-z0-9]+(?:[ -]*[a-z0-9]+)*$/.test(value)) ||
          "the slug can't start with a number",
        repeated: (value) => {
          if (mode === "create") {
            return venues[value] === undefined || "Id is already in the list ";
          }
        },
      },
    }),
  };

  const venueHelmID = register("venueHelmID");
  const status = { ...register("status", { required: "Status is required" }) };
  const venueUrl = {
    ...register("venueUrl", {
      required: "Venue url is required",
      pattern: {
        value:
          // eslint-disable-next-line
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        message: "Your url is invalid",
      },
    }),
  };

  const tags = register("tags");

  const navigate = useNavigate();

  const geocode = (request) => {
    return geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        return results;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(`Geocode error: ${e}`);
      });
  };

  const handleGetCoordinates = async ({ address: _a, city: _c, state: _s, zip: _z }) => {
    let coordinates;

    if (_a && _c && _s && _z) {
      const fullAddress = [_a, _c, _s, _z].toString();
      const res = await geocode({ address: fullAddress });
      const loc = res?.[0]?.geometry?.location;
      coordinates = [loc?.lng(), loc?.lat()];
    }

    return coordinates;
  };

  const handleGetNewLoc = (_slug, coordinates) => {
    const locationName = "Primary Location";
    const geocoordinates = {
      type: "Point",
      geoFenceRadius: 100,
      coordinates: coordinates || [0, 0],
    };

    return {
      locationName,
      primaryLocation: "Yes",
      locationSlug: formatSlug(slug || "", locationName),
      geocoordinates,
    };
  };

  const queryClient = useQueryClient();

  const { mutateAsync: generateQuestionsMutation, isLoading: isLoadingGenerateQuestion } =
    useMutation(generateVenueAIQuestions);

  const { mutate, mutateAsync, isLoading } = useMutation(createVenue, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, {
        variant: "error",
        autoHideDuration: 3000,
      }),
    onSuccess: async (_, data) => {
      const extraAiSettings = {};
      enqueueSnackbar("Venue has been created!", { variant: "success" });

      if (
        company?.aiInterviewsAvailable === "Yes" &&
        company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
        company?.aiSettings?.autoGenerateCustomerQuestions === "Yes" &&
        data?.data?.aiSettings?.aiInterviewsEnabled === "Yes" &&
        (data?.data?.venueUrl || data?.data?.description)
      ) {
        enqueueSnackbar("Generating AI questions...", { variant: "warning" });
        // Autogenerate questions
        try {
          const aiRes = await generateQuestionsMutation(
            {
              venueSlug: data?.data?.slug,
              saveToDb: true,
              educationLevel: company?.aiSettings?.defaultEducationLevel ?? "High School",
            },
            {
              onSuccess: async (r) => {
                if (!extraAiSettings.aiSettings)
                  extraAiSettings.aiSettings = data?.data?.aiSettings ?? {};
                extraAiSettings.aiSettings.questions = r.data.aiInterview.questions;
                enqueueSnackbar("New questions have been generated.", { variant: "success" });
              },
              onError: (err) =>
                enqueueSnackbar("An error has ocurred generating questions.", {
                  variant: "error",
                }),
            }
          );
        } catch {
          //
        }
      }

      await queryClient.invalidateQueries("venues");
      setRefreshVenues((prev) => !prev);
      refetchVenues();
      setCurrentVenue({ ...currentVenue, ...data.data, ...extraAiSettings });
      navigate(`/venues/${data.data?.slug}`);
    },
  });

  const updateVenueMutation = useMutation(updateVenue, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, {
        variant: "error",
        autoHideDuration: 3000,
      }),
    onSuccess: async (_, { data }) => {
      const extraAiSettings = {};
      enqueueSnackbar("Venue has been updated!", { variant: "success" });

      if (
        company?.aiInterviewsAvailable === "Yes" &&
        company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
        company?.aiSettings?.autoGenerateCustomerQuestions === "Yes" &&
        currentVenue?.aiSettings?.aiInterviewsEnabled === "Yes" &&
        (currentVenue?.venueUrl || currentVenue?.description) &&
        !currentVenue?.aiSettings?.questions?.length
      ) {
        enqueueSnackbar("Generating AI questions...", { variant: "warning" });
        // Autogenerate questions
        try {
          const aiRes = await generateQuestionsMutation(
            {
              venueSlug: currentVenue?.slug,
              saveToDb: true,
              educationLevel: company?.aiSettings?.defaultEducationLevel ?? "High School",
            },
            {
              onSuccess: async (r) => {
                if (!extraAiSettings.aiSettings)
                  extraAiSettings.aiSettings = currentVenue?.aiSettings ?? {};
                extraAiSettings.aiSettings.questions = r.data.aiInterview.questions;
                enqueueSnackbar("New questions have been generated.", { variant: "success" });
              },
              onError: (err) =>
                enqueueSnackbar("An error has ocurred generating questions.", {
                  variant: "error",
                }),
            }
          );
        } catch {
          //
        }
      }

      setRefreshVenues((prev) => !prev);
      await queryClient.invalidateQueries("venues");
      refetchVenues();

      const newData = imageUrlFixer(data, currentVenue, IMAGE_SERVER);
      setCurrentVenue({ ...currentVenue, ...newData, ...extraAiSettings });
    },
  });

  const createVenueHandler = async (values) => {
    // if (evaluate()) {
    const {
      contact: _contact,
      email: _email,
      phone: _phone,
      phoneExt: _phoneExt,
      address: _address,
      city: _city,
      state: _state,
      zip: _zip,
      venueSlug: _venueSlug,
    } = values;

    const contactArr = _contact?.split(" ");
    const firstItems = contactArr?.slice(0, -1).join(" ");
    const lastItem = contactArr?.[contactArr?.length - 1];

    const contacts = [
      ...(_contact || _email || _phone
        ? [
            {
              ...(_contact && {
                firstName: firstItems || "",
                lastName: lastItem || "",
              }),
              ...(_email && { email: _email }),
              ...(_phone && { phone: _phone }),
              ...(_phoneExt && { phoneExt: _phoneExt }),
              primaryContact: "Yes",
            },
          ]
        : []),
    ];

    const initLoc = { type: "Point", geoFenceRadius: 100, coordinates: [0, 0] };
    let location = { ...initLoc };
    let locations = [];

    if (_address || _city || _state || _zip) {
      const coordinates = (await handleGetCoordinates(values)) || [0, 0];

      const commonProps = {
        address: _address,
        city: _city,
        state: _state,
        zip: _zip,
        radius: 100,
        graceDistanceFeet: 100,
        latitude: coordinates?.[1] || 0,
        longitude: coordinates?.[0] || 0,
      };

      locations = [
        {
          ...commonProps,
          ...handleGetNewLoc(values?.slug, coordinates),
        },
      ];

      location = { ...initLoc, coordinates, ...commonProps };
    }

    const data = {
      ...values,
      settings: {},
      contacts,
      location,
      locations,
    };

    if (
      company?.aiInterviewsAvailable === "Yes" &&
      company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
      company?.aiSettings?.aiInterviewsEnabledByDefault?.includes("venues")
    ) {
      data.aiSettings = {
        aiInterviewsEnabled: "Yes",
        aiInterviewsEnabledByDefault: "",
        displayCustomerQuestions: "No",
        autoGenerateJobQuestions: "No",
        autoScreening: "No",
        questions: [],
      };
    }

    data.createAgent = currentLoggedUser._id;
    Object.keys(VENUE_DEFAULT_SETTINGS).forEach((key) => {
      data.settings[key] = VENUE_DEFAULT_SETTINGS[key];
    });
    await mutateAsync({
      data: {
        ...data,
        location: {
          coordinates: [
            Number(values.location.coordinates[0]),
            Number(values.location.coordinates[1]),
          ],
          geoFenceRadius: Number(values.location.geoFenceRadius),
          type: "Point",
        },
      },
    });
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getVenueValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentVenue?.[key] || "";
    });
    return values;
  };

  const getDefaultValues = () => {
    const defaults = { ...defaultValues, ...VENUE_DEFAULT_SETTINGS };
    Object.keys(watch()).forEach((key) => {
      setValue(key, defaults[key] || "");
    });
    return defaults;
  };

  const cancelChanges = () => {
    reset(getVenueValues());

    setIsModified(false);
    enqueueSnackbar("Changes have been abandoned", { variant: "warning" });
  };

  const saveChanges = async (values) => {
    if (!currentVenue._id) {
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
      return;
    }

    let data = getDirtyFields(values, dirtyFields);

    if (dirtyFields?.venueHelmID) {
      data.venueHelmID = data.venueHelmID?.trim();
    }

    if (dirtyFields.contact || dirtyFields.email || dirtyFields.phone) {
      const contactArr = values?.contact?.split(" ");
      const firstItems = contactArr?.slice(0, -1).join(" ");
      const lastItem = contactArr?.[contactArr?.length - 1];

      const tempContact = {
        firstName: firstItems || "",
        lastName: lastItem || "",
        email: values?.email,
        phone: values?.phone,
        phoneExt: values?.phoneExt,
        title: values?.title,
      };
      data = integratePrimaryToContacts(data, tempContact, currentVenue?.contacts);
    }

    const { address: _a, city: _c, state: _s, zip: _z } = data;

    const dirtyTest = Object.keys(dirtyFields).some((a) => locFields.includes(a));

    if (dirtyTest) {
      const commonProps = {
        ...(dirtyFields.address && { address: _a }),
        ...(dirtyFields.city && { city: _c }),
        ...(dirtyFields.state && { state: _s }),
        ...(dirtyFields.zip && { zip: _z }),
      };

      const currentLoc = {
        address: currentVenue?.address,
        city: currentVenue?.city,
        state: currentVenue?.state,
        zip: currentVenue?.zip,
        ...commonProps,
      };

      const coordinates = await handleGetCoordinates(currentLoc);

      const tempLoc = {
        ...currentLoc,
        ...(coordinates && { latitude: coordinates?.[1], longitude: coordinates?.[0] }),
      };

      const defaultNewLoc = handleGetNewLoc(currentVenue?.slug, coordinates);

      data = integratePrimaryToLocations(
        data,
        tempLoc,
        defaultNewLoc,
        currentVenue?.location,
        coordinates,
        currentVenue?.locations
      );
    }

    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data,
    });
  };

  const { mutateAsync: updateLocation } = useMutation(updateVenue);

  // not recommended to use, only temporary to fill up locations
  useEffect(() => {
    if (currentVenue && (!currentVenue?.locations || isEmpty(currentVenue?.locations))) {
      const { address: _address, city: _city, state: _state, zip: _zip } = currentVenue;
      if (_address || _city || _state || _zip) {
        const locations = [
          {
            locationName: "Primary Location",
            primaryLocation: "Yes",
            ...(_address && { address: _address }),
            ...(_city && { city: _city }),
            ...(_state && { state: _state }),
            ...(_zip && { zip: _zip }),
          },
        ];
        updateLocation(
          {
            venueId: currentVenue._id,
            data: { locations },
          },
          {
            onSuccess: async () => {
              setRefreshVenues((prev) => !prev);
              await queryClient.invalidateQueries("venues");
              refetchVenues();
              setCurrentVenue({ ...currentVenue, locations });
              reset({ ...currentVenue, locations }, { keepValues: true });
            },
          }
        );
      }
    }
  }, [currentVenue]);

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {
        settings: {},
      };

      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      Object.keys(VENUE_DEFAULT_SETTINGS).forEach((key) => {
        data.settings[key] = VENUE_DEFAULT_SETTINGS[key];
      });

      setCurrentVenue(data);
      reset(getDefaultValues());
      setSlugDisable(false);
    } else {
      setSlugDisable(true);
    }
  }, [mode]);

  useEffect(() => {
    if (mode === "edit") {
      if (currentVenue) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentVenue[key] || "");
        });
        setIsModified(false);
        if (currentVenue?.location === null || currentVenue?.location === undefined) {
          setValue("location.coordinates.0", 0);
          setValue("location.coordinates.1", 0);
          setValue("location.geoFenceRadius", 0);
        }
      }

      const subscription = watch((value) => {
        if (currentVenue) {
          setIsModified(JSON.stringify(getVenueValues()) !== JSON.stringify(value));
        }
      });

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
  }, [watch, currentVenue, confirmModal.response]);

  useEffect(() => {
    if (currentVenue) reset(getVenueValues());
  }, [currentVenue]);

  const cleaningFunc = () => {
    clearErrors("logo");
    setLogoNotUploaded(false);
    setSlugDisable(true);
  };

  const handleOpenMenu = (e) => {
    e.preventDefault();
    setAnchorRef(e.target);
    setIsOpenMenu(true);
  };

  const handleCloseMenu = (e) => {
    e.preventDefault();
    setIsOpenMenu(false);
  };

  const handleOpenBannerMenu = (e) => {
    e.preventDefault();
    setBannerAnchorRef(e.target);
    setIsBannerOpen(true);
  };

  const handleCloseBannerMenu = (e) => {
    e.preventDefault();
    setIsBannerOpen(false);
  };

  useEffect(() => {
    if (!isEdit) onPanelSelect("");
  }, [isEdit]);

  // useEffect(() => {
  //   if (watchStatus !== "Prospect" && selectedPanel === "sales") {
  //     onPanelSelect("activities");
  //   }
  // }, [watchStatus]);

  useEffect(() => {
    if (!geocoding) return;
    setGeocoder(new geocoding.Geocoder());
  }, [geocoding]);

  const icon = (
    <>
      {mode === "edit" ? (
        <>
          <Tooltip title="Click to Change Logo" placement="top">
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <img
                src={currentVenue?.logoUrl}
                width="50"
                height="50"
                className={classes.logoImage}
                alt="Venue Logo"
              />
            </IconButton>
          </Tooltip>
          <PhotoDropzone
            title="Upload Icon"
            isOpen={isOpenMenu}
            onClose={handleCloseMenu}
            anchorElem={anchorRef}
            listName="logoUrls"
            attribute="logoUrl"
            apiUrl={`/upload/${currentVenue?.slug}/venues/logo`}
          />
        </>
      ) : (
        <Icon fontSize="large">stadium</Icon>
      )}
    </>
  );

  const deleteModalHandler = () => setOpenDeleteModal(!openDeleteModal);
  const deleteVenueHandler = async () => {
    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { status: "Deleted", deleteAgent: currentLoggedUser._id },
    });
  };

  const cardActions = (
    <Box>
      {mode === "edit" && isModified && (
        <>
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            onClick={onCancel}
            disabled={isSubmitting}
            type="button"
            color="error"
            sx={{ color: "#F44335", fontSize: 14 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            endIcon={<SaveIcon />}
            disabled={isSubmitting}
            variant="text"
            color="success"
            sx={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
          >
            Save
          </Button>
        </>
      )}

      {isEdit && !isModified && isEqual(userType, "Master") && company?.primaryCompany && (
        <Button
          variant="text"
          startIcon={<DeleteIcon />}
          onClick={deleteModalHandler}
          style={{ color: "#F44335", fontSize: 14 }}
          disabled={isSubmitting}
        >
          Delete Venue
        </Button>
      )}

      {mode === "create" && (
        <Button
          type="submit"
          variant="contained"
          endIcon={<SaveIcon />}
          sx={{ color: "white" }}
          disabled={isLoading}
        >
          Create
        </Button>
      )}
    </Box>
  );

  return (
    currentVenue &&
    Object.keys(currentVenue).length && (
      <form
        onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createVenueHandler)}
      >
        <CustomCard
          key={currentVenue.id}
          icon={icon}
          cardTitle={mode === "edit" ? `Venue Information: ${currentVenue?.name}` : "Create Venue"}
          currentData={currentVenue}
          cardActions={cardActions}
          isStacked
          {...customPanelCard(isMobile, "transparent")}
        >
          <Box sx={{ ...cardStyles.container }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
              mt={1}
              mb={3}
            >
              {panelOptions.map((tab) => (
                <Tooltip title={tab?.title} key={tab?.title}>
                  <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow}>
                    <IconButton
                      color={tab.color}
                      aria-label={tab.ariaLabel}
                      onClick={tab.fn}
                      disabled={!isEdit} // || (tab.title === "Sales" && watchStatus !== "Prospect")}
                    >
                      {tab.icon}
                    </IconButton>
                  </MDBox>
                </Tooltip>
              ))}
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`name_${currentVenue?.slug}`}
                  type="text"
                  label="Name"
                  {...name}
                  onChange={(e) => {
                    setValue("name", e?.target?.value, { shouldDirty: true });
                    if (mode !== "edit")
                      setValue("slug", formatTitle(e?.target?.value)?.trim(), {
                        shouldDirty: true,
                      });
                  }}
                />
                {errors?.name && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.name?.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`venueSlug_${currentVenue?.slug}`}
                  type="text"
                  label="Venue Id"
                  {...slug}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  // disabled={slugDisable}
                />
                {errors?.slug && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.slug?.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`contactName_${currentVenue?.slug}`}
                  type="text"
                  label="Contact Name"
                  {...contact}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormField
                  key={`firstName_${currentVenue?.slug}`}
                  type="text"
                  label="First Name"
                  {...firstName}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`lastName_${currentVenue?.slug}`}
                  type="text"
                  label="Last Name"
                  {...lastName}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`helmClientId_${currentVenue?.slug}`}
                  type="text"
                  label="Helm Client Id"
                  placeholder="Helm Client Id"
                  {...venueHelmID}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`contactEmailAddress_${currentVenue?.slug}`}
                  type="text"
                  label="Contact Email Address"
                  placeholder="Contact Email Address"
                  {...email}
                  InputLabelProps={{ shrink: true }}
                />
                {errors?.email && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.email?.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`venueUrl_${currentVenue?.slug}`}
                  type="text"
                  label="Venue URL"
                  placeholder="Venue URL"
                  {...venueUrl}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip
                          title={`Visit ${watch("venueUrl")} in another tab`}
                          placement="top"
                        >
                          <IconButton
                            aria-label="open link"
                            onClick={() => window.open(`http://${watch("venueUrl")}`)}
                            edge="end"
                          >
                            <Language />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.venueUrl && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.venueUrl?.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <PatternFormat
                          {...field}
                          format="(###) ### ####"
                          key={`phone_${currentVenue?.slug}`}
                          allowEmptyFormatting
                          mask="_"
                          label="Contact Phone"
                          onValueChange={({ value }) =>
                            setValue("phone", value, { shouldDirty: true })
                          }
                          showError
                          customInput={FormField}
                        />
                      )}
                    />
                    {errors?.phone && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.phone?.message}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      key={`phoneExt_${currentVenue?.slug}`}
                      label="Phone Extension"
                      type="number"
                      {...phoneExt}
                    />
                    {errors?.phoneExt && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.phoneExt?.message}
                      </MDTypography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  key={`title_${currentVenue?.slug}`}
                  type="text"
                  label="Job Title"
                  {...title}
                  InputLabelProps={{ shrink: true }}
                />
                {errors?.title && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.title?.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  {...status}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      key={`status_autocomplete_${currentVenue?.slug}`}
                      options={statuses || []}
                      name="status"
                      required
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.innerText)}
                      renderInput={(params) => (
                        <FormField
                          key={`status_${currentVenue?.slug}`}
                          {...params}
                          type="text"
                          label="Status"
                        />
                      )}
                    />
                  )}
                />

                {errors?.status && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.status?.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormField
                          key={`address_${currentVenue?.slug}`}
                          type="text"
                          label="Address"
                          {...address}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormField
                          key={`city_${currentVenue?.slug}`}
                          type="text"
                          label="City"
                          {...city}
                        />
                        {errors?.city && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.city?.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              key={`autocomplete_state_${currentVenue?.slug}`}
                              options={states || []}
                              {...field}
                              value={field.value || ""}
                              autoSelect
                              freeSolo
                              name="state"
                              onChange={(e, v) => {
                                setValue("state", v?.toUpperCase() || "", { shouldDirty: true });
                              }}
                              onBlur={() => trigger("state")}
                              renderInput={(params) => (
                                <FormField
                                  key={`state_${currentVenue?.slug}`}
                                  {...params}
                                  type="text"
                                  label="State"
                                />
                              )}
                            />
                          )}
                        />
                        {errors?.state && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.state?.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <FormField
                          key={`zipCode_${currentVenue?.slug}`}
                          type="text"
                          label="Zip Code"
                          {...zip}
                        />
                        {errors?.zip && (
                          <MDTypography className={classes.error} color="error">
                            {errors?.zip?.message}
                          </MDTypography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Stack my={0.5} direction="row" alignItems="center">
                          <Controller
                            {...displayVenueEvents}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                          <MDTypography variant="body2">Display Venue Events</MDTypography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2} justifyContent="flex-end" alignItems="flex-start">
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="bold"
                        color="info"
                      >
                        VENUE BANNER
                      </MDTypography>

                      {getValues().slug !== "" && !errors.slug ? (
                        <>
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="350px"
                            height="175px"
                            variant="gradient"
                            bgColor={mode === "edit" ? "white" : "info"}
                            color="white"
                            borderRadius="xl"
                            mt={-2}
                            sx={{
                              "&": {
                                transition: "0.2s",
                              },
                              "&:hover": {
                                background: "#D3D3D3",
                              },
                            }}
                          >
                            {mode === "edit" ? (
                              <>
                                <Tooltip title="Click to Change Banner" placement="top">
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    aria-controls="notification-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    onClick={handleOpenBannerMenu}
                                  >
                                    {IMAGE_SERVER && currentVenue?.bannerUrl && (
                                      <img
                                        src={
                                          currentVenue?.bannerUrl?.startsWith("http")
                                            ? `${currentVenue?.bannerUrl}`
                                            : `${IMAGE_SERVER}/{currentVenue?.slug}/banner/${currentVenue?.bannerUrl}`
                                        }
                                        width="340"
                                        height="160"
                                        alt="Venue Banner"
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <PhotoDropzone
                                  title="Upload Banner"
                                  isOpen={isBannerOpen}
                                  onClose={handleCloseBannerMenu}
                                  anchorElem={bannerAnchorRef}
                                  listName="imageUrls"
                                  attribute="bannerUrl"
                                  urlPath=""
                                  imgHeight="100px"
                                  imgWidth="200px"
                                  apiUrl={`/upload/${currentVenue?.slug}/venues/banner`}
                                />
                              </>
                            ) : (
                              <Icon fontSize="large">stadium</Icon>
                            )}
                          </MDBox>
                        </>
                      ) : (
                        <MDBox p={1} className={classes.blockedDropzone} alignItems="center">
                          <Grid direction="row" alignItems="center">
                            <MDTypography className={classes.text} component="label">
                              You must set a valid venue Id before you can upload a Logo
                            </MDTypography>
                          </Grid>
                        </MDBox>
                      )}
                      {errors?.logo && (
                        <MDTypography className={classes.error} color="error">
                          {errors?.logo?.message}
                        </MDTypography>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Stack spacing={2}>
              <MDBox>
                <Controller
                  {...tags}
                  control={control}
                  defaultValue={currentVenue?.tags || []}
                  render={({ field }) => (
                    <TagList
                      {...field}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      currentComponent={currentVenue}
                    />
                  )}
                />
                {errors?.invalidTag && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.invalidTag?.message}
                  </MDTypography>
                )}
              </MDBox>
            </Stack>
            {openDeleteModal && (
              <ConfirmModal
                title="Delete Venue"
                description="Please confirm you want to DELETE this venue."
                isOpen={openDeleteModal}
                negativeBtn={{
                  label: "Cancel",
                  fn: deleteModalHandler,
                  isShown: true,
                }}
                positiveBtn={{
                  label: "Confirm",
                  fn: deleteVenueHandler,
                  isShown: true,
                }}
              />
            )}
            <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
          </Box>
        </CustomCard>
      </form>
    )
  );
}

export default VenueMainInfoPanel;
