import axios from "axios";

import { baseAxios } from "config";

const generateJobAISkills = async ({ jobSlug }) => {
  try {
    const res = await baseAxios.post(`/jobs/${jobSlug}/ai/skills/generate/`, {});
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default generateJobAISkills;
