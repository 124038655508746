// Default Form Values
export const defaultValues = {
  label: "",
  type: "",
  placeholder: "",
  isVisible: true,
  isRequired: false,
  isEditable: false,
  options: [],
};
